import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Colors from '../Theme/Colors';
import {CustomText} from '../../Components/Common';
import BackgroundImg from '../.././Assets/Images/search-result-header.jpg';
import CommonResult from '../../Components/CommonResult';
import {useLocation} from 'react-router-dom';
import {
  FIND_COMPANIES,
  FIND_LAWYERS,
  FIND_SPECIALTY_BY_ID,
} from './GraphQl/Search';
import {useLazyQuery} from '@apollo/client';
import {VARIABLES} from './SearchLawyers';
import {Alert, Loader, Pagination} from 'rsuite';
import {Account, LawyerType} from '../../Action/types';
import i18n from '../../I18next/I18n';

const SearchResult = () => {
  const [paginationCount, setPaginationCount] = useState<number | undefined>();
  const location = useLocation<{
    submittedValues: VARIABLES;
    nameSearch: string;
    type: string;
  }>();
  const {state} = location;
  const [
    findSpecialties,
    {loading: loadingSpecialtyById, data: SpecialtyById},
  ] = useLazyQuery(FIND_SPECIALTY_BY_ID);

  useEffect(() => {
    if (state?.submittedValues?.specialties) {
      findSpecialties({
        variables: {id: state?.submittedValues?.specialties},
      });
    }
  }, [findSpecialties, state?.submittedValues?.specialties]);

  const [sorted, setSorted] = useState<Account[]>([]);
  const [lawyersArray, setLawyerArray] = useState<Account[] | any | undefined>(
    [],
  );
  const [loadMore, setLoadMore] = useState<number>(1);
  const [findLawyers, {data: findLawyer, loading: loadingLawyer}] =
    useLazyQuery(FIND_LAWYERS, {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
    });
  const [findCompanies, {data: findCompany, loading: loadingCompany}] =
    useLazyQuery(FIND_COMPANIES);

  const loadPageNumber = (page_number: number) => {
    const variables: VARIABLES = {
      page_number: page_number,
    };
    if (state?.submittedValues?.country) {
      variables.country = state?.submittedValues?.country;
    }
    if (state?.submittedValues?.type) {
      variables.type = state?.submittedValues?.type;
    }
    if (state?.nameSearch) {
      if (state?.submittedValues?.name) {
        variables.name = state?.submittedValues?.name;
      }
      if (state?.submittedValues?.verified) {
        variables.verified = state?.submittedValues?.verified;
      }
    } else {
      if (state?.submittedValues?.city) {
        variables.city = state?.submittedValues?.city;
      }
      if (state?.submittedValues?.specialties) {
        if (state?.type === LawyerType.company) {
          variables.goals = state?.submittedValues?.specialties;
        } else {
          variables.specialties = state?.submittedValues?.specialties;
        }
      }
      if (state?.submittedValues?.years_of_practice) {
        variables.years_of_practice = state?.submittedValues?.years_of_practice;
      }
      if (state?.submittedValues?.rating) {
        variables.rating = Number(state?.submittedValues?.rating);
      }
      if (state?.submittedValues?.associations) {
        variables.associations = state?.submittedValues?.associations;
      }
      if (state?.submittedValues?.court_degrees) {
        variables.court_degrees = state?.submittedValues?.court_degrees;
      }
      if (state?.submittedValues?.verified) {
        variables.verified = state?.submittedValues?.verified;
      }
    }
    try {
      if (state?.type === LawyerType.company) {
        findCompanies({variables});
      } else {
        findLawyers({variables});
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong'));
    }
  };

  useEffect(() => {
    if (state?.submittedValues) {
      loadPageNumber(loadMore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.submittedValues, loadMore]);

  useEffect(() => {
    if (findLawyer) {
      let lawyers = findLawyer?.findLawyers?.lawyers;
      setSorted(lawyers);
      setLawyerArray(lawyers);
    }
    if (findCompany) {
      if (findCompany?.findCompanies?.companies?.length !== 0) {
        let companies = findCompany?.findCompanies?.companies;
        setSorted(companies);
        setLawyerArray(companies);
      }
    }
  }, [findLawyer, findCompany, state?.nameSearch]);

  const pageCount: number | undefined =
    findLawyer?.findLawyers?.page_count ||
    findCompany?.findCompanies?.page_count;

  useEffect(() => {
    pageCount && setPaginationCount(pageCount);
  }, [pageCount]);

  return (
    <SearchResultContainer>
      <Header>
        {state?.submittedValues?.specialties && !loadingSpecialtyById ? (
          <HeaderTitle weight="500">
            {state?.type === LawyerType.lawyer
              ? ` نتائج البحث عن المحامين المتخصصين في`
              : state?.type === LawyerType.judge
              ? ` نتائج البحث عن المحكمين المتخصصين في`
              : state?.type === LawyerType.shari
              ? ` نتائج البحث عن المحامين الشرعيين المتخصصين في`
              : state?.type === LawyerType.expert
              ? ` نتائج البحث عن الخبراء المتخصصين في`
              : ` نتائج البحث عن الشركات المتخصصين في`}

            <span> {SpecialtyById?.findSpecialtyById?.specialty}</span>
          </HeaderTitle>
        ) : (
          <HeaderTitle weight="500">
            {state?.type === LawyerType.lawyer
              ? ` نتائج البحث عن المحامين `
              : state?.type === LawyerType.advisor
              ? ` نتائج البحث عن المستشارين القانونيين `
              : state?.type === LawyerType.judge
              ? ` نتائج البحث عن المحكمين`
              : state?.type === LawyerType.shari
              ? ` نتائج البحث عن المحامين الشرعيين`
              : state?.type === LawyerType.expert
              ? ` نتائج البحث عن الخبراء`
              : ` نتائج البحث عن الشركات`}
          </HeaderTitle>
        )}
      </Header>
      {sorted?.length !== 0 || loadingLawyer || loadingCompany ? (
        <>
          {sorted.length !== 0 ? (
            <SearchResults>
              {lawyersArray.map((info: Account) => {
                return (
                  <CommonResult
                    lawyerData={info}
                    searchWithName={state?.nameSearch}
                    type={state?.submittedValues?.type}
                    key={info?.id}
                  />
                );
              })}
            </SearchResults>
          ) : (
            <LoadMoreWrapper>
              <Loader />
            </LoadMoreWrapper>
          )}
          <LoadMoreWrapper>
            <Pagination
              total={100}
              maxButtons={10}
              pages={paginationCount || 0}
              prev={loadMore !== 1}
              next={loadMore !== paginationCount}
              activePage={loadMore}
              onSelect={setLoadMore}
              disabled={loadingLawyer || loadingCompany}
            />
          </LoadMoreWrapper>
        </>
      ) : (
        <NoResults weight="500" size={30}>
          &nbsp; لا يوجد نتائج
        </NoResults>
      )}
    </SearchResultContainer>
  );
};

export default SearchResult;
const SearchResultContainer = styled.div`
  background-color: ${Colors.lightBlueBg};
  direction: rtl;
  height: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  div:last-of-type {
    border-bottom: 0px;
  }
`;
const Header = styled.div`
  background-image: url(${BackgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80%;
  height: 375px;
  padding: 100px 120px;
  margin-top: -120px;
  @media (max-width: 768px) {
    padding: 100px 80px;
  }
  @media (max-width: 515px) {
    padding: 100px 60px;
  }
  @media (max-width: 448px) {
    padding: 100px 25px;
  }
`;

const HeaderTitle = styled(CustomText)`
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 500;
  text-align: center;
  border-radius: 10px;
  font-size: 29px;
  margin-top: 10%;
  color: ${Colors.white};
  padding: 25px 75px;
  span {
    font-weight: 900;
  }
  @media (max-width: 906px) {
    font-size: 27px;
    padding: 25px 70px;
    margin-top: 15%;
  }
  @media (max-width: 860px) {
    padding: 25px 0px;
  }
  @media (max-width: 835px) {
    font-size: 25px;
  }
  @media (max-width: 800px) {
    margin-top: 20%;
  }
  @media (max-width: 600px) {
    margin-top: 25%;
  }
  @media (max-width: 500px) {
    margin-top: 30%;
  }
  @media (max-width: 515px) {
    font-size: 22px;
  }
  @media (max-width: 380px) {
    font-size: 19px;
    margin-top: 35%;
  }
`;

const SearchResults = styled.div`
  background-color: ${Colors.white};
  width: 80%;
  height: auto;
  margin: auto;
  margin-top: 6%;
`;
const LoadMoreWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

const NoResults = styled(CustomText)`
  text-align: center;
  margin-top: 10%;
  padding: 25px 75px;
`;
