import React, {useState} from 'react';
import styled from 'styled-components';
import Colors from '../Theme/Colors';
import {CustomButton, CustomText} from '../../Components/Common';
import noPhoto from '../../Assets/Images/noPhoto.png';
import {FaWhatsapp, FaPhoneAlt} from 'react-icons/fa';
import {MdLocationOn} from 'react-icons/md';
import {Rate, Modal, Alert, Loader, Whisper, Popover} from 'rsuite';
import SearchResultInfo from '../Search/Components/SearchResultInfo';
import SearchResultSocials from '../Search/Components/SearchResultSocials';
import {useLocation, useParams} from 'react-router-dom';
import {Account, Company, Lawyer, LawyerType, Review} from '../../Action/types';
import ayat1 from '../../Assets/Images/ayatt1.jpg';
import ayat from '../../Assets/Images/ayattt.jpg';
import {useMutation, useQuery} from '@apollo/client';
import {
  FIND_COMPANY_BY_ID,
  FIND_LAWYER_BY_ID,
  RATE_LAWYER,
} from '../Search/GraphQl/ProfilePreview';
import {UPLOAD_URI} from '../../Configs';
import i18n from '../../I18next/I18n';

interface RATING_INPUTS {
  lawyer?: string;
  company?: string;
  rating: number;
  average_rate?: number;
}
export interface QUERY_RETURN {
  findLawyerById?: Lawyer;
  findCompanyById?: Company;
}

const ProfilePreview = () => {
  const params: {id: string; userType: string} = useParams();
  const location = useLocation<{
    lawyerData: Account;
    type?: string;
  }>();
  const {state} = location;

  const {
    data: details,
    loading: loadingDetails,
    refetch,
  } = useQuery<QUERY_RETURN>(
    state?.type === LawyerType.company ||
      params?.userType === LawyerType.company
      ? FIND_COMPANY_BY_ID
      : FIND_LAWYER_BY_ID,
    {
      variables: {id: state?.lawyerData?.id || params?.id},
    },
  );

  const [createReview, {loading: loadingRate}] = useMutation<{
    createReview: Review;
  }>(RATE_LAWYER);

  const [ratingModal, setRatingModal] = useState(false);
  const [currentRating, setCurrentRating] = useState<number>(1);

  const handleCloseRatingModal = () => {
    setRatingModal(!ratingModal);
  };

  const handleRating = async (inputs: RATING_INPUTS) => {
    try {
      const {data} = await createReview({
        variables: {
          ...inputs,
        },
      });
      if (data) {
        Alert.success('تم التقييم بنجاح');
      }
      handleCloseRatingModal();
      if (data?.createReview?.rating) {
        refetch();
      }
    } catch (error) {
      Alert.error(i18n.t('Something went wrong'));
    }
  };

  return (
    <Wrapper>
      <SubWrapper>
        <RightSection>
          <ProfileWrapper>
            <ProfileContainer>
              <ProfilePic
                src={
                  details?.findCompanyById?.logo
                    ? `${UPLOAD_URI}/image/${details?.findCompanyById?.logo?.filename}`
                    : details?.findLawyerById?.photo
                    ? `${UPLOAD_URI}/image/${details?.findLawyerById?.photo?.filename}`
                    : noPhoto
                }
              />
              <Row marginTop={'20px'}>
                {(details?.findLawyerById?.phone ||
                  details?.findCompanyById?.mobile_phone) && (
                  <Whisper
                    placement="auto"
                    trigger="active"
                    speaker={
                      <Popover>
                        <CustomText>
                          {details?.findLawyerById?.phone ||
                            details?.findCompanyById?.mobile_phone}
                        </CustomText>
                      </Popover>
                    }>
                    <IconsView>
                      <FaPhoneAlt color={Colors.white} size={14} />
                    </IconsView>
                  </Whisper>
                )}
                {details?.findLawyerById?.whats_app_phone && (
                  <Link
                    href={`https://wa.me/${details?.findLawyerById?.whats_app_phone.substring(
                      1,
                    )}`}
                    target="_blank">
                    <IconsView>
                      <FaWhatsapp color={Colors.white} size={20} />
                    </IconsView>
                  </Link>
                )}
                {details?.findCompanyById?.whats_app_phone && (
                  <Link
                    href={`https://wa.me/${details?.findCompanyById?.whats_app_phone?.substring(
                      1,
                    )}`}
                    target="_blank">
                    <IconsView>
                      <FaWhatsapp color={Colors.white} size={20} />
                    </IconsView>
                  </Link>
                )}
              </Row>
              <Row marginTop={'20px'}>
                <ProfileName size={20}>
                  {details?.findLawyerById?.name ||
                    details?.findCompanyById?.name}
                </ProfileName>
              </Row>
              {details?.findLawyerById?.average_rate ||
              details?.findCompanyById?.average_rate ? (
                <Rating marginTop={'20px'}>
                  <Rate
                    defaultValue={
                      details?.findLawyerById?.average_rate ||
                      details?.findCompanyById?.average_rate
                    }
                    color="orange"
                    readOnly
                    allowHalf
                    size={'xs'}
                  />
                  <RatingRow2>
                    <RateText size={14} weight={'400'} color={Colors.seaBlue}>
                      تقييمات
                    </RateText>
                    {details?.findLawyerById?.rate_count && (
                      <RateNumber
                        size={14}
                        weight={'400'}
                        color={Colors.seaBlue}>
                        {details?.findLawyerById?.rate_count.toFixed()}
                      </RateNumber>
                    )}
                    {details?.findCompanyById?.rate_count && (
                      <RateNumber
                        size={14}
                        weight={'400'}
                        color={Colors.seaBlue}>
                        {details?.findCompanyById?.rate_count.toFixed()}
                      </RateNumber>
                    )}
                  </RatingRow2>
                </Rating>
              ) : details?.findLawyerById?.disable_review ? (
                <></>
              ) : (
                <Row marginTop={'5px'}>
                  <RateText size={14} weight={'400'} color={Colors.darkGray}>
                    لم يتم التقييم بعد
                  </RateText>
                </Row>
              )}
              <Row marginTop={'20px'}>
                {state?.type !== LawyerType.company && (
                  <ExperienceYears size={14} weight={'400'}>
                    سنة خبرة {details?.findLawyerById?.years_of_practice}
                  </ExperienceYears>
                )}
                <IconText>
                  <LocationIcon color={Colors.lightGray} size={17} />
                  <CustomText size={14} weight={'400'}>
                    {details?.findLawyerById?.city?.city ||
                      details?.findCompanyById?.city?.city}
                  </CustomText>
                </IconText>
              </Row>

              <Row marginTop={'20px'}>
                <RateButton
                  orangeBg
                  width={'45%'}
                  disabled={details?.findLawyerById?.disable_review}
                  onClick={() => {
                    handleCloseRatingModal();
                  }}>
                  <ButtonText weight={'400'} color={Colors.white} size={13}>
                    أترك تقييما
                  </ButtonText>
                </RateButton>
              </Row>
            </ProfileContainer>
          </ProfileWrapper>
        </RightSection>
        <PagesContainer>
          {!loadingDetails && details?.findLawyerById && (
            <SearchResultInfo
              info={details?.findLawyerById}
              type={state?.type}
            />
          )}
          {!loadingDetails && details?.findCompanyById && (
            <SearchResultInfo
              info={details?.findCompanyById}
              type={state?.type}
              companyInfo={details?.findCompanyById}
            />
          )}
          {!loadingDetails && details?.findLawyerById && (
            <SearchResultSocials
              info={details?.findLawyerById}
              type={state?.type}
            />
          )}
          {!loadingDetails && details?.findCompanyById && (
            <SearchResultSocials
              info={details?.findCompanyById}
              type={state?.type}
            />
          )}
        </PagesContainer>
      </SubWrapper>
      <LeaveRatingModal
        show={ratingModal}
        onHide={() => handleCloseRatingModal()}>
        <ModalBody>
          <ImageDiv>
            <CancelIcon onClick={() => handleCloseRatingModal()}>
              <svg
                focusable="false"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24">
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
              </svg>
            </CancelIcon>
          </ImageDiv>
          {state?.type === LawyerType.company ? (
            <RatingTitle>أترك تقييماً للشركة</RatingTitle>
          ) : (
            <RatingTitle>أترك تقييماً لمحاميك</RatingTitle>
          )}
          <RatingRow marginTop={'20px'}>
            <Rate
              defaultValue={1}
              color="orange"
              readOnly={false}
              size={'xs'}
              value={currentRating}
              onChange={rate => {
                setCurrentRating(rate);
              }}
              cleanable={false}
            />
          </RatingRow>
        </ModalBody>
        <ModalFooter>
          {loadingRate ? (
            <LoaderContainer>
              <Loader color="white" size={'sm'} />
            </LoaderContainer>
          ) : (
            <Confirm
              orangeBg
              onClick={() => {
                const input: RATING_INPUTS = {
                  rating: currentRating,
                };
                state?.lawyerData?.id && state?.type === LawyerType.company
                  ? (input.company = state?.lawyerData?.id)
                  : (input.lawyer = state?.lawyerData?.id);
                return handleRating(input);
              }}>
              <SendText color={Colors.white} size={23}>
                إرسال
              </SendText>
            </Confirm>
          )}
        </ModalFooter>
      </LeaveRatingModal>
    </Wrapper>
  );
};

export default ProfilePreview;

export const LeaveRatingModal = styled(Modal)`
  direction: rtl;
  width: 50%;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;
export const Register = styled(CustomButton)`
  width: 80%;
  margin: 0 10%;
`;
export const Confirm = styled(Register)``;
const SendText = styled(CustomText)`
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1150px) {
    font-size: 13px;
  }
`;
export const ModalBody = styled(Modal.Body)`
  margin-top: 0;
  padding-bottom: 20px;
`;
export const ModalFooter = styled(Modal.Footer)`
  width: 50%;
  margin: auto;
  margin-bottom: 20px;
`;
export const CancelIcon = styled.span`
  cursor: pointer;
  svg {
    width: 6%;
    fill: gray;
    @media (max-width: 1100px) {
      width: 8%;
    }

    @media (max-width: 500px) {
      width: 9%;
    }
  }
`;
const ImageDiv = styled.div`
  width: 100%;
  background-image: url(${ayat1});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 120px;

  @media (max-width: 600px) {
    background-image: url(${ayat});
  }
`;
const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: rtl;
`;
const SubWrapper = styled.div`
  width: 100%;
  display: flex;
  direction: rtl;
  justify-content: center;
  margin-top: 6rem;
  margin-bottom: 4rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ProfileWrapper = styled.div`
  width: 55%;
  display: flex;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  margin-right: 100px;
  margin-bottom: 50px;

  @media (max-width: 1024px) {
    width: 75%;
    margin: 0 auto 50px;
  }
  @media (max-width: 600px) {
    width: 85%;
    margin-right: 7.5%;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 35px 0px;
`;

const PagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
    align-items: center;
  }
`;
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
  /* margin-left: -70px; */
  /* display: block; */

  /* @media(max-width: 1024px){
    width: 100%;
  } */

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const ProfilePic = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  position: relative;
  align-self: center;
`;
const IconsView = styled.div`
  border-radius: 30px;
  background-color: ${Colors.darkGray1};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0px 7px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const Row = styled.div<{justify?: string; width?: string; marginTop?: string}>`
  display: flex;
  flex-direction: row;
  width: ${props => (props.width ? props.width : '100%')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}` : '')}
`;
const Rating = styled(Row)`
  @media only screen and (min-width: 1025px) and (max-width: 1090px) {
    /* flex-wrap: wrap; */
    flex-direction: column;
    align-items: center;
  }
`;

const RatingRow2 = styled.div`
  display: flex;
  flex-direction: row;
`;
const RatingRow = styled(Row)`
  ul {
    font-size: 43px;
    @media (max-width: 1300px) {
      font-size: 38px;
    }
    @media (max-width: 1150px) {
      font-size: 33px;
    }
    @media (max-width: 1000px) {
      font-size: 31px;
    }
    @media (max-width: 850px) {
      font-size: 27px;
    }
    @media (max-width: 400px) {
      font-size: 24px;
    }
  }
`;

const RatingTitle = styled(CustomText)`
  width: 75%;
  margin: auto;
  margin-top: 10px;
  text-align: center;
  font-size: 25px;
  @media (max-width: 1150px) {
    font-size: 23px;
  }
  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 850px) {
    font-size: 19px;
  }
  @media (max-width: 400px) {
    font-size: 17px;
  }
`;
const ProfileName = styled(CustomText)`
  text-align: center;
  width: 70%;
`;
const RateText = styled(CustomText)`
  margin-right: 15px;
  padding-top: 3px;
`;
const RateNumber = styled(CustomText)`
  margin-top: 5px;
  margin-right: 5px;
`;
const ExperienceYears = styled(CustomText)`
  margin-left: 10px;
`;

const IconText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 10px;
`;
const ButtonText = styled(CustomText)`
  padding-top: 1px;
`;
const LocationIcon = styled(MdLocationOn)`
  margin-left: 5px;
  margin-top: -2px;
`;

const RateButton = styled(CustomButton)`
  height: 35px;
`;
const Link = styled.a``;

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
