import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Banner from '../../Assets/Images/SearchPageBg.jpg';
import Zoom from '../../Assets/Images/zoom.svg';
import {
  CustomButton,
  CustomInput,
  CustomText,
  Row,
} from '../../Components/Common';
import Colors from '../Theme/Colors';
import {BsSearch} from 'react-icons/bs';
import {
  LawyerType,
  Specialty,
  YEARS_OF_PRACTICE_ENUM,
  Association,
  CourtDegree,
  ConfigsReducer,
} from '../../Action/types';
import {useLocation} from 'react-router-dom';
import {
  ASSOCIATIONS,
  COURT_DEGREES,
  FIND_CITY_BY_COUNTRY,
  SPECIALTIES,
} from './GraphQl/Search';
import {useQuery} from '@apollo/client';
import _ from 'lodash';
import {useFormik} from 'formik';
import {useHistory} from 'react-router-dom';
import Suggestions from './Components/Suggestions';
import {useSelector} from 'react-redux';
import {RootState} from '../../Reducer';
import Fonts from '../../Theme/Fonts';

export interface VARIABLES {
  name?: string;
  type?: string;
  country?: string;
  city?: string | {city: string};
  specialties?: string;
  goals?: string;
  years_of_practice?: YEARS_OF_PRACTICE_ENUM | string;
  rating?: number;
  associations?: never[];
  court_degrees?: never[];
  verified?: boolean;
  page_number?: number;
}

const SearchLawyers = () => {
  const history = useHistory();
  const location = useLocation<{type: string; country: string}>();
  const [advancedSearchBox, setAdvancedSearchBox] = useState<boolean>(false);
  const [registeredCherity, setRegisteredCherity] = useState<boolean>(false);
  const [showCourtDegree, setShowCourtDegree] = useState<boolean>(false);
  const [typeSpecialties, setTypeSpecialties] = useState<Specialty[]>();
  const [typeAssociations, setTypeAssociations] = useState<Association[]>();
  const [typeCourtDegrees, setTypeCourtDegrees] = useState<CourtDegree[]>();

  const {selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {data: cities, loading: loadingCities} = useQuery(
    FIND_CITY_BY_COUNTRY,
    {
      variables: {country: selectedCountry?.id},
    },
  );
  const {data: specialtiesInfo, loading: loadingSpecialties} = useQuery<{
    findAllSpecialties: Array<Specialty>;
  }>(SPECIALTIES);
  const {data: associations, loading: loadingAssociations} = useQuery<{
    findAllAssociations: Array<Association>;
  }>(ASSOCIATIONS);

  const {data: courtDegrees, loading: loadingCourtDegrees} = useQuery<{
    findAllCourtDegrees: Array<CourtDegree>;
  }>(COURT_DEGREES);

  useEffect(() => {
    if (specialtiesInfo && location?.state?.type) {
      const filter = _.groupBy(
        specialtiesInfo?.findAllSpecialties,
        speciality => speciality.type,
      )[location?.state?.type];
      if (filter) {
        setTypeSpecialties(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.type, specialtiesInfo, setTypeSpecialties]);

  useEffect(() => {
    if (associations && location?.state?.type) {
      const filter = _.groupBy(
        associations?.findAllAssociations,
        association => association.type,
      )[location?.state?.type];
      if (filter) {
        setTypeAssociations(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.type, associations, setTypeAssociations]);

  useEffect(() => {
    if (courtDegrees && location?.state?.type) {
      const filter = _.groupBy(
        courtDegrees?.findAllCourtDegrees,
        courtDegree => courtDegree.type,
      )[location?.state?.type];
      if (filter) {
        setTypeCourtDegrees(filter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.type, courtDegrees, setTypeCourtDegrees]);

  const {values, handleChange, handleSubmit, setFieldValue, resetForm} =
    useFormik({
      initialValues: {
        country: selectedCountry?.id,
        type: location?.state?.type,
        nameSearch: '',
        years_of_practice: '',
        city: '',
        specialties: '',
        associations: [],
        court_degrees: [],
        rating: undefined,
        searchType: '',
        verified: false,
      },

      onSubmit: async () => {
        const variables: VARIABLES = {};
        if (selectedCountry?.id) {
          variables.country = selectedCountry?.id;
        }
        if (location?.state?.type) {
          variables.type = location?.state?.type;
        }
        if (values.searchType === 'byName') {
          if (values.nameSearch) {
            variables.name = values.nameSearch;
          }
          if (values.verified) {
            variables.verified = values.verified;
          }
        } else {
          if (values.city) {
            variables.city = values.city;
          }
          if (values.specialties) {
            variables.specialties = values.specialties;
          }
          if (values.years_of_practice) {
            variables.years_of_practice = values.years_of_practice;
          }
          if (values.rating) {
            variables.rating = values.rating;
          }
          if (values.associations) {
            variables.associations = values.associations;
          }
          if (values.court_degrees) {
            variables.court_degrees = values.court_degrees;
          }
          if (values.verified) {
            variables.verified = values.verified;
          }
        }
        handleFindLawyer(variables);
      },
    });

  const handleFindLawyer = async (variables: VARIABLES) => {
    history.push({
      pathname: '/search-result',
      state: {
        nameSearch: values.nameSearch,
        submittedValues: variables,
        type: location?.state?.type,
      },
    });
    resetForm();
  };

  return (
    <Wrapper>
      <HeroBanner>
        {location?.state?.type === LawyerType.lawyer ? (
          <BannerText>
            ‫المحامين‬
            <ZoomImg src={Zoom} />
          </BannerText>
        ) : location?.state?.type === LawyerType.shari ? (
          <BannerText>
            ‫المحامين الشرعيين‬
            <ZoomImg src={Zoom} />
          </BannerText>
        ) : location?.state?.type === LawyerType.judge ? (
          <BannerText>
            المحكمين
            <ZoomImg src={Zoom} />
          </BannerText>
        ) : location?.state?.type === LawyerType.expert ? (
          <BannerText>
            الخبراء
            <ZoomImg src={Zoom} />
          </BannerText>
        ) : location?.state?.type === LawyerType.advisor ? (
          <BannerText>
            ‫المستشارين‬
            <ZoomImg src={Zoom} />
          </BannerText>
        ) : (
          <BannerText>
            شركات المحاماة والتحكيم
            <ZoomImg src={Zoom} />
          </BannerText>
        )}
        <HeaderTransparentContainer>
          {location?.state?.type === LawyerType.lawyer ? (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد المحامين المتخصصين في
                  موضوع قضيتك بالإضافة الى انه يمكنك تحديد المدينة التي تريد
                  البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على السيرة الذاتية للمحامي
                  وتقييمات من تعاملوا معه والحصول على جميع وسائل التواصل معه.
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          ) : location?.state?.type === LawyerType.advisor ? (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد المستشارين المتخصصين في
                  موضوع قضيتك بالإضافة الى انه يمكنك تحديد المدينة التي تريد
                  البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على السيرة الذاتية
                  للمستشار وتقييمات من تعاملوا معه والحصول على جميع وسائل
                  التواصل معه.
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          ) : location?.state?.type === LawyerType.shari ? (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد المحامين المتخصصين في
                  موضوع قضيتك الشرعية بالإضافة الى انه يمكنك تحديد المدينة التي
                  تريد البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على السيرة الذاتية للمحامي
                  الشرعي وتقييمات من تعاملوا معه والحصول على جميع وسائل التواصل
                  معه.
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          ) : location?.state?.type === LawyerType.judge ? (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد المحكمين المتخصصين في
                  موضوع النزاع بالإضافة الى انه يمكنك تحديد المدينة التي تريد
                  البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على السيرة الذاتية للمحكم
                  وتقييمات من تعاملوا معه والحصول على جميع وسائل التواصل معه.{' '}
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          ) : location?.state?.type === LawyerType.expert ? (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد الخبراء المتخصصين في جميع
                  المجالات المهنية والعلمية والفنية بالإضافة الى انه يمكنك تحديد
                  المدينة التي تريد البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على السيرة الذاتية للخبير
                  وتقييمات من تعاملوا معه والحصول على جميع وسائل التواصل معه.
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          ) : (
            <>
              <TextDotWrapper>
                <Dot />
                <BannerSubText>
                  البحث بواسطة التخصص يساعدك على ايجاد الشركات القانونية
                  المتخصصة في موضوع وغاية محددة بالإضافة الى انه يمكنك تحديد
                  المدينة التي تريد البحث فيها.
                </BannerSubText>
              </TextDotWrapper>
              <SecondTextDotWrapper>
                <DotTwo />
                <BannerSubText>
                  البحث بواسطة الاسم يمكنك من الإطلاع على معلومات الشركة
                  وتقييمات من تعاملوا معها والحصول على جميع وسائل التواصل معها.
                </BannerSubText>
              </SecondTextDotWrapper>
            </>
          )}
        </HeaderTransparentContainer>
      </HeroBanner>
      <EditAccountWrapper>
        <StyledRow>
          <ProfileTitle color={Colors.orange} size={20}>
            البحث بواسطة التخصص
          </ProfileTitle>
        </StyledRow>
        <StyledRow>
          {location?.state?.type === LawyerType.lawyer ||
          location?.state?.type === LawyerType.advisor ||
          location?.state?.type === LawyerType.shari ? (
            <RightSelectContainer>
              <Label size={14} weight={'400'}>
                ‫تخصص‬ ‫القضايا‬
              </Label>
              <Select
                onChange={speciality =>
                  setFieldValue('specialties', speciality.target.value)
                }>
                <Option value={'default'} disabled selected>
                  ‫اختر‬
                </Option>
                {!loadingSpecialties && typeSpecialties ? (
                  typeSpecialties.map((specialty: Specialty) => {
                    return (
                      <>
                        <Option value={specialty.id} key={specialty.id}>
                          {specialty.specialty}
                        </Option>
                      </>
                    );
                  })
                ) : (
                  <Option value={'default'}>‫اختر‬</Option>
                )}
              </Select>
            </RightSelectContainer>
          ) : location?.state?.type === LawyerType.company ? (
            <RightSelectContainer>
              <Label size={14} weight={'400'}>
                غاياتها
              </Label>
              <Select
                onChange={speciality =>
                  setFieldValue('specialties', speciality.target.value)
                }>
                <Option value={'default'} disabled selected>
                  ‫اختر‬
                </Option>
                {!loadingSpecialties && typeSpecialties ? (
                  typeSpecialties.map((specialty: Specialty) => {
                    return (
                      <>
                        <Option value={specialty.id} key={specialty.id}>
                          {specialty.specialty}
                        </Option>
                      </>
                    );
                  })
                ) : (
                  <Option value={'default'}>‫اختر‬</Option>
                )}{' '}
              </Select>
            </RightSelectContainer>
          ) : (
            <RightSelectContainer>
              <Label size={14} weight={'400'}>
                متخصص في‬
              </Label>
              <Select
                onChange={speciality =>
                  setFieldValue('specialties', speciality.target.value)
                }>
                <Option value={'default'} disabled selected>
                  ‫اختر‬
                </Option>
                {!loadingSpecialties && typeSpecialties ? (
                  typeSpecialties.map((specialty: Specialty) => {
                    return (
                      <>
                        <Option value={specialty.id} key={specialty.id}>
                          {specialty.specialty}
                        </Option>
                      </>
                    );
                  })
                ) : (
                  <Option value={'default'}>‫اختر‬</Option>
                )}
              </Select>
            </RightSelectContainer>
          )}

          <LeftSelectContainer>
            <Label size={14} weight={'400'}>
              ‫المدينة‬
            </Label>
            <Select onChange={city => setFieldValue('city', city.target.value)}>
              <Option value={'default'} disabled selected>
                ‫اختر‬
              </Option>

              {!loadingCities && cities ? (
                cities?.findCitiesByCountry.map(
                  (city: {city: any; id: any}) => {
                    return (
                      <Option value={city?.id} key={city?.id}>
                        {city?.city}
                      </Option>
                    );
                  },
                )
              ) : (
                <Option value={'default'}>‫اختر‬</Option>
              )}
            </Select>
          </LeftSelectContainer>
        </StyledRow>
        <AdvancedSearchRow padding={'0px 35px 0px'}>
          <CheckBoxWrapper>
            <CheckBox
              type={'checkbox'}
              id={'AdvancedSearch'}
              width={'10px'}
              onChange={() => setAdvancedSearchBox(!advancedSearchBox)}
              checked={advancedSearchBox}
            />
            <CheckBoxLabel htmlFor={'AdvancedSearch'}>
              البحث المتقدم
            </CheckBoxLabel>
          </CheckBoxWrapper>
        </AdvancedSearchRow>
        {advancedSearchBox && (
          <>
            {location?.state?.type === LawyerType.company ? (
              <StyledRow>
                <CheritySelectContainer>
                  <Label size={14} weight={'400'}>
                    التقييم
                  </Label>
                  <CheritySelect
                    onChange={review =>
                      setFieldValue('rating', review.target.value)
                    }>
                    <Option value={'default'} disabled selected>
                      ‫اختر‬
                    </Option>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                  </CheritySelect>
                </CheritySelectContainer>
              </StyledRow>
            ) : (
              <StyledRow>
                <RightSelectContainer>
                  <Label size={14} weight={'400'}>
                    عدد سنوات الخبرة
                  </Label>
                  <Select
                    onChange={year =>
                      setFieldValue('years_of_practice', year.target.value)
                    }>
                    <Option value={'default'} disabled selected>
                      ‫اختر‬
                    </Option>
                    <Option value={YEARS_OF_PRACTICE_ENUM.lessThanFive}>
                      اقل من خمس سنوات
                    </Option>
                    <Option value={YEARS_OF_PRACTICE_ENUM.fiveToTen}>
                      من خمس الى عشر سنوات
                    </Option>
                    <Option value={YEARS_OF_PRACTICE_ENUM.tenToTwenty}>
                      من عشرة الى عشرين سنة
                    </Option>
                    <Option value={YEARS_OF_PRACTICE_ENUM.moreThanTwenty}>
                      عشرين سنة فما فوق
                    </Option>
                  </Select>
                </RightSelectContainer>

                <LeftSelectContainer>
                  <Label size={14} weight={'400'}>
                    التقييم
                  </Label>
                  <Select
                    onChange={review =>
                      setFieldValue('rating', review.target.value)
                    }>
                    <Option value={'default'} disabled selected>
                      ‫اختر‬
                    </Option>
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                    <Option value={4}>4</Option>
                    <Option value={5}>5</Option>
                  </Select>
                </LeftSelectContainer>
              </StyledRow>
            )}

            {location?.state?.type === LawyerType.lawyer ||
            location?.state?.type === LawyerType.advisor ||
            location?.state?.type === LawyerType.shari ? (
              <StyledRow padding={'0px 35px 0px'}>
                <CheckBoxWrapper>
                  <CheckBox
                    type={'checkbox'}
                    id={'CheckAdvancedSearch'}
                    width={'10px'}
                    checked={values.verified}
                    onClick={() => {
                      setFieldValue('verified', !values.verified);
                    }}
                  />
                  <CheckBoxLabel htmlFor={'CheckAdvancedSearch'}>
                    اظهار من تم التحقق من صحة تخصصهم فقط
                  </CheckBoxLabel>
                </CheckBoxWrapper>
              </StyledRow>
            ) : (
              <CustomText />
            )}
            {location?.state?.type === LawyerType.shari ||
            location?.state?.type === LawyerType.judge ||
            location?.state?.type === LawyerType.expert ? (
              <>
                <StyledRow padding={'0px 35px 0px'}>
                  <CheckBoxWrapper>
                    <CheckBox
                      type={'checkbox'}
                      id={'CheckCheritySearch'}
                      width={'10px'}
                      checked={registeredCherity}
                      onChange={() => setRegisteredCherity(!registeredCherity)}
                    />
                    <CheckBoxLabel htmlFor={'CheckCheritySearch'}>
                      معتمد في:
                    </CheckBoxLabel>
                  </CheckBoxWrapper>
                </StyledRow>
                {registeredCherity && (
                  <StyledRow>
                    <CheritySelectContainer>
                      <Label size={14} weight={'400'}>
                        الجمعيات او الجهات
                      </Label>
                      <CheritySelect
                        onChange={association => {
                          setFieldValue(
                            'associations',
                            association.target.value,
                          );
                        }}>
                        <Option value={'default'} disabled selected>
                          ‫اختر‬
                        </Option>
                        {!loadingAssociations && typeAssociations ? (
                          typeAssociations.map((association: Association) => {
                            return (
                              <Option
                                value={association.id}
                                key={association.id}>
                                {association.name}
                              </Option>
                            );
                          })
                        ) : (
                          <Option value={'default'}>‫اختر‬</Option>
                        )}
                      </CheritySelect>
                    </CheritySelectContainer>
                  </StyledRow>
                )}
              </>
            ) : (
              <CustomText />
            )}
            {location?.state?.type === LawyerType.lawyer ? (
              <>
                {typeCourtDegrees && !!typeCourtDegrees.length && (
                  <StyledRow padding={'0px 35px 0px'}>
                    <CheckBoxWrapper>
                      <CheckBox
                        type={'checkbox'}
                        id={'CheckCourtSearch'}
                        width={'10px'}
                        checked={showCourtDegree}
                        onChange={() => setShowCourtDegree(!showCourtDegree)}
                      />
                      <CheckBoxLabel htmlFor={'CheckCourtSearch'}>
                        التخصص حسب درجة المحكمة
                      </CheckBoxLabel>
                    </CheckBoxWrapper>
                  </StyledRow>
                )}

                {showCourtDegree &&
                  typeCourtDegrees &&
                  !!typeCourtDegrees.length && (
                    <StyledRow>
                      <CheritySelectContainer>
                        <Label size={14} weight={'400'}>
                          درجة المحكمة
                        </Label>
                        <CheritySelect
                          onChange={courtDegree => {
                            setFieldValue(
                              'court_degrees',
                              courtDegree.target.value,
                            );
                          }}>
                          <Option value={'default'} disabled selected>
                            ‫اختر‬
                          </Option>
                          {!loadingCourtDegrees && typeCourtDegrees ? (
                            typeCourtDegrees.map((courtDegree: CourtDegree) => {
                              return (
                                <Option
                                  value={courtDegree.id}
                                  key={courtDegree.id}>
                                  {courtDegree.name}
                                </Option>
                              );
                            })
                          ) : (
                            <Option value={'default'}>‫اختر‬</Option>
                          )}
                        </CheritySelect>
                      </CheritySelectContainer>
                    </StyledRow>
                  )}
              </>
            ) : (
              <CustomText />
            )}
          </>
        )}

        <StyledRow>
          <CustomButton
            height={'33px'}
            width={'110px'}
            orangeBg
            type="submit"
            onClick={() => handleSubmit()}>
            <ButtonText color={Colors.white} weight={'400'}>
              ‫إبحث‬
            </ButtonText>
          </CustomButton>
        </StyledRow>
      </EditAccountWrapper>

      <SecondEditAccountWrapper>
        <StyledRow>
          <ProfileTitle color={Colors.orange} size={20}>
            البحث بواسطة الاسم
          </ProfileTitle>
        </StyledRow>
        <SearchRow>
          <BannerInput
            placeholder="‫إبحث‬"
            name={'nameSearch'}
            value={values.nameSearch}
            onChange={handleChange}
          />
          <SearchIcon>
            <BsSearch size={16} />
          </SearchIcon>
        </SearchRow>
        {/* {location?.state?.type === LawyerType.lawyer ||
        location?.state?.type === LawyerType.shari ? (
          <CheckboxRow padding={'0px 35px 0px'}>
            <CheckBoxWrapper>
              <CheckBox
                type={'checkbox'}
                id={'CheckAdvancedSearch'}
                width={'10px'}
                checked={values.verified}
                onClick={() => {
                  setFieldValue('verified', !values.verified);
                }}
              />
              <CheckBoxLabel htmlFor={'CheckAdvancedSearch'}>
                اظهار من تم التحقق من صحة تخصصهم فقط
              </CheckBoxLabel>
            </CheckBoxWrapper>
          </CheckboxRow>
        ) : (
          <CustomText />
        )} */}

        <StyledRow>
          <CustomButton
            height={'33px'}
            width={'110px'}
            orangeBg
            type="submit"
            disabled={!!!values.nameSearch}
            onClick={() => {
              setFieldValue('searchType', 'byName');
              handleSubmit();
            }}>
            <ButtonText color={Colors.white} weight={'400'}>
              ‫إبحث‬
            </ButtonText>
          </CustomButton>
        </StyledRow>
      </SecondEditAccountWrapper>
      <ThirdEditAccountWrapper>
        <Suggestions />
      </ThirdEditAccountWrapper>
    </Wrapper>
  );
};

export default SearchLawyers;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.lightBlueBg};
`;
const ZoomImg = styled.img`
  width: 40px;
  margin-left: 8px;
`;
export const HeroBanner = styled.div`
  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 70%;
  height: 350px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 250px;
  z-index: 0;
  @media (max-width: 1000px) {
    padding: 0 140px;
  }
  @media (max-width: 750px) {
    padding: 0 110px;
  }
  @media (max-width: 650px) {
    padding: 0 60px;
  }
  @media (max-width: 550px) {
    padding: 0 40px;
  }
  @media (max-width: 420px) {
    padding: 0 30px;
  }
`;

const BannerText = styled(CustomText)`
  color: ${Colors.white};
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 15px;
  z-index: 999;
  @media (max-width: 650px) {
    align-self: center;
  }
  @media (max-width: 600px) {
    font-size: 27px;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
`;

const BannerSubText = styled(CustomText)`
  color: ${Colors.white};
  font-size: 15px;
  z-index: 999;
  @media (max-width: 600px) {
    font-size: 13px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;

const HeaderTransparentContainer = styled.div`
  background-color: ${Colors.darkGray2};
  border-radius: 15px;
  width: 100%;
  direction: rtl;
  padding: 20px;
  @media (max-width: 420px) {
    padding: 20px;
  }
`;
const TextDotWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const SecondTextDotWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;
const Dot = styled.div`
  background-color: ${Colors.white};
  direction: rtl;
  height: 6px;
  width: 6px;
  border-radius: 100px;
  margin-top: 7px;
  margin-left: 25px;
  @media (max-width: 1200px) {
    width: 7px;
  }
  @media (max-width: 1100px) {
    width: 8px;
  }
  @media (max-width: 850px) {
    width: 10px;
  }
  @media (max-width: 600px) {
    border-radius: 0px;
  }
  @media (max-width: 370px) {
    width: 18px;
  }
`;

const DotTwo = styled(Dot)`
  width: 4.7px;
  @media (max-width: 1200px) {
    width: 5.7px;
  }
  @media (max-width: 1100px) {
    width: 6.7px;
  }
  @media (max-width: 850px) {
    width: 8.7px;
  }
  @media (max-width: 370px) {
    width: 16.7px;
  }
`;
const EditAccountWrapper = styled.div`
  margin-top: 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  align-self: center;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
`;

const SecondEditAccountWrapper = styled.div`
  margin-top: 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  align-self: center;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
`;
const ThirdEditAccountWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  align-self: center;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
`;

export const ProfileTitle = styled(CustomText)`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    font-size: 18px;
    text-align: center;
  }
`;
export const TextArea = styled.textarea`
  height: 150px;
  line-height: 60px;
  width: 100%;
  font-size: 16px;
  border-radius: 2.5px;
  border: 2px solid ${Colors.borderGray};
  outline: none;
  margin-top: 10px;
  font-weight: 400;
  &::placeholder {
    margin-top: 20px;
    padding-right: 20px;
    color: ${Colors.lightNavyBlue};
    font-size: 16px;
    font-weight: 400;
  }
`;

const RightSelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  width: 95%;
  margin-left: 30px;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const LeftSelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  width: 95%;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const CheritySelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  width: 48%;
`;
export const Select = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  outline: none;
  border: none;
  cursor: pointer;
  padding-bottom: 12px;
  width: 95%;
  margin-left: 7px;
  color: ${Colors.lightGray};
`;
export const CheritySelect = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  outline: none;
  border: none;
  padding-bottom: 12px;
  width: 95%;
  margin-left: 7px;
  color: ${Colors.lightGray};
`;
export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  &:first-child {
    color: ${Colors.lightGray};
  }
`;

export const Label = styled(CustomText)`
  color: ${Colors.darkGray};
`;
export const CheckBoxWrapper = styled(CustomText)`
  display: flex;
  flex-direction: row;
  text-align: end;
  @media (max-width: 500px) {
    align-self: end;
  }
`;
export const CheckBoxLabel = styled.label`
  color: ${Colors.lightGray};
  font-size: 13px;
  margin: 5px 4px 0px 0px;
  cursor: pointer;
  @media (max-width: 500px) {
    font-size: 11px;
  }
  @media (max-width: 450px) {
    margin: 7px 0px 0px 0px;
  }
  @media (max-width: 420px) {
    font-size: 9px;
  }
`;
export const CheckBox = styled(CustomInput)`
  margin: 6px;
  cursor: pointer;
`;
export const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;

export const BannerInput = styled(CustomInput)`
  font-size: 16px;
  direction: rtl;
  width: 100%;
  border: 1px solid ${Colors.borderGray};
  border-radius: 3px;
  position: relative;
`;
export const SearchIcon = styled.div`
  position: absolute;
  top: 48%;
  left: 10%;
  @media (max-width: 1100px) {
    left: 12%;
  }
  @media (max-width: 500px) {
    left: 10%;
    top: 34%;
  }
  @media (max-width: 400px) {
    left: 12%;
  }
`;
const SearchRow = styled(Row)`
  position: relative;
  width: 60%;
  @media (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    padding: 10px 20px 20px;
  }
`;

export const MessageText = styled.textarea<{error?: boolean}>`
  height: 175px;
  line-height: 25px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  outline: none;
  padding: 5px 10px;
  margin-bottom: -20px;
  &::placeholder {
    font-family: ${Fonts.regular};
    margin-top: 20px;
    color: ${props => props.theme.colors.gray};
    font-size: 14px;
    @media (max-width: 480px) {
      font-size: 11px;
    }
  }
  overflow: auto;
  resize: none;
  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
  font-family: ${Fonts.regular};
`;

export const StyledRow = styled(Row)<{padding?: string}>`
  justify-content: flex-start;
  padding: ${props => (props.padding ? props.padding : '30px 40px 20px')};
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    align-items: center;
  }
  @media (max-width: 400px) {
    padding: 30px 30px 20px;
  }
`;

export const TextRow = styled(StyledRow)`
  @media (max-width: 500px) {
    padding: 10px 30px;
  }
  @media (max-width: 350px) {
    padding: 0px 30px;
  }
`;

export const TextRowText = styled(CustomText)`
  @media (max-width: 500px) {
    font-size: 14px;
    text-align: center;
  }
`;
// const CheckboxRow = styled(StyledRow)`
//   @media (max-width: 400px) {
//     padding: 0 20px 0;
//   }
//   @media (max-width: 330px) {
//     padding: 0 18px 0;
//   }
// `;

const AdvancedSearchRow = styled(StyledRow)<{padding?: string}>`
  @media (max-width: 400px) {
    padding: 10px 30px 20px;
  }
`;
