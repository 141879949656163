import gql from 'graphql-tag';

export const CONTACTUS = gql`
  mutation createContactUs(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $messageBody: String!
  ) {
    createContactUs(
      createContactUsInput: {
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        messageBody: $messageBody
      }
    )
  }
`;

export const NEWSLETTER = gql`
  mutation createNewsletter($email: String!) {
    createNewsletter(createNewsletterInput: {email: $email}) {
      id
      email
      subscribed
    }
  }
`;
