export const SET_TOKEN = 'SET_TOKEN';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_DEVICE_ID = 'SET_DEVICE_ID';
export const SET_SELECTED_COUNTRY = 'SET_SELECTED_COUNTRY';
export const SET_COUNTRIES = 'SET_COUNTRIES';
interface SetToken {
  type: typeof SET_TOKEN;
  payload: string;
}
export interface City {
  id: string;
  country?: Country;
  city: string;
  show?: boolean;
  updated_at?: Date;
  created_at?: Date;
}
export enum searchButtonEnum {
  search_for_lawyer = 'search_for_lawyer',
  search_for_judge = 'search_for_judge',
  search_for_expert = 'search_for_expert',
  search_for_shari = 'search_for_shari',
  search_for_advisor = 'search_for_advisor',
  search_for_company = 'search_for_company',
}

export enum LawyerType {
  default = 'default',
  lawyer = 'lawyer',
  shari = 'shari',
  judge = 'judge',
  expert = 'expert',
  advisor = 'advisor',
  company = 'company',
}
export enum YEARS_OF_PRACTICE_ENUM {
  lessThanFive = 'lessThanFive',
  fiveToTen = 'fiveToTen',
  tenToTwenty = 'tenToTwenty',
  moreThanTwenty = 'moreThanTwenty',
}
export interface Upload {
  id: string;
  label: string;
  type: string;
  filename: string;
  created_at: Date;
}
export interface Specialty {
  id: string;
  type: string;
  specialty: string;
  show: string;
  updated_at: Date;
  created_at: Date;
}
export interface WorkExperience {
  name: string;
  years: string;
}

export interface Association {
  id: string;
  name: string;
  type: LawyerType;
  updated_at: Date;
  created_at: Date;
}

export interface CourtDegree {
  id: string;
  name: string;
  type: LawyerType;
  updated_at: Date;
  created_at: Date;
}

export interface Lawyer {
  id?: string;
  type?: LawyerType;
  name?: string;
  email?: string;
  phone?: string;
  password?: string;
  work_phone?: string;
  years_of_practice?: number;
  is_disabled?: boolean;
  disable_review?: boolean;
  city?: City;
  specialties?: [Specialty];
  verified_specialties?: [Specialty];
  pending_specialties?: [Specialty?];
  photo?: Upload;
  office_address?: string;
  building_name?: string;
  street_name?: string;
  facebook_id?: string;
  google_id?: string;
  apple_id?: string;
  linkedin_id?: string;
  twitter_handle?: string;
  fax?: string;
  land_line?: string;
  google_maps_link?: string;
  website?: string;
  youtube?: string;
  telegram?: string;
  whats_app_phone?: string;
  token?: string;
  rating?: number;
  rate_count?: number;
  ejazeh_number?: string;
  naqabeh_number?: string;
  average_rate?: number;
  ssn?: string;
  hours_of_operation?: string;
  commercial_name?: string;
  biography?: string;
  languages?: [string?];
  scholar_degree?: string;
  scholar_major?: string;
  social_status?: string;
  date_of_birth?: string;
  research?: Research[] | [];
  address?: string;
  work_experience?: [WorkExperience?];
  training_courses?: [TrainingCourses?];
  is_email_confirmed?: boolean;
  is_phone_confirmed?: boolean;
  deleted?: boolean;
  updated_at?: Date;
  created_at?: Date;
  membership?: Membership;
  membership_expiry?: string;
}
export interface WebPaymentInput {
  cart_id?: string;
  cart_currency?: string;
  cart_amount?: string;
  dynamic_redirection?: string;
  email?: string;
  membership_id?: string;
}
export interface WorkExperience {
  name: string;
  years: string;
}
export interface Certificate {
  id: string;
  lawyer: Lawyer;
  certificate_id: string;
  certificate_name?: string;
  certificate_issued_from: string;
  certificate_issue_date: Date;
  certificate_url?: string;
  photo?: Upload;
  deleted: boolean;
  updated_at: Date;
  created_at: Date;
}
export interface TrainingCourses {
  title?: string;
  acquired_from?: string;
  acquired_at?: string;
}
export interface Review {
  id: string;
  company: Company;
  lawyer: Lawyer;
  rating: number;
  created_at: Date;
}
export interface Blog {
  id?: string;
  body?: string;
  poster_name?: string;
  title?: string;
  approved?: boolean;
  comments?: [Comment];
  comments_count?: number;
  deleted?: boolean;
  created_at: Date;
}

export interface Comment {
  id?: string;
  user_name?: string;
  body?: string;
  blog: Blog;
  deleted?: boolean;
  created_at: Date;
}
export interface Research {
  label: string;
  url?: string;
}
export interface TeamMember {
  id: string;
  photo: Upload;
  name: string;
  role: string;
  updated_at: Date;
}

export interface Company {
  id?: string;
  email?: string;
  password?: string;
  name?: string;
  address?: string;
  is_disabled?: boolean;
  disable_review?: boolean;
  mobile_phone?: string;
  whats_app_phone?: string;
  fax?: string;
  land_line?: string;
  google_maps_link?: string;
  website?: string;
  youtube?: string;
  telegram?: string;
  logo?: Upload;
  photos?: Upload[];
  token?: string;
  rating?: number;
  rate_count?: number;
  ssn?: string;
  city?: City;
  verified_specialties?: [Specialty];
  average_rate?: number;
  authorized_signatory?: string;
  activities?: [string];
  clients?: [string];
  research?: Research[] | [];
  company_type?: string;
  company_nature?: string;
  commercial_name?: string;
  date_of_establishment?: Date;
  hours_of_operation?: string;
  number_of_employees?: number;
  biography?: string;
  is_email_confirmed?: boolean;
  deleted?: boolean;
  updated_at?: Date;
  created_at?: Date;
  team?: [TeamMember];
  membership?: Membership;
  membership_expiry?: string;
  goals?: [Specialty];
}

export interface SpecialtyPhotosReturn {
  photos: string[];
  specialty: string;
}

export interface SpecialtyPhotosInput {
  photos: string[];
  specialty: string;
}

export interface LawyerPrivileges {
  pending_specialties: number;
  scholar_degree: boolean;
  training_courses: boolean;
  research: boolean;
  cv: boolean;
}

export interface CompanyPrivileges {
  goals: number;
  activities: boolean;
  clients: boolean;
  research: boolean;
  team: boolean;
}
export interface Membership {
  id: string;
  type: number;
  name: string;
  cost: number;
  lawyer_privileges: LawyerPrivileges;
  company_privileges: CompanyPrivileges;
  new_cost: number;
}
export interface PrivilegesTranslateType {
  pending_specialties: string;
  scholar_degree: string;
  training_courses: string;
  goals: string;
  activities: string;
  clients: string;
  research: string;
  team: string;
  cv: string;
}
export const PrivilegesTranslate: PrivilegesTranslateType = {
  pending_specialties: 'عدد التخصصات',
  scholar_degree: 'الشهادات العلمية',
  training_courses: 'الخبرات العملية / الدورات التدريبية',
  goals: 'عدد الغايات',
  activities: 'النشاطات',
  clients: 'عملاؤنا',
  research: 'الابحاث والمصنفات',
  team: 'فريق العمل',
  cv: 'السيرة الذاتية',
};

export interface Account {
  id?: string;
  email?: string;
  password?: string;
  name?: string;
  address?: string;
  disable_review?: boolean;
  is_disabled?: boolean;
  mobile_phone?: string;
  whats_app_phone?: string;
  logo?: Upload;
  photos?: Upload[];
  token?: string;
  rating?: number;
  city?: City;
  authorized_signatory?: string;
  activities?: [string];
  research?: Research[] | [];
  company_type?: string;
  company_nature?: string;
  commercial_name?: string;
  date_of_establishment?: Date;
  hours_of_operation?: string;
  number_of_employees?: number;
  biography?: string;
  team?: [TeamMember];
  type?: LawyerType;
  phone?: string;
  work_phone?: string;
  clients?: [string];
  years_of_practice?: number;
  pending_specialties?: [Specialty?];
  specialty_photos?: [SpecialtyPhotosReturn];
  verified_specialties?: [Specialty];
  specialties?: [Specialty];
  goals?: [Specialty];
  photo?: Upload;
  building_name?: string;
  street_name?: string;
  facebook_id?: string;
  google_id?: string;
  apple_id?: string;
  linkedin_id?: string;
  twitter_handle?: string;
  fax?: string;
  land_line?: string;
  google_maps_link?: string;
  website?: string;
  youtube?: string;
  telegram?: string;
  ejazeh_number?: string;
  naqabeh_number?: string;
  ssn?: string;
  training_courses?: [TrainingCourses?];
  work_experience?: [WorkExperience?];
  is_email_confirmed?: boolean;
  is_phone_confirmed?: boolean;
  deleted?: boolean;
  updated_at?: Date;
  created_at?: Date;
  rememberMe?: boolean;
  membership?: Membership;
  membership_expiry?: string;
}

export interface Country {
  country: string;
  disabled: boolean;
  id: string;
  isoCode: string;
  show: boolean;
  whats_app_phone: string;
  currency: string;
}
interface UpdateUser {
  type: typeof UPDATE_USER;
  payload: Lawyer;
}
interface SetDeviceId {
  type: typeof SET_DEVICE_ID;
  payload: string;
}

interface LoginAction {
  type: typeof LOGIN;
}

interface CountriesAction {
  type: typeof SET_COUNTRIES;
  payload: Country[];
}

interface SetUserTypeAction {
  type: typeof SET_USER_TYPE;
  payload: LawyerType;
}

interface SetSelectedCountryAction {
  type: typeof SET_SELECTED_COUNTRY;
  payload: {
    id: string | null;
    isoCode: string | null;
    supportNumber: string | null;
    currency: string | null;
  };
}
export interface AccountReducer {
  userType?: LawyerType | '';
  user?: Account;
}
export interface ConfigsReducer {
  token: string;
  loggedIn: boolean;
  deviceId: string;
  countries: Country[];
  selectedCountry: {
    id: string | null;
    isoCode: string | null;
    supportNumber: string | null;
    currency: string | null;
  };
}
interface LogoutAction {
  type: typeof LOGOUT;
}

export type ActionTypes =
  | SetToken
  | LoginAction
  | UpdateUser
  | SetUserTypeAction
  | LogoutAction
  | SetDeviceId
  | CountriesAction
  | SetSelectedCountryAction;
