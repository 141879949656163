import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {
  AiFillPlusCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';
import noPhoto from '../../../Assets/Images/noPhoto.png';
import {
  Account,
  AccountReducer,
  City,
  Company,
  LawyerType,
  ConfigsReducer,
} from '../../../Action/types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Confirm, Select} from '../../Auth/SignUp';
import {useMutation, useQuery} from '@apollo/client';
import {CITIES} from '../../Auth/GraphQl/SignUp';
import {E164Number} from 'libphonenumber-js';
import {updateUser} from '../../../Action';
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_COMPANY} from '../GraphQL/EditAccount';
import {COMPANY_UPDATE_PASSWORD} from '../../Auth/GraphQl/ResetPassword';
import {RootState} from '../../../Reducer';
import {Modal, Loader, Alert} from 'rsuite';
import {ModalFooter, ModalBody, CancelIcon} from '../../User/ProfilePreview';
import Dropzone from 'react-dropzone';
import {UPLOAD_URI} from '../../../Configs';
import axios from 'axios';
import Fonts from '../../../Theme/Fonts';
import i18n from '../../../I18next/I18n';
import {validatePhoneNumber} from '../../../helpers';
export interface AccountProps {
  user?: Account;
  userType?: LawyerType;
}
const sharedValidation = {
  email: Yup.string().email().required('البريد الالكتروني خاطئ'),
  city: Yup.string().required('الرجاء الاختيار من القائمة'),
};

export interface UpdateCompanyInput {
  authorized_signatory?: string;
  name?: string;
  email?: string;
  city?: string;
  address?: string;
  fax?: E164Number | null;
  mobile_phone?: E164Number | null;
  commercial_name?: string;
}

export interface PASSWORDS {
  oldPassword: string;
  password: string;
  confirm_password: string;
}

const EditAccountCompany: React.FC = () => {
  const dispatch = useDispatch();
  const [passVisibility, setpassVisibility] = useState(false);

  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const handleResetPasswordModal = () => {
    setResetPasswordModal(!resetPasswordModal);
  };
  const [resetCompanyPassword, {loading: loadingResetPassword}] = useMutation<{
    companyUpdatePassword: Company;
  }>(COMPANY_UPDATE_PASSWORD);
  const [updateCompany, {loading: updateCompanyLoading}] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const {token} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {data, loading} = useQuery(CITIES);
  const [cities, setCities] = useState<City[]>();

  const ValidationSchema = Yup.object().shape(
    resetPasswordModal
      ? {
          oldPassword: Yup.string().min(8).required('مطلوب'),
          password: Yup.string()
            .min(8)
            .required('كلمة السر يجب ان تحتوي على ٨ خانات على الأقل'),
          confirm_password: Yup.string().required(
            'كلمة السر يجب ان تتطابق مع تأكيد كلمة السر',
          ),
        }
      : {
          ...sharedValidation,
          company_name: Yup.string().required('مطلوب'),
          // fax: Yup.string().required('رقم الهاتف خاطئ'),
          // address: Yup.string().required('مطلوب'),
          // mobile_phone: Yup.string().required('رقم الهاتف خاطئ'),
          authorized_signatory: Yup.string().required('مطلوب'),
        },
  );

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setErrors,
  } = useFormik({
    initialValues: {
      name: user?.name || '',
      fax: user?.fax || '',
      email: user?.email || '',
      city: user?.city?.id || '',
      address: user?.address || user?.address || '',
      authorized_signatory: user?.authorized_signatory || '',
      company_name: user?.name || '',
      commercial_name: user?.commercial_name || '',
      mobile_phone: user?.mobile_phone || '',
      oldPassword: '',
      password: '',
      confirm_password: '',
    },

    validationSchema: ValidationSchema,

    onSubmit: async submittedValues => {
      if (resetPasswordModal) {
        if (submittedValues.oldPassword === submittedValues.password) {
          return Alert.error(
            i18n.t("old password can't be the same as the new password"),
          );
        }
        if (submittedValues.password !== submittedValues.confirm_password) {
          return Alert.error(i18n.t('Passwords do not match'));
        }
        try {
          handleResetPassword(submittedValues);
        } catch (err) {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      } else {
        let parsedPhone = null;
        let parsedFax = null;
        if (submittedValues?.mobile_phone) {
          const phoneNumber = validatePhoneNumber(
            submittedValues?.mobile_phone,
            true,
          );
          if (!phoneNumber.isValid())
            return Alert.error(i18n.t('Phone number is incorrect'));
          parsedPhone = phoneNumber.number;
        }

        if (submittedValues?.fax) {
          const faxNumber = validatePhoneNumber(submittedValues?.fax, true);
          if (!faxNumber.isValid())
            return Alert.error(i18n.t('Fax number is incorrect'));
          parsedFax = faxNumber.number;
        }

        try {
          await handleEditCompany({
            city: submittedValues.city,
            address: submittedValues.address,
            name: submittedValues.company_name,
            email: submittedValues.email,
            authorized_signatory: submittedValues.authorized_signatory,
            mobile_phone: parsedPhone,
            commercial_name: submittedValues.commercial_name,
            fax: parsedFax,
          });
          return;
        } catch (error: any) {
          return setModal({
            isOn: true,
            message: error.graphQLErrors[0].message,
          });
        }
      }
    },
  });
  const handleResetPassword = async (submittedValues: PASSWORDS) => {
    let variables: PASSWORDS = {
      password: submittedValues.password,
      oldPassword: submittedValues.oldPassword,
      confirm_password: submittedValues.confirm_password,
    };
    let data;
    let type;

    try {
      ({data} = await resetCompanyPassword({
        variables: variables,
      }));
      type = LawyerType.company;
      if (type && data?.companyUpdatePassword) {
        dispatch(updateUser({...user, ...data?.companyUpdatePassword}));
        Alert.success('تمت العملية بنجاح');
        handleResetPasswordModal();
        clearResetpasswordModalInputs();
      }
    } catch (err: any) {
      if (err.message === `Old password doesn't match`) {
        Alert.error(
          i18n.t(err?.message, {
            fallback: 'Something went wrong please try again later',
          }),
        );
      } else {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    }
  };
  const clearResetpasswordModalInputs = () => {
    values.oldPassword = '';
    values.password = '';
    values.confirm_password = '';
    setErrors({});
  };
  const handleEditCompany = async (inputs: UpdateCompanyInput) => {
    const variables: UpdateCompanyInput = {};
    if (inputs.email !== user?.email) variables.email = inputs.email;
    if (inputs.name !== user?.name) variables.name = inputs.name;
    if (inputs.address !== user?.address) variables.address = inputs.address;
    if (inputs.authorized_signatory !== user?.authorized_signatory)
      variables.authorized_signatory = inputs.authorized_signatory;
    if (inputs.city !== user?.city?.id) variables.city = inputs.city;
    if (inputs.mobile_phone && inputs.mobile_phone !== user?.mobile_phone)
      variables.mobile_phone = inputs.mobile_phone;
    if (inputs.fax && inputs.fax !== user?.fax) variables.fax = inputs.fax;
    if (inputs.commercial_name !== user?.commercial_name)
      variables.commercial_name = inputs.commercial_name;

    if (Object.keys(variables).length !== 0) {
      try {
        const data = await updateCompany({
          variables,
        });
        const updateUserData = data?.data?.updateCompany;
        dispatch(updateUser({...updateUserData}));
        setModal({isOn: true, message: 'تم تحديث البيانات بنجاح'});
      } catch (err: any) {
        setModal({isOn: true, message: err?.graphQLErrors[0].message});
      }
    } else {
      setModal({isOn: true, message: 'يجب ان تغير حقل واحد على اﻷقل'});
    }
  };

  useEffect(() => {
    if (!loading) {
      setCities(data.findAllCities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const uploadFiles = async (file: any) => {
    const body = new FormData();
    body.append('file', file[0]);
    axios({
      method: 'post',
      url: `${UPLOAD_URI}`,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
      },
    })
      .then(async res => {
        await updateCompany({
          variables: {
            logo: res?.data?.id,
          },
        })
          .then(async ({data}) => {
            if (data?.updateCompany) {
              dispatch(updateUser({...user, ...data?.updateCompany}));
            }
          })
          .catch(err => {
            // tslint:disable-next-line: no-console
            console.log(err);
          });
      })
      .catch(error => {
        // tslint:disable-next-line: no-console
        console.log(error);
      });
  };

  return (
    <EditAccountWrapper>
      <Col>
        <ProfileTitle>الملف الشخصي</ProfileTitle>
        <ProfilePicWrapper>
          <IconWrapper>
            <PlusIcon size={20} color={Colors.lightGray4} />
          </IconWrapper>
          <Dropzone
            onDrop={acceptedFiles => {
              uploadFiles(acceptedFiles);
            }}
            multiple={false}>
            {({getRootProps, getInputProps}) => (
              <ProfilePicWrapper {...getRootProps()}>
                <input {...getInputProps()} />
                <IconWrapper>
                  <PlusIcon size={20} color={Colors.lightGray4} />
                </IconWrapper>
                <ProfilePic
                  src={
                    user?.logo?.filename
                      ? `${UPLOAD_URI}/image/${user?.logo?.filename}`
                      : noPhoto
                  }
                />
              </ProfilePicWrapper>
            )}
          </Dropzone>
        </ProfilePicWrapper>
        <ResetPasswordText
          color={Colors.black}
          size={15}
          weight={'400'}
          onClick={() => {
            handleResetPasswordModal();
          }}>
          تغيير كلمة السر
        </ResetPasswordText>
      </Col>
      <Divider />
      <Inputs>
        <CustomInput
          placeholder="اسم الشركة"
          width={'86%'}
          name="company_name"
          value={values.company_name}
          error={!!(errors.company_name && touched.company_name)}
          onChange={handleChange}
        />
        <CustomInput
          placeholder="اسم المفوض بالتوقيع"
          width={'86%'}
          name="authorized_signatory"
          value={values.authorized_signatory}
          error={
            !!(errors.authorized_signatory && touched.authorized_signatory)
          }
          onChange={handleChange}
        />
        <CustomInput
          placeholder="الاسم التجاري"
          width={'86%'}
          name="commercial_name"
          value={values.commercial_name}
          error={!!(errors.commercial_name && touched.commercial_name)}
          onChange={handleChange}
        />

        <CustomInput
          placeholder={`${'البريد الإلكتروني'}`}
          width={'86%'}
          name="email"
          onChange={handleChange}
          value={values.email}
          error={!!(errors.email && touched.email)}
        />
        <CustomInput
          placeholder={`${'رقم الهاتف'}`}
          width={'86%'}
          name="mobile_phone"
          style={{direction: 'ltr', textAlign: 'right'}}
          onChange={handleChange}
          value={values.mobile_phone}
          error={!!(errors.mobile_phone && touched.mobile_phone)}
        />
        <CustomInput
          placeholder={`${'فاكس'}`}
          width={'86%'}
          name="fax"
          style={{direction: 'ltr', textAlign: 'right'}}
          onChange={handleChange}
          value={values.fax}
          error={!!(errors.fax && touched.fax)}
        />
        <SelectContainer error={!!(errors.city && touched.city)}>
          <SelectCity onChange={handleChange} required name="city">
            <Option value={user?.city && user?.city.id} hidden>
              {user?.city ? user?.city?.city : 'المدينة'}
            </Option>
            {cities?.map((city, index) => (
              <Option value={city.id} key={index}>
                {city.city}
              </Option>
            ))}
          </SelectCity>
        </SelectContainer>
        <CustomInput
          width={'86%'}
          placeholder={`${'العنوان'}`}
          name="address"
          onChange={handleChange}
          value={values.address}
          error={!!(errors.address && touched.address)}
        />

        <Row>
          <CustomButton
            height={'33px'}
            width={'100px'}
            orangeBg
            type="submit"
            disabled={updateCompanyLoading}
            onClick={() => {
              handleSubmit();
            }}>
            {updateCompanyLoading ? (
              <Loader />
            ) : (
              <ButtonText color={Colors.white} weight={'400'}>
                حفظ
              </ButtonText>
            )}
          </CustomButton>
        </Row>
      </Inputs>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => setModal({isOn: false, message: ''})}>
            <CustomText color={Colors.white} size={12}>
              حسنا
            </CustomText>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
      <ResetPasswordModal
        show={resetPasswordModal}
        onHide={() => {
          handleResetPasswordModal();
          clearResetpasswordModalInputs();
        }}>
        <ResetPasswordModalBody>
          <CancelIcon
            onClick={() => {
              handleResetPasswordModal();
              clearResetpasswordModalInputs();
            }}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>تغيير كلمة السر</ResetPasswordTitle>
          <InputsWrapper>
            <PasswordIconInputContainer
              error={!!(errors.oldPassword && touched.oldPassword)}>
              <PasswordInput
                error={!!(errors.oldPassword && touched.oldPassword)}
                width={'86%'}
                placeholder={`${'كلمة السر القديمة'}`}
                name="oldPassword"
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>
            <PasswordIconInputContainer
              error={!!(errors.password && touched.password)}>
              <PasswordInput
                error={!!(errors.password && touched.password)}
                width={'86%'}
                placeholder={`${'كلمة السر الجديدة'}`}
                name="password"
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>
            <PasswordIconInputContainer
              error={!!(errors.password && touched.password)}>
              <PasswordInput
                error={!!(errors.confirm_password && touched.confirm_password)}
                width={'86%'}
                placeholder={`${'تأكيد كلمة السر الجديدة'}`}
                name="confirm_password"
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>
            {values.confirm_password !== values.password &&
              touched.password &&
              touched.confirm_password && (
                <ErrorText
                  color="red"
                  style={{
                    marginLeft: 'auto',
                    marginTop: '10px',
                    marginRight: '35px',
                    position: 'relative',
                  }}>
                  يجب ان تتطابق كلمة السر مع تأكيد كلمة السر
                </ErrorText>
              )}
          </InputsWrapper>
        </ResetPasswordModalBody>
        <ModalFooter>
          {loadingResetPassword ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Save orangeBg onClick={() => handleSubmit()}>
              <SaveText color={Colors.white} size={23}>
                حفظ
              </SaveText>
            </Save>
          )}
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default EditAccountCompany;
export const PrivacyModal = styled(Modal)`
  direction: rtl;
`;
export const ResetPasswordModal = styled(Modal)`
  direction: rtl;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;
const ResetPasswordModalBody = styled(ModalBody)`
  @media (max-width: 500px) {
    padding-bottom: 5px;
  }
`;
const ResetPasswordTitle = styled(CustomText)`
  width: 70%;
  margin-top: 10px;
  text-align: center;
  margin: auto;
  font-size: 33px;
  @media (max-width: 1300px) {
    font-size: 28px;
  }
  @media (max-width: 1150px) {
    font-size: 23px;
  }
  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 850px) {
    font-size: 19px;
  }
  @media (max-width: 782px) {
    font-size: 18px;
  }
  @media (max-width: 426px) {
    font-size: 16px;
  }
  @media (max-width: 338px) {
    font-size: 15px;
  }
`;
const Save = styled(Confirm)`
  @media (max-width: 500px) {
    padding: 7.5px;
  }
`;
const SaveText = styled(CustomText)`
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1150px) {
    font-size: 13px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  @media (max-width: 400px) {
    margin: 20px 0;
  }
`;
const PasswordInput = styled(CustomInput)`
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
  }
  @media (max-width: 1100px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
  }
`;
const EditAccountWrapper = styled.div`
  margin: 100px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  align-items: center;
`;

const ProfileTitle = styled(CustomText)`
  padding: 35px 0px 20px 0px;
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

const ButtonText = styled(CustomText)`
  margin-top: -3px;
  width: 100%;
  font-size: 16px;
`;
const ResetPasswordText = styled(ButtonText)`
  font-size: 15px;
  cursor: pointer;
  margin-top: 25%;
  display: flex;
  color: cornflowerblue;
  justify-content: center;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  direction: rtl;
  padding: 30px 0px 30px;
  @media (max-width: 768px) {
    margin-bottom: 7%;
    width: 100%;
  }
`;
const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 30px;
  padding-top: 20px;
`;
const ProfilePicWrapper = styled.div`
  position: relative;
  background-color: ${Colors.white};
  border-radius: 50px;
`;
const PlusIcon = styled(AiFillPlusCircle)``;

const IconWrapper = styled.div`
  background-color: ${Colors.white};
  position: absolute;
  top: 85px;
  z-index: 2;
  border-radius: 50px;
  right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const ProfilePic = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
  @media (max-width: 320px) {
    width: 100px;
    height: 100px;
  }
`;
const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 355px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    height: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
`;
const ErrorText = styled(CustomText)`
  align-self: end;
  font-size: 10px;
  color: red;
  margin-right: 50px;
`;
export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.Grey};
`;
export const SelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  margin-top: 15px;
  width: 86%;
  @media screen and (max-width: 1366px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const SelectCity = styled(Select)`
  width: 93%;
  display: flex;
  margin: auto;
  color: ${Colors.Grey};
  font-family: ${Fonts.regular};
`;

export const PasswordIconInputContainer = styled.div<{error?: boolean}>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  justify-content: space-around;
  width: 86%;
`;
