const Colors = {
  white: '#FFFFFF',
  OffWhite: '#e0e2e4',
  darkGray: '#363a3e',
  darkGray2: '#ffffff17',
  mediumGray: '#ccced0',
  Grey: '#808386',
  Gray: '#c2c2c2',
  lightGray: '#808386',
  lightGray2: '#777777',
  lightGray3: '#808487',
  lightGray4: '#00000040',
  lightGray5: '#efefef',
  blue: '#5292CA',
  seaBlue: '#4f95d4',
  borderGray: '#c1c1c1',
  borderGray2: '#C1C1C180',
  lightBlueBg: '#F6FAFD',
  darkBlue: '#3B66B8',
  lightBlue: '#4285F4',
  orange: '#E55C12',
  shadow: '#e9edf0',
  black: '#000',
  bgBlue: '#4f95d40f',
  textGray: '#777777',
  blogBlue: '#5292ca',
  softBlue: '#3b96d5',
  red: '#ff0000',
  lightOrange: '#f2ac64',
  buttonColor1: '#F4AC64',
  buttonColor2: '#E55B0E',
  gradientSect1: '#395FA5',
  gradientSect2: '#385894',
  gradientSect3: '#374968',
  gradientSect4: '#363A3E',
  darkGray1: '#9B999E',
  lightNavyBlue: '#8e9093',
  green: '#5fc446',
};
export default Colors;
