import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown} from 'rsuite';
import styled from 'styled-components';
import {ConfigsReducer} from '../Action/types';
import Logo from '../Assets/Images/logo.png';
import {RootState} from '../Reducer';
import {CustomText} from './Common';
import {useHistory} from 'react-router-dom';
import {FaUserAlt} from 'react-icons/fa';
import Colors from '../Containers/Theme/Colors';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';
import {logoutUser, updateUser} from '../Action';
import {useCookies} from 'react-cookie';
import Menu from './Menu';
import Fonts from '../Theme/Fonts';
import CountryDropDown from './CountryDropDown';
const Header = () => {
  const [, , removeCookie] = useCookies(['rememberMeCookie']);
  const [random, setRandom] = useState<Boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const {loggedIn} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const [flipArrow, setFlipArrow] = useState<boolean>(false);

  return (
    <HeaderWrapper>
      <HeaderNav>
        <HeaderNavOptionLink to="/">
          <CustomTextLink>الرئيسية</CustomTextLink>
        </HeaderNavOptionLink>
        <HeaderNavOptionLink to="/guide">
          <CustomTextLink>دليل الإستخدام</CustomTextLink>
        </HeaderNavOptionLink>
        <HeaderNavOptionLink to="/blog-listing">
          <CustomTextLink>المجلة القانونية</CustomTextLink>
        </HeaderNavOptionLink>
        <HeaderNavOptionAnchor href="http://mustsharcom.com/" target="_blank">
          <CustomTextLink>المدونة</CustomTextLink>
        </HeaderNavOptionAnchor>
        <HeaderNavOptionLink
          onClick={() => setRandom(!random)}
          to={{
            pathname: '/',
            state: {contactus: random},
          }}>
          <CustomTextLink>تواصل معنا</CustomTextLink>
        </HeaderNavOptionLink>

        {loggedIn ? (
          <DropContainer>
            <DropWrapper>
              <Select
                title=""
                onToggle={() => setFlipArrow(!flipArrow)}
                icon={
                  <>
                    {flipArrow ? (
                      <ArrowUpIcon color={Colors.seaBlue} size={15} />
                    ) : (
                      <ArrowDownIcon color={Colors.seaBlue} size={15} />
                    )}
                    <FaUserAlt color={Colors.seaBlue} size={17} />
                  </>
                }
                noCaret>
                <DropItem
                  style={{
                    fontFamily: Fonts.regular,
                    fontSize: 14,
                    paddingLeft: 0,
                  }}
                  onSelect={() => history.push('/my-account')}>
                  الحساب
                </DropItem>
                <DropItem
                  style={{
                    fontFamily: Fonts.regular,
                    fontSize: 14,
                    paddingLeft: 0,
                  }}
                  onSelect={() => {
                    dispatch(logoutUser());
                    dispatch(updateUser({}));
                    removeCookie('rememberMeCookie');
                    history.push('/');
                  }}>
                  تسجيل الخروج
                </DropItem>
              </Select>
            </DropWrapper>
          </DropContainer>
        ) : (
          <HeaderNavOptionLink to="/login">
            <CustomTextLink>تسجيل الدخول</CustomTextLink>
          </HeaderNavOptionLink>
        )}
        <CountryDropContainer>
          <CountryDropDown />
        </CountryDropContainer>

        <Menu />
      </HeaderNav>
      <HeaderLogoWrapper>
        <HeaderNavOption to="/">
          <HeaderLogo src={Logo} />
        </HeaderNavOption>
      </HeaderLogoWrapper>
    </HeaderWrapper>
  );
};

export default Header;

export const HeaderWrapper = styled.div`
  background-color: white;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: relative;
  width: 100%;
  @media (max-width: 980px) {
    justify-content: space-around;
  }
  @media (max-width: 700px) {
    justify-content: space-evenly;
  }
  @media (max-width: 580px) {
    justify-content: space-around;
  }
`;

export const HeaderNav = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  flex: 0.95;
  padding-left: 35px;
  width: fit-content;
  @media (max-width: 1300px) {
    padding-left: 20px;
  }
  @media (max-width: 1200px) {
    padding-left: 10px;
  }
  @media (max-width: 1100px) {
    padding-left: 40px;
  }
  @media (max-width: 950px) {
    padding-left: 10px;
  }
  @media (max-width: 850px) {
    padding-left: 20px;
  }
  @media (max-width: 768px) {
    justify-content: flex-end;
  }
  @media (max-width: 700px) {
    justify-content: flex-end;
    width: 95%;
  }
  @media (max-width: 625px) {
    justify-content: flex-end;
    width: 80%;
  }
`;

export const HeaderNavOption = styled(Link)`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  outline: none;
  border: unset;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const HeaderNavOptionLink = styled(HeaderNavOption)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderNavOptionAnchorWrapper = styled.a`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  outline: none;
  border: unset;
  &:focus,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
export const HeaderNavOptionAnchor = styled(HeaderNavOptionAnchorWrapper)`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const HeaderLogo = styled.img`
  width: 80px;
  margin: 0 10px;
  @media (max-width: 768px) {
    margin: 0%;
  }
`;

export const HeaderLogoWrapper = styled.div`
  width: 10%;

  @media (max-width: 1100px) {
    width: 12%;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
`;
export const ArrowDownIcon = styled(IoIosArrowDown)`
  margin-right: 24px;
`;
export const ArrowUpIcon = styled(IoIosArrowUp)`
  margin-right: 24px;
`;
export const Select = styled(Dropdown)`
  margin-left: -15px;
`;
export const DropWrapper = styled.div`
  border-bottom: 5px solid ${Colors.seaBlue};
  width: 83%;
`;
export const DropContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const DropItem = styled(Dropdown.Item)`
  text-align: end;
  padding-left: 2rem;
`;

export const CustomTextLink = styled(CustomText)`
  cursor: pointer;
  :hover {
    color: ${Colors.darkGray};
  }
  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;

export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  cursor: pointer;
  &:first-child {
    color: ${Colors.lightGray};
  }
`;

export const CountryDropContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
