import React, {useState} from 'react';
import styled from 'styled-components';
import {CustomButton, CustomInput, CustomText} from '../../Components/Common';
import Colors from '../Theme/Colors';
import {FiEdit} from 'react-icons/fi';
import {useHistory, useParams} from 'react-router-dom';
import {setToken} from '../../Action';
import {useDispatch} from 'react-redux';
import {useMutation} from '@apollo/client';
import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_COMPANY,
} from './GraphQl/ForgotPassword';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Loader} from 'rsuite';
import {LoaderContainer} from './SignUp';
import {ErrorText} from './ResetPassword';
import {HeaderNavOptionLink} from '../../Components/Header';
import i18n from '../../I18next/I18n';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{usertype: string}>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [random, setRandom] = useState<Boolean>(true);

  const [forgetPassword, {loading: loadingLawyer}] = useMutation<{
    forgetPassword: string;
  }>(FORGET_PASSWORD);
  const [companyForgetPassword, {loading: loadingCompany}] = useMutation<{
    companyForgetPassword: boolean;
  }>(FORGET_PASSWORD_COMPANY);

  const ValidationSchema = Yup.object().shape(
    params?.usertype === 'lawyer'
      ? {
          lawyerEmail: Yup.string()
            .email()
            .required('الرجاء ادخال بريد الكتروني'),
        }
      : {
          email: Yup.string().required('الرجاء ادخال بريد الكتروني'),
        },
  );

  const {errors, touched, values, handleChange, handleSubmit} = useFormik({
    initialValues: {
      lawyerEmail: '',
      email: '',
    },
    onSubmit: async submittedValues => {
      if (params?.usertype === 'company') {
        return handleEmail(submittedValues.email.trim());
      } else {
        return handleLawyerEmail(submittedValues.lawyerEmail.trim());
      }
    },
    validationSchema: ValidationSchema,
  });

  const handleLawyerEmail = async (lawyerEmail: string) => {
    try {
      const isValid = await forgetPassword({
        variables: {
          email: lawyerEmail,
        },
      });
      if (isValid?.data?.forgetPassword) {
        dispatch(setToken(isValid?.data?.forgetPassword));

        history.push({
          pathname: `/verification/forgotpassword/${lawyerEmail}/lawyer`,
          state: {userType: 'lawyer', lawyerEmail: lawyerEmail},
        });
      }
    } catch (err) {
      if (err.message === 'account not found') {
        setErrorMessage('البريد الالكتروني الذي ادخلته غير صحيح');
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
        return;
      }
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  const handleEmail = async (email: string) => {
    try {
      const isValid = await companyForgetPassword({
        variables: {
          email,
        },
      });
      if (isValid?.data?.companyForgetPassword) {
        history.push({
          pathname: `/verification/forgotpassword/${email}/company`,
          state: {userType: 'company', email: email},
        });
      } else {
        setErrorMessage('البريد الالكتروني الذي ادخلته غير مسجل');
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  return (
    <>
      <Wrapper>
        <InnerContainer>
          <PreviewWrapper>
            <LeftSection>
              <ContactLink
                onClick={() => setRandom(!random)}
                to={{
                  pathname: '/',
                  state: {contactus: random},
                }}>
                <LoginSubtitle
                  marginTop={'8px'}
                  weight={'500'}
                  color={Colors.seaBlue}
                  size={15}>
                  هل نسيت البريد الالكتروني ؟
                </LoginSubtitle>
              </ContactLink>
              <SignIn
                color={Colors.seaBlue}
                weight={'500'}
                size={15}
                onClick={() => history.push('/login')}>
                تسجيل الدخول
              </SignIn>
              <LoginSubtitle
                weight={'500'}
                color={Colors.darkGray}
                size={15}
                noPointer>
                هل لديك حساب في عدل؟
              </LoginSubtitle>
            </LeftSection>
            <Divider />
            <TextWrapper>
              <CodeText size={21} color={Colors.darkGray}>
                اعادة تعيين كلمة السر
              </CodeText>
              {params?.usertype === 'lawyer' ? (
                <IconInputContianer
                  error={!!(errors.lawyerEmail && touched.lawyerEmail)}>
                  <StyledInput
                    type={'lawyerEmail'}
                    name="lawyerEmail"
                    placeholder={'البريد الالكتروني'}
                    value={values.lawyerEmail}
                    width={'90%'}
                    onChange={handleChange}
                  />
                  <FiEditIcon size={12} color={Colors.darkGray} />
                </IconInputContianer>
              ) : (
                <IconInputContianer>
                  <StyledInput
                    type={'email'}
                    name={'email'}
                    placeholder={'بريدك الإلكتروني'}
                    value={values.email}
                    width={'90%'}
                    onChange={handleChange}
                  />
                  <FiEditIcon size={12} color={Colors.darkGray} />
                </IconInputContianer>
              )}
              {loadingLawyer || loadingCompany ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <SendButton
                    type="submit"
                    orangeBg
                    width={'100%'}
                    onClick={() => {
                      handleSubmit();
                    }}>
                    <CustomText color={Colors.white} size={12}>
                      إرسال
                    </CustomText>
                  </SendButton>
                  {!!(errors.lawyerEmail && touched.lawyerEmail) && (
                    <>
                      {values.lawyerEmail ? (
                        <ErrorText color="red">
                          الرجاء ادخال بريد الكتروني صحيح
                        </ErrorText>
                      ) : (
                        <ErrorText color="red">
                          الرجاء ادخال البريد الالكتروني{' '}
                        </ErrorText>
                      )}
                    </>
                  )}
                  {!!(errors.email && touched.email) && (
                    <>
                      {values.email ? (
                        <ErrorText color="red">
                          الرجاء ادخال بريد الكتروني صحيح
                        </ErrorText>
                      ) : (
                        <ErrorText color="red">
                          الرجاء ادخال البريد الالكتروني{' '}
                        </ErrorText>
                      )}
                    </>
                  )}
                  {errorMessage !== '' && (
                    <ErrorText color="red">{errorMessage}</ErrorText>
                  )}
                </>
              )}
            </TextWrapper>
          </PreviewWrapper>
        </InnerContainer>
      </Wrapper>
    </>
  );
};

export default ForgotPassword;

export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CodeText = styled(CustomText)`
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    margin-right: 0;
  }
  @media (max-width: 910px) {
    font-size: 19px;
  }
  @media (max-width: 880px) {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    margin: auto;
    margin-bottom: 4%;
    font-size: 17px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 320px;
  margin-top: -20px;
  margin-bottom: -20px;
  @media (max-width: 780px) {
    display: none;
  }
`;
export const ContactLink = styled(HeaderNavOptionLink)`
  @media (max-width: 768px) {
    display: block;
  }
`;
export const PreviewWrapper = styled.div`
  margin: 100px 0;
  width: 65%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 1px 1px ${Colors.borderGray};
  padding: 20px 40px;

  @media (max-width: 780px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 400px) {
    padding: 20px 19px;
    width: 80%;
  }
`;
export const LoginSubtitle = styled(CustomText)<{noPointer?: boolean}>`
  ${props => !props.noPointer && 'cursor: pointer'};
  @media (max-width: 880px) {
    font-size: 13px;
  }
  @media screen and (max-width: 764px) {
    font-size: small;
  }
  @media (max-width: 470px) {
    font-size: 12px;
  }
`;
export const StyledInput = styled(CustomInput)`
  direction: rtl;
  margin-top: 11px;
  border-bottom: none;
  width: 90%;
  outline: none;
  margin-left: -18px;
  @media (max-width: 880px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
`;
export const FiEditIcon = styled(FiEdit)`
  position: absolute;
  top: 23px;
  right: 19px;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  margin-right: -20px;
  margin-left: 20px;
  @media (max-width: 780px) {
    margin-left: 0;
    margin-right: 0;
    width: 70%;
    margin-top: 8%;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
  width: 40%;
  @media (max-width: 1147px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 780px) {
    margin-top: 8%;
  }
  @media (max-width: 715px) {
    width: 80%;
  }
`;
export const IconInputContianer = styled.div<{error?: boolean}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  width: 100%;
  position: relative;
`;
export const SendButton = styled(CustomButton)`
  margin-top: 20px;
  @media (max-width: 575px) {
    padding: 7px;
  }
`;
export const SignIn = styled(CustomText)`
  margin-right: 4px;
  margin-top: 8px;
  cursor: pointer;
  @media (max-width: 880px) {
    font-size: 13px;
  }
  @media (max-width: 470px) {
    font-size: 12px;
  }
`;
