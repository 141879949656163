import gql from 'graphql-tag';
import {COMPANY_FRAGMENT, LAWYER_FRAGMENT} from '../../Auth/GraphQl/SignUp';

export const COUNTRIES = gql`
  query findAllCountries {
    findAllCountries {
      id
      country
      show
      disabled
      isoCode
      whats_app_phone
      currency
    }
  }
`;
export const SUGGESTION = gql`
  mutation createSuggestion(
    $suggestion: String!
    $name: String!
    $phoneNumber: String!
    $email: String!
  ) {
    createSuggestion(
      suggestionInput: {
        suggestion: $suggestion
        phoneNumber: $phoneNumber
        email: $email
        name: $name
      }
    ) {
      id
      suggestion
    }
  }
`;

export const SWITCH_ACCOUNT_LAWYER_LOGIN = gql`
  mutation switchAccountLawyerLogin(
    $token: String!
    $lawyer_id: String
    $company_id: String
  ) {
    switchAccountLawyerLogin(
      switchAccountInput: {
        token: $token
        lawyer_id: $lawyer_id
        company_id: $company_id
      }
    ) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const SWITCH_ACCOUNT_COMPANY_LOGIN = gql`
  mutation switchAccountCompanyLogin(
    $token: String!
    $lawyer_id: String
    $company_id: String
  ) {
    switchAccountCompanyLogin(
      switchAccountInput: {
        token: $token
        lawyer_id: $lawyer_id
        company_id: $company_id
      }
    ) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const SEARCH_CONFIG = gql`
  query searchConfigByCountry {
    searchConfigByCountry {
      configs {
        buttonParams
        button
        labelEn
        labelAr
      }
      country {
        id
        country
        show
        disabled
        isoCode
      }
      updated_at
      created_at
      deleted
    }
  }
`;

export const ANNOUNCEMENTS = gql`
  query findAllAnnouncements {
    findAllAnnouncements {
      title
      body
      active
      redirect_url
      order
      background_image {
        filename
      }
    }
  }
`;

export const HOME_PAGE_CONTENT = gql`
  query getHomePageContent {
    getHomePageContent {
      id
      country {
        id
        country
        show
        disabled
        isoCode
      }
      main {
        title
        firstContent
        secondContent
      }
      learnMore {
        title
        content
      }
      afaq {
        title
        content
      }
      afaqPoints {
        title
        content
      }
    }
  }
`;
