import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {
  CustomText,
  CustomInput,
  CustomButton,
  CustomTextButton,
} from '../../Components/Common';
import Colors from '../Theme/Colors';
import Fonts from '../../Theme/Fonts';
import {AiOutlineEye} from 'react-icons/ai';
import {AiOutlineEyeInvisible} from 'react-icons/ai';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {
  Company,
  ConfigsReducer,
  LawyerType,
  Membership,
  searchButtonEnum,
} from '../../Action/types';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  CREATE_COMPANY,
  CREATE_LAWYER,
  FIND_BY_Naqabeh_Number,
  FIND_MEMBERSHIPS,
} from './GraphQl/SignUp';
import {Link, useHistory} from 'react-router-dom';
import {Alert, Loader, Modal} from 'rsuite';
import {MakePaymentAction} from '../../PaymentHelper';
import i18n from '../../I18next/I18n';
import CountryCityPicker from '../../Components/CountryCityPicker';
import {useSelector} from 'react-redux';
import {RootState} from '../../Reducer';
import {validateArabicInput, validatePhoneNumber} from '../../helpers';
import {SEARCH_CONFIG} from '../Search/GraphQl/Home';

const Shared_Scheema = {
  phone: Yup.string().required('مطلوب'),
  email: Yup.string().required('مطلوب').email('البريد الالكتروني خاطئ'),
  city: Yup.string().required('الرجاء الاختيار من القائمة'),
  password: Yup.string()
    .required('مطلوب')
    .min(8, 'يجب ان تتكون كلمة السر من 8 خانات على الأقل'),
  confirm_password: Yup.string()
    .required('مطلوب')
    .oneOf([Yup.ref('password')], 'كلمة السر غير مطابقة'),
  membershipId: Yup.string().required('يجب اختيار الاشتراك'),
  name: Yup.string()
    .required('مطلوب')
    .test('name', val => validateArabicInput(val))
    .matches(
      /^\s*\S+(?:\s+\S+){3,}\s*$/,
      'يجب ان يتكون الاسم من 4 مقاطع على الأقل',
    ),
  type: Yup.string().required('مطلوب'),
};

const SignUp = () => {
  const [passVisibility, setpassVisibility] = useState(false);
  const [subType, setSubType] = useState<string>('');
  const [agreed, setAgreed] = useState(false);

  const [changeNaqabeh, setChangeNaqabeh] = useState('');

  const history = useHistory();
  const client = useApolloClient();
  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const [
    findLawyerByNaqabehNumber,
    {data: findLawyer, loading: loadingNaqabehNumber},
  ] = useLazyQuery(FIND_BY_Naqabeh_Number);

  const {data: searchConfigs, loading: loadingSearchConfigs} =
    useQuery(SEARCH_CONFIG);
  const [createLawyer, {loading: createLawyerLoading}] =
    useMutation(CREATE_LAWYER);
  const [createCompany, {loading: createCompanyLoading}] = useMutation<{
    createCompany: Company;
  }>(CREATE_COMPANY);

  const {data: memberships} = useQuery<{
    findAllMemberships: Membership[];
  }>(FIND_MEMBERSHIPS);

  const handleSelectChange = (e: any) => {
    setSubType(e.target.value);
    const persistedCityValue = values.city;
    resetForm();
    setTimeout(() => {
      setFieldValue('city', persistedCityValue);
    }, 0);
    setFieldValue('type', e.target.value);
    setAgreed(false);
  };

  const {selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const ValidationSchema = Yup.object().shape(
    subType === 'lawyer' || subType === 'advisor'
      ? selectedCountry.isoCode !== 'QA'
        ? {
            ...Shared_Scheema,
            naqabeh_number: Yup.string().required('مطلوب'),
          }
        : {
            ...Shared_Scheema,
            ssn: Yup.string().required('مطلوب'),
          }
      : subType === 'shari'
      ? {
          ...Shared_Scheema,
          ejazeh_number: Yup.string().required('مطلوب'),
        }
      : subType === 'judge' || subType === 'expert'
      ? {
          ...Shared_Scheema,
          ssn: Yup.string().required('مطلوب'),
        }
      : {
          type: Yup.string().required('مطلوب'),
          email: Yup.string().email().required('البريد الالكتروني خاطئ'),
          city: Yup.string().required('الرجاء الاختيار من القائمة'),
          password: Yup.string()
            .required('مطلوب')
            .min(8, 'يجب ان تتكون كلمة السر من 8 خانات على الأقل'),
          confirm_password: Yup.string()
            .required('مطلوب')
            .oneOf([Yup.ref('password')], 'كلمة السر غير مطابقة'),
          company_id: Yup.string().required('مطلوب'),
          signature_auth: Yup.string()
            .required('مطلوب')
            .test('name', val => validateArabicInput(val))
            .matches(
              /^\s*\S+(?:\s+\S+){3,}\s*$/,
              'يجب ان يتكون الاسم من 4 مقاطع على الأقل',
            ),
          name: Yup.string().required('مطلوب'),
          membershipId: Yup.string().required('يجب اختيار الاشتراك'),
        }, // to be used later for company sign up
  );

  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      type: '',
      phone: '',
      email: '',
      city: '',
      naqabeh_number: changeNaqabeh,
      ejazeh_number: '',
      ssn: '',
      company_id: '',
      name: '',
      specialties: '60acb93c12aa5d001c3646a1',
      password: '',
      confirm_password: '',
      signature_auth: '',
      membershipId: '',
    },
    validationSchema: ValidationSchema,

    onSubmit: async submittedValues => {
      if (!agreed) {
        return setModal({
          isOn: true,
          message: 'الرجاء الموافقة على سياسة الخصوصية واتفاقية الاستخدام',
        });
      }
      if (submittedValues.type !== 'company') {
        const phoneNumber = validatePhoneNumber(submittedValues.phone, false);
        if (
          phoneNumber.isValid() &&
          submittedValues.password === submittedValues.confirm_password
        ) {
          try {
            const {company_id, signature_auth, ...lawyerInfo} = submittedValues;
            handleCreateLawyer({
              ...lawyerInfo,
              country: selectedCountry.id || '',
              phone: phoneNumber.number as string,
            });
          } catch (err: any) {
            if (err.graphQLErrors[0].message === 'Account already exists') {
              setModal({isOn: true, message: 'الحساب موجود بالفعل'});
            } else if (
              err.graphQLErrors[0].message ===
              "You already have an account use the 'Forgot Password' button below to regain access to it"
            ) {
              setModal({
                isOn: true,
                message:
                  'لديك حساب بالفعل ، استخدم الزر "نسيت كلمة المرور" أدناه لاستعادة الوصول إليه',
              });
            } else {
              setModal({isOn: true, message: err.graphQLErrors[0].message});
            }
          }
        } else {
          if (submittedValues.password !== submittedValues.confirm_password) {
            setFieldError('confirm_password', 'كلمة السر غير مطابقة');
          }
          if (!phoneNumber.isValid()) {
            setFieldError('phone', 'رقم الهاتف غير صحيح');
          }
        }
      } else {
        if (submittedValues.password === submittedValues.confirm_password) {
          handleCreateCompany({
            ssn: submittedValues.company_id.toString(),
            name: submittedValues.name,
            authorized_signatory: submittedValues.signature_auth,
            email: submittedValues.email,
            city: submittedValues.city,
            country: selectedCountry.id || '',
            password: submittedValues.password,
          });
        } else {
          if (submittedValues.password !== submittedValues.confirm_password) {
            setFieldError('confirm_password', 'كلمة السر غير مطابقة');
          }
        }
      }
    },
  });

  const handleCreateCompany = async (inputs: {
    ssn: string;
    name: string;
    authorized_signatory: string;
    email: string;
    password: string;
    city: string;
    country: string;
  }) => {
    try {
      const {data} = await createCompany({
        variables: {
          ssn: inputs.ssn.toString(),
          name: inputs.name,
          authorized_signatory: inputs.authorized_signatory,
          email: inputs.email,
          password: inputs.password,
          city: inputs.city,
          country: inputs.country,
        },
      });
      if (data) {
        const {email, token} = data.createCompany;
        if (email) {
          history.push({
            pathname: `/verification/verifyuser/${email}/company`,
            state: {email},
          });
        }
        const currentMembership = memberships?.findAllMemberships?.find(
          membership => values.membershipId === membership.id,
        );
        if (currentMembership && !currentMembership.type && email) {
          history.push({
            pathname: `/verification/verifyuser/${email}/company`,
            state: {email},
          });
        } else {
          currentMembership &&
            MakePaymentAction({
              client,
              accountToken: token,
              input: {
                cart_amount: currentMembership?.cost?.toString(),
                cart_currency: 'JOD',
                dynamic_redirection: 'signup',
                email,
                membership_id: values.membershipId,
              },
            });
        }
      }
    } catch (error: any) {
      if (
        error.graphQLErrors[0].message ===
        'No Correct email Provided Please Contact System Admins'
      ) {
        setModal({
          isOn: true,
          message: 'سيقوم مسؤول النظام بالتواصل معك لتوثيق حسابك',
        });
        return;
      }
      if (
        error.message ===
        "You already have an account use the 'Forgot Password' button below to regain access to it"
      ) {
        setModal({isOn: true, message: 'يوجد حساب بهذا الرقم الوطني'});
      } else if (
        error?.message ===
        'Email Or PhoneAlready Associated With Another Account'
      ) {
        setModal({
          isOn: true,
          message:
            'هذا الحساب موجود سابقا، او انك سجلت حساب سابق بنفس البريد الإلكتروني في مجال قانوني آخر، يجب ان يكون لكل حساب بريد الكتروني مختلف.',
        });
      } else {
        setModal({
          isOn: true,
          message: i18n.t('Something went wrong please try again later'),
        });
      }
    }
  };

  const handleCreateLawyer = async (inputs: {
    type: String;
    phone: String;
    email: String;
    city: String;
    country: String;
    naqabeh_number?: String;
    ejazeh_number?: String;
    ssn?: String;
    name?: string;
    specialties?: String;
    password: String;
    confirm_password: String;
  }) => {
    const {naqabeh_number, ejazeh_number, ssn, ...restInputs} = inputs;
    let variables;
    if (
      (inputs.type === 'lawyer' || inputs.type === 'advisor') &&
      selectedCountry.isoCode !== 'QA'
    ) {
      const naqabeh_numberString = naqabeh_number?.toString();
      variables = {
        ...restInputs,
        naqabeh_number: naqabeh_numberString,
      };
    } else if (inputs.type === 'shari') {
      const ejazeh_numberString = ejazeh_number?.toString();
      variables = {
        ...restInputs,
        ejazeh_number: ejazeh_numberString,
      };
    } else {
      const ssnToString = ssn?.toString();
      variables = {
        ...restInputs,
        ssn: ssnToString,
      };
    }

    try {
      const data = await createLawyer({
        variables,
      });
      const {phone, email, token} = data?.data?.createLawyer;
      const currentMembership = memberships?.findAllMemberships?.find(
        membership => values.membershipId === membership.id,
      );
      if (currentMembership && !currentMembership.type) {
        history.push({
          pathname: `/verification/verifyuser/${email}/lawyer`,
          state: {phone, lawyerEmail: email},
        });
      } else {
        currentMembership &&
          MakePaymentAction({
            client,
            accountToken: token,
            input: {
              cart_amount: currentMembership?.cost?.toString(),
              cart_currency: 'JOD',
              dynamic_redirection: 'signup',
              email,
              membership_id: values.membershipId,
            },
          });
      }
    } catch (err: any) {
      if (
        err.graphQLErrors[0].message ===
        'No Correct email Provided Please Contact System Admins'
      ) {
        setModal({
          isOn: true,
          message: 'سيقوم مسؤول النظام بالتواصل معك لتوثيق حسابك',
        });

        return;
      }
      if (err?.message === 'Account already exists') {
        setModal({
          isOn: true,
          message:
            'هذا الحساب موجود سابقا، او انك سجلت حساب سابق بنفس البريد الإلكتروني في مجال قانوني آخر، يجب ان يكون لكل حساب بريد الكتروني مختلف.',
        });
      } else if (
        err?.message === 'Email Or PhoneAlready Associated With Another Account'
      ) {
        setModal({
          isOn: true,
          message:
            'هذا الحساب موجود سابقا، او انك سجلت حساب سابق بنفس البريد الإلكتروني في مجال قانوني آخر، يجب ان يكون لكل حساب بريد الكتروني مختلف.',
        });
      } else {
        setModal({isOn: true, message: err?.message});
      }
    }
  };
  const checkNaqabehNumber = async (inputValue: string) => {
    if (inputValue === '') {
      values.name = '';
      values.email = '';
      values.phone = '';
    }
    setFieldValue('naqabeh_number', inputValue);
    setChangeNaqabeh(inputValue);
    if (inputValue.length >= 2 && inputValue.length < 10) {
      try {
        await findLawyerByNaqabehNumber({
          variables: {
            naqabeh_number: inputValue,
          },
        });
      } catch (err: any) {
        Alert.success('Error creating', err.graphQLErrors[0].message);
      }
    }
  };

  useEffect(() => {
    if (findLawyer?.findLawyerByNaqabehNumber) {
      if (changeNaqabeh === values.naqabeh_number) {
        setFieldValue('naqabeh_number', changeNaqabeh);
        setFieldValue('name', findLawyer?.findLawyerByNaqabehNumber?.name);
        setFieldValue('phone', findLawyer?.findLawyerByNaqabehNumber?.phone);
        if (
          !findLawyer?.findLawyerByNaqabehNumber?.email.includes(
            'he_doesnt_have_email',
          )
        ) {
          setFieldValue('email', findLawyer?.findLawyerByNaqabehNumber?.email);
        }
      }
    } else {
      values.name = '';
      values.email = '';
      values.phone = '';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingNaqabehNumber, findLawyer?.findLawyerByNaqabehNumber]);

  const inputPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    // @ts-ignore
    if (e.target.value === '' || re.test(e.target.value)) {
      // @ts-ignore
      setFieldValue('phone', e.target.value);
    }
  };

  const allowOnlyNumerics = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/\D/g.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, '');
    }
  };
  const searchConfigsButtons = searchConfigs?.searchConfigByCountry?.configs;

  const buttonToTypeMap = {
    [searchButtonEnum.search_for_lawyer]: LawyerType.lawyer,
    [searchButtonEnum.search_for_judge]: LawyerType.judge,
    [searchButtonEnum.search_for_expert]: LawyerType.expert,
    [searchButtonEnum.search_for_shari]: LawyerType.shari,
    [searchButtonEnum.search_for_advisor]: LawyerType.advisor,
    [searchButtonEnum.search_for_company]: LawyerType.company,
  };

  return (
    <Wrapper>
      <InnerContainer>
        <PreviewWrapper>
          <LeftSection>
            <SignIn
              color={Colors.seaBlue}
              weight={'500'}
              size={15}
              onClick={() => history.push('/login')}>
              تسجيل الدخول
            </SignIn>
            <LoginSubtitle weight={'500'} color={Colors.darkGray} size={15}>
              هل لديك حساب في عدل؟
            </LoginSubtitle>
          </LeftSection>
          <Divider />
          <TextWrapper>
            <ColumnWrapper>
              <LabelsWrapper>
                <FreeAndEasyText weight="500" size={17} color={Colors.darkGray}>
                  سهل وسريع ومجاني
                </FreeAndEasyText>
                <LoginText size={21} color={Colors.darkGray}>
                  التسجيل في عدل
                </LoginText>
              </LabelsWrapper>
            </ColumnWrapper>
            <ListWrapper>
              {(values.type === 'lawyer' || values.type === 'advisor') && (
                <UnOrderedList>
                  <List>
                    {
                      <CustomText
                        weight="500"
                        color={Colors.darkGray}
                        size={13.3}>
                        يجب أن يتطابق عنوان البريد الإلكتروني و رقم الهاتف مع
                        الاسم المسجل في النقابة
                      </CustomText>
                    }
                  </List>
                </UnOrderedList>
              )}
            </ListWrapper>
            <Inputs>
              <CountryCityPicker
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                touched={touched}
                resetForm={resetForm}
                subType={subType}
              />
              <SelectContainer error={!!(errors.type && touched.type)}>
                <Select onChange={handleSelectChange} required>
                  <Option value={'default'} disabled selected>
                    التخصص القانوني
                  </Option>
                  {!loadingSearchConfigs &&
                    searchConfigsButtons?.length > 0 &&
                    searchConfigsButtons.map(
                      ({
                        button,
                        labelAr,
                      }: {
                        button: searchButtonEnum;
                        labelAr: string;
                      }) => {
                        const buttonType = buttonToTypeMap[button];
                        return (
                          <Option key={labelAr} value={buttonType}>
                            {buttonType === 'lawyer'
                              ? 'محامي'
                              : buttonType === 'shari'
                              ? 'محامي شرعي'
                              : buttonType === 'judge'
                              ? 'محكم'
                              : buttonType === 'expert'
                              ? 'خبير'
                              : buttonType === 'advisor'
                              ? 'مستشار قانوني'
                              : 'شركة'}
                          </Option>
                        );
                      },
                    )}
                </Select>
              </SelectContainer>
              {!!(errors.type && touched.type) && (
                <>
                  <ErrorText size={10}>
                    {errors.type && touched.type && errors.type}
                  </ErrorText>
                </>
              )}

              {(subType === 'lawyer' || subType === 'advisor') &&
              selectedCountry.isoCode !== 'QA' ? (
                <>
                  <StyledInput
                    placeholder={'الرقم النقابي'}
                    name="naqabeh_number"
                    width={'90%'}
                    type={'number'}
                    disabled={!values.type}
                    onChange={e => {
                      checkNaqabehNumber(e?.target?.value);
                      allowOnlyNumerics(e);
                      handleChange(e?.target?.value);
                    }}
                    // value={values.naqabeh_number}
                    error={
                      !!(
                        errors.naqabeh_number &&
                        touched.naqabeh_number &&
                        values.type
                      )
                    }
                  />
                  {!!(
                    errors.naqabeh_number &&
                    touched.naqabeh_number &&
                    values.type
                  ) && (
                    <>
                      <ErrorText size={10}>
                        {errors.naqabeh_number &&
                          touched.naqabeh_number &&
                          errors.naqabeh_number}
                      </ErrorText>
                    </>
                  )}
                </>
              ) : subType === 'shari' ? (
                <>
                  <StyledInput
                    placeholder={'رقم الإجازة الشرعية'}
                    name="ejazeh_number"
                    width={'90%'}
                    disabled={!values.type}
                    onChange={handleChange}
                    value={values.ejazeh_number}
                    error={
                      !!(
                        errors.ejazeh_number &&
                        touched.ejazeh_number &&
                        values.type
                      )
                    }
                  />
                  {!!(
                    errors.ejazeh_number &&
                    touched.ejazeh_number &&
                    values.type
                  ) && (
                    <>
                      <ErrorText size={10}>
                        {errors.ejazeh_number &&
                          touched.ejazeh_number &&
                          errors.ejazeh_number}
                      </ErrorText>
                    </>
                  )}
                </>
              ) : subType === 'company' ? (
                <>
                  <StyledInput
                    placeholder={'الرقم الوطني للمنشأة'}
                    name="company_id"
                    width={'90%'}
                    type={'number'}
                    disabled={!values.type}
                    onChange={handleChange}
                    value={values.company_id}
                    error={
                      !!(errors.company_id && touched.company_id && values.type)
                    }
                  />
                  {!!(
                    errors.company_id &&
                    touched.company_id &&
                    values.type
                  ) && (
                    <>
                      <ErrorText size={10}>
                        {errors.company_id &&
                          touched.company_id &&
                          errors.company_id}
                      </ErrorText>
                    </>
                  )}
                </>
              ) : (
                <>
                  <StyledInput
                    placeholder={
                      selectedCountry.isoCode !== 'QA'
                        ? 'الرقم الوطني'
                        : 'الرقم الشخصي'
                    }
                    name="ssn"
                    width={'90%'}
                    type={'number'}
                    onChange={handleChange}
                    value={values.ssn}
                    disabled={!values.type}
                    error={!!(errors.ssn && touched.ssn)}
                  />
                  {!!(errors.ssn && touched.ssn && values.type) && (
                    <>
                      <ErrorText size={10}>
                        {errors.ssn && touched.ssn && errors.ssn}
                      </ErrorText>
                    </>
                  )}
                </>
              )}
              {subType === 'company' ? (
                <>
                  <StyledInput
                    placeholder={'اسم الشركة'}
                    width={'90%'}
                    onChange={handleChange}
                    name="name"
                    value={values.name}
                    disabled={!values.type}
                    error={!!(errors.name && touched.name && values.type)}
                  />
                  {!!(errors.name && touched.name && values.type) && (
                    <ErrorText size={10}>
                      {errors.name && touched.name && errors.name}
                    </ErrorText>
                  )}
                  <StyledInput
                    placeholder={'اسم المفوض بالتوقيع'}
                    name="signature_auth"
                    width={'90%'}
                    onChange={handleChange}
                    disabled={!values.type}
                    value={values.signature_auth}
                    error={
                      !!(
                        errors.signature_auth &&
                        touched.signature_auth &&
                        values.type
                      )
                    }
                  />
                  {!!(
                    errors.signature_auth &&
                    touched.signature_auth &&
                    values.type
                  ) && (
                    <ErrorText size={10}>
                      {errors.signature_auth.includes('مقاطع')
                        ? errors.signature_auth
                        : 'يجب أن يكون الاسم باللغة العربية'}
                    </ErrorText>
                  )}
                </>
              ) : (
                <>
                  <StyledInput
                    placeholder={
                      'الاسم' || findLawyer?.findLawyerByNaqabehNumber?.name
                    }
                    name="name"
                    disabled={!values.type}
                    width={'90%'}
                    onChange={handleChange}
                    value={values.name}
                    readOnly={
                      findLawyer && findLawyer?.findLawyerByNaqabehNumber?.name
                    }
                    error={!!(errors.name && touched.name && values.type)}
                  />
                  {!!(errors.name && touched.name && values.type) && (
                    <ErrorText size={10}>
                      {errors.name.includes('مقاطع')
                        ? errors.name
                        : 'يجب أن يكون الاسم باللغة العربية'}
                    </ErrorText>
                  )}
                </>
              )}
              {subType !== 'company' && (
                <>
                  <StyledInput
                    placeholder={
                      'رقم الهاتف' ||
                      findLawyer?.findLawyerByNaqabehNumber?.phone
                    }
                    name="phone"
                    onChange={inputPhoneNumber}
                    width={'90%'}
                    value={values.phone}
                    disabled={!values.type}
                    readOnly={
                      findLawyer && findLawyer?.findLawyerByNaqabehNumber?.phone
                    }
                    error={!!(errors.phone && touched.phone && values.type)}
                  />
                  {!!(errors.phone && touched.phone && values.type) && (
                    <>
                      <ErrorText size={10}>
                        {errors.phone && touched.phone && errors.phone}
                      </ErrorText>
                    </>
                  )}
                </>
              )}
              <StyledInput
                placeholder={
                  'البريد الإلكتروني' ||
                  findLawyer.findLawyerByNaqabehNumber.email
                }
                name="email"
                onChange={handleChange}
                width={'90%'}
                disabled={!values.type}
                value={values.email}
                readOnly={
                  findLawyer &&
                  findLawyer?.findLawyerByNaqabehNumber?.email &&
                  !findLawyer?.findLawyerByNaqabehNumber?.email.includes(
                    'he_doesnt_have_email',
                  )
                }
                error={!!(errors.email && touched.email && values.type)}
              />
              {!!(errors.email && touched.email && values.type) && (
                <>
                  <ErrorText size={10}>
                    {errors.email && touched.email && errors.email}
                  </ErrorText>
                </>
              )}
              <PasswordIconInputContainer
                error={!!(errors.password && touched.password && values.type)}>
                <StyledInputPassword
                  type={passVisibility ? 'text' : 'password'}
                  placeholder={'كلمة السر'}
                  name="password"
                  disabled={!values.type}
                  onChange={handleChange}
                  width={'80%'}
                  value={values.password}
                />
                <AiOutlineEyeInvisible
                  size={passVisibility ? '0' : '20'}
                  color={Colors.darkGray}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
                <AiOutlineEye
                  size={passVisibility ? '20' : '0'}
                  color={Colors.darkGray}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              </PasswordIconInputContainer>
              {!!(errors.password && touched.password && values.type) && (
                <>
                  <ErrorText size={10}>
                    {errors.password && touched.password && errors.password}
                  </ErrorText>
                </>
              )}

              <PasswordIconInputContainer
                error={
                  !!(
                    errors.confirm_password &&
                    touched.confirm_password &&
                    values.type
                  )
                }>
                <StyledInputPassword
                  type={passVisibility ? 'text' : 'password'}
                  placeholder={'إعادة كلمة السر'}
                  name="confirm_password"
                  disabled={!values.type}
                  onChange={handleChange}
                  width={'90%'}
                  value={values.confirm_password}
                />
                <AiOutlineEyeInvisible
                  size={passVisibility ? '0' : '20'}
                  color={Colors.darkGray}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
                <AiOutlineEye
                  size={passVisibility ? '20' : '0'}
                  color={Colors.darkGray}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              </PasswordIconInputContainer>
              {!!(
                errors.confirm_password &&
                touched.confirm_password &&
                values.type
              ) && (
                <>
                  <ErrorText size={10}>
                    {errors.confirm_password &&
                      touched.confirm_password &&
                      errors.confirm_password}
                  </ErrorText>
                </>
              )}
              <SelectContainer
                error={
                  !!(errors.membershipId && touched.membershipId && values.type)
                }>
                <Select
                  required
                  disabled={!values.type}
                  onChange={value =>
                    setFieldValue('membershipId', value.currentTarget.value)
                  }>
                  <Option
                    value={''}
                    disabled={!!values.membershipId}
                    selected={!values.membershipId}>
                    نوع الإشتراك
                  </Option>
                  {memberships?.findAllMemberships?.map(membership => (
                    <Option value={membership?.id} key={membership.id}>
                      {membership?.name}
                    </Option>
                  ))}
                </Select>
              </SelectContainer>
              {!!(
                errors.membershipId &&
                touched.membershipId &&
                values.type
              ) && (
                <>
                  <ErrorText size={10}>
                    {errors.membershipId &&
                      touched.membershipId &&
                      errors.membershipId}
                  </ErrorText>
                </>
              )}
              <Link
                to={
                  subType === 'company'
                    ? '/memberships/fromSignup/company'
                    : '/memberships/fromSignup/lawyer'
                }
                target="_blank"
                rel="noopener noreferrer">
                <ReadMore size={10}>...للمزيد من المعلومات</ReadMore>
              </Link>
            </Inputs>
            <CheckBoxWrapper>
              <Label htmlFor={'rememberMe'}>
                أوافق على سياسة الخصوصية و اتفاقية الاستخدام
              </Label>
              <CheckBox
                type={'checkbox'}
                id={'rememberMe'}
                width={'10px'}
                onChange={() => setAgreed(!agreed)}
                checked={agreed}
              />
            </CheckBoxWrapper>
            <Policy size={14} weight={'500'} color={Colors.darkGray}>
              عند الضغط على إنشاء حساب فأنت توافق على{' '}
              <Span onClick={() => history.push('/privacy-policy')}>
                سياسة الخصوصية{' '}
              </Span>{' '}
              و{' '}
              <Span onClick={() => history.push('/terms-and-conditions')}>
                اتفاقية الاستخدام
              </Span>{' '}
              الخاصة بالموقع
            </Policy>
            <SubmitWrapper>
              {createLawyerLoading || createCompanyLoading ? (
                <LoaderContainer>
                  <Loader style={{alignSelf: 'center'}} />
                </LoaderContainer>
              ) : (
                <Register
                  type="submit"
                  // disable={createLawyerLoading || createCompanyLoading}
                  orangeBg
                  width={'100%'}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  <CustomTextButton color={Colors.white} size={12}>
                    إنشاء حساب
                  </CustomTextButton>
                </Register>
              )}
            </SubmitWrapper>
          </TextWrapper>
        </PreviewWrapper>
      </InnerContainer>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => {
              if (
                findLawyer &&
                findLawyer?.findLawyerByNaqabehNumber?.email &&
                findLawyer?.findLawyerByNaqabehNumber?.email.includes(
                  'he_doesnt_have_email',
                )
              ) {
                history.push('/login');
              }
              setModal({isOn: false, message: ''});
            }}>
            <CustomText color={Colors.white} size={12}>
              حسنا
            </CustomText>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
    </Wrapper>
  );
};

export default SignUp;
export const PrivacyModal = styled(Modal)`
  direction: rtl;
  margin-top: 20%;
  @media (max-width: 768px) {
    width: 75%;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
`;
const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 25px;
`;
export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const FreeAndEasyText = styled(CustomText)`
  margin-top: 3px;
  margin-right: 3px;
  @media screen and (max-width: 859px) {
    font-size: 14px;
  }
`;
export const SignIn = styled(CustomText)`
  margin-right: 3px;
  cursor: pointer;
  @media screen and (max-width: 552px) {
    font-size: small;
  }
`;

export const UnOrderedList = styled.ul`
  margin-top: 30px;
  width: 100%;
  direction: rtl;
  margin-left: 15px;
`;
export const List = styled.li`
  font-size: 18px;
  color: ${Colors.darkGray};
`;

export const Inputs = styled.div`
  margin-bottom: 15px;
  margin-left: 1px;
  width: 100%;
  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
export const SelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  margin-top: 15px;
  width: 98.5%;
  @media screen and (max-width: 1366px) {
    width: 98.5%;
    margin-left: 0px;
  }
  @media screen and (max-width: 395px) {
    font-size: x-small;
  }
`;
export const Select = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  outline: none;
  border: none;
  padding-bottom: 12px;
  width: 95%;
  margin-left: 7px;
  color: ${Colors.lightGray};
  font-family: ${Fonts.regular};
`;
export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  &:first-child {
    color: ${Colors.lightGray};
  }
`;
export const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 800px;
  margin-top: -20px;
  margin-bottom: -20px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PreviewWrapper = styled.div`
  margin: 100px 0;
  width: 65%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 1px 1px ${Colors.borderGray};
  padding: 40px;
  @media screen and (max-width: 1327px) {
    width: 80%;
  }
  @media screen and (max-width: 418px) {
    padding: 30px;
    width: 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const StyledInput = styled(CustomInput)`
  direction: rtl;
  margin-top: 10px;
  outline: none;
  width: 98.6%;
  @media screen and (max-width: 395px) {
    font-size: x-small;
  }
`;
export const StyledInputPassword = styled(StyledInput)`
  border-bottom: 0px;
`;
export const CheckBox = styled(CustomInput)`
  margin: 8px 0px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 45%;
  padding-left: 13px;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  margin-top: 19%;
  width: 40%;
  text-align: center;
  @media (max-width: 1122px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 768px) {
    margin-top: 8%;
    width: 100%;
  }
`;
export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: end;
  margin-right: 20px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin-right: 10px;
  }
`;
export const ListWrapper = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  color: ${Colors.lightGray};
  font-size: 13px;
  margin: 7px 15px 0px 0px;
`;

export const Span = styled.span`
  cursor: pointer;
  color: ${Colors.seaBlue};
`;
export const Policy = styled(CustomText)`
  margin: 20px 0 0 0;
  direction: rtl;
`;
export const Register = styled(CustomButton)`
  width: 100%;
`;
export const Confirm = styled(Register)``;
const LoginSubtitle = styled(CustomText)`
  margin: 0 0 0 1px;
  @media screen and (max-width: 1122px) {
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 764px) {
    font-size: small;
  }
`;
const LoginText = styled(CustomText)`
  margin: 0 0 0 1px;
  @media screen and (max-width: 859px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1073px) {
    flex-direction: column-reverse;
    margin-top: 15px;
  }
`;
const LabelsWrapper = styled(CustomText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: end;
  margin: 0 0 0 1px;
  @media screen and (max-width: 768px) {
    font-size: small;
    text-align: center;
    width: 100%;
    justify-content: center;
  }
  @media screen and (max-width: 1078px) {
    flex-direction: column-reverse;
  }
`;
const CheckBoxWrapper = styled(CustomText)`
  display: flex;
  flex-direction: row;
  text-align: end;
`;
const ReadMore = styled(CustomText)`
  cursor: pointer;
  color: ${Colors.softBlue};
  margin-top: 8px;
  margin-left: 5px;
  @media (max-width: 450px) {
    font-size: 9px;
  }
`;

export const PasswordIconInputContainer = styled.div<{error?: boolean}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  justify-content: space-around;
`;
const ErrorText = styled(CustomText)`
  text-align: right;
  font-size: 10;
  margin: auto;
  padding-top: 10px;
  color: red;
  margin-right: 7px;
`;
