import React from 'react';
import {Modal} from 'rsuite';
import styled from 'styled-components';
import {CustomText} from '../../../Components/Common';
import {Link, PhotosWapper, ProfilePic} from './Albums';

interface Props {
  uploads: string[] | undefined;
  closeModal: () => void;
}

const SpecialtyPhotos = ({uploads, closeModal}: Props) => {
  return (
    <Modal show={(uploads || [])?.length > 0} onHide={closeModal}>
      <Modal.Header>
        <Title size={20}>المرفقات</Title>
      </Modal.Header>
      <Body>
        <PhotosRow>
          {(uploads || []).map((photo, index) => {
            return (
              <PhotosWapper key={index}>
                <Link href={photo} target="_blank">
                  <ProfilePic src={photo} width={'80'} height={'80'} />
                </Link>
              </PhotosWapper>
            );
          })}
        </PhotosRow>
      </Body>
    </Modal>
  );
};

export default SpecialtyPhotos;

const Title = styled(CustomText)`
  direction: rtl;
  text-align: center;
`;

const Body = styled(Modal.Body)`
  padding: 10px 40px 30px;
  direction: rtl;
`;

const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
