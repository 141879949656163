import React from 'react';
import styled from 'styled-components';
import Colors from '../../Theme/Colors';
import {CustomText} from '../../../Components/Common';
import {
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import {Info} from './SearchResultInfo';

const SearchResultSocials: React.FC<Info> = ({info}) => {
  return (
    <EditAccountWrapper>
      <Wrapper>
        <ProfileTitle size={18}> وسائل التواصل</ProfileTitle>
        {info?.hours_of_operation && (
          <CustomText weight={'400'} size={14}>
            اوقات الدوام: {info?.hours_of_operation}
          </CustomText>
        )}
        {info?.city?.city && !info?.street_name ? (
          <CustomText weight={'400'} size={14}>
            {`الموقع: ${info?.city?.city}`}
          </CustomText>
        ) : (
          <CustomText weight={'400'} size={14}>
            {`العنوان: ${info?.city?.city && info?.city?.city}${
              info?.street_name && ' - ' + info?.street_name
            }`}
          </CustomText>
        )}
        {info?.building_name && (
          <CustomText weight={'400'} size={14} marginTop="3px">
            {`اسم الشارع ورقم المبنى: ${info?.building_name}`}
          </CustomText>
        )}

        {info?.address && (
          <CustomText weight={'400'} size={14}>
            {`العنوان: ${info?.address}`}
          </CustomText>
        )}

        {info?.commercial_name && (
          <CustomText weight={'400'} size={14}>
            {`الاسم التجاري: ${info?.commercial_name}`}
          </CustomText>
        )}

        <Row2 justify={'space-between'} marginTop={'10px'}>
          <Col>
            {info?.phone && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  رقم الهاتف الشخصي:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.phone}
                </Phones>
              </Row>
            )}
            {info?.work_phone && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  رقم هاتف العمل:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.work_phone}
                </Phones>
              </Row>
            )}

            {info?.whats_app_phone && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  رقم الواتساب:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.whats_app_phone}
                </Phones>
              </Row>
            )}

            {info?.telegram && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  رقم التيليجرام:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.telegram}
                </Phones>
              </Row>
            )}
            {info?.fax && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  الفاكس:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.fax}
                </Phones>
              </Row>
            )}
            {info?.land_line && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  الهاتف الارضي:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  {info?.land_line}
                </Phones>
              </Row>
            )}

            {info?.email && !info?.email?.includes('he_doesnt_have_email') && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  البريد الالكتروني:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  <Link href={`mailto:${info?.email}`} target="_blank">
                    {info?.email}
                  </Link>
                </Phones>
              </Row>
            )}
            {info?.website && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  الموقع الالكتروني:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  <Link href={info?.website} target="_blank">
                    {info?.website}
                  </Link>
                </Phones>
              </Row>
            )}

            {info?.google_maps_link && (
              <Row justify={'flex-start'}>
                <PhoneNumber weight={'400'} size={14}>
                  الموقع الجغرافي:
                </PhoneNumber>
                <Phones weight={'400'} size={14} color={Colors.seaBlue}>
                  <Link href={info?.google_maps_link} target="_blank">
                    {info?.google_maps_link}
                  </Link>
                </Phones>
              </Row>
            )}
          </Col>
          <Row width={'50%'} justify={'flex-end'}>
            {info?.twitter_handle && (
              <Link
                href={`https://twitter.com/${info?.twitter_handle}`}
                target="_blank">
                <IconsView>
                  <FaTwitter color={Colors.white} size={16} />
                </IconsView>
              </Link>
            )}

            {info?.linkedin_id && (
              <Link
                href={`https://www.linkedin.com/in/${info?.linkedin_id}`}
                target="_blank">
                <IconsView>
                  <FaLinkedinIn color={Colors.white} size={16} />
                </IconsView>
              </Link>
            )}

            {info?.facebook_id && (
              <Link
                href={`https://www.facebook.com/profile.php?id=${info?.facebook_id}`}
                target="_blank">
                <IconsView>
                  <FacebookIcon color={Colors.white} size={16} />
                </IconsView>
              </Link>
            )}
            {info?.whats_app_phone && (
              <Link
                href={`https://wa.me/${info?.whats_app_phone.substring(1)}`}
                target="_blank">
                <IconsView>
                  <FaWhatsapp color={Colors.white} size={16} />
                </IconsView>
              </Link>
            )}

            {info?.youtube && (
              <Link href={info?.youtube} target="_blank">
                <IconsView>
                  <FaYoutube color={Colors.white} size={16} />
                </IconsView>
              </Link>
            )}
          </Row>
        </Row2>
      </Wrapper>
    </EditAccountWrapper>
  );
};

export default SearchResultSocials;

const EditAccountWrapper = styled.div`
  margin: 0 0 50px;
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  padding: 10px 25px;
  @media (max-width: 600px) {
    width: 85%;
  }
`;
const Wrapper = styled.div`
  /* margin: 1.5rem 20px 2rem; */
`;
const ProfileTitle = styled(CustomText)`
  padding-top: 20px;
`;

const PhoneNumber = styled(CustomText)`
  padding-top: 6px;
  padding-left: 12px;
  flex-shrink: 0;
`;
const Phones = styled(CustomText)`
  direction: ltr;
`;
const Row = styled.div<{justify?: string; width?: string; marginTop?: string}>`
  display: flex;
  flex-direction: row;
  width: ${props => (props.width ? props.width : '100%')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}` : '')}
  margin-bottom: 5px;

  @media (max-width: 650px) {
    justify-content: flex-start;
  }
  @media (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

const Row2 = styled(Row)`
  @media (max-width: 650px) {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
`;

const IconsView = styled.div`
  border-radius: 30px;
  background-color: ${Colors.borderGray};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 25px;
  margin-top: 7px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const FacebookIcon = styled(FaFacebookF)`
  /* position: absolute; */
  top: 5px;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
`;
const Link = styled.a`
  text-decoration: none;
  color: ${Colors.seaBlue};
`;
