import gql from 'graphql-tag';
import {COMPANY_FRAGMENT, LAWYER_FRAGMENT} from './SignUp';

export const RESET_PASSWORD = gql`
  mutation updatePassword($oldPassword: String, $password: String!) {
    updatePassword(
      updatePassword: {oldPassword: $oldPassword, password: $password}
    ) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const COMPANY_UPDATE_PASSWORD = gql`
  mutation companyUpdatePassword($oldPassword: String, $password: String!) {
    companyUpdatePassword(
      updatePassword: {oldPassword: $oldPassword, password: $password}
    ) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;
