import {DefaultTheme} from 'styled-components';

export const Theme: DefaultTheme = {
  colors: {
    primary: '#363a3e',
    white: '#fff',
    black: '#000',
    gray: '#808386',
    lightOrange: '#f2ac64',
    hotOrange: '#e35d25',
    orange: '#e55c12',
    red: '#e3585d',
    darkBlue: '#3b62ae',
    lightBlue: '#5195d0',
    lightSilver: '#c1c1c1',
    gradientFromLeft: 'linear-gradient(90deg, #f2ac64 0, #e35d25 100%)',
    gradientBlue: 'linear-gradient(90deg,#3b62ae 0,#5195d0 100%)',
  },
};
