import {useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Account,
  AccountReducer,
  Specialty,
  SpecialtyPhotosReturn,
} from '../../../Action/types';
import {CustomButton, CustomText} from '../../../Components/Common';
import {RootState} from '../../../Reducer';
import Colors from '../../Theme/Colors';
import {
  FIND_SPECIALTIES_BY_TYPE,
  REMOVE_SPECIALTIES,
  UPDATE_LAWYER,
} from '../GraphQL/EditAccount';
import {CheckPicker} from 'rsuite';
import {useFormik} from 'formik';
import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {StyledRow} from './CompanyCV';
import {MdDelete} from 'react-icons/md';
import {LoaderContainer} from './Certificates';
import {Alert, Loader} from 'rsuite';
import AddSpecialtyImages from './AddSpecialtyImages';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import SpecialtyPhotos from './SpecialtyPhotod';
import {UPLOAD_URI} from '../../../Configs';
import i18n from '../../../I18next/I18n';

const SpecialtyPicker = () => {
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const {data: specialtiesData, loading: loadingSpecialties} = useQuery(
    FIND_SPECIALTIES_BY_TYPE,
    {
      variables: {type: user?.type},
    },
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [currentSpecialtyId, setCurrentSpecialtyId] = useState<string>('');
  const [specialtyPhotosModal, setSpecialtyPhotosModal] = useState<
    string[] | undefined
  >([]);
  const [concatedSpecialties, setConcatedSpecialties] = useState<
    (Specialty | undefined)[]
  >([]);

  const clearCurrentSpecialty = () => {
    setCurrentSpecialtyId('');
  };
  let pending_specialties: [Specialty?] | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({pending_specialties} = user);
  }

  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: Account;
  }>(UPDATE_LAWYER);
  const [removeSpecialties, {loading: specialLoading}] = useMutation<{
    removeSpecialties: Account;
  }>(REMOVE_SPECIALTIES);

  const {values, setFieldValue, handleSubmit} = useFormik({
    initialValues: {
      pending_specialties: pending_specialties
        ? pending_specialties.map(pending_specialty => pending_specialty?.id)
        : [],
      specialty: '',
      specialtyIndex: 0,
      deleteSpecialty: concatedSpecialties || [],
    },
    onSubmit: async submittedValues => {
      if (
        submittedValues.pending_specialties?.length <
        (pending_specialties || [])?.length
      ) {
        return handleSpecialtyRemove(submittedValues.pending_specialties);
      }
      try {
        const {data} = await updateLawyer({
          variables: {
            pending_specialties: submittedValues?.pending_specialties,
          },
        });
        if (data?.updateLawyer) {
          dispatch(
            updateUser({
              ...user,
              pending_specialties:
                data?.updateLawyer?.pending_specialties || [],
            }),
          );
          setAdd(false);
          setShow(true);
          Alert.success('تمت العملية بنجاح');
        }
      } catch (err: any) {
        if (
          err.message === 'Your membership level allows Only 1 Specialty' ||
          err.message === 'Your membership level allows Only 3 Specialties'
        ) {
          Alert.error(
            i18n.t(err.message, {
              fallback: 'Something went wrong please try again later',
            }),
            5000,
          );
          history.push('Memberships');
        } else if (
          err.message === 'Your membership level allows Only 5 Specialties'
        ) {
          Alert.error(
            i18n.t(err.message, {
              fallback: 'Something went wrong please try again later',
            }),
            5000,
          );
        } else {
          Alert.error(
            i18n.t('Something went wrong please try again later'),
            5000,
          );
        }
      }
    },
  });

  const handleSpecialtyRemove = async (ids: (string | undefined)[]) => {
    try {
      const {data} = await removeSpecialties({
        variables: {specialties_ids: ids},
      });
      if (data?.removeSpecialties) {
        dispatch(
          updateUser({
            ...user,
            pending_specialties:
              data?.removeSpecialties?.pending_specialties || [],
          }),
        );
        Alert.success('تمت العملية بنجاح');
        setFieldValue('pending_specialties', ids);
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong please try again later'), 5000);
    }
  };

  const handlePickSpecialty = (specialtiesIds: string[]) => {
    setFieldValue('pending_specialties', specialtiesIds);
  };

  const handleViewImage = (value: string[] | undefined) => {
    setSpecialtyPhotosModal(value);
  };

  const closeModal = () => {
    setSpecialtyPhotosModal([]);
  };

  useEffect(() => {
    const concatSpecialties = (user?.pending_specialties || [])?.concat(
      user?.verified_specialties || [],
    );
    setConcatedSpecialties(concatSpecialties);
  }, [user]);

  return (
    <EditAccountWrapper>
      <Inputs>
        <StyledRow>
          <ProfileTitle>التخصص</ProfileTitle>
          {show && (
            <Col
              onClick={() => {
                setAdd(true);
                setShow(false);
              }}>
              {/* {concatedSpecialties.length < 5 && ( */}
              <>
                <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
                <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
                  أضف تخصص جديد
                </AddSectionText>
              </>
              {/* )} */}
            </Col>
          )}
        </StyledRow>
        <SpecialtiesList>
          {(concatedSpecialties || [])?.map((item, key: number) => {
            const findPhoto: SpecialtyPhotosReturn | undefined =
              user?.specialty_photos?.find(
                photo => photo.specialty === item?.id,
              );

            return (
              <StyledRow>
                <ListItem>
                  <CustomText>{`${item?.specialty}`}</CustomText>
                </ListItem>
                <IconsDiv>
                  {(user?.verified_specialties || [])?.find(
                    verified_specialty => verified_specialty?.id === item?.id,
                  ) ? (
                    <DocumentsText
                      color={Colors.green}
                      size={13}
                      pointer={findPhoto && findPhoto?.photos?.length > 0}
                      onClick={() =>
                        handleViewImage(
                          findPhoto?.photos?.map(
                            id => `${UPLOAD_URI}/image-by-id/${id}`,
                          ),
                        )
                      }>
                      معتمد
                    </DocumentsText>
                  ) : user?.specialty_photos?.find(
                      photo => photo.specialty === item?.id,
                    ) ? (
                    <DocumentsText
                      color={Colors.orange}
                      size={13}
                      pointer
                      onClick={() =>
                        handleViewImage(
                          findPhoto?.photos?.map(
                            id => `${UPLOAD_URI}/image-by-id/${id}`,
                          ),
                        )
                      }>
                      قيد الاعتماد
                    </DocumentsText>
                  ) : (
                    <AddDocumentsText
                      color={Colors.blue}
                      size={13}
                      onClick={() =>
                        item?.specialty && setCurrentSpecialtyId(item?.id)
                      }>
                      أضف وثائق
                    </AddDocumentsText>
                  )}
                  {!(user?.verified_specialties || [])?.find(
                    verified_specialty => verified_specialty?.id === item?.id,
                  ) && (
                    <DeleteIcon
                      size={19}
                      color={Colors.red}
                      onClick={() => {
                        let newArray = _.differenceBy(
                          concatedSpecialties,
                          concatedSpecialties.splice(key, 1),
                        );
                        handleSpecialtyRemove(newArray?.map(item => item?.id));
                      }}
                    />
                  )}
                </IconsDiv>
              </StyledRow>
            );
          })}
        </SpecialtiesList>

        {add && (
          <>
            <div dir="rtl">
              <CheckPicker
                data={
                  !loadingSpecialties && specialtiesData
                    ? specialtiesData?.findSpecialtiesByType.map(
                        (specialty: Specialty) => {
                          return {
                            label: specialty?.specialty,
                            value: specialty?.id,
                          };
                        },
                      )
                    : []
                }
                onChange={(item: any) => {
                  handlePickSpecialty(item);
                }}
                disabledItemValues={user?.verified_specialties?.map(
                  item => item?.id,
                )}
                value={values.pending_specialties}
                appearance="subtle"
                placeholder="اختر"
                menuStyle={{textAlign: 'right'}}
                locale={{
                  searchPlaceholder: 'بحث',
                  noResultsText: 'لا يوجد نتائج',
                }}
                cleanable={false}
                style={{
                  width: '91.5%',
                  // maxWidth: '500px',
                  direction: 'rtl',
                  textAlign: 'right',
                  border: `1px solid #c1c1c1`,
                  borderRadius: 5,
                }}
              />
            </div>
            <Row>
              {updateLoading || specialLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <CustomButton
                    height={'33px'}
                    width={'33%'}
                    orangeBg
                    disabled={values.pending_specialties.length === 0}
                    onClick={() => {
                      if (
                        values.pending_specialties?.length +
                          concatedSpecialties.length >
                          1 &&
                        user?.membership?.type === 0
                      ) {
                        Alert.error(
                          i18n.t(
                            'Your membership level allows Only 1 Specialty',
                          ),
                          5000,
                        );
                        history.push('Memberships');
                      } else if (
                        values.pending_specialties.length +
                          concatedSpecialties.length >
                          3 &&
                        user?.membership?.type === 1
                      ) {
                        Alert.error(
                          i18n.t(
                            'Your membership level allows Only 3 Specialties',
                          ),
                          5000,
                        );
                        history.push('Memberships');
                      } else if (
                        values.pending_specialties.length +
                          concatedSpecialties.length >
                          5 &&
                        user?.membership?.type === 2
                      ) {
                        Alert.error(
                          i18n.t(
                            'Your membership level allows Only 5 Specialties',
                          ),
                          5000,
                        );
                        return;
                      } else {
                        handleSubmit();
                      }
                    }}>
                    <ButtonText color={Colors.white} weight={'400'}>
                      حفظ
                    </ButtonText>
                  </CustomButton>
                  <CancelButton
                    height={'33px'}
                    bgColor={Colors.white}
                    withBorder
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      values.pending_specialties = pending_specialties
                        ? pending_specialties.map(
                            pending_specialty => pending_specialty?.id,
                          )
                        : [];
                    }}
                    width={'33%'}>
                    <ButtonText weight={'400'}>إلغاء</ButtonText>
                  </CancelButton>
                </>
              )}
            </Row>
          </>
        )}
      </Inputs>
      <AddSpecialtyImages
        currentSpecialtyId={currentSpecialtyId}
        clearCurrentSpecialty={clearCurrentSpecialty}
      />
      <SpecialtyPhotos uploads={specialtyPhotosModal} closeModal={closeModal} />
    </EditAccountWrapper>
  );
};

export default SpecialtyPicker;
const SpecialtiesList = styled.ol`
  width: 95%;
`;
const ListItem = styled.li``;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ProfileTitle = styled(CustomText)`
  padding: 10px 12px 15px 0px;
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0px 30px;
`;
const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;

const DocumentsText = styled(CustomText)<{pointer?: boolean}>`
  margin: 3px 6px 0px;
  cursor: ${props => (props.pointer ? 'pointer' : 'default')};
`;

const AddDocumentsText = styled(DocumentsText)`
  margin: 3px 6px 0px;
  cursor: pointer;
`;

const DeleteIcon = styled(MdDelete)`
  cursor: pointer;
`;

export const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Col = styled.div`
  display: flex;
  justify-content: center;
  width: 253px;
  margin-top: 5px;
  cursor: pointer;
`;
export const AddSectionText = styled(CustomText)`
  margin-top: 5px;
  margin-right: 10px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
`;
