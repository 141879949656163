import React from 'react';
import styled from 'styled-components';
import Colors from '../../Theme/Colors';
import {CustomText} from '../../../Components/Common';
import {Certificate, Company, Lawyer, LawyerType} from '../../../Action/types';
import moment from 'moment';
import {CERTIFICATES} from '../GraphQl/ProfilePreview';
import {useQuery} from '@apollo/client';
import {UPLOAD_URI} from '../../../Configs';
import noPhoto from '../../../Assets/Images/noPhoto.png';

export interface Info {
  info?: Lawyer;
  type?: string;
  companyInfo?: Company;
}

const SearchResultInfo: React.FC<Info> = ({info, type, companyInfo}) => {
  const {data: certificates, loading: loadingCertificates} = useQuery<{
    findCertificatesByLawyerId: [Certificate] | [];
  }>(CERTIFICATES, {variables: {id: info?.id || companyInfo?.id}});

  // const [specialtyPhotosModal, setSpecialtyPhotosModal] = useState<
  //   string[] | undefined
  // >([]);

  // const handleViewImage = (value: string[] | undefined) => {
  //   setSpecialtyPhotosModal(value);
  // };

  // const closeModal = () => {
  //   setSpecialtyPhotosModal([]);
  // };

  const pendingSpecialties =
    (info?.pending_specialties || [])?.concat(
      (info?.verified_specialties as any) || [],
    ) || [];

  return (
    <EditAccountWrapper>
      <Wrapper>
        {type !== LawyerType.company ? (
          <>
            <ProfileTitle size={18}> السيرة الذاتية</ProfileTitle>
            <Paragraph
              weight={'400'}
              size={14}
              style={{whiteSpace: 'pre-line'}}>
              {info?.biography ? info?.biography : 'لا يوجد معلومات'}
            </Paragraph>

            {info?.languages?.length !== 0 && (
              <>
                {info?.languages && (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Paragraph weight={'400'} size={14}>
                      اللغات:
                    </Paragraph>
                    {info?.languages &&
                      info?.languages.map((language, index) => (
                        <Languages weight={'400'} size={14} key={index}>
                          {` ${language} `}
                        </Languages>
                      ))}
                  </Row>
                )}
                {info?.scholar_major && (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Paragraph weight={'400'} size={14}>
                      التخصص الجامعي:
                    </Paragraph>
                    <Languages weight={'400'} size={14}>
                      {` ${info?.scholar_major} `}
                    </Languages>
                  </Row>
                )}
                {info?.scholar_degree && (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Paragraph weight={'400'} size={14}>
                      الدرجة العلمية:
                    </Paragraph>
                    <Languages weight={'400'} size={14}>
                      {` ${info?.scholar_degree} `}
                    </Languages>
                  </Row>
                )}
                {info?.social_status && (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Paragraph weight={'400'} size={14}>
                      الحالة الاجتماعية:
                    </Paragraph>
                    <Languages weight={'400'} size={14}>
                      {` ${info?.social_status} `}
                    </Languages>
                  </Row>
                )}
                {info?.date_of_birth && (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Paragraph weight={'400'} size={14}>
                      تاريخ الميلاد:
                    </Paragraph>
                    <Languages weight={'400'} size={14}>
                      {moment(info?.date_of_birth)
                        .lang('ar-sa')
                        .format('MMMM D, YYYY')}
                    </Languages>
                  </Row>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <ProfileTitle size={18}> نبذة عن الشركة</ProfileTitle>
            <Paragraph
              weight={'400'}
              size={14}
              style={{whiteSpace: 'pre-line'}}>
              {companyInfo?.biography
                ? companyInfo?.biography
                : 'لا يوجد معلومات'}
            </Paragraph>
          </>
        )}
        {type === LawyerType.company ? (
          <>
            {/* <ProfileTitle size={18}> طبيعة الشركة</ProfileTitle>
            <Paragraph weight={'400'} size={14}>
              {companyInfo?.company_nature
                ? companyInfo?.company_nature
                : 'لا يوجد معلومات'}
            </Paragraph> */}

            {(companyInfo?.goals || [])?.length > 0 ? (
              <>
                <ProfileTitle size={18}>غايات الشركة</ProfileTitle>
                {companyInfo?.goals?.map((goals, index) => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Languages weight={'400'} size={14} key={index}>
                      {` ${goals.specialty} `}
                    </Languages>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <ProfileTitle size={18}> غايات الشركة</ProfileTitle>
                <Paragraph weight={'400'} size={14}>
                  لا يوجد معلومات
                </Paragraph>
              </>
            )}

            {(companyInfo?.activities || [])?.length > 0 ? (
              <>
                <ProfileTitle size={18}> النشاطات</ProfileTitle>
                {companyInfo?.activities?.map((activities, index) => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Languages weight={'400'} size={14} key={index}>
                      {` ${activities} `}
                    </Languages>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <ProfileTitle size={18}> النشاطات</ProfileTitle>
                <Paragraph weight={'400'} size={14}>
                  لا يوجد معلومات
                </Paragraph>
              </>
            )}

            {(companyInfo?.research || [])?.length > 0 ? (
              <>
                <ProfileTitle size={18}> الابحاث</ProfileTitle>
                {companyInfo?.research?.map((research, index) => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    {research?.url ? (
                      <Ahref href={research?.url} target="_blank">
                        <Languages
                          weight={'400'}
                          size={14}
                          key={index}
                          color={Colors.seaBlue}>
                          {` ${research?.label} `}
                        </Languages>
                      </Ahref>
                    ) : (
                      <Languages
                        weight={'400'}
                        size={14}
                        key={index}
                        color={Colors.darkGray}>
                        {` ${research?.label} `}
                      </Languages>
                    )}
                  </Row>
                ))}
              </>
            ) : (
              <>
                <ProfileTitle size={18}> الابحاث</ProfileTitle>
                <Languages weight={'400'} size={14}>
                  لا يوجد معلومات
                </Languages>
              </>
            )}
            {(companyInfo?.clients || [])?.length > 0 ? (
              <>
                <ProfileTitle size={18}> عملاؤنا</ProfileTitle>
                {companyInfo?.clients?.map((clients, index) => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Languages weight={'400'} size={14} key={index}>
                      {` ${clients} `}
                    </Languages>
                  </Row>
                ))}
              </>
            ) : (
              <>
                <ProfileTitle size={18}> عملاؤنا</ProfileTitle>
                <Languages weight={'400'} size={14}>
                  لا يوجد معلومات
                </Languages>
              </>
            )}
            <ProfileTitle size={18}> فريق العمل</ProfileTitle>
            {(companyInfo?.team || [])?.length > 0 ? (
              <RenderOnSameLine>
                {companyInfo?.team?.map((team, index) => (
                  <>
                    <Row
                      justify={'flex-start'}
                      marginTop={'10px'}
                      width={'unset'}
                      marginLeft={'25px'}
                      key={index}
                      alignedCenter>
                      {/* <Dot /> */}
                      <TeamMemberImage
                        src={
                          team?.photo
                            ? `${UPLOAD_URI}/image/${team?.photo?.filename}`
                            : noPhoto
                        }
                      />
                      <TeamMemberText weight={'400'} size={14}>
                        {` ${team?.role} `} {` ${team?.name} `}
                      </TeamMemberText>
                    </Row>
                  </>
                ))}
              </RenderOnSameLine>
            ) : (
              <Languages weight={'400'} size={14}>
                لا يوجد معلومات
              </Languages>
            )}

            <ProfileTitle size={18}>الألبومات</ProfileTitle>
            {(companyInfo?.photos || [])?.length > 0 ? (
              <RenderOnSameLine>
                {companyInfo?.photos?.map((photo, index) => (
                  <Link
                    href={`${UPLOAD_URI}/image/${photo?.filename}`}
                    target="_blank">
                    <AlbumImage
                      key={index}
                      src={
                        photo?.filename
                          ? `${UPLOAD_URI}/image/${photo?.filename}`
                          : noPhoto
                      }
                    />
                  </Link>
                ))}
              </RenderOnSameLine>
            ) : (
              <Languages weight={'400'} size={14}>
                لا يوجد معلومات
              </Languages>
            )}
          </>
        ) : (
          <>
            {type === 'company' ? (
              <>
                <ProfileTitle size={18}> التخصصات</ProfileTitle>
                {info &&
                info?.verified_specialties &&
                info?.verified_specialties.length > 0 ? (
                  info?.verified_specialties.map((specialty, index) => (
                    <Row justify={'flex-start'} marginTop={'10px'}>
                      <Dot />
                      <Languages weight={'400'} size={14} key={index}>
                        {` ${specialty?.specialty} `}
                      </Languages>
                    </Row>
                  ))
                ) : (
                  <Languages weight={'400'} size={14}>
                    لا يوجد معلومات
                  </Languages>
                )}
              </>
            ) : (
              <>
                <ProfileTitle size={18}> التخصصات</ProfileTitle>
                {pendingSpecialties.length > 0 ? (
                  pendingSpecialties?.map((specialty, index) => (
                    <Row
                      justify={'flex-start'}
                      marginTop={'10px'}
                      // style={{cursor: 'pointer'}}
                      // onClick={() =>
                      //   handleViewImage(
                      //     info?.specialty_photos
                      //       ?.find(
                      //         (photo: {specialty: string}) =>
                      //           photo.specialty === specialty?.id,
                      //       )
                      //       ?.photos?.map(
                      //         (id: string) => `${UPLOAD_URI}/image-by-id/${id}`,
                      //       ),
                      //   )
                      // }
                    >
                      <Dot />
                      <Languages
                        weight={'400'}
                        size={14}
                        key={index}
                        // color={
                        //   info?.specialty_photos?.find(
                        //     (photo: {specialty: string}) =>
                        //       photo.specialty === specialty?.id,
                        //   )
                        //     ? Colors.orange
                        //     : Colors.darkGray
                        // }
                      >
                        {` ${specialty?.specialty} `}
                      </Languages>
                    </Row>
                  ))
                ) : (
                  <Languages weight={'400'} size={14}>
                    لا يوجد معلومات
                  </Languages>
                )}
              </>
            )}

            <ProfileTitle size={18}> الشهادات</ProfileTitle>
            {!loadingCertificates &&
            certificates &&
            certificates?.findCertificatesByLawyerId?.filter(
              item => !item.deleted,
            )?.length !== 0 ? (
              certificates.findCertificatesByLawyerId
                .filter(item => !item.deleted)
                .map((certificate: Certificate) => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Lang weight={'400'} size={14}>
                      {`${certificate.certificate_name} `}
                      {certificate.certificate_issued_from &&
                        ` من ${certificate.certificate_issued_from} `}
                      {certificate.certificate_id &&
                        ` ورقمها ${certificate.certificate_id} `}
                      {certificate?.certificate_issue_date &&
                        ` وتاريخها ${moment(certificate?.certificate_issue_date)
                          .lang('ar-sa')
                          .format('MMMM D, YYYY')} `}
                      {certificate?.photo && (
                        <Ahref
                          href={`${UPLOAD_URI}/image/${certificate?.photo?.filename}`}
                          target="_blank">
                          رابط الشهادة
                        </Ahref>
                      )}
                    </Lang>
                  </Row>
                ))
            ) : (
              <CustomText weight={'400'} size={14}>
                لا يوجد معلومات
              </CustomText>
            )}
            <ProfileTitle size={18}> الدورات التدريبية </ProfileTitle>
            {info?.training_courses && info?.training_courses?.length !== 0 ? (
              <>
                {info?.training_courses?.map(course => (
                  <Row justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <Lang weight={'400'} size={14}>
                      {`${course && course.title} اصدرت من ${
                        course && course.acquired_from
                      } بتاريخ: ${moment(course && course.acquired_at)
                        .lang('ar-sa')
                        .format('MMMM D, YYYY')}`}
                    </Lang>
                  </Row>
                ))}
              </>
            ) : (
              <CustomText weight={'400'} size={14}>
                لا يوجد معلومات
              </CustomText>
            )}

            <ProfileTitle size={18}>الخبرات العملية</ProfileTitle>
            {(info?.work_experience?.length || []) > 0 ? (
              <>
                {(info?.work_experience || [])?.map(experience => (
                  <WorkedForRow justify={'flex-start'} marginTop={'10px'}>
                    <Dot />
                    <WorkedFor>عمل لدى:</WorkedFor>
                    <Languages weight={'400'} size={14}>
                      {`${experience?.name} لمدة: ${experience?.years}`}
                    </Languages>
                  </WorkedForRow>
                ))}
              </>
            ) : (
              <CustomText weight={'400'} size={14}>
                لا يوجد معلومات
              </CustomText>
            )}

            <ProfileTitle size={18}> الأبحاث</ProfileTitle>
            {info?.research && info?.research.length > 0 ? (
              info?.research.map((research, index) => (
                <Row justify={'flex-start'} marginTop={'10px'}>
                  <Dot />
                  {research?.url ? (
                    <Ahref href={research?.url} target="_blank">
                      <Languages
                        weight={'400'}
                        size={14}
                        key={index}
                        color={Colors.seaBlue}>
                        {` ${research?.label} `}
                      </Languages>
                    </Ahref>
                  ) : (
                    <Languages
                      weight={'400'}
                      size={14}
                      key={index}
                      color={Colors.darkGray}>
                      {` ${research?.label} `}
                    </Languages>
                  )}
                </Row>
              ))
            ) : (
              <CustomText weight={'400'} size={14}>
                لا يوجد معلومات
              </CustomText>
            )}
          </>
        )}
      </Wrapper>
      {/* <SpecialtyPhotos uploads={specialtyPhotosModal} closeModal={closeModal} /> */}
    </EditAccountWrapper>
  );
};

export default SearchResultInfo;

const WorkedFor = styled.div`
  font-weight: 400;
  size: 14px;
  @media (max-width: 357px) {
    flex-wrap: wrap;
  }
  /* width: 50%; */
`;
const EditAccountWrapper = styled.div`
  margin: 0 0 50px;
  width: 75%;
  height: auto;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  padding: 10px 25px;
  @media (max-width: 600px) {
    width: 85%;
  }
`;
const Wrapper = styled.div`
  margin: 1.5rem 20px;
`;

const Paragraph = styled(CustomText)`
  /* width: 85%; */
  @media (max-width: 725px) {
    /* width: 65%; */
  }
`;
const ProfileTitle = styled(CustomText)`
  padding-top: 20px;
`;
const Languages = styled(CustomText)`
  margin-top: -1px;
  margin-right: 5px;
`;
const Lang = styled(Languages)`
  width: 100%;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${Colors.darkGray};
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 0.5rem;
`;

const RenderOnSameLine = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Row = styled.div<{
  justify?: string;
  width?: string;
  marginTop?: string;
  marginLeft?: string;
  alignedCenter?: boolean;
}>`
  display: flex;
  flex-direction: row;
  ${props => props.alignedCenter && 'align-items: center'};
  width: ${props => (props.width ? props.width : '100%')};
  justify-content: ${props => (props.justify ? props.justify : 'center')};
  ${props => (props.marginTop ? `margin-top: ${props.marginTop}` : '')};
  ${props => props.marginLeft && `margin-left: ${props.marginLeft}`};
  align-items: baseline;
`;

const WorkedForRow = styled(Row)`
  flex-wrap: wrap;
`;

const Ahref = styled.a`
  text-decoration: none;
`;

const TeamMemberImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
`;

const TeamMemberText = styled(CustomText)`
  margin-top: 3px;
  margin-right: 10px;
`;

const AlbumImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-left: 25px;
  margin-top: 10px;
  &:hover {
    opacity: 0.8;
  }
`;

const Link = styled.a`
  text-decoration: none;
`;
