import gql from 'graphql-tag';
import {BLOG_FRAGMENT} from './CreateBlog';

export const CREATE_COMMENT = gql`
  mutation createComment($user_name: String!, $body: String!, $blog: ID!) {
    createComment(
      createCommentInput: {user_name: $user_name, body: $body, blog: $blog}
    ) {
      id
      user_name
      body
      blog {
        ...BlogFragment
      }
      deleted
      created_at
      __typename
    }
  }
  ${BLOG_FRAGMENT}
`;

export const COMMENT = gql`
  query commentsByBlog($blog: ID!, $pageNumber: Float) {
    commentsByBlog(blog: $blog, pageNumber: $pageNumber) {
      page_count
      comments {
        id
        body
        user_name
        lawyer {
          photo {
            id
            filename
            label
          }
        }
        company {
          logo {
            id
            filename
            label
          }
        }
        created_at
      }
    }
  }
`;

export const BLOG = gql`
  query findBlogById($blogId: String) {
    findBlogById(blogId: $blogId) {
      id
      body
      created_at
      poster_name
      comments_count
      title
    }
  }
`;
