import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Banner from '../../Assets/Images/blogListingBanner.png';
import BannerAbs from '../../Assets/Images/blogBannerAbsolute.svg';
import Colors from '../Theme/Colors';
import {CustomButton, CustomInput, CustomText} from '../../Components/Common';
import BlogPreview from '../../Components/BlogPreview';
import {useHistory} from 'react-router-dom';
import {RootState} from '../../Reducer';
import {useSelector} from 'react-redux';
import {AccountReducer, Blog} from '../../Action/types';
import {LawyerImg} from '../../Components/CommonResult';
import {UPLOAD_URI} from '../../Configs';
import noPhoto from '../../Assets/Images/noPhoto.png';
import {Alert, Pagination} from 'rsuite';
import {ALL_BLOGS} from '../Search/GraphQl/Search';
import {useLazyQuery} from '@apollo/client';
import i18n from '../../I18next/I18n';

interface AllBlogs {
  pageNumber?: number;
}

const BlogListing = () => {
  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const {user, userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const [findAllApprovedBlogs, {data, loading}] =
    useLazyQuery<{findAllApprovedBlogs: {blogs: Blog[]; page_count: number}}>(
      ALL_BLOGS,
    );

  const loadPageNumber = useCallback(
    (pageNumber: number = activePage) => {
      const variables: AllBlogs = {
        pageNumber,
      };
      try {
        findAllApprovedBlogs({
          variables,
        });
      } catch (err) {
        Alert.error(i18n.t('Something went wrong'));
      }
    },
    [activePage, findAllApprovedBlogs],
  );
  useEffect(() => {
    if (activePage) {
      loadPageNumber();
    }
  }, [activePage, loadPageNumber]);

  return (
    <>
      <HeroBanner>
        <BannerShape src={BannerAbs} />
        <BannerText>المجلة القانونية</BannerText>
        <BannerSubText>
          مجلة قانونية تختص بنشر المقالات القانونية المتخصصة في جميع المجالات
          القانونية وخاصة في التعديلات على التشريعات والتشريعات المستحدثة
          والتشريعات المتخصصة_ في الامن السيبراني والتجارة الإلكترونية وغيره
        </BannerSubText>
        {/* <BannerInput placeholder="إبحث عن مدونة معينة"></BannerInput> */}
      </HeroBanner>
      <Wrapper>
        {!!userType && (
          <>
            <CreateBlogWrapper onClick={() => history.push('/create-blog')}>
              <Thumbnail
                src={
                  user?.photo?.filename
                    ? `${UPLOAD_URI}/image/${user?.photo?.filename}`
                    : user?.logo?.filename
                    ? `${UPLOAD_URI}/image/${user?.logo?.filename}`
                    : noPhoto
                }
              />
              <CreateBlogBtn>
                <CreateBlogText>إنشاء مقالة</CreateBlogText>
              </CreateBlogBtn>
            </CreateBlogWrapper>
          </>
        )}
        <BlogPreview
          blogListing={data?.findAllApprovedBlogs?.blogs}
          loadingBlogs={loading}
        />
        {data?.findAllApprovedBlogs?.blogs &&
          data?.findAllApprovedBlogs?.blogs?.length > 0 && (
            <Pagination
              total={100}
              maxButtons={10}
              pages={data?.findAllApprovedBlogs?.page_count || 0}
              prev
              next
              activePage={activePage}
              onSelect={setActivePage}
              disabled={loading}
            />
          )}
      </Wrapper>
    </>
  );
};

export default BlogListing;

export const HeroBanner = styled.div`
  background-image: linear-gradient(
      to right,
      ${Colors.black},
      rgba(255, 0, 0, 0)
    ),
    url(${Banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 70%;
  height: 450px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 250px;
  z-index: 0;
  @media (max-width: 1130px) {
    padding: 0 200px;
  }
  @media (max-width: 1010px) {
    padding: 0 160px;
  }
  @media (max-width: 920px) {
    padding: 0 100px;
  }
  @media (max-width: 720px) {
    padding: 0 70px;
  }
  @media (max-width: 620px) {
    padding: 0 50px;
  }
  @media (max-width: 580px) {
    padding: 0 30px;
  }
  @media (max-width: 500px) {
    height: 300px;
  }
`;

export const BannerShape = styled.img`
  position: absolute;
  top: -20px;
  right: 0;
`;

export const BannerText = styled(CustomText)`
  color: ${Colors.white};
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 15px;
  z-index: 999;
  @media (max-width: 500px) {
    margin: 0 auto 15px;
  }
  @media (max-width: 410px) {
    font-size: 28px;
  }
`;

export const BannerSubText = styled(CustomText)`
  color: ${Colors.white};
  font-size: 16px;
  z-index: 999;
  text-align: end;
  width: 70%;
  @media (max-width: 1200px) {
    width: 85%;
  }
  @media (max-width: 1100px) {
    width: 95%;
  }
  @media (max-width: 620px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    text-align: center;
  }
  @media (max-width: 410px) {
    font-size: 14px;
  }
`;

export const BannerInput = styled(CustomInput)`
  font-size: 16px;
  margin: 20px 0 0;
  z-index: 999;
  direction: rtl;
  width: 38%;
  ::placeholder {
    @media (max-width: 500px) {
      text-align: center;
    }
  }
  @media (max-width: 520px) {
    width: 50%;
  }
  @media (max-width: 500px) {
    margin: 0px auto;
  }
  @media (max-width: 410px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    width: 65%;
  }
`;

export const Wrapper = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.bgBlue};
`;

export const CreateBlogWrapper = styled.div`
  margin: 60px 0 20px;
  width: 65%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 3px ${props => props.theme.colors.lightSilver};
  padding: 20px 40px;
  background-color: ${Colors.white};
  cursor: pointer;
  @media (max-width: 600px) {
    width: 70%;
    padding: 20px;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
  @media (max-width: 420px) {
    flex-direction: column;
  }
`;

export const CreateBlogBtn = styled(CustomButton)`
  width: 70%;
  background: ${Colors.white};
  border: 1px solid ${Colors.blogBlue};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0 30px;
  direction: rtl;
  @media (max-width: 500px) {
    margin: 0;
    margin-right: auto;
  }
  @media (max-width: 420px) {
    margin: auto;
    justify-content: center;
  }
`;

export const CreateBlogText = styled(CustomText)`
  font-weight: 400;
  font-size: 16px;
`;

export const Thumbnail = styled(LawyerImg)<{marginTop?: string}>`
  ${props => props.marginTop && `margin-top: ${props.marginTop}px`}
  @media (max-width: 420px) {
    margin-bottom: 12px;
  }
`;
