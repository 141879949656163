import gql from 'graphql-tag';
import {COMPANY_FRAGMENT, LAWYER_FRAGMENT} from './SignUp';

export const VERIFICATION = gql`
  mutation verifyCodeMutation($code: Float!, $email: String!) {
    verifyCode(verifyCodeInput: {code: $code, email: $email}) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const COMPANY_VERIFICATION = gql`
  mutation companyVerifyCode($code: Float!, $email: String!) {
    companyVerifyCode(companyVerifyCodeInput: {code: $code, email: $email}) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const RESEND_CODE = gql`
  mutation resendCode($email: String!) {
    resendCode(email: $email)
  }
`;

export const COMPANY_RESEND_CODE = gql`
  mutation companyResendCode($email: String!) {
    companyResendCode(email: $email)
  }
`;
