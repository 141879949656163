import gql from 'graphql-tag';

export const BLOG_FRAGMENT = gql`
  fragment BlogFragment on Blog {
    id
    body
    title
    poster_name
    approved
    comments_count
    deleted
    created_at
    __typename
  }
`;

export const CREATE_BLOG = gql`
  mutation createBlogOnDB(
    $title: String!
    $poster_name: String!
    $body: String!
  ) {
    createBlogOnDB(
      createBlogInput: {title: $title, poster_name: $poster_name, body: $body}
    )
  }
`;
