import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
const ar = require('./ar.json');
const ar_old = require('./ar_old.json');

export const resources = {
  ar: {
    translation: {...ar, ...ar_old},
  },
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'ar',
  compatibilityJSON: 'v3',
  fallbackLng: 'ar',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
});

export default i18n;
