import styled from 'styled-components';
import Colors from '../Containers/Theme/Colors';
import {CustomText} from './Common';
import {FaCommentAlt} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ar-sa';
import {useState} from 'react';
import {LoginSubtitle} from '../Containers/Auth/ForgotPassword';
import {Blog} from '../Action/types';

interface Blogs {
  blogListing: Blog[] | undefined;
  loadingBlogs: boolean;
}

const BlogPreview = ({blogListing, loadingBlogs}: Blogs) => {
  const history = useHistory();
  const [show, setShow] = useState<string>('');
  // const {data: blogListing, loading: loadingBlogs, refetch} = useQuery(BLOG);

  // useEffect(() => {
  //   if (blogListing) {
  //     refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [blogListing]);

  return (
    <>
      {blogListing?.map(blog => {
        return (
          <>
            <PreviewWrapper
            // onClick={() =>
            //   history.push({pathname: '/blog-details', state: blog})
            // }
            >
              <TextWrapper>
                {loadingBlogs ? (
                  <>
                    <CustomText
                      color={Colors.borderGray}
                      weight="400"
                      size={14}>
                      {''}
                    </CustomText>
                    <PreviewContent>{''}</PreviewContent>
                  </>
                ) : (
                  <>
                    <BlogDetailsRow>
                      <CustomText
                        color={Colors.borderGray}
                        weight="400"
                        size={14}>
                        نشرت بتاريخ{' '}
                        {moment(blog?.created_at)
                          .lang('ar-sa')
                          .format('MMMM D, YYYY')}{' '}
                        بواسطة
                      </CustomText>

                      <CustomTextPosterName
                        color={Colors.borderGray}
                        weight="400"
                        size={14}>
                        {blog?.poster_name}
                      </CustomTextPosterName>
                    </BlogDetailsRow>

                    <CustomText size={18}>{blog?.title}</CustomText>
                    <PreviewContent style={{whiteSpace: 'pre-line'}}>
                      {blog?.body && (blog?.body).slice(0, 350)}
                      {blog?.body && (blog?.body).length > 350 ? (
                        show === blog?.poster_name ? (
                          <>
                            {blog?.body.slice(350)}
                            <LoginSubtitle
                              weight={'500'}
                              color={Colors.orange}
                              size={15}
                              onClick={() => setShow('')}>
                              .. اقل
                            </LoginSubtitle>
                          </>
                        ) : (
                          <LoginSubtitle
                            weight={'500'}
                            color={Colors.orange}
                            size={15}
                            onClick={() =>
                              blog?.poster_name && setShow(blog?.poster_name)
                            }>
                            .. المزيد
                          </LoginSubtitle>
                        )
                      ) : (
                        <></>
                      )}
                    </PreviewContent>
                  </>
                )}
              </TextWrapper>
              <ColumnWrapper
                onClick={() =>
                  history.push({pathname: '/blog-details', state: blog})
                }>
                {/* <Social>
                  <IoIosShareAlt size={20} color={Colors.textGray} />
                  <SocialText>شارك</SocialText>
                </Social> */}
                <Comment>
                  <FaCommentAlt size={16} color={Colors.textGray} />
                  <SocialText>{blog?.comments_count} تعليقات</SocialText>
                </Comment>
              </ColumnWrapper>
            </PreviewWrapper>
          </>
        );
      })}
    </>
  );
};

export default BlogPreview;

export const PreviewWrapper = styled.div`
  margin: 20px 0;
  width: 65%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 3px ${props => props.theme.colors.lightSilver};
  padding: 20px 40px;
  background-color: ${Colors.white};
  @media (max-width: 800px) {
    flex-direction: column;
  }
  @media (max-width: 600px) {
    width: 70%;
    padding: 20px;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const BlogDetailsRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  flex-wrap: wrap;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const CustomTextPosterName = styled(CustomText)`
  margin-right: 3px;
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  width: 70%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const PreviewContent = styled(CustomText)`
  margin: 10px 0;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  @media (max-width: 400px) {
    font-size: 15px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
  @media (max-width: 800px) {
    width: 100%;
    flex-direction: row;
  }
`;

export const Social = styled.div`
  width: 100%;
  background: ${Colors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin: 0;
  margin-top: 5px;
`;
export const Comment = styled.div`
  width: 100%;
  background: ${Colors.white};
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  @media (max-width: 800px) {
    justify-content: flex-end;
  }
`;
export const SocialText = styled(CustomText)`
  color: ${Colors.textGray};
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 10px;
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;
