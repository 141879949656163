import {gql} from '@apollo/client';

export const SYSTEM_CONFIGS = gql`
  query systemConfigsByCountry {
    systemConfigsByCountry {
      id
      video_url
    }
  }
`;
