import {gql} from '@apollo/client';
import {COMPANY_FRAGMENT, LAWYER_FRAGMENT} from '../../Auth/GraphQl/SignUp';

export const UPDATE_LAWYER = gql`
  mutation updateLawyer(
    $name: String
    $phone: String
    $email: String
    $city: ID
    $building_name: String
    $street_name: String
    $twitter_handle: String
    $fax: String
    $land_line: String
    $google_maps_link: String
    $website: String
    $youtube: String
    $telegram: String
    $whats_app_phone: String
    $facebook_id: String
    $google_id: String
    $linkedin_id: String
    $years_of_practice: Float
    $photo: ID
    $training_courses: [TrainingCoursesInput!]
    $research: [LawyerResearchInput!]
    $work_experience: [LawyerWorkExperienceInput!]
    $pending_specialties: [ID!]
    $specialty_photos: [SpecialtyPhotosInput]
    $biography: String
  ) {
    updateLawyer(
      updateLawyerInput: {
        name: $name
        phone: $phone
        email: $email
        city: $city
        building_name: $building_name
        street_name: $street_name
        twitter_handle: $twitter_handle
        fax: $fax
        land_line: $land_line
        google_maps_link: $google_maps_link
        website: $website
        youtube: $youtube
        telegram: $telegram
        whats_app_phone: $whats_app_phone
        facebook_id: $facebook_id
        google_id: $google_id
        linkedin_id: $linkedin_id
        years_of_practice: $years_of_practice
        photo: $photo
        training_courses: $training_courses
        research: $research
        work_experience: $work_experience
        pending_specialties: $pending_specialties
        specialty_photos: $specialty_photos
        biography: $biography
      }
    ) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $email: String
    $name: String
    $address: String
    $authorized_signatory: String
    $city: ID
    $research: [ResearchInput!]
    $biography: String
    $activities: [String!]
    $clients: [String!]
    $logo: ID
    $specialties: [ID!]
    $goals: [ID!]
    $photos: [ID!]
    $fax: String
    $mobile_phone: String
    $commercial_name: String
    $land_line: String
    $google_maps_link: String
    $website: String
    $youtube: String
    $telegram: String
    $whats_app_phone: String
  ) {
    updateCompany(
      updateCompanyInput: {
        email: $email
        name: $name
        address: $address
        authorized_signatory: $authorized_signatory
        city: $city
        research: $research
        biography: $biography
        activities: $activities
        clients: $clients
        logo: $logo
        specialties: $specialties
        goals: $goals
        photos: $photos
        fax: $fax
        mobile_phone: $mobile_phone
        commercial_name: $commercial_name
        land_line: $land_line
        google_maps_link: $google_maps_link
        website: $website
        youtube: $youtube
        telegram: $telegram
        whats_app_phone: $whats_app_phone
      }
    ) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const CREATE_CERTIFICATE = gql`
  mutation createCertificate(
    $certificate_name: String
    $certificate_id: String
    $certificate_url: String
    $certificate_issued_from: String
    $certificate_issue_date: DateTime
    $photo: ID
  ) {
    createCertificate(
      createCertificateInput: {
        certificate_name: $certificate_name
        certificate_id: $certificate_id
        certificate_url: $certificate_url
        certificate_issued_from: $certificate_issued_from
        certificate_issue_date: $certificate_issue_date
        photo: $photo
      }
    ) {
      id
      certificate_name
      certificate_id
      certificate_url
      certificate_issued_from
      certificate_issue_date
      photo {
        id
        label
        type
        filename
      }
    }
  }
`;

export const REMOVE_CERTIFICATE = gql`
  mutation removeCertificate($certificateId: ID!) {
    removeCertificate(certificateId: $certificateId) {
      id
      certificate_id
      certificate_url
      certificate_issued_from
      certificate_issue_date
      photo {
        id
        label
        type
        filename
      }
    }
  }
`;

export const REMOVE_SPECIALTIES = gql`
  mutation removeSpecialties($specialties_ids: [ID!]!) {
    removeSpecialties(specialties_ids: $specialties_ids) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;

export const FIND_SPECIALTIES_BY_TYPE = gql`
  query findSpecialtiesByType($type: TYPES_ENUM!) {
    findSpecialtiesByType(type: $type) {
      id
      type
      specialty
      show
    }
  }
`;

export const TEAM_MEMBER = gql`
  fragment TeamMember on TeamMember {
    id
    name
    role
    photo {
      id
      label
      type
      filename
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation addTeamMembers($name: String, $role: String, $photo: ID) {
    addTeamMembers(teamMemberDTO: {name: $name, role: $role, photo: $photo}) {
      ...TeamMember
    }
  }
  ${TEAM_MEMBER}
`;

export const REMOVE_MEMBER = gql`
  mutation removeTeamMember($member_id: String!) {
    removeTeamMember(member_id: $member_id) {
      id
      name
      role
      photo {
        label
      }
    }
  }
`;
export const GET_CURRENT_COMPANY = gql`
  query findCurrentCompany {
    findCurrentCompany {
      id
      email
      name
      address
      fax
      is_disabled
      disable_review
      city {
        id
        city
        country {
          id
          country
          show
          isoCode
          updated_at
          created_at
        }
        show
        updated_at
        created_at
      }
      mobile_phone
      whats_app_phone
      logo {
        id
        filename
        label
        type
        created_at
      }
      specialties {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      photos {
        id
        filename
        label
        type
        created_at
      }
      ssn
      city {
        id
        city
        country {
          id
          country
          show
          updated_at
          created_at
          isoCode
        }
        show
        updated_at
        created_at
      }
      authorized_signatory
      goals {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      activities
      research {
        label
        url
      }
      company_type
      commercial_name
      date_of_establishment
      hours_of_operation
      number_of_employees
      biography
      is_email_confirmed
      deleted
      updated_at
      created_at
      membership {
        id
        type
        name
        created_at
        cost
        lawyer_privileges {
          pending_specialties
          scholar_degree
          training_courses
          research
          cv
          social_media
        }
        company_privileges {
          goals
          activities
          clients
          research
          team
          biography
          social_media
        }
      }
      membership_expiry
    }
  }
`;

export const GET_CURRENT_LAWYER = gql`
  query findCurrentLawyer {
    findCurrentLawyer {
      id
      type
      name
      email
      phone
      work_phone
      years_of_practice
      city {
        id
        city
        country {
          id
          country
          show
          updated_at
          created_at
          isoCode
        }
        show
        updated_at
        created_at
      }
      pending_specialties {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      specialty_photos {
        photos
        specialty
      }
      verified_specialties {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      photo {
        id
        filename
        label
        type
        created_at
      }
      research {
        label
        url
      }
      building_name
      street_name
      facebook_id
      google_id
      apple_id
      linkedin_id
      twitter_handle
      fax
      land_line
      google_maps_link
      website
      youtube
      telegram
      whats_app_phone
      token
      ejazeh_number
      naqabeh_number
      ssn
      training_courses {
        title
        acquired_from
        acquired_at
      }
      work_experience {
        name
        years
      }
      is_email_confirmed
      is_phone_confirmed
      is_disabled
      disable_review
      deleted
      updated_at
      created_at
      membership {
        id
        type
        name
        created_at
        cost
        lawyer_privileges {
          pending_specialties
          scholar_degree
          training_courses
          research
          cv
          social_media
        }
        company_privileges {
          goals
          activities
          clients
          research
          team
          biography
          social_media
        }
      }
      membership_expiry
    }
  }
`;

export const DELETE_LAWYER_ACCOUNT = gql`
  mutation requestLawyerDeletion {
    requestLawyerDeletion
  }
`;

export const DELETE_COMPANY_ACCOUNT = gql`
  mutation requestCompanyDeletion {
    requestCompanyDeletion
  }
`;
