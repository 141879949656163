import gql from 'graphql-tag';

export const SPECIALTIES = gql`
  query findAllSpecialties {
    findAllSpecialties {
      id
      specialty
      type
      created_at
      updated_at
      show
    }
  }
`;

export const ASSOCIATIONS = gql`
  query findAllAssociations {
    findAllAssociations {
      id
      name
      type
    }
  }
`;

export const COURT_DEGREES = gql`
  query findAllCourtDegrees {
    findAllCourtDegrees {
      id
      name
      type
    }
  }
`;

export const FIND_CITY_BY_COUNTRY = gql`
  query findCitiesByCountry($country: String!) {
    findCitiesByCountry(country: $country) {
      id
      country {
        id
        country
      }
      city
    }
  }
`;

export const FIND_SPECIALTY_BY_ID = gql`
  query findSpecialtyById($id: ID!) {
    findSpecialtyById(id: $id) {
      id
      specialty
    }
  }
`;

export const FIND_LAWYERS = gql`
  query findLawyers(
    $name: String
    $type: LAWYER_ENUM
    $rating: Float
    $city: ID
    $specialties: [ID!]
    $years_of_practice: YEARS_OF_PRACTICE_ENUM
    $verified: Boolean
    $page_number: Float
  ) {
    findLawyers(
      filter: {
        name: $name
        type: $type
        rating: $rating
        city: $city
        specialties: $specialties
        years_of_practice: $years_of_practice
        verified: $verified
        page_number: $page_number
      }
    ) {
      page_count
      lawyers {
        id
        type
        name
        rating
        city {
          id
          city
          country {
            id
            country
            isoCode
          }
        }
        pending_specialties {
          id
          show
          specialty
          type
          created_at
          updated_at
        }
        specialty_photos {
          photos
          specialty
        }
        verified_specialties {
          id
          type
          specialty
          show
        }
        photo {
          filename
          label
        }
        years_of_practice
        work_phone
        email
        phone
        street_name
        building_name
        biography
        languages
        scholar_degree
        scholar_major
        social_status
        date_of_birth
        research {
          label
          url
        }
        hours_of_operation
        work_experience {
          name
          years
        }
        training_courses {
          title
          acquired_from
          acquired_at
        }
        membership {
          type
          id
        }
        verified
      }
    }
  }
`;

export const FIND_COMPANIES = gql`
  query findCompanies(
    $name: String
    $rating: Float
    $city: ID
    $goals: [ID!]
    $page_number: Float
  ) {
    findCompanies(
      filter: {
        name: $name
        rating: $rating
        city: $city
        goals: $goals
        page_number: $page_number
      }
    ) {
      page_count
      companies {
        id
        email
        name
        address
        fax
        mobile_phone
        clients
        whats_app_phone
        land_line
        google_maps_link
        website
        youtube
        telegram
        logo {
          id
          filename
          label
        }
        photos {
          id
          filename
          label
        }
        token
        rating
        city {
          id
          city
          country {
            id
            country
            isoCode
          }
        }
        specialties {
          specialty
        }
        ssn
        authorized_signatory
        goals {
          id
          type
          specialty
          show
        }
        activities
        research {
          label
          url
        }
        company_type
        company_nature
        commercial_name
        date_of_establishment
        hours_of_operation
        number_of_employees
        biography
        is_email_confirmed
        team {
          id
          photo {
            filename
            label
          }
          name
          role
        }
      }
    }
  }
`;
export const ALL_BLOGS = gql`
  query findAllApprovedBlogs($pageNumber: Float) {
    findAllApprovedBlogs(pageNumber: $pageNumber) {
      page_count
      blogs {
        id
        body
        poster_name
        title
        approved
        comments {
          id
          user_name
          body
          deleted
          created_at
        }
        comments_count
        deleted
        created_at
      }
    }
  }
`;
