import {applyMiddleware, compose, createStore, Store} from 'redux';
import * as Sentry from '@sentry/react';
import Thunk from 'redux-thunk';
import persistedReducer from './Reducer/index';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(Thunk),
    sentryReduxEnhancer,
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
      : (f: any) => f,
  ),
) as Store;
