import React from 'react';
import {useState} from 'react';
import {Modal, CheckPicker} from 'rsuite';
import styled from 'styled-components';
import {LawyerType, YEARS_OF_PRACTICE_ENUM} from '../../../Action/types';
import {CustomText} from '../../../Components/Common';
import {
  Select,
  SelectContainer,
  Option,
  CheckBox,
  Label,
} from '../../Auth/SignUp';
import Colors from '../../Theme/Colors';
const DATA_MOCK = [
  {
    label: 'اقل من خمس سنة',
    value: YEARS_OF_PRACTICE_ENUM.lessThanFive,
  },
  {
    label: 'خمسة الى عشر سنوات',
    value: YEARS_OF_PRACTICE_ENUM.fiveToTen,
  },
  {
    label: 'عشرة الى عشرين سنة',
    value: YEARS_OF_PRACTICE_ENUM.tenToTwenty,
  },
  {
    label: 'فوق العشرين سنة',
    value: YEARS_OF_PRACTICE_ENUM.moreThanTwenty,
  },
];
const AdvancedSearch = () => {
  const [withAssociation, setWithAssociation] = useState(false);
  const type = LawyerType.lawyer; // staticlly until integration
  return (
    <Modal show={false}>
      <Modal.Header>
        <Title size={20}>البحث المتقدم</Title>
      </Modal.Header>
      <Body>
        {type === LawyerType.lawyer && (
          <SelectContainer>
            <Select name="years-of-experience">
              <Option value={'default'} disabled selected>
                سنوات الخبرة
              </Option>
              <Option
                value={1}>{`${YEARS_OF_PRACTICE_ENUM.lessThanFive}`}</Option>
              <Option value={2}>{`${YEARS_OF_PRACTICE_ENUM.fiveToTen}`}</Option>

              <Option
                value={3}>{`${YEARS_OF_PRACTICE_ENUM.tenToTwenty}`}</Option>

              <Option
                value={4}>{`${YEARS_OF_PRACTICE_ENUM.moreThanTwenty}`}</Option>
            </Select>
          </SelectContainer>
        )}
        <SelectContainer>
          <Select name="city">
            <Option value={'default'} disabled selected>
              التقييم
            </Option>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>

            <Option value={3}>3</Option>

            <Option value={4}>4</Option>

            <Option value={5}>5</Option>
          </Select>
        </SelectContainer>
        <CheckBoxWrapper>
          <CheckBox
            type={'checkbox'}
            id={'checkBox'}
            width={'10px'}
            onChange={() => setWithAssociation(!withAssociation)}
          />
          <Label htmlFor={'rememberMe'}>
            <CustomText>مسجل في جمعية او جهة:</CustomText>
          </Label>
        </CheckBoxWrapper>
        {withAssociation && (
          <SelectContainer>
            <MultiPicker
              placeholder="الجمعية او الجهة"
              onChange={(value: any) => console.log(value)}
              data={DATA_MOCK}
            />
          </SelectContainer>
        )}
      </Body>
    </Modal>
  );
};

export default AdvancedSearch;
const MultiPicker = styled(CheckPicker)`
  outline: none !important;
  width: 100%;
  & a {
    border: none !important;
    direction: rtl !important;

    transform: rotateY(180deg);
    width: 100%;
  }
  & span {
    transform: rotateY(180deg);
    display: inline-block;
    color: ${Colors.darkGray1};
  }
  & .rs-picker-value-count {
    display: none;
  }
`;
const Title = styled(CustomText)`
  direction: rtl;
  text-align: center;
`;

const Body = styled(Modal.Body)`
  padding: 10px 40px 30px;
`;

const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  direction: row;
  justify-content: flex-start;
  direction: rtl;
  margin: 25px -10px 10px;
`;
