import styled from 'styled-components';
import {SubTitle} from './Guide';
import {Container, PrivacySection, PrivacyTitle} from './PrivacyPolicy';
import {CustomText} from '../Components/Common';

const TermsAndCondtions = () => {
  return (
    <Container>
      <PrivacySection>
        <PrivacyTitle>اتفاقية الإستخدام</PrivacyTitle>
        <STitle>
          <Paragraph>مرحبًا بك في عدل!</Paragraph>
          <Paragraph>
            يحرص عدل على تصميم تقنيات وخدمات تساعد الأشخاص على التواصل مع مقدمي
            الخدمات القانونية المتخصصة، وتعزيز نمو الأنشطة القانونية. تحكم هذه
            الشروط استخدامك عدل والمنتجات والميزات والتطبيقات والخدمات والتقنيات
            والبرامج الأخرى التي سنوفرها مستقبلا، ما لم نوضح صراحةً أنه يتم
            تطبيق شروط أخرى مستقلة (خلاف هذه الشروط).
          </Paragraph>
          <Paragraph>
            لا نُحصِل أي رسوم من الباحثين عن الخدمات القانونية مقابل استخدام عدل
            أو المنتجات والخدمات الأخرى التي تغطيها هذه الشروط. وبدلاً من ذلك،
            يدفع مقدم الخدمة القانونية المتخصصة اشتراك سنوي (محامي، محامي شرعي،
            خبير، محكم، شركة قانونية)، وتدفع لنا الأنشطة التجارية والمؤسسات
            مقابل عرض إعلانات منتجاتها وخدماتها على الباحث عن الخدمات القانونية
            المتخصصة او مقدم الخدمات القانونية المتخصصة. باستخدامك لعدل، فإنك
            توافق على أنه يمكننا أن نعرض عليك إعلانات نرى أنها ستكون ملاءمة لك
            ولاهتماماتك. نحن نستخدم بياناتك الشخصية لمساعدتنا في تحديد الإعلانات
            التي نعرضها عليك.
          </Paragraph>
          <Paragraph>
            لا نبيع بياناتك الشخصية للمعلنين، ولا نشارك المعلومات التي تحدد
            هويتك مباشرةً (مثل اسمك أو عنوان بريدك الإلكتروني أو معلومات الاتصال
            الأخرى) مع المعلنين ما لم تمنحنا إذنًا محددًا بذلك.
          </Paragraph>
          <Paragraph>
            نشر المواضيع القانونية ضمن المجلة القانونية، مشروط بموافقة إدارة عدل
            والمشرفين على المجلة القانونية على أي منشور او مقال قانوني يتم
            ارساله إليها لنشره
          </Paragraph>
          <Paragraph>
            تستطيع الظهور ضمن نتائج البحث عن المتحقق من تخصصهم في حال إرسالك
            للمستندات او الاوراق او الوثائق التي تلزم لإثبات حصه التخصص
            القانوني، عن طريق مراسلة إدارة عدل على البريد الإلكتروني الخاص فيهم.
          </Paragraph>
          <Paragraph>
            عدل او الشركة المالكة له او المالكين له غير مسؤولين عن التعليقات او
            المنشورات او التقييمات التي تنشر من قبل المتصفحين والباحثين عن
            الخدمات القانونية المتخصصة على السيرة الذاتية او في أي موضع ضمن عدل
            لأي من مقدمي الخدمات القانونية المتخصصة (محامي، محامي شرعي، شركة
            قانونية، محكم، خبير) باستخدامك لعدل، فإنك توافق على عدم مسؤولية عدل
            او الشركة المالكة له او المالكين له عن أي تعليق او تقييم او منشور
            يقوم بنشره أي زائر او متصفح او باحث عن خدمات القانونية وانك تسقط حقك
            ضد عدل او الشركة المالكة له او المالكين له او أي جهة ذات علاقة بعدل.
          </Paragraph>
          <WrapContent>
            <Heading>الخدمات التي نوفرها </Heading>
            <CustomText>
              تتمثل مهمتنا في تعزيز قدرة مقدمي الخدمات القانونية على بناء شبكة
              تواصل متخصصة والتعاون من أجل تقريب المسافات بينهم وبين الباحثين عن
              الخدمات القانونية المتخصصة. وللمضي قدمًا لتحقيق هذه المهمة، نقدم
              لك المنتجات والخدمات الموضّحة أدناه:
            </CustomText>
            <CustomText>تقديم تجربة ذات طابع شخصي لك:</CustomText>
            <CustomText>
              تختلف تجربتك على عدل عن تجربة أي شخص آخر: بدءًا من التخصصات
              القانونية والسيرة الذاتية والخبرات وغيرها من أنواع المحتوى الأخرى
              التي تميزك عن غيرك والتي تستطيع نشرها ليستطيع الباحث عن الخدمة
              القانونية الاطلاع عليها.
            </CustomText>
            <CustomText>
              توفير وسائل التواصل مع الأشخاص والمؤسسات التي تقدم الخدمات
              القانونية المتخصصة حول العالم:
            </CustomText>
            <CustomText>
              نساعدك في العثور على الأشخاص والمجموعات والأنشطة القانونية
              المتخصصة والمؤسسات وغيرها مما يقع ضمن الدائرة القانونية والتواصل
              معها عبر ما يقدمه عدل من وسائل تواصل معها. ونحن على يقين أن
              خدماتنا تصبح أكثر فائدة عندما يتواصل المتخصصون في مجالات القانون
              معًا ومع المجموعات والمؤسسات القانونية المتخصصة.
            </CustomText>
            <CustomText>تشجيعك على التعبير عن نفسك:</CustomText>
            <CustomText>
              نوفر لك العديد من الطرق للتعبير عن نفسك على عدل والتواصل مع مقدمي
              الخدمات القانونية المتخصصة وما يتعلق بهم وبأعمالهم، على سبيل
              المثال، نشر تخصصك القانوني وسيرتك الذاتية والمؤلفات والابحاث
              القانونية وغيرها، وسنبقى نطور من خدماتنا، بالإضافة الى إمكانية نشر
              مواضيع قانونية محكمة في المجلة القانونية عدل.
            </CustomText>
            <CustomText>مساعدتك في إشهار تخصصك القانوني:</CustomText>
            <CustomText>
              حيث نوفر محرك بحث متخصص يساعد الباحث عن الخدمات القانونية المتخصصة
              من العثور على مقدم الخدمة القانونية المناسبة لحاجته و/او لقضيته،
              بالإضافة الى السيرة الذاتية لمقدم الخدمة القانونية من محامي او
              محامي شرعي او خبير او شركة قانونية او محكم.
            </CustomText>
            <CustomText>
              مواجهة السلوكيات الضارة وحماية ودعم مجتمعنا:
            </CustomText>
            <CustomText>
              نحرص على توظيف فرق عمل مخصصة من جميع أنحاء العالم بالإضافة إلى
              تطوير أنظمة تقنية متقدمة لاكتشاف حالات إساءة استخدام منتجاتنا
              والسلوكيات الضارة تجاه الآخرين والمواقف التي يمكننا تقديم المساعدة
              خلالها تجاه دعم وحماية مجتمعنا. فإذا نما إلى علمنا وجود مثل هذا
              المحتوى أو السلوك، فسنتخذ الإجراء المناسب، على سبيل المثال، عرض
              تقديم مساعدة، أو إزالة المحتوى، أو إزالة أو حجب الوصول إلى ميزات
              محددة، أو تعطيل الحساب، أو التواصل مع جهات تنفيذ القانون.
            </CustomText>
            <CustomText>البحث عن طرق لتحسين خدماتنا على نحو أفضل:</CustomText>
            <CustomText>
              نهتم بإجراء الأبحاث لتطوير منتجاتنا واختبارها وتحسينها. ويتضمن ذلك
              تحليل بيانات المستخدمين المتوفرة لدينا والتعرف على طبيعة استخدام
              الأشخاص لمنتجاتنا، على سبيل المثال من خلال إجراء استبيانات وإجراء
              اختبارات على ميزات جديدة واكتشاف المشكلات وحلها.
            </CustomText>
            <CustomText>تمكين الوصول العالمي إلى خدماتنا:</CustomText>
            <CustomText>
              سيتم عرض الخدمات القانونية المتخصصة لجميع المسجلين في عدل للباحثين
              عن الخدمات القانونية المتخصصة عبر انحاء العالم، حتى نتمكن من تقديم
              خدمتنا على مستوى العالم، يجب علينا تخزين المحتوى والبيانات
              وتوزيعها في أنظمة ومراكز البيانات التي نستخدمها في جميع أنحاء
              العالم، بما في ذلك تلك التي يقع خارج بلد إقامتك.{' '}
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>التزاماتك تجاه عدل</Heading>
            <Paragraph>
              نقدم هذه الخدمات لك وللآخرين للمساعدة في المضي قدمًا لتحقيق
              مهمتنا. وفي المقابل، نريد منك الالتزام بما يلي:
            </Paragraph>
            <Paragraph>
              وكون اننا نقدمك كشخصية تعمل في احد المجالات القانونية ومقدم لخدمات
              قانونية متخصصة، وهذا يعني اهمية التحقق من شخص المشترك والمستخدم
              لخدمات عدل لحساسيتها واهميتها، حتى يعم الامان والثقة بما يقدمه عدل
              للمستخدمين من معلومات حول مقدمي الخدمات القانونية المتخصصة ولهذه
              الاسباب، يجب عليك:
            </Paragraph>
            <Point>
              {' '}
              &bull; استخدام نفس الاسم الذي تستخدمه في حياتك اليومية.
            </Point>
            <Point> &bull; تقديم معلومات دقيقة عن نفسك ومجال عملك.</Point>
            <Point>
              {' '}
              &bull; إنشاء حساب واحد فقط في كل مجال قانوني (محامي، محامي شرعي،
              خبير، محكم، شركة قانونية).
            </Point>
            <Point>
              {' '}
              &bull; عدم مشاركة كلمة السر أو منح صلاحية الوصول إلى حسابك على عدل
              إلى الآخرين أو نقل حسابك إلى أي شخص آخر .
            </Point>
            <CustomText>
              نسعى دائمًا إلى تمكين جميع الأشخاص بصفة عامة من استخدام عدل، إلا
              أنه لا يمكنك استخدام عدل في الحالات التالية:{' '}
            </CustomText>
            <Point>
              &bull; إذا لم تكن حاصلا على إجازة ممارسة المحاماة ومزاول لمهنة
              المحاماة للتسجيل في حساب محامي او محامي شرعي من الجهات الرسمية
              المعتمدة في بلدك.
            </Point>
            <Point>
              &bull; إذا لم تكن الشركة مسجلة قانونيا لدى الجهات الرسمية في بلدك،
              للتسجيل في حساب شركة قانونية.
            </Point>
            <Point>
              &bull; إذا كان عمرك أقل من 18 عامًا (أو الحد الأدنى للسن القانوني
              المسموح به لاستخدام خدماتنا في بلدك) للتسجيل في حساب خبير او محكم.
            </Point>
            <Point>
              &bull;إذا تمت إدانتك في جريمة جزائية او تأديبية او تم تسجيل عدد
              يزيد عن ثلاث قضايا جزائية او مطالبات مالية او تأديبية.
            </Point>
            <Point>
              &bull; إذا كنا قد قمنا بتعطيل حسابك في وقت سابق بسبب انتهاك شروطنا
              أو سياساتنا.
            </Point>
            <Heading> ما الذي يمكنك القيام به على عدل</Heading>
            <CustomText>
              نريد أن يستخدم الأشخاص عدل للتعريف بأنفسهم وتخصصاتهم وخدماتهم
              القانونية والتواصل مع باقي المشتركين على عدل، ولكن دون أن يكون ذلك
              على حساب أمان وسلامة الآخرين أو على حساب نزاهة مجتمعنا. ولذلك أنت
              توافق على عدم ارتكاب أي من السلوكيات الموضّحة أدناه (أو تسهيل ذلك
              للآخرين أو مساعدتهم في ارتكابه):
            </CustomText>
            <Point>
              1. لا يجوز لك استخدام منتجاتنا للقيام بأي شيء أو نشر أي شيء:
              <Point>&bull;مخالف للواقع او الحقيقة.</Point>
              <Point>&bull;غير قانوني أو مضلل أو تمييزي أو احتيالي.</Point>
              <Point>
                &bull;يخالف أو يخرق حقوق شخص آخر، بما في ذلك حقوق الملكية
                الفكرية الخاصة به.
              </Point>
            </Point>
            <Point>
              2. لا يجوز لك تحميل الفيروسات أو الرموز البرمجية الضارة أو القيام
              بأي شيء من شأنه تعطيل أو عرقلة أو إعاقة تشغيل منتجاتنا على النحو
              المناسب أو ظهورها بالشكل المناسب.
            </Point>
            <Point>
              3. لا يجوز لك الوصول إلى البيانات أو جمعها من منتجاتنا باستخدام
              وسائل تلقائية (دون الحصول على إذن مسبق منّا) أو محاولة الوصول إلى
              بيانات لا تملك صلاحية الوصول إليها.
            </Point>
            <CustomText>
              يمكننا إزالة أو حجب الوصول إلى المحتوى الذي ينتهك هذه الأحكام.
            </CustomText>
            <CustomText>
              عند انتهاك هذه الشروط بشكل خطير أو متكرر؛ أو إذا كان القيام بذلك
              يعرضنا نحن أو غيرنا للمسؤولية القانونية؛ أو يلحق الضرر بمجتمع
              مستخدمينا؛ أو يعرض أمان أي من خدماتنا أو أنظمتنا أو منتجاتنا للخطر
              أو يؤدي إلى إعاقة تشغيلها؛ أو عندما لا نتمكن من التعامل مع الأمور
              بالشكل المطلوب بسبب قيود فنية؛ أو في الحالات التي يحظر علينا
              القيام بذلك لأسباب قانونية.{' '}
            </CustomText>
            <CustomText>
              للمساعدة في دعم عدل، نحثك على الإبلاغ عن محتوى أو سلوك تعتقد أنه
              يمثل انتهاكًا لحقوقك (بما في ذلك حقوق الملكية الفكرية) أو شروطنا
              وسياساتنا).
            </CustomText>
            <CustomText>
              يمكننا أيضًا إزالة أو حجب صلاحية الوصول إلى عناصر المحتوى أو
              الخدمات التي تقدمها أو المعلومات الخاصة بك إذا قررنا أن القيام
              بذلك ضروري بشكل معقول لتجنب أو تخفيف وطأة الآثار القانونية أو
              التنظيمية السلبية على عدل.{' '}
            </CustomText>
            <CustomText>
              في حال انتهاء الاشتراك المدفوع او طلب حذف الحساب من طرف المستخدم
              فإن الحساب يعود لإظهار بيانات الحساب وفق اعدادات الحساب المجاني
              ويمكنك في هذه الحالة طلب الغاء التقييم لحسابك.
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>الأذونات التي تمنحها لنا</Heading>
            <CustomText>
              نطلب منك منحنا أذونات محددة حتى نتمكن من تقديم خدماتنا:{' '}
            </CustomText>
            <Point>
              1. إذن استخدام المحتوى الذي تقوم بإنشائه : قد تكون بعض عناصر
              المحتوى التي تقوم بمشاركتها أو تحميلها، مثل الصور، محمية بموجب
              قوانين الملكية الفكرية.
              <CustomText>
                أنت تمتلك حقوق الملكية الفكرية (حقوق مثل حقوق النشر أو حقوق
                العلامات التجارية) لأي محتوى تقوم بإنشائه ومشاركته على عدل. ولا
                يوجد في هذه الشروط ما يحرمك من الحقوق التي تتمتع بها فيما يتعلق
                بالمحتوى الخاص بك. ولك مطلق الحرية في مشاركة المحتوى الخاص بك مع
                أي شخص آخر، في أي مكان تريده.
              </CustomText>
              <CustomText>
                ومع ذلك، حتى نتمكن من تقديم خدماتنا، نطلب منك أن تمنحنا بعض
                الأذونات القانونية (المعروفة باسم "الترخيص") التي تخول لك
                استخدام هذا المحتوى. ويتعلق هذا الأمر فقط بأغراض توفير وتحسين
                منتجاتنا وخدماتنا.
              </CustomText>
              <CustomText>
                على وجه التحديد، عندما تقوم بمشاركة محتوى محمي بموجب حقوق
                الملكية الفكرية أو نشره أو تحميله على أو في منتجاتنا أو بأي
                طريقة ذات صلة بمنتجاتنا، فإنك بذلك تمنحنا ترخيصًا دوليًا غير
                حصري، قابلاً للنقل، وقابلاً للترخيص من الباطن، وغير محفوظ
                الحقوق، لاستضافة المحتوى، واستخدامه، وتوزيعه، وتعديله، وتشغيله،
                ونسخه، وتقديمه أو عرضه على العامة، وترجمته، وإنشاء أعمال مشتقة
                منه وفقا لسياسة الخصوصية الخاصة بنا. وذلك يعني أنه، على سبيل
                المثال، إذا قمت بمشاركة صورة او بحث على عدل، فإنك بذلك تمنحنا
                إذنًا يسمح لنا بالحق في تخزينها ونسخها ومشاركتها مع الآخرين
                (ونكرر، بما يتوافق مع سياسة الخصوصية الخاصة بنا) وتنتهي صلاحية
                هذا الترخيص بمجرد حذف المحتوى الخاص بك من أنظمتنا.
              </CustomText>
              <CustomText>
                بمجرد حذف المحتوى، لا يمكن للمستخدمين الآخرين رؤيته، ومع ذلك فقد
                يظل موجودًا في مكان آخر على أنظمتنا للأسباب التالية:
                <Point>
                  &bull; لا يمكن إجراء الحذف الفوري للمحتوى بسبب قيود تقنية (في
                  هذه الحالة، سيتم حذف المحتوى الخاص بك في غضون 90 يومًا بحد
                  أقصى من وقت حذفه).
                </Point>
                <Point>
                  &bull; يؤدي الحذف الفوري للمحتوى إلى تقييد قدرتنا على:
                  <Point>
                    &bull; إجراء تحقيق أو تحديد نشاط غير قانوني أو انتهاكات
                    للشروط والسياسات التي نستخدمها (على سبيل المثال، تحديد إساءة
                    استخدام منتجاتنا أو أنظمتنا أو التحقيق فيه).
                  </Point>
                  <Point>
                    &bull; الامتثال إلى التزام قانوني، مثل الحفاظ على الأدلة, أو
                  </Point>
                  <Point>
                    &bull; الامتثال إلى طلب سلطة قضائية أو إدارية أو جهة إنفاذ
                    قانون أو جهة حكومية.
                  </Point>
                </Point>
              </CustomText>
              <CustomText>
                وفي هذه الحالة، لن يتم الاحتفاظ بالمحتوى لمدة أطول من المدة التي
                تطلبها الأغراض التي تم الاحتفاظ به من أجلها (تختلف المدة المحددة
                حسب كل حالة على حدة).{' '}
              </CustomText>
              <CustomText>
                في كل حالة من الحالات المذكورة أعلاه، سيستمر العمل بهذا الترخيص
                حتى يتم حذف المحتوى بالكامل.{' '}
              </CustomText>
            </Point>
            <Point>
              2. إذن استخدام اسمك وصورة ملفك الشخصي والمعلومات المتعلقة
              بالإجراءات التي تتخذها فبما يتعلق بالإعلانات والمحتوى المُموَّل:
              إنك تمنحنا إذنًا باستخدام اسمك وصورة ملفك الشخصي والمعلومات
              المتعلقة بالإجراءات التي اتخذتها على عدل بجوار أو في أي محتوى له
              صلة بالإعلانات والعروض وغيرها من عناصر المحتوى المُموَّل التي
              نعرضها عبر منتجاتنا، دون تقديم أي تعويض لك مقابل ذلك. على سبيل
              المثال، يجوز لنا أن نعرض على أصدقائك أنك مهتم بمناسبة تم الإعلان
              عنها او انك قمت بنشر مقال قانوني في مجلة عدل القانونية او قمت
              بتعديلات على ملفك الشخصي.{' '}
            </Point>
            <Point>
              3. إذن تحديث البرنامج الذي تستخدمه أو تقوم بتنزيله: في حال تنزيل
              برنامجنا أو استخدامه، فأنت تمنحنا إذنًا بتنزيل التحديثات وتثبيتها
              على البرنامج كلما أمكن.
            </Point>
          </WrapContent>
          <WrapContent>
            <Heading>شروط إضافية </Heading>
            <CustomText>تحديث شروطنا</CustomText>
            <CustomText>
              نسعى دائمًا إلى تحسين خدماتنا وتطوير ميزات جديدة لتصبح منتجاتنا
              أفضل وأكثر ملاءمة لك ولمجتمعنا. ونتيجة لذلك، فقد يتطلب الأمر تحديث
              هذه الشروط من وقت لآخر لتعبر عن خدماتنا وممارساتنا بدقة أكبر. لن
              نقوم بإجراء تغييرات إلا إذا لم تعد الأحكام مناسبة أو كانت غير
              مكتملة، وكانت التغييرات معقولة وتراعي مصالحك.{' '}
            </CustomText>
            <CustomText>
              سنقوم بإبلاغك (على سبيل المثال: عبر البريد الإلكتروني أو عبر
              منتجاتنا) قبل إجراء أي تغييرات على هذه الشروط بمدة لا تقل عن 30
              يومًا ونمنحك فرصة لمراجعتها قبل أن تدخل حيّز التنفيذ، ما لم تكن
              التغييرات مطلوبة بموجب القانون. وبمجرد دخول أي شروط تم تحديثها حيز
              التنفيذ، يجب عليك الالتزام بها إذا قررت الاستمرار في استخدام
              منتجاتنا.
            </CustomText>
            <CustomText>
              ويسعدنا كثيرًا أن تستمر في استخدام منتجاتنا، إلا أنه إن لم توافق
              على شروطنا المحدّثة واخترت ألا تكون جزءًا من مجتمع عدل بعد الآن،
              يمكنك حذف حسابك في أي وقت تشاء.{' '}
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>تعليق الحساب أو إنهاؤه</Heading>
            <CustomText>
              نريد أن يصبح عدل مكانًا يستطيع من خلاله الأشخاص الحصول عن طريقه
              بالخدمات القانونية المتخصصة بأمان تام وثقة عالية وسرية تامه.
            </CustomText>
            <CustomText>
              إذا وجدنا أنك قمت بشكل واضح أو جدي أو متكرر بمخالفة شروطنا أو
              سياساتنا، بما في ذلك معايير مجتمعنا على وجه الخصوص، يجوز لنا تعليق
              حسابك أو تعطيل صلاحية الوصول إليه بشكل دائم. يجوز لنا أيضًا تعليق
              حسابك أو تعطيله في حال تكرار انتهاكك لحقوق الملكية الفكرية لأشخاص
              آخرين أو إذا طُلب منا القيام بذلك لأسباب قانونية.{' '}
            </CustomText>
            <CustomText>
              وعندما نتخذ مثل هذا الإجراء، سنقوم بإخطارك بذلك ونوضح لك أي خيارات
              متوفرة لك لطلب المراجعة عبر احد وسائل التواصل التي تسجلها في
              حسابك، إلا إذا كان القيام بذلك يعرضنا نحن أو غيرنا للمسؤولية
              القانونية؛ أو يلحق الضرر بمجتمع مستخدمينا؛ أو يعرض أمان أي من
              خدماتنا أو أنظمتنا أو منتجاتنا للخطر، أو يؤدي إلى إعاقة تشغيلها؛
              أو عندما لا نتمكن من التعامل مع الأمور بالشكل المطلوب بسبب قيود
              فنية؛ أو في الحالات التي يحظر علينا القيام بذلك لأسباب قانونية.{' '}
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>حدود المسؤولية</Heading>
            <CustomText>
              نعمل جاهدين لتقديم أفضل المنتجات فضلاً عن توفير إرشادات واضحة لكل
              من يستخدمها. وعلى الرغم من ذلك، فإن منتجاتنا تُقدم "كما هي"، ولا
              نُقدم أي ضمانات تجاه بقائها آمنة، محمية، أو خالية من الأخطاء، أو
              أنها ستعمل دون حالات تعطل أو تأخير أو حالات تشغيل غير مثالية. إلى
              الحد الذي يسمح به القانون، فإننا نخلي مسؤوليتنا أيضًا تجاه كل
              أنواع الضمانات، سواءً كانت صريحة أو ضمنية، بما في ذلك الضمانات
              الضمنية للرواج التجاري، والملاءمة لغرض معين، وعدم انتهاك الحقوق
              والملكية. نحن لا نتحكم أو نوجه الأشخاص والآخرين إلى ما يجب أن
              يفعلوه أو يقولوه، ولا نتحمل أي مسؤولية تجاه أفعالهم أو سلوكياتهم
              (سواء عبر الإنترنت أو في الواقع) أو أي محتوى ينشرونه او يشاركونه
              عبر التعليقات او المنشورات او البيانات التي يتم ادخالها من قبل
              المشتركين (بما في ذلك أي محتوى مسيء أو غير ملائم أو فاحش أو غير
              قانوني، وغيرها من عناصر المحتوى المرفوضة).
            </CustomText>
            <CustomText>
              لا يمكننا التنبؤ بالأوقات التي يمكن أن تحدث خلالها مشكلات تتعلق
              بمنتجاتنا. وبناءً على ذلك، فإن مسؤوليتنا تقتصر على الحد الأقصى
              الذي تسمح به القوانين السارية، ولن نتحمل أي مسؤولية تحت أي ظرف من
              الظروف تجاه أي خسائر في الأرباح أو الإيرادات أو المعلومات أو
              البيانات أو أي أضرار تبعية أو خاصة أو غير مباشرة أو تحذيرية أو
              عقابية أو عارضة تنشأ عن أو ترتبط بهذه الشروط أو منتجات عدل، حتى
              وإن تم إبلاغنا باحتمالية وقوع مثل تلك الأضرار والخسائر.{' '}
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>النزاعات</Heading>
            <CustomText>
              نسعى جاهدين إلى توفير قواعد واضحة للحد من نشوب نزاعات بينك وبيننا،
              بل ومنعها تمامًا إن أمكن. وفي حالة نشوب نزاع، على الرغم من ذلك،
              فمن المفيد معرفة أماكن حله والقوانين التي تنطبق عليه مسبقًا.{' '}
            </CustomText>
            <CustomText>
              نسعى جاهدين إلى توفير قواعد واضحة للحد من نشوب نزاعات بينك وبيننا،
              بل ومنعها تمامًا إن أمكن. وفي حالة نشوب نزاع، على الرغم من ذلك،
              فمن المفيد معرفة أماكن حله والقوانين التي تنطبق عليه مسبقًا. تنطبق
              القوانين السارية في البلد محل إقامتك على أي دعوى أو سبب دعوى أو
              نزاع تقوم برفعه ضدنا، والذي ينشأ عن أو يرتبط بهذه الشروط أو منتجات
              عدل، ويجوز لك أيضًا تسوية النزاع في اي محكمة مختصة في عاصمة ذلك
              البلد الذي تخضع هذه الدعوى لسلطته القضائية. في كل الحالات الأخرى،
              أنت توافق على أن يتم الفصل في الدعوى حصريًا من خلال المحاكم
              الاردنية في العاصمة عمان وتوافق أيضًا على الخضوع للسلطة القضائية
              الشخصية للمحاكم بغرض البت في جميع هذه الدعاوى، كما أن هذه الشروط
              وأي دعاوى تتعلق بها ستخضع للقوانين السارية في المملكة الأردنية
              الهاشمية، دون الاعتبار لأي حالات لتعارض القوانين.
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>شروط أخرى</Heading>
            <Point>
              1. في حالة تعذّر تنفيذ أي جزء من هذه الشروط، فإن الجزء المتبقي يظل
              نافذًا وساريًا بالكامل. إذا عجزنا عن تنفيذ أي شرط من هذه الشروط،
              فلا يعد هذا تنازلاً عنه. أي تعديل أو تنازل عن هذه الشروط يجب أن
              يكون مكتوبًا وموقعًا بواسطتنا.
            </Point>
            <Point>
              2. لا يحق لك نقل أي من حقوقك أو واجباتك بموجب هذه الشروط إلى أي
              شخص آخر من دون الحصول على موافقتنا.
            </Point>
            <Point>
              3. يجب أن تعلم أننا قد نضطر إلى تغيير اسم المستخدم الخاص بحسابك في
              ظروف معينة (على سبيل المثال، إذا طالب شخص آخر بالحصول على اسم
              المستخدم هذا وثبت أنه لا يرتبط باسمك الذي تستخدمه في حياتك
              اليومية). سنبلغك سلفًا إذا كان يجب علينا القيام بذلك مع توضيح
              السبب.
            </Point>
            <Point>
              4. نُقدر دائمًا ملاحظاتك واقتراحاتك بشأن منتجاتنا وخدماتنا. ولكن
              ينبغي عليك أن تعلم أنه يحق لنا استخدامها دون أي قيود أو التزام
              بتعويضك، ولا نخضع لأي التزام تجاه الاحتفاظ بسريتها.
            </Point>
            <Point>5. نحتفظ بجميع الحقوق التي لم يتم منحها لك بشكل صريح.</Point>
          </WrapContent>
          {/* <WrapContent>
            <Heading>الخدمات التي نوفرها </Heading>
            <CustomText>
              تتمثل مهمتنا في تعزيز قدرة مقدمي الخدمات القانونية على بناء شبكة
              تواصل متخصصة والتعاون من أجل تقريب المسافات بينهم وبين الباحثين عن
              الخدمات القانونية المتخصصة. وللمضي قدمًا لتحقيق هذه المهمة، نقدم
              لك المنتجات والخدمات الموضّحة أدناه:
            </CustomText>
            <CustomText>تقديم تجربة ذات طابع شخصي لك:</CustomText>
            <CustomText>
              تختلف تجربتك على عدل عن تجربة أي شخص آخر: بدءًا من التخصصات
              القانونية والسيرة الذاتية والخبرات وغيرها من أنواع المحتوى الأخرى
              التي تميزك عن غيرك والتي تستطيع نشرها ليستطيع الباحث عن الخدمة
              القانونية الاطلاع عليها.
            </CustomText>
            <CustomText>
              توفير وسائل التواصل مع الأشخاص والمؤسسات التي تقدم الخدمات
              القانونية المتخصصة حول العالم:
            </CustomText>
            <CustomText>
              نساعدك في العثور على الأشخاص والمجموعات والأنشطة القانونية
              المتخصصة والمؤسسات وغيرها مما يقع ضمن الدائرة القانونية والتواصل
              معها عبر ما يقدمه عدل من وسائل تواصل معها. ونحن على يقين أن
              خدماتنا تصبح أكثر فائدة عندما يتواصل المتخصصون في مجالات القانون
              معًا ومع المجموعات والمؤسسات القانونية المتخصصة.
            </CustomText>
            <CustomText>تشجيعك على التعبير عن نفسك:</CustomText>
            <CustomText>
              نوفر لك العديد من الطرق للتعبير عن نفسك على عدل والتواصل مع مقدمي
              الخدمات القانونية المتخصصة وما يتعلق بهم وبأعمالهم، على سبيل
              المثال، نشر تخصصك القانوني وسيرتك الذاتية والمؤلفات والابحاث
              القانونية وغيرها، وسنبقى نطور من خدماتنا، بالإضافة الى إمكانية نشر
              مواضيع قانونية محكمة في المجلة القانونية عدل.
            </CustomText>
            <CustomText>مساعدتك في إشهار تخصصك القانوني:</CustomText>
            <CustomText>
              حيث نوفر محرك بحث متخصص يساعد الباحث عن الخدمات القانونية المتخصصة
              من العثور على مقدم الخدمة القانونية المناسبة لحاجته و/او لقضيته،
              بالإضافة الى السيرة الذاتية لمقدم الخدمة القانونية من محامي او
              محامي شرعي او خبير او شركة قانونية او محكم.
            </CustomText>
            <CustomText>
              مواجهة السلوكيات الضارة وحماية ودعم مجتمعنا:
            </CustomText>
            <CustomText>
              نحرص على توظيف فرق عمل مخصصة من جميع أنحاء العالم بالإضافة إلى
              تطوير أنظمة تقنية متقدمة لاكتشاف حالات إساءة استخدام منتجاتنا
              والسلوكيات الضارة تجاه الآخرين والمواقف التي يمكننا تقديم المساعدة
              خلالها تجاه دعم وحماية مجتمعنا. فإذا نما إلى علمنا وجود مثل هذا
              المحتوى أو السلوك، فسنتخذ الإجراء المناسب، على سبيل المثال، عرض
              تقديم مساعدة، أو إزالة المحتوى، أو إزالة أو حجب الوصول إلى ميزات
              محددة، أو تعطيل الحساب، أو التواصل مع جهات تنفيذ القانون.
            </CustomText>
            <CustomText>البحث عن طرق لتحسين خدماتنا على نحو أفضل:</CustomText>
            <CustomText>
              نهتم بإجراء الأبحاث لتطوير منتجاتنا واختبارها وتحسينها. ويتضمن ذلك
              تحليل بيانات المستخدمين المتوفرة لدينا والتعرف على طبيعة استخدام
              الأشخاص لمنتجاتنا، على سبيل المثال من خلال إجراء استبيانات وإجراء
              اختبارات على ميزات جديدة واكتشاف المشكلات وحلها.
            </CustomText>
            <CustomText>تمكين الوصول العالمي إلى خدماتنا:</CustomText>
            <CustomText>
              سيتم عرض الخدمات القانونية المتخصصة لجميع المسجلين في عدل للباحثين
              عن الخدمات القانونية المتخصصة عبر انحاء العالم، حتى نتمكن من تقديم
              خدمتنا على مستوى العالم، يجب علينا تخزين المحتوى والبيانات
              وتوزيعها في أنظمة ومراكز البيانات التي نستخدمها في جميع أنحاء
              العالم، بما في ذلك تلك التي يقع خارج بلد إقامتك.{' '}
            </CustomText>
          </WrapContent> */}
          <WrapContent>
            <Heading>سياسة استرداد المال</Heading>
            <CustomText>
              عند الإشتراك في خدمات المدفوعة لتطبيق عدل على الانترنت او الهواتف
              المحمولة لا يمكن استرداد المبلغ المدفوع لأي سبب من الأسباب، لذا
              ننصح بتجربة الحساب المجاني قبل استخدام الحساب المدفوع.
            </CustomText>
          </WrapContent>
          <WrapContent>
            <Heading>سياسة الغاء الحساب</Heading>
            <CustomText>
              يمكن الغاء الحساب على تطبيق عدل حيث ستعود اعدادات الحساب تلقائيا
              الى اعدادات الحساب المجاني ولا يمكن إلغاء الحساب نهائيا، إلا أنك
              تستطيع طلب وقف تفعيل التقييم على حسابك المحذوف.
            </CustomText>
          </WrapContent>
        </STitle>
      </PrivacySection>
    </Container>
  );
};

export default TermsAndCondtions;

export const STitle = styled(SubTitle)`
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;
const Heading = styled(CustomText)`
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 21px;
  @media (max-width: 500px) {
    font-size: 19px;
  }
`;

const Point = styled(CustomText)`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
const Paragraph = styled(CustomText)`
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
const WrapContent = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
