import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from 'history';
import {version} from '../package.json';
import './I18next/I18n';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://3bea4cb663d24a4bb3f2463b0851cb17@sentry.wtmsrv.com/16',
  replaysSessionSampleRate: 0.8,
  replaysOnErrorSampleRate: 1.0,
  release: version,
  environment:
    window.location.hostname === 'eadllaw.com'
      ? 'production'
      : window.location.hostname === 'eadllaw.staging.wtmsrv.com'
      ? 'staging'
      : 'development',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
