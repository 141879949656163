import styled from 'styled-components';
import {Section, Title, SubTitle} from './Guide';
import Colors from './Theme/Colors';
import {CustomText} from '../Components/Common';

const PrivacyPolicy = () => {
  return (
    <Container>
      <PrivacySection>
        <PrivacyTitle>سياسة الخصوصية</PrivacyTitle>
        <STitle>
          <WrapContent>
            <Heading>أ – مقدمة</Heading>
            <Point>
              1. إننا نهتم بشكل كبير بخصوصية زوار تطبيق عدل WEB IOS Android،
              ونتعهد بحمايتها. تشرح هذه السياسة كيفية التصرف في معلوماتك
              الشخصية.
            </Point>

            <Point>
              2. بموافقتك على استخدامنا لملفات تعريف الارتباط وفقًا لبنود هذه
              السياسة عند زيارتك لموقعنا الإلكتروني لأول مرة فإنك تسمح لنا
              باستخدام ملفات تعريف الارتباط في كل مرة تزور فيها موقعنا.
            </Point>
          </WrapContent>
          <WrapContent>
            <Heading>ب. جمع المعلومات الشخصية</Heading>
            <Point>
              1. قد يتم جمع وتخزين واستخدام المعلومات الشخصية التالية: معلومات
              حول جهاز الكمبيوتر الخاص بك بما في ذلك عنوان بروتوكول الإنترنت
              الخاص بك، والموقع الجغرافي، ونوع المتصفح والإصدار، ونظام التشغيل.
            </Point>
            <Point>
              2.  معلومات حول زياراتك لهذا الموقع واستخدامه بما في ذلك مصدر
              الإحالة، ومدة الزيارة، وما تشاهده على الصفحة، ومسارات التصفح في
              الموقع.
            </Point>
            <Point>
              3.  المعلومات التي تدخلها عند التسجيل في موقعنا الإلكتروني، مثل
              عنوان بريدك الإلكتروني.
            </Point>
            <Point>
              4. المعلومات التي تدخلها عند إنشاء ملف تعريف على موقعنا – على سبيل
              المثال، اسمك، صور ملفك الشخصي، الجنس، تاريخ الميلاد، الحالة
              الاجتماعية، الاهتمامات والهوايات، الحالة التعليمية، وتفاصيل
              الوظيفة.
            </Point>
            <Point>
              5.  المعلومات، مثل اسمك وعنوان بريدك الإلكتروني، التي تدخلها من
              أجل إعداد اشتراكات في رسائل البريد الإلكتروني و / أو الرسائل
              الإخبارية لدينا ؛
            </Point>
            <Point>
              6.  المعلومات التي تدخلها أثناء استخدام الخدمات على موقعنا.
            </Point>
            <Point>
              7.  المعلومات التي يتم إنشاؤها أثناء استخدام موقعنا الإلكتروني،
              بما في ذلك مواعيد وتكرار استخدامك للموقع والظروف التي تستخدم فيها
              الموقع.
            </Point>
            <Point>
              8. المعلومات المتعلقة بأي شيء تشتريه، أو الخدمات التي تستخدمها، أو
              المعاملات التي تجريها من خلال موقعنا الإلكتروني، والتي تشمل اسمك
              وعنوانك ورقم هاتفك وعنوان بريدك الإلكتروني وتفاصيل بطاقة الائتمان.
            </Point>
            <Point>
              9. المعلومات التي تنشرها على موقعنا الإلكتروني بغرض مشاركتها على
              الإنترنت والتطبيقات، والتي تشمل اسم المستخدم الخاص بك وصور ملفك
              الشخصي ومحتوى مشاركاتك.
            </Point>
            <Point>
              10.  المعلومات الواردة في أي مراسلات ترسلها إلينا عبر البريد
              الإلكتروني أو عبر موقعنا الإلكتروني، بما في ذلك المحتوى المشترك
              والبيانات الوصفية.
            </Point>
            <Point>11.  أي معلومات شخصية أخرى ترسلها إلينا.</Point>
          </WrapContent>
          <WrapContent>
            <Heading>ج. استخدام معلوماتك الشخصية</Heading>
            <Paragraph>
              تستخدم المعلومات الشخصية المقدمة إلينا عبر موقعنا الإلكتروني في
              الأغراض الموضحة في هذه السياسة أو على الصفحات ذات الصلة من الموقع.
              قد نستخدم معلوماتك الشخصية في الأغراض التالية:
            </Paragraph>
            <Point>1. إدارة موقعنا وأعمالنا على شبكة الإنترنت.</Point>
            <Point>
              2. تخصيص موقعنا على شبكة الإنترنت ليكون أكثر ملاءمة بالنسبة لك.
            </Point>
            <Point>3. تمكينك من استخدام الخدمات المتاحة على موقعنا.</Point>
            <Point>
              4. توفير الخدمات المشتراة من خلال موقعنا على شبكة الإنترنت.
            </Point>
            <Point>
              5. إرسال البيانات والفواتير وإشعارات الدفع لك، وتحصيل المدفوعات
              منك
            </Point>
            <Point>6. إرسال رسائل تجارية غير تسويقية إليك.</Point>
            <Point>
              7. إرسال إشعارات عبر البريد الإلكتروني التي قمت بطلب إرسالها على
              وجه التحديد إليك
            </Point>
            <Point>
              8. إرسال نشرة البريد الإلكتروني إليك، إذا كنت قد طلبت ذلك (يمكنك
              إبلاغنا في أي وقت إذا لم تعد تحتاج إلى النشرة الإخبارية).
            </Point>
            <Point>
              9. إرسال المراسلات التسويقية المتعلقة بأعمالنا أو أعمال الجهات
              الخارجية التي تم اختيارها بعناية والتي نعتقد أنها قد تهمك، عن طريق
              البريد أو او الرسائل النصية او سائل التواصل الأخرى، إذا كنت قد
              وافقت على ذلك بالتحديد، عن طريق البريد الإلكتروني أو أي تقنية
              مشابهة (يمكنك إبلاغنا في أي وقت إذا لم تعد تحتاج إلى هذه المراسلات
              التسويقية).
            </Point>
            <Point>
              10. تزويد الأطراف الثالثة بمعلومات إحصائية عن مستخدمينا (غير أن
              هذه الأطراف الثالثة لن تتمكن من تحديد المستخدمين لتلك المعلومات
              بحد ذاتهم).
            </Point>
            <Point>
              11. التعامل مع الاستفسارات والشكاوى التي تقدمها أنت أو تقدم لنا
              بشأنك على موقعنا.
            </Point>
            <Point>12. حماية موقعنا من الاحتيال والحفاظ على أمنه.</Point>
            <Point>
              13. التحقق من الامتثال للشروط والأحكام التي تحكم استخدام موقعنا
              الإلكتروني (بما في ذلك مراقبة الرسائل الخاصة المرسلة عبر خدمة
              الرسائل الخاصة بموقعنا).
            </Point>
            <Point>14. `استخدامات أخرى.</Point>
            <Paragraph>
              إذا قمت بتقديم معلومات شخصية للنشر على موقعنا، فسنقوم بنشر هذه
              المعلومات واستخدامها بأي شكل آخر وفقًا للترخيص الذي تمنحه لنا. لن
              نزود أي طرف ثالث، دون موافقتك الصريحة، بمعلوماتك الشخصية لأغراض
              التسويق المباشر لأي طرف آخر.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>د. الكشف عن المعلومات الشخصية</Heading>
            <Paragraph>
              يمكن أن نقوم بالكشف عن معلوماتك الشخصية لأي من موظفينا أو مسؤولينا
              أو شركات التأمين أو المستشارين المحترفين أو الوكلاء أو الموردين أو
              المقاولين من الباطن التابعين لنا القدر الضروري بشكل معقول للأغراض
              المنصوص عليها في هذه السياسة. قد نقوم بالإفصاح عن معلوماتك الشخصية
              لأي عضو في مجموعة شركاتنا (وهذا يعني الشركات التابعة لنا، والشركة
              القابضة الأصلية وجميع فروعها) بالقدر الضروري بشكل معقول للأغراض
              المنصوص عليها في هذه السياسة.
            </Paragraph>
            <Paragraph>قد نكشف عن معلوماتك الشخصية:</Paragraph>
            <Point>1. القدر الذي يطلب منا القيام به بموجب القانون.</Point>
            <Point>2.  فيما يتعلق بأي إجراءات قانونية جارية أو محتملة.</Point>
            <Point>
              3.  من أجل إنشاء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في
              ذلك توفير معلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر
              الائتمان).
            </Point>
            <Point>
              4. أي شخص نعتقد على نحو معقول أنه قد يتقدم بطلب إلى محكمة أو سلطة
              مختصة أخرى بطلب الإفصاح عن تلك المعلومات الشخصية، وفقًا لتقديرنا
              المعقول، ويكون من المحتمل بشكل معقول أن تأمر هذه المحكمة أو السلطة
              بالكشف عن تلك المعلومات الشخصية.
            </Point>
            <Paragraph>
              باستثناء ما هو منصوص عليه في هذه السياسة، فإننا لن نقدم معلوماتك
              الشخصية إلى أطراف ثالثة.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>هـ. النقل الدولي للبيانات</Heading>
            <Point>
              1. قد يتم تخزين المعلومات التي نجمعها أو معالجتها أو نقلها بين أي
              من البلدان التي نعمل فيها لتمكيننا من استخدام المعلومات وفقًا لهذه
              السياسة.
            </Point>
            <Point>
              2.  لا نستطيع منع استخدام الآخرين أو إساءة استخدامهم للمعلومات
              الشخصية التي تنشرها على موقعنا الإلكتروني أو ترسلها للنشر على
              موقعنا الإلكتروني والمتاحة عبر الإنترنت في جميع أنحاء العالم.
            </Point>
            <Point>
              3. أنت توافق صراحة على عمليات نقل المعلومات الشخصية الموضحة في هذا
              القسم “و”.
            </Point>
          </WrapContent>
          <WrapContent>
            <Heading>و. الاحتفاظ بالمعلومات الشخصية</Heading>
            <Point>
              1.  يحدد هذا القسم “و” السياسات والإجراءات التي نتبعها للاحتفاظ
              بالبيانات، والتي صممت للمساعدة في ضمان امتثالنا لالتزاماتنا
              القانونية المتعلقة بالاحتفاظ بالمعلومات الشخصية وحذفها.
            </Point>
            <Point>
              2. لا يجوز الاحتفاظ بالمعلومات الشخصية التي نقوم بمعالجتها لأي غرض
              أو أغراض لفترة أطول مما هو ضروري لهذا الغرض أو لتلك الأغراض.
            </Point>
            <Point>
              3. بصرف النظر عن الأحكام الأخرى الواردة في هذا القسم و، فإننا
              نحتفظ بالوثائق (بما في ذلك المستندات الإلكترونية) التي تحتوي على
              بيانات شخصية:
            </Point>
            <SubPoint>أ. بالقدر المطلوب بموجب القانون.</SubPoint>
            <SubPoint>
              ب. إذا كنا نعتقد أن هذه المستندات قد تكون ذات صلة بأي إجراءات
              قانونية جارية أو محتملة.
            </SubPoint>
            <SubPoint>
              ت. من أجل بدء أو ممارسة أو الدفاع عن حقوقنا القانونية (بما في ذلك
              توفير المعلومات للآخرين لأغراض منع الاحتيال والحد من مخاطر
              الائتمان).
            </SubPoint>
          </WrapContent>
          <WrapContent>
            <Heading>ز. أمن معلوماتك الشخصية</Heading>
            <Point>
              1. أمن معلوماتك الشخصية  نتعهد باتخاذ الاحتياطات التقنية
              والتنظيمية المعقولة لمنع فقدان معلوماتك الشخصية أو إساءة استخدامها
              أو تغييرها.
            </Point>
            <Point>
              2. نتعهد بتخزين جميع المعلومات الشخصية التي تقدمها على خوادمنا
              الآمنة (المحمية بكلمة مرور وجدار الحماية).
            </Point>
            <Point>
              3. جميع المعاملات المالية الإلكترونية التي يتم القيام بها من خلال
              موقعنا الإلكتروني محمية بتقنية التشفير.
            </Point>
            <Point>
              4. تقرّ بموافقتك على هذه السياسة بمعرفتك بأن نقل المعلومات عبر
              الإنترنت غير آمن بطبيعته، ولا يمكننا ضمان أمن البيانات المرسلة عبر
              الإنترنت.
            </Point>
            <Point>
              5. أنت مسؤول عن الحفاظ على سرية كلمة السر التي تستخدمها للوصول إلى
              موقعنا. لن نطلب منك كلمة السر الخاصة بك (إلا عند تسجيل الدخول إلى
              موقعنا).
            </Point>
          </WrapContent>
          <WrapContent>
            <Heading>ح. التعديلات</Heading>
            <Paragraph>
              قد نقوم بتحديث هذه السياسة من وقت لآخر عن طريق نشر نسخة جديدة على
              موقعنا. يجب عليك مراجعة هذه الصفحة من حين لآخر للتأكد من فهمك لأي
              تغييرات تطرأ على هذه السياسة، وقد نخطرك بالتغييرات التي تطرأ على
              هذه السياسة عن طريق البريد الإلكتروني أو من خلال نظام المراسلة
              الخاص على موقعنا.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>ط. حقوقك</Heading>
            <Paragraph>
              قد تطلب منا تزويدك بأي معلومات شخصية نحتفظ بها عنك، يخضع توفير هذه
              المعلومات لما يلي:
            </Paragraph>
            <Point>1. دفع الرسوم (أدخل الرسوم إذا كانت منطبقة).</Point>
            <Point>2. وتقديم إثبات الهوية المناسب .</Point>
            <Paragraph>
              قد نمتنع عن تزويدك بالمعلومات الشخصية التي تطلبها بالقدر الذي يسمح
              به القانون.
            </Paragraph>
            <Paragraph>
              قد تطلب منا في أي وقت عدم معالجة معلوماتك الشخصية لأغراض التسويق.
            </Paragraph>
            <Paragraph>
              من الناحية العملية، إما أنك ستوافق بشكل صريح مسبقًا على استخدامنا
              لمعلوماتك الشخصية لأغراض التسويق، أو سنمنحك فرصة لإلغاء الاشتراك
              في استخدام معلوماتك الشخصية لأغراض التسويق.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>ي. مواقع الطرف الثالث</Heading>
            <Paragraph>
              يشتمل موقعنا على روابط تشعبية وتفاصيل عن مواقع خاصة بأطراف ثالثة،
              علمًا بأنه ليس لدينا أي سيطرة على سياسات الخصوصية وممارسات الأطراف
              الثالثة كما أننا لسنا مسؤولين عنها.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>ك. تحديث المعلومات</Heading>
            <Paragraph>
              يرجى إعلامنا إذا كانت المعلومات الشخصية التي نحتفظ بها عنك تحتاج
              إلى تصحيح أو تحديث.
            </Paragraph>
          </WrapContent>
          <WrapContent>
            <Heading>ل. ملفات تعريف الارتباط</Heading>
            <Paragraph>
              يستخدم موقعنا ملفات تعريف الارتباط. ملف تعريف الارتباط هو ملف
              يحتوي على معرف (سلسلة من الحروف والأرقام) يتم إرساله بواسطة خادم
              الإنترنت إلى متصفح الإنترنت ويتم تخزينه بواسطة المتصفح، ثم يتم
              إرسال المعرف مرة أخرى إلى الخادم في كل مرة يطلب فيها المتصفح صفحة
              من الخادم. قد تكون ملفات تعريف الارتباط إما ملفات تعريف ارتباط
              “دائمة” أو ملفات تعريف ارتباط خاصة بـ “جلسة”: يخزن ملف تعريف
              الارتباط الدائم بواسطة متصفح الإنترنت ويظل صالحًا حتى تاريخ انتهاء
              الصلاحية المحدد له، ما لم يتم حذفه من قبل المستخدم قبل تاريخ
              انتهاء الصلاحية؛ من ناحية أخرى، تنتهي صلاحية ملف تعريف ارتباط
              الجلسة بنهاية جلسة المستخدم، أي بإغلاق متصفح الإنترنت. لا تحتوي
              ملفات تعريف الارتباط عادةً على أي معلومات تعرّف عن المستخدم
              شخصيًا، ولكن قد يتم ربط المعلومات الشخصية التي نخزنها عنك
              بالمعلومات المخزنة في ملفات تعريف الارتباط التي يتم الحصول عليها
              منها.{' '}
            </Paragraph>
            <Paragraph>
              فيما يلي أسماء ملفات تعريف الارتباط التي نستخدمها على موقعنا
              الإلكتروني والأغراض التي تستخدم من أجلها :
            </Paragraph>
            <Point>
              1. نستخدم تحليلات جوجل و Adwords على موقعنا للتعرف على جهاز
              الكمبيوتر عندما يقوم المستخدم أضف جميع استخدامات ملفات تعريف
              الارتباط المنطبقة على موقعك زيارة الموقع الإلكتروني / تتبع
              المستخدمين أثناء تصفحهم للموقع / تحسين قابلية استخدام الموقع /
              تحليل استخدام الموقع / إدارة الموقع / منع الاحتيال وتحسين أمن
              الموقع / تخصيص الموقع لكل مستخدم / الإعلانات المستهدفة التي قد
              تكون ذات أهمية خاصة لمستخدمين محددين / وصف الغرض.
            </Point>
            <Point>
              2. تسمح لك معظم المتصفحات برفض قبول ملفات تعريف الارتباط .
            </Point>
            <Point>
              3. سيكون لحظر جميع ملفات تعريف الارتباط تأثير سلبي على سهولة
              استخدام العديد من المواقع. إذا قمت بحظر ملفات تعريف الارتباط، فلن
              تتمكن من استخدام جميع الميزات الموجودة على موقعنا ويمكنك حذف ملفات
              تعريف الارتباط المخزنة بالفعل على جهاز الكمبيوتر الخاص بك .
            </Point>
            <Point>
              {' '}
              4. سيكون لحذف ملفات تعريف الارتباط تأثير سلبي على سهولة استخدام
              مواقع الويب الخاصة بنا .
            </Point>
          </WrapContent>
        </STitle>
      </PrivacySection>
    </Container>
  );
};

export default PrivacyPolicy;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: ${Colors.lightBlueBg};
  padding: 40px 20px 30px;
  direction: rtl;

  @media (max-width: 450px) {
    padding: 20px;
  }
`;
export const PrivacySection = styled(Section)`
  @media (max-width: 1000px) {
    margin: 30px 80px;
  }

  @media (max-width: 768px) {
    margin: auto;
  }

  @media (max-width: 450px) {
    padding: 40px 20px;
  }
`;
export const PrivacyTitle = styled(Title)`
  font-weight: bold;
  font-size: 30px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 500px) {
    font-size: 25px;
  }
  @media (max-width: 400px) {
    font-size: 23px;
  }
`;

export const STitle = styled(SubTitle)`
  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

const Heading = styled(CustomText)`
  font-size: 21px;
  @media (max-width: 500px) {
    font-size: 19px;
  }
`;

const Point = styled(CustomText)`
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

const SubPoint = styled(CustomText)`
  font-size: 14px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;
const Paragraph = styled(CustomText)`
  @media (max-width: 500px) {
    font-size: 14px;
  }
`;
const WrapContent = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;
