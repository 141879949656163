import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  ButtonsRow,
  RowExtended,
  Label,
  IconsDiv,
  DetailsText,
  A,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {MdDelete} from 'react-icons/md';
import {useMutation, useQuery} from '@apollo/client';
import {
  AccountReducer,
  Certificate,
  ConfigsReducer,
  LawyerType,
} from '../../../Action/types';
import {CREATE_CERTIFICATE, REMOVE_CERTIFICATE} from '../GraphQL/EditAccount';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Loader, Modal} from 'rsuite';
import DatePicker from 'react-datepicker';
import {useSelector} from 'react-redux';
import {CERTIFICATES} from '../../Search/GraphQl/ProfilePreview';
import {RootState} from '../../../Reducer';
import moment from 'moment';
import {ModalFooter, ModalBody, CancelIcon} from '../../User/ProfilePreview';
import {Confirm} from '../../Auth/SignUp';
import noPhoto from '../../../Assets/Images/noPhoto.png';
import {UPLOAD_URI} from '../../../Configs';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const Certificates = () => {
  const history = useHistory();
  const [startDate, setStartDate] = useState<any>();
  const setDate = (date: Date | [Date, Date] | null) => {
    setStartDate(date);
  };
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [more, showMore] = useState<string>('');
  const [less, showLess] = useState<string>('');

  const [certificateModal, setCertificateModalModal] = useState(false);
  const [certificateId, setCertificateId] = useState<string>();
  const [uploadPhotoId, setUploadPhotoId] = useState<any>();

  const handledCertificateModal = (id: string) => {
    setCertificateModalModal(!certificateModal);
    setCertificateId(id);
  };

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const {token} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const [createCertificate, {loading: updateLoading}] = useMutation<{
    createCertificate: Certificate;
  }>(CREATE_CERTIFICATE);
  const [removeCertificate] = useMutation<{
    removeCertificate: Certificate;
  }>(REMOVE_CERTIFICATE);
  const {
    data: certificates,
    loading: loadingCertificates,
    refetch,
  } = useQuery<{
    findCertificatesByLawyerId: [Certificate] | [];
  }>(CERTIFICATES, {variables: {id: user?.id}});

  const validationSchema = Yup.object().shape({
    certificate_name: Yup.string().required(),
    certificate_id: Yup.string(),
    certificate_url: Yup.string(),
    certificate_issued_from: Yup.string().required(),
    certificate_issue_date: Yup.string(),
    photo: Yup.string(),
  });

  const {errors, touched, handleChange, handleSubmit, resetForm} = useFormik({
    initialValues: {
      certificate_name: '',
      certificate_id: '',
      certificate_url: '',
      certificate_issued_from: '',
      certificate_issue_date: '',
      photo: '',
    },
    onSubmit: async submittedValues => {
      const variables: {
        certificate_name?: string;
        certificate_id?: string;
        certificate_issued_from?: string;
        certificate_issue_date?: string;
        certificate_url?: string;
        photo?: string;
      } = {};
      if (submittedValues?.certificate_name) {
        variables.certificate_name = submittedValues.certificate_name;
      }
      if (submittedValues?.certificate_id) {
        variables.certificate_id = submittedValues.certificate_id;
      }
      if (startDate) {
        let momentYears = moment(startDate).lang('en').format();
        variables.certificate_issue_date = momentYears;
      }
      if (submittedValues?.certificate_url) {
        variables.certificate_url = submittedValues.certificate_url;
      }
      if (submittedValues?.certificate_issued_from) {
        variables.certificate_issued_from =
          submittedValues.certificate_issued_from;
      }
      if (uploadPhotoId) {
        variables.photo = uploadPhotoId?.id;
      }

      try {
        if (Object.keys(variables).length !== 0) {
          const {data} = await createCertificate({
            variables: variables,
          });
          if (data?.createCertificate) {
            setAdd(false);
            setShow(true);
            resetForm();
            setStartDate('');
            refetch();
            setUploadPhotoId('');
            Alert.success('تمت العملية بنجاح');
          }
        } else {
          Alert.error(i18n.t('Please enter at least one field'));
        }
      } catch (err) {
        console.log(err, 'erro');
        setUploadPhotoId('');
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
    validationSchema: validationSchema,
  });

  const handleRemoveCertificate = async () => {
    try {
      if (certificateId !== '') {
        const {data} = await removeCertificate({
          variables: {
            certificateId,
          },
        });
        if (data?.removeCertificate) {
          refetch();
          Alert.success('تم الحذف بنجاح');
        }
      }
    } catch (error) {
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };

  const uploadFiles = async (file: any) => {
    const body = new FormData();
    body.append('file', file[0]);
    axios({
      method: 'post',
      url: `${UPLOAD_URI}`,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
      },
    })
      .then(async res => {
        setUploadPhotoId(res?.data);
      })
      .catch(error => {
        // tslint:disable-next-line: no-console
        console.log(error);
      });
  };

  return (
    <EditAccountWrapper>
      <RowExtended>
        <ProfileTitle>الشهادات العلمية</ProfileTitle>
        {show && (
          <Col
            onClick={() => {
              if (
                !user?.membership?.lawyer_privileges?.scholar_degree &&
                user?.type !== LawyerType.company
              ) {
                return notAvailable();
              }
              setAdd(true);
              setShow(false);
            }}>
            <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
            <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
              أضف شهادة جديدة
            </AddSectionText>
          </Col>
        )}
      </RowExtended>
      {(loadingCertificates ||
        certificates?.findCertificatesByLawyerId.length !== 0) && (
        <>
          {certificates?.findCertificatesByLawyerId
            .filter(feiled => feiled?.deleted === false)
            .map((field: Certificate, key) => {
              return (
                <>
                  <Label key={key}>
                    <Detail>
                      {field?.certificate_name && (
                        <A>
                          <CustomText padding={'3px'}>
                            {field?.certificate_name}
                          </CustomText>
                        </A>
                      )}
                      {more === field.id && (
                        <>
                          {field?.certificate_url && (
                            <DetailsText>
                              &#x2022; رابط الشهادة:
                              <Link
                                href={field?.certificate_url}
                                target="_blank">
                                رابط
                              </Link>
                            </DetailsText>
                          )}
                          {field?.certificate_id && (
                            <DetailsText>
                              &#x2022;{' '}
                              {`رقم الشهادة:  ${field?.certificate_id}`}
                            </DetailsText>
                          )}
                          {field?.certificate_issued_from && (
                            <DetailsText>
                              &#x2022;{' '}
                              {`مكان الحصول: ${field?.certificate_issued_from}`}
                            </DetailsText>
                          )}
                          {field?.certificate_issue_date && (
                            <DetailsText>
                              &#x2022;{' '}
                              {`تاريخ الحصول: ${moment(
                                field?.certificate_issue_date,
                              )
                                .lang('ar-sa')
                                .format('MMMM D, YYYY')}`}
                            </DetailsText>
                          )}
                          {field?.photo && (
                            <PhotosRow>
                              <Link
                                href={`${UPLOAD_URI}/image/${field?.photo?.filename}`}
                                target="_blank">
                                <ProfilePic
                                  src={
                                    field?.photo
                                      ? `${UPLOAD_URI}/image/${field?.photo?.filename}`
                                      : noPhoto
                                  }
                                />
                              </Link>
                            </PhotosRow>
                          )}
                        </>
                      )}
                      {less === field.id ? (
                        <ShowMore
                          padding={'3px'}
                          marginTop={'2px'}
                          size={13}
                          color={Colors.lightGray}
                          onClick={() => {
                            showMore('');
                            showLess('');
                          }}>
                          اقل ..
                        </ShowMore>
                      ) : (
                        <ShowMore
                          padding={'3px'}
                          marginTop={'2px'}
                          size={13}
                          color={Colors.lightGray}
                          onClick={() => {
                            showMore(field.id);
                            showLess(field.id);
                          }}>
                          المزيد ..
                        </ShowMore>
                      )}
                    </Detail>
                    <IconsDiv
                      onClick={() => {
                        handledCertificateModal(field?.id);
                      }}>
                      <MdDelete size={19} color={Colors.red} />
                    </IconsDiv>
                  </Label>
                </>
              );
            })}
        </>
      )}
      {add && (
        <div>
          <Inputs>
            <CustomInput
              placeholder={'أسم المعهد/الجامعة'}
              width={'100%'}
              onChange={handleChange}
              name={'certificate_issued_from'}
              error={
                !!(
                  errors.certificate_issued_from &&
                  touched.certificate_issued_from
                )
              }
            />
            <CustomInput
              placeholder={'أسم الشهادة'}
              width={'100%'}
              name={'certificate_name'}
              onChange={handleChange}
              error={!!(errors.certificate_name && touched.certificate_name)}
            />
            <CustomInput
              placeholder={'رقم الشهادة'}
              width={'100%'}
              onChange={handleChange}
              name={'certificate_id'}
            />
            <StyledDatePicker
              strictParsing
              placeholderText={'تاريخ الاصدار'}
              selected={startDate}
              dateFormat="dd/MM/yyyy"
              onChange={(date: Date) => {
                setDate(date);
              }}
            />
            <Dropzone
              onDrop={acceptedFiles => {
                uploadFiles(acceptedFiles);
              }}
              multiple={false}>
              {({getRootProps, getInputProps}) => (
                <Upload {...getRootProps()}>
                  <input {...getInputProps()} />

                  <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
                  <UploadText size={17} color={Colors.seaBlue} weight={'400'}>
                    حمل صورة الشهادة
                  </UploadText>
                </Upload>
              )}
            </Dropzone>
            {uploadPhotoId?.filename && (
              <PhotosRow>
                <Link
                  href={`${UPLOAD_URI}/image/${uploadPhotoId?.filename}`}
                  target="_blank">
                  <ProfilePic
                    src={
                      uploadPhotoId?.filename
                        ? `${UPLOAD_URI}/image/${uploadPhotoId?.filename}`
                        : noPhoto
                    }
                  />
                </Link>
              </PhotosRow>
            )}

            {updateLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <ButtonsRow>
                <CustomButton
                  height={'33px'}
                  width={'30%'}
                  type="button"
                  orangeBg
                  onClick={() => handleSubmit()}>
                  <ButtonText color={Colors.white} weight={'400'}>
                    حفظ
                  </ButtonText>
                </CustomButton>
                <CancelButton
                  height={'33px'}
                  bgColor={Colors.white}
                  withBorder
                  width={'30%'}>
                  <ButtonText
                    weight={'400'}
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      setUploadPhotoId('');
                      setDate(null);
                    }}>
                    إلغاء
                  </ButtonText>
                </CancelButton>
              </ButtonsRow>
            )}
          </Inputs>
        </div>
      )}
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal('')}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal('')}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleRemoveCertificate();
              handledCertificateModal('');
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default Certificates;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
  margin-right: 30px;
  cursor: pointer;
  /* background-color: ${Colors.white}; */

  /* @media (max-width: 768px) {
    justify-content: space-around;
  } */
  @media (max-width: 561px) {
    /* width: 70%; */
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
`;

const AddSectionText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 15px;

  @media (max-width: 561px) {
    font-size: 15px;
  }
`;
const UploadText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 7px;
`;

const ProfileTitle = styled(CustomText)`
  width: 91%;
  /* @media (max-width: 884px) {
    width: 70%;
  } */
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;

const Upload = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  cursor: pointer;
`;
const Inputs = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  width: 100%;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 15px 15px 10px;
  border-bottom: 1px solid ${Colors.borderGray};
  &::placeholder {
    color: ${props => props.theme.colors.gray};
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
`;
const ShowMore = styled(CustomText)`
  cursor: pointer;
`;
const Link = styled.a`
  text-decoration: none;
  margin-right: 5px;
`;
export const ResetPasswordModalBody = styled(ModalBody)`
  @media (max-width: 500px) {
    padding-bottom: 5px;
  }
`;
export const ResetPasswordTitle = styled(CustomText)`
  width: 70%;
  margin-top: 10px;
  text-align: center;
  margin: auto;
  font-size: 33px;
  @media (max-width: 1300px) {
    font-size: 28px;
  }
  @media (max-width: 1150px) {
    font-size: 23px;
  }
  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 850px) {
    font-size: 19px;
  }
  @media (max-width: 782px) {
    font-size: 18px;
  }
  @media (max-width: 426px) {
    font-size: 16px;
  }
  @media (max-width: 338px) {
    font-size: 15px;
  }
`;
export const Save = styled(Confirm)`
  @media (max-width: 500px) {
    padding: 7.5px;
  }
`;

export const SaveText = styled(CustomText)`
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1150px) {
    font-size: 13px;
  }
`;
export const ResetPasswordModal = styled(Modal)`
  direction: rtl;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;
const ProfilePic = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 5px;
`;
const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
