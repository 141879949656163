import styled from 'styled-components';
import {CustomButton, CustomInput, CustomText} from '../../Components/Common';
import Colors from '../Theme/Colors';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import {
  COMPANY_VERIFICATION,
  RESEND_CODE,
  VERIFICATION,
  COMPANY_RESEND_CODE,
} from './GraphQl/Verification';
import {useDispatch} from 'react-redux';
import {useEffect, useState} from 'react';
import {logIn, setToken, setUserTypeRedux, updateUser} from '../../Action';
import {ApolloError, useMutation} from '@apollo/client';
import {useFormik} from 'formik';
import {Confirm, PrivacyModal, LoaderContainer} from './SignUp';
import {Loader, Modal, Alert} from 'rsuite';
import {Company, Lawyer, LawyerType} from '../../Action/types';
import {ErrorText} from './ResetPassword';
import i18n from '../../I18next/I18n';

const Verification = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const dispatch = useDispatch();
  const params =
    useParams<{page: string; phone: string; email: string; userType: string}>();
  const [seconds, setTime] = useState(30);
  const [disableCode, setDisableCode] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const {state} = location;

  const [verifyCode, {loading}] = useMutation<{
    verifyCode: Lawyer;
  }>(VERIFICATION);
  const [companyVerifyCode, {loading: companyLoading}] = useMutation<{
    companyVerifyCode: Company;
  }>(COMPANY_VERIFICATION);
  const [resendCode, {loading: resendLoading}] = useMutation<{
    resendCode: boolean;
  }>(RESEND_CODE);
  const [companyResendCode, {loading: resendComapnyLoading}] = useMutation<{
    resendCode: boolean;
  }>(COMPANY_RESEND_CODE);

  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      codeValue: '',
    },

    onSubmit: async submittedValues => {
      try {
        if (submittedValues.codeValue.length === 5) {
          handleVerification(submittedValues.codeValue);
        } else {
          values.codeValue
            ? setErrorMessage('رمز التحقق المدخل غير صحيح')
            : setErrorMessage('الرجاء ادخال رمز التحقق');
        }
      } catch (err) {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
  });

  const handleVerification = async (codeValue: string) => {
    try {
      if (params?.email && params?.userType === 'lawyer') {
        verifyCode({
          variables: {
            email: params?.email,
            code: Number(codeValue),
          },
        })
          .then(async ({data}) => {
            if (data?.verifyCode) {
              const {token, is_disabled, ...lawyerData} = data?.verifyCode;
              if (is_disabled) {
                Alert.info('الحساب يتطلب موافقة مسؤول النظام قبل الاستخدام');
                return setTimeout(() => {
                  return history.push({
                    pathname: '/login',
                  });
                }, 2000);
              }
              if (params?.page === 'verifyuser') {
                if (token) dispatch(setToken(token));
                dispatch(updateUser({...lawyerData}));
                dispatch(logIn());
                if (lawyerData.type) {
                  dispatch(setUserTypeRedux(lawyerData.type));
                }
                history.push('/');
              } else {
                if (token) dispatch(setToken(token));
                history.push({
                  pathname: '/reset-password',
                  state: {userType: location?.state?.userType},
                });
              }
            } else {
              values.codeValue
                ? setErrorMessage('رمز التحقق المدخل غير صحيح')
                : setErrorMessage('الرجاء ادخال رمز التحقق');
            }
          })
          .catch(error => {
            if (error.message === 'wrong verification code') {
              setErrorMessage('رمز التحقق المدخل غير صحيح');
            } else {
              setErrorMessage(
                i18n.t('Something went wrong please try again later'),
              );
            }
          });
      }
      if (params?.email && params?.userType === 'company') {
        companyVerifyCode({
          variables: {
            email: params?.email,
            code: Number(codeValue),
          },
        })
          .then(async ({data}) => {
            if (!data?.companyVerifyCode) {
              return setErrorMessage('رمز التحقق المدخل غير صحيح');
            }
            let type;
            if (data?.companyVerifyCode) {
              const {token, is_disabled, ...companyData} =
                data?.companyVerifyCode;
              if (is_disabled) {
                Alert.info('الحساب يتطلب موافقة مسؤول النظام قبل الاستخدام');
                return setTimeout(() => {
                  return history.push({
                    pathname: '/login',
                  });
                }, 2000);
              }
              if (params?.page === 'verifyuser') {
                type = LawyerType.company;
                if (token) dispatch(setToken(token));
                dispatch(updateUser({...companyData}));
                dispatch(logIn());
                dispatch(setUserTypeRedux(type));
                history.push('/');
              } else {
                if (token) dispatch(setToken(token));
                history.push({
                  pathname: '/reset-password',
                  state: {userType: type},
                });
              }
            }
          })
          .catch(error => {
            if (error.message === 'wrong verification code') {
              setErrorMessage('رمز التحقق المدخل غير صحيح');
            } else {
              setErrorMessage(
                i18n.t('Something went wrong please try again later'),
              );
            }
          });
      }
    } catch (err) {
      if (err.message === 'wrong verification code') {
        return values.codeValue
          ? setErrorMessage('رمز التحقق المدخل غير صحيح')
          : setErrorMessage('الرجاء ادخال رمز التحقق');
      }
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (disableCode) {
      interval = setTimeout(() => {
        setTime(second => second - 1);
      }, 1000);
      if (seconds === 0) {
        setDisableCode(false);
        setTime(30);
        clearTimeout(interval);
      }
    }
    return () => clearTimeout(interval);
  }, [disableCode, seconds]);

  const handleResendCode = async () => {
    setErrors({codeValue: ''});
    setErrorMessage('');
    resetForm();
    if (params?.email && params?.userType === 'lawyer') {
      await resendCode({
        variables: {
          email: params?.email,
        },
      })
        .then(({data}) => {
          if (data) {
            setDisableCode(true);
          }
        })
        .catch((err: ApolloError) => {
          console.log(err.graphQLErrors[0].message);
        });
    }
    if (state?.email && params?.userType === 'company') {
      await companyResendCode({
        variables: {
          email: params?.email,
        },
      })
        .then(({data}) => {
          if (data) {
            setDisableCode(true);
          }
        })
        .catch((err: ApolloError) => {
          console.log(err.graphQLErrors[0].message);
        });
    }
  };
  return (
    <>
      <Wrapper>
        <InnerContainer>
          <PreviewWrapper>
            <LeftSection>
              {resendLoading || resendComapnyLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <Col>
                  <ResendCodeText
                    weight={'500'}
                    color={Colors.darkGray}
                    size={13}
                    onClick={() => {
                      return disableCode ? null : handleResendCode();
                    }}>
                    إعادة إرسال رمز التحقق
                  </ResendCodeText>
                  {disableCode && (
                    <ResendCodeSeconds size={13} color={Colors.darkGray}>
                      {' '}
                      {seconds} ثانية
                    </ResendCodeSeconds>
                  )}
                </Col>
              )}
            </LeftSection>
            <Divider />
            <TextWrapper>
              <CodeText size={21} color={Colors.darkGray}>
                رمز التحقق
              </CodeText>
              {state?.email ? (
                <SentCodeText weight={'500'} color={Colors.darkGray} size={15}>
                  الرجاء إدخال رمز التحقق المرسل إلى بريدك الالكتروني
                </SentCodeText>
              ) : (
                <SentCodeText weight={'500'} color={Colors.darkGray} size={15}>
                  الرجاء إدخال رمز التحقق المرسل إلى بريدك الالكتروني
                </SentCodeText>
              )}
              {state?.email ? (
                <SentCodeNumber
                  weight={'500'}
                  color={Colors.darkGray}
                  size={15}>
                  {state?.email}
                </SentCodeNumber>
              ) : (
                <SentCodeNumber
                  weight={'500'}
                  color={Colors.darkGray}
                  size={15}>
                  {params.email}
                </SentCodeNumber>
              )}

              <Inputs>
                <StyledInput
                  name="codeValue"
                  placeholder={'رمز التحقق'}
                  width={'90%'}
                  value={values.codeValue}
                  onChange={handleChange}
                />
              </Inputs>
              {loading || companyLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <SendButton
                    type="submit"
                    orangeBg
                    width={'100%'}
                    onClick={() => {
                      handleSubmit();
                    }}>
                    <CustomText color={Colors.white} size={12}>
                      إرسال
                    </CustomText>
                  </SendButton>
                  {!!(errors.codeValue && touched.codeValue) && (
                    <ErrorText color="red">
                      الرجاء ادخال بريد الكتروني صحيح
                    </ErrorText>
                  )}
                  {errorMessage !== '' && (
                    <ErrorText color="red">{errorMessage}</ErrorText>
                  )}
                </>
              )}
            </TextWrapper>
          </PreviewWrapper>
        </InnerContainer>
      </Wrapper>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => setModal({isOn: false, message: ''})}>
            <CustomText color={Colors.white} size={12}>
              حسنا
            </CustomText>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
    </>
  );
};

export default Verification;

export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ResendCodeText = styled(CustomText)`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
export const ResendCodeSeconds = styled(CustomText)`
  align-self: center;
`;
export const SentCodeText = styled(CustomText)`
  direction: rtl;
  margin-right: 20px;
  @media (max-width: 910px) {
    font-size: 13px;
  }
  @media (max-width: 800px) {
    margin-right: 0;
    text-align: center;
  }
  @media (max-width: 650px) {
    margin: auto;
    margin-top: 5px;
  }
`;
export const SentCodeNumber = styled(CustomText)`
  margin-right: 20px;
  @media (max-width: 910px) {
    font-size: 13px;
  }
  @media (max-width: 800px) {
    align-self: center;
    margin-right: 0;
  }
`;
export const CodeText = styled(CustomText)`
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 1100px) {
    margin-right: 0;
  }
  @media (max-width: 910px) {
    font-size: 19px;
  }
  @media (max-width: 880px) {
    font-size: 18px;
  }
  @media (max-width: 800px) {
    margin: auto;
    margin-bottom: 4%;
    font-size: 17px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }
`;

export const Inputs = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
`;

export const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 350px;
  margin-top: -20px;
  margin-bottom: -20px;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const PreviewWrapper = styled.div`
  margin: 100px 0;
  width: 65%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 1px 1px ${Colors.borderGray};
  padding: 20px 40px;
  @media (max-width: 780px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 400px) {
    padding: 20px 19px;
    width: 80%;
  }
`;

export const StyledInput = styled(CustomInput)<{error?: boolean}>`
  direction: rtl;
  margin-top: 11px;
  width: 100%;
  outline: none;
  border-bottom: 1px solid ${Colors.borderGray};
  @media (max-width: 910px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  margin-right: -20px;
  margin-left: 20px;
  @media (max-width: 780px) {
    margin-right: 0;
    margin-left: 0;
    margin-top: 8%;
    width: 70%;
    align-items: center;
  }
  @media (max-width: 650px) {
    width: 90%;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 40%;
  @media (max-width: 780px) {
    margin-top: 4%;
  }
  @media (max-width: 602px) {
    width: 100%;
  }
`;
export const SendButton = styled(CustomButton)`
  margin-top: 20px;
  @media (max-width: 575px) {
    padding: 7px;
  }
`;
export const Col = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @media (max-width: 800px) {
    align-items: end;
    padding-left: 10px;
  }
  @media (max-width: 780px) {
    align-items: center;
    padding-left: 0;
    margin: 20px 0 30px;
  }
`;
