import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import styled from 'styled-components';
import {AccountReducer, Blog, LawyerType} from '../../Action/types';
import {CustomButton, CustomInput, CustomText} from '../../Components/Common';
import Colors from '../Theme/Colors';
import Fonts from '../../Theme/Fonts';
import {Confirm, PrivacyModal} from '../Auth/SignUp';
import {CREATE_BLOG} from './GraphQl/CreateBlog';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Modal} from 'rsuite';
import {RootState} from '../../Reducer';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Loader} from 'rsuite';
import i18n from '../../I18next/I18n';

const CreateBlog = () => {
  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const history = useHistory();

  const [createBlog, {loading}] =
    useMutation<{createBlogOnDB: Blog}>(CREATE_BLOG);

  const {user, userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const ValidationSchema = Yup.object().shape({
    title: Yup.string().required('مطلوب'),
    body: Yup.string().required('مطلوب'),
  });

  const {errors, touched, values, handleChange, handleSubmit} = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: ValidationSchema,

    onSubmit: async submittedValues => {
      if (userType === LawyerType.company) {
        return Alert.error(i18n.t('Companies cannot share blogs'));
      }
      const variables: {
        poster_name?: string;
        title?: string;
        body?: string;
      } = {
        poster_name: user?.name,
        title: submittedValues.title,
        body: submittedValues.body,
      };
      try {
        const {data} = await createBlog({
          variables,
        });
        if (data?.createBlogOnDB) {
          history.goBack();
          Alert.success(
            'تم ارسال المدونة بنجاح! و سوف يتم مراجعتها من قبل مسؤول النظام',
          );
        }
      } catch (err) {
        setModal({
          isOn: true,
          message: i18n.t('Something went wrong'),
        });
      }
    },
  });

  return (
    <>
      <Wrapper>
        <BlogWrapper>
          <TitleText>إنشاء مقالة</TitleText>
          <BlogTitle
            name={'title'}
            placeholder="الموضوع"
            value={values.title}
            onChange={handleChange}
            error={!!(errors.title && touched.title)}
          />
          <BlogContent
            name={'body'}
            value={values.body}
            rows={15}
            cols={65}
            placeholder="انشاء مقالة"
            onChange={handleChange}
            error={!!(errors.body && touched.body)}
          />
          <ButtonWrapper>
            <PublishBtn
              orangeBg
              onClick={() => (loading ? null : handleSubmit())}>
              {loading ? <Loader /> : <PublishText>نشر</PublishText>}
            </PublishBtn>
            {/* <DraftBtn>
              <DraftText>حفظ المسودة</DraftText>
            </DraftBtn> */}
          </ButtonWrapper>
        </BlogWrapper>
      </Wrapper>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => setModal({isOn: false, message: ''})}>
            <CustomText color={Colors.white} size={12}>
              حسنا
            </CustomText>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
    </>
  );
};

export default CreateBlog;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.lightBlueBg};
`;

export const BlogWrapper = styled.div`
  margin: 90px 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 5px;
  box-shadow: 0px 0px 12px 3px ${props => props.theme.colors.lightSilver};
  padding: 20px 40px;
  background-color: ${Colors.white};
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 900px) {
    width: 80%;
  }
  @media (max-width: 650px) {
    width: 90%;
  } ;
`;

export const TitleText = styled(CustomText)`
  color: ${Colors.black};
  margin: 40px 0 30px;
  font-size: 20px;
  @media (max-width: 500px) {
    margin: 40px auto 30px;
  }
`;

export const BlogTitle = styled(CustomInput)<{error?: boolean}>`
  width: 95%;
  border-radius: 5px;
  border: 1px solid ${Colors.blogBlue};
  outline: none;
  ${props => (props.error ? 'border-color: red' : '')};
  @media (max-width: 380px) {
    width: 100%;
  }
`;

export const BlogContent = styled.textarea<{error?: boolean}>`
  width: 95%;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid ${Colors.blogBlue};
  direction: rtl;
  padding: 10px 15px;
  outline: none;
  resize: none;
  font-family: ${Fonts.regular};
  ${props => (props.error ? 'border-color: red' : '')};
  @media (max-width: 600px) {
    height: 25em;
  }
  @media (max-width: 500px) {
    height: 20em;
  }
  @media (max-width: 425px) {
    height: 15em;
  }

  @media (max-width: 380px) {
    width: 100%;
    height: 10em;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin: 20px 0 50px;
  @media (max-width: 500px) {
    justify-content: space-between;
  }
  @media (max-width: 380px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PublishBtn = styled(CustomButton)`
  margin: 5px 10px;
  min-width: 120px;
  @media (max-width: 380px) {
    width: 80%;
  }
`;

export const DraftBtn = styled(CustomButton)`
  background: ${Colors.white};
  border: 1px solid ${Colors.blogBlue};
  width: fit-content;
  min-width: 120px;
  @media (max-width: 380px) {
    width: 80%;
  }
`;

export const PublishText = styled(CustomText)`
  color: ${Colors.white};
  cursor: pointer;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;

export const DraftText = styled(CustomText)`
  color: ${Colors.darkGray};
  cursor: pointer;
  @media (max-width: 400px) {
    font-size: 13px;
  }
`;
