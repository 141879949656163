import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {ActionTypes} from '../Action/types';
import {Configs} from './Configs';
import {Account} from './Account';

const rootReducer = combineReducers({
  Configs,
  Account,
});

export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<RootState, ActionTypes>(
  {
    key: 'MyLegalID-Website',
    storage,
  },
  rootReducer,
);

export default persistedReducer;
