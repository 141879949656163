import React, {useState} from 'react';
import {Modal, Alert, Loader} from 'rsuite';
import styled from 'styled-components';
import {CustomButton, CustomText} from '../../../Components/Common';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import Dropzone from 'react-dropzone';
import Colors from '../../Theme/Colors';
import {
  AccountReducer,
  ConfigsReducer,
  SpecialtyPhotosInput,
  Upload,
} from '../../../Action/types';
import {UPLOAD_URI} from '../../../Configs';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../../Reducer';
import {MdDelete} from 'react-icons/md';
import {useMutation} from '@apollo/client';
import {updateUser} from '../../../Action';
import {UPDATE_LAWYER_DATA} from './ExperienceYears';
import {UPDATE_LAWYER} from '../GraphQL/EditAccount';
import i18n from '../../../I18next/I18n';
interface Props {
  currentSpecialtyId: string;
  clearCurrentSpecialty: () => void;
}
interface DocumentRowProps {
  upload: Upload;
  handleRemoveUpload: (uploadId: string) => void;
}
const DocumentRow = ({upload, handleRemoveUpload}: DocumentRowProps) => {
  const remove = () => handleRemoveUpload(upload.id);

  return (
    <UploadWrapper>
      <CustomText style={{direction: 'ltr'}}>
        {upload?.filename?.substring(0, 8) +
          '... .' +
          upload?.type?.split('/')[1]}
      </CustomText>
      <MdDelete size={19} color={Colors.red} onClick={remove} />
    </UploadWrapper>
  );
};
const AddSpecialtyImages = ({
  currentSpecialtyId,
  clearCurrentSpecialty,
}: Props) => {
  const {token} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const [updateLawyer, {loading: updateLawyerLoading}] = useMutation<{
    updateLawyer: UPDATE_LAWYER_DATA;
  }>(UPDATE_LAWYER);

  const dispatch = useDispatch();
  const [uploads, setUploads] = useState<Upload[]>([]);

  const handleAddUpload = (upload: Upload) => {
    setUploads([...uploads, upload]);
  };
  const handleRemoveUpload = (uploadId: string) => {
    setUploads(uploads.filter((upload: Upload) => upload.id !== uploadId));
  };
  const uploadFiles = async (file: any) => {
    const body = new FormData();
    body.append('file', file[0]);
    axios({
      method: 'post',
      url: `${UPLOAD_URI}`,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
      },
    })
      .then(async res => {
        handleAddUpload(res.data);
      })
      .catch(error => {
        Alert.error(i18n.t('Something went wrong please try again later'));
      });
  };
  const handleAddPhotosToSpecialty = async () => {
    const specialty_photo = {} as SpecialtyPhotosInput;
    specialty_photo.specialty = currentSpecialtyId;
    specialty_photo.photos = uploads?.map(upload => upload.id);
    try {
      const returnData = await updateLawyer({
        variables: {
          specialty_photos: [
            ...(user?.specialty_photos || []),
            specialty_photo,
          ],
        },
      });
      const updateUserData = returnData?.data?.updateLawyer;
      dispatch(updateUser({...updateUserData}));
      Alert.success('تم اضافة المرفقات بنجاح');
    } catch (error) {
      Alert.error(i18n.t('Something went wrong please try again later'));
    } finally {
      setUploads([]);
      clearCurrentSpecialty();
    }
  };

  return (
    <Modal
      show={!!currentSpecialtyId}
      onHide={() => {
        clearCurrentSpecialty();
        setUploads([]);
      }}>
      <Modal.Header>
        <Title size={20}>اضافة المرفقات</Title>
      </Modal.Header>
      <Body>
        <Dropzone
          multiple={false}
          onDrop={acceptedFiles => {
            uploadFiles(acceptedFiles);
          }}>
          {({getRootProps, getInputProps}) => (
            <AddImageWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <PlusIcon color={Colors.seaBlue} size={26} />
              <AddImageText color={Colors.seaBlue}>اضف وثيقة</AddImageText>
            </AddImageWrapper>
          )}
        </Dropzone>
        {uploads?.map((upload: Upload) => (
          <DocumentRow
            upload={upload}
            handleRemoveUpload={handleRemoveUpload}
            key={upload?.id}
          />
        ))}
      </Body>
      <Footer>
        {uploads.length ? (
          <CustomButton
            height={'33px'}
            width={'100px'}
            orangeBg
            type="submit"
            disabled={updateLawyerLoading}
            onClick={handleAddPhotosToSpecialty}>
            {updateLawyerLoading ? (
              <Loader />
            ) : (
              <CustomText color={Colors.white} weight={'400'}>
                حفظ
              </CustomText>
            )}
          </CustomButton>
        ) : (
          <></>
        )}
      </Footer>
    </Modal>
  );
};

export default AddSpecialtyImages;

const Title = styled(CustomText)`
  direction: rtl;
  text-align: center;
`;

const Body = styled(Modal.Body)`
  padding: 10px 40px 30px;
  direction: rtl;
`;

const AddImageText = styled(CustomText)`
  cursor: pointer;
  margin: 0px 5px;
`;
const AddImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  cursor: pointer;
  margin-bottom: 15px;
`;

const PlusIcon = styled(AiOutlinePlusCircle)``;

const UploadWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Footer = styled(Modal.Footer)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
