import {
  ActionTypes,
  ConfigsReducer,
  SET_TOKEN,
  LOGOUT,
  LOGIN,
  SET_DEVICE_ID,
  SET_SELECTED_COUNTRY,
  SET_COUNTRIES,
} from '../Action/types';

const initialState: ConfigsReducer = {
  token: '',
  loggedIn: false,
  deviceId: '',
  countries: [],
  selectedCountry: {
    id: null,
    isoCode: null,
    supportNumber: null,
    currency: null,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export const Configs = (
  state = initialState,
  action: ActionTypes,
): ConfigsReducer => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        loggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        loggedIn: false,
        token: '',
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_DEVICE_ID:
      return {
        ...state,
        deviceId: action.payload,
      };
    case SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.payload,
      };

    default:
      return state;
  }
};
