import {useQuery} from '@apollo/client';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {AccountReducer, LawyerType, Specialty} from '../../../Action/types';
import {CustomButton, CustomText, IconsDiv} from '../../../Components/Common';
import {RootState} from '../../../Reducer';
import Colors from '../../Theme/Colors';
import {FIND_SPECIALTIES_BY_TYPE} from '../GraphQL/EditAccount';
import {CheckPicker} from 'rsuite';
import {useFormik} from 'formik';
import {UPDATE_COMPANY} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {Company} from '../../../Action/types';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {StyledRow, Col, AddSectionText} from './CompanyCV';
import {MdDelete} from 'react-icons/md';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import {CancelIcon} from '../../User/ProfilePreview';
import {
  LoaderContainer,
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Certificates';
import {Alert, Loader} from 'rsuite';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const CompanyGoals = () => {
  const {data: specialtiesData, loading: loadingspecialties} = useQuery(
    FIND_SPECIALTIES_BY_TYPE,
    {
      variables: {type: LawyerType.company},
    },
  );
  const [certificateModal, setCertificateModalModal] = useState(false);
  const [removing, setRemoving] = useState<boolean>(false);
  const handleCheckIfRemoving = () => {
    setRemoving(true);
  };
  const handledCertificateModal = () => {
    setCertificateModalModal(!certificateModal);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let goals: [Specialty] | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({goals} = user);
  }

  const [updateCompany, {loading: loadingCompanyUpdate}] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);

  const handlePickData = (item: string[]) => {
    setFieldValue('specialty', item);
  };

  const {values, setFieldValue, handleSubmit} = useFormik({
    initialValues: {
      specialties: [],
      specialty: [],
      specialtyIndex: 0,
    },
    onSubmit: async submittedValues => {
      let variables: any;
      if (removing) {
        goals && goals?.splice(values.specialtyIndex, 1);
        variables = {
          goals: goals && goals?.map(goal => goal?.id),
        };
        setRemoving(false);
        handledCertificateModal();
      } else {
        if (
          values.specialty.length <
          // @ts-ignore
          [...new Set(submittedValues?.specialties)].length
        ) {
          variables = {goals: values.specialty};
        } else {
          submittedValues?.specialties?.push(...submittedValues?.specialty);
          variables = {
            // @ts-ignore
            goals: [...new Set(submittedValues?.specialties)],
          };
        }
      }
      try {
        const {data} = await updateCompany({
          variables,
        });
        if (data?.updateCompany) {
          dispatch(
            updateUser({
              ...user,
              ...data?.updateCompany,
            }),
          );
          setAdd(false);
          setShow(true);
          Alert.success('تمت العملية بنجاح', 5000);
        }
      } catch (err) {
        if (
          err.message === 'Your membership level allows Only 1 Goal' ||
          err.message === 'Your membership level allows Only 3 Goals'
        ) {
          Alert.error(
            i18n.t(err.message, {
              fallback: 'Something went wrong please try again later',
            }),
            5000,
          );
          history.push('Memberships', {
            userType: 'company',
          });
        } else if (
          err.message === 'Your membership level allows Only 5 Goals'
        ) {
          Alert.error(
            i18n.t(err.message, {
              fallback: 'Something went wrong please try again later',
            }),
            5000,
          );
          history.push('Memberships');
        } else {
          Alert.error(
            i18n.t('Something went wrong please try again later'),
            5000,
          );
        }
      }
    },
  });

  useEffect(() => {
    setFieldValue(
      'specialties',
      goals?.map(goal => goal?.id),
    );
  }, [goals, setFieldValue]);
  return (
    <EditAccountWrapper>
      <Inputs>
        <StyledRow>
          <ProfileTitle>غايات الشركة</ProfileTitle>
          {show && (
            <Col
              onClick={() => {
                // if (
                //   !user?.membership?.company_privileges?.goals &&
                //   user?.type !== LawyerType.company
                // ) {
                //   return notAvailable();
                // }
                setAdd(true);
                setShow(false);
              }}>
              <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
              <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
                أضف غاية جديدة
              </AddSectionText>
            </Col>
          )}
        </StyledRow>

        <CompanyGoalsList>
          {goals &&
            goals.map((goal, index) => (
              <StyledRow>
                <ListItem>
                  <Speciality>{`${goal?.specialty}`}</Speciality>
                </ListItem>
                <IconsDiv>
                  <MdDelete
                    size={19}
                    color={Colors.red}
                    onClick={() => {
                      handleCheckIfRemoving();
                      setFieldValue('specialtyIndex', index);
                      handledCertificateModal();
                    }}
                  />
                </IconsDiv>
              </StyledRow>
            ))}
        </CompanyGoalsList>
        {add && (
          <>
            <CheckPicker
              data={
                !loadingspecialties && specialtiesData
                  ? specialtiesData?.findSpecialtiesByType?.map(
                      (specialty: any) => {
                        return {
                          label: specialty?.specialty,
                          value: specialty?.id,
                        };
                      },
                    )
                  : []
              }
              onChange={item => {
                handlePickData(item);
              }}
              defaultValue={goals?.map(item => item?.id)}
              appearance="subtle"
              placeholder="اختر"
              menuStyle={{textAlign: 'right'}}
              locale={{
                searchPlaceholder: 'بحث',
                noResultsText: 'لا يوجد نتائج',
              }}
              cleanable={false}
              style={{
                width: '91.5%',
                // maxWidth: '500px',
                direction: 'rtl',
                textAlign: 'right',
                border: `1px solid #c1c1c1`,
                borderRadius: 5,
              }}
            />
            <Row>
              {loadingCompanyUpdate ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <CustomButton
                    height={'33px'}
                    width={'33%'}
                    orangeBg
                    onClick={() => {
                      setRemoving(false);
                      handleSubmit();
                    }}
                    disabled={values.specialty.length === 0}>
                    <ButtonText color={Colors.white} weight={'400'}>
                      حفظ
                    </ButtonText>
                  </CustomButton>
                  <CancelButton
                    height={'33px'}
                    bgColor={Colors.white}
                    withBorder
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                    }}
                    width={'33%'}>
                    <ButtonText weight={'400'}>إلغاء</ButtonText>
                  </CancelButton>
                </>
              )}
            </Row>
          </>
        )}
      </Inputs>
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleSubmit();
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default CompanyGoals;
const CompanyGoalsList = styled.ol``;
const ListItem = styled.li``;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ProfileTitle = styled(CustomText)`
  padding: 10px 0px 15px 0px;
`;

const Speciality = styled(CustomText)`
  width: 440px;
`;

const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0px 30px;
`;
const Inputs = styled.div`
  width: 93%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
