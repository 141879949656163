import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {CustomText, CustomButton, CustomInput} from '../../Components/Common';
import Colors from '../Theme/Colors';
import {FaCommentAlt} from 'react-icons/fa';
import {IoIosShareAlt} from 'react-icons/io';
import {AiOutlineArrowDown} from 'react-icons/ai';
import moment from 'moment';
import 'moment/locale/ar-sa';
import {useLocation} from 'react-router-dom';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {CREATE_COMMENT, COMMENT, BLOG} from './GraphQl/Comments';
import {
  AccountReducer,
  Blog,
  Comment,
  Company,
  Lawyer,
} from '../../Action/types';
import {RootState} from '../../Reducer';
import {useSelector} from 'react-redux';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Alert, Pagination} from 'rsuite';
import {Thumbnail} from './BlogListing';
import {STAGING_URI, UPLOAD_URI} from '../../Configs';
import noPhoto from '../../Assets/Images/noPhoto.png';
import {StickyShareButtons} from 'sharethis-reactjs';
import i18n from '../../I18next/I18n';

interface Comments {
  user_name: string;
  body: string;
  lawyer: Lawyer;
  company: Company;
  created_at: Date;
}
interface AllComments {
  pageNumber?: number;
  blog?: string;
}

const BlogDetails = () => {
  const location = useLocation();
  const {state} = location;
  const query = new URLSearchParams(location.search);
  let id: string | undefined;

  if (state) {
    ({id} = state as Blog);
  }
  const [createComment] = useMutation<{createComment: Comment}>(CREATE_COMMENT);
  const {data: blog, refetch: refetchBlog} = useQuery<{findBlogById: Blog}>(
    BLOG,
    {
      variables: {
        blogId: query.get('blogId') ? query.get('blogId') : id,
      },
    },
  );

  const [activePage, setActivePage] = useState(1);

  const [
    commentsByBlog,
    {data: commentListing, loading: loadingcomments, refetch},
  ] = useLazyQuery(COMMENT);

  const loadPageNumber = useCallback(
    (pageNumber: number = activePage) => {
      const variables: AllComments = {
        pageNumber,
        blog: id,
      };
      try {
        commentsByBlog({
          variables,
        });
      } catch (err) {
        Alert.error(i18n.t('Something went wrong'));
      }
    },
    [activePage, commentsByBlog, id],
  );
  useEffect(() => {
    if (activePage) {
      loadPageNumber();
    }
  }, [activePage, loadPageNumber]);

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const ValidationSchema = Yup.object().shape(
    !user?.name
      ? {
          user_name: Yup.string().required('مطلوب'),
          body: Yup.string().required('مطلوب'),
        }
      : {body: Yup.string().required('مطلوب')},
  );

  const {errors, touched, values, handleChange, resetForm, handleSubmit} =
    useFormik({
      initialValues: {
        body: '',
        user_name: '',
      },
      validationSchema: ValidationSchema,

      onSubmit: async submittedValues => {
        const variables: {
          user_name?: string;
          body?: string;
          blog?: string;
        } = {
          blog: id,
          user_name: user?.name ? user?.name : submittedValues.user_name,
          body: submittedValues.body,
        };
        try {
          const {data} = await createComment({
            variables,
          });

          if (data?.createComment) {
            Alert.success('تم النشر بنجاح');
            resetForm();
            setTimeout(() => {
              refetch && refetch();
              refetchBlog();
            }, 1000);
          }
        } catch (err) {
          Alert.error(i18n.t('Something went wrong'));
        }
      },
    });

  return (
    <Wrapper>
      <StickyShareButtons
        config={{
          alignment: 'left', // alignment of buttons (left, right)
          color: 'social', // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 16, // font size for the buttons
          // hide_desktop: false, // hide buttons on desktop (true, false)
          labels: 'counts', // button labels (cta, counts, null)
          language: 'en', // which language to use (see LANGUAGES)
          min_count: 0, // hide react counts less than min_count (INTEGER)
          networks: [
            // which networks to include (see SHARING NETWORKS)
            'linkedin',
            'facebook',
            'twitter',
            'email',
          ],
          padding: 12, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false, // show/hide the total share count (true, false)
          show_mobile: true, // show/hide the buttons on mobile (true, false)
          show_toggle: true, // show/hide the toggle buttons (true, false)
          size: 48, // the size of each button (INTEGER)
          top: 160, // offset in pixels from the top of the page

          // OPTIONAL PARAMETERS
          url: query.get('blogId')
            ? STAGING_URI + '?blogId=' + query.get('blogId')
            : STAGING_URI + '?blogId=' + id,
          description: '', // (defaults to og:description or twitter:description)
          title: blog?.findBlogById?.title, // (defaults to og:title or twitter:title)
          message: blog?.findBlogById?.body, // (only for email sharing)
          username: blog?.findBlogById?.poster_name, // (only for twitter sharing)
        }}
      />
      <SubWrapper>
        <DateContainer>
          <StyledThumbnail
            src={
              user?.photo?.filename
                ? `${UPLOAD_URI}/image/${user?.photo?.filename}`
                : user?.logo?.filename
                ? `${UPLOAD_URI}/image/${user?.logo?.filename}`
                : noPhoto
            }
          />
          <TitleCommentWrapper>
            <CommentText size={20} color={Colors.darkGray}>
              {blog?.findBlogById?.title}
            </CommentText>
            <CreatedBy color={Colors.lightGray2}>
              {' '}
              نُشرت بتاريخ{' '}
              {moment(
                blog?.findBlogById?.created_at
                  ? blog?.findBlogById?.created_at
                  : '',
              )
                .lang('ar-sa')
                .format('MMMM D, YYYY')}{' '}
              بواسطة {blog?.findBlogById?.poster_name}{' '}
            </CreatedBy>
          </TitleCommentWrapper>
        </DateContainer>
        <TextRow>
          <CustomText weight={'400'}>{blog?.findBlogById?.body}</CustomText>
        </TextRow>
        <ShareCommentContainer>
          {/* <CustomText size={16} color={Colors.blue}>
            {' '}
            شارك{' '}
          </CustomText>
          <ShareIcon color={Colors.blue} size={20} /> */}
          <CommentText size={16} color={Colors.darkGray}>
            {blog?.findBlogById?.comments_count || 0} تعليقات
          </CommentText>
          <CommentIcon color={Colors.lightGray2} size={16} />
        </ShareCommentContainer>
        <TextAreaWrapper>
          <Thumbnail
            src={
              user?.photo?.filename
                ? `${UPLOAD_URI}/image/${user?.photo?.filename}`
                : user?.logo?.filename
                ? `${UPLOAD_URI}/image/${user?.logo?.filename}`
                : noPhoto
            }
          />
          <TitleCommentWrapper>
            {!user?.name && (
              <UsernameInput
                name="user_name"
                placeholder={'اسم المستخدم'}
                value={values.user_name}
                width={'30%'}
                onChange={handleChange}
                error={!!(errors.user_name && touched.user_name)}
              />
            )}
            <TextArea
              placeholder={'أترك تعليقاً'}
              name={'body'}
              value={values.body}
              onChange={handleChange}
              error={!!(errors.body && touched.body)}
            />
          </TitleCommentWrapper>
        </TextAreaWrapper>
        <CommentButtonDiv>
          <CommentButton orangeBg width={'12%'} onClick={() => handleSubmit()}>
            نشر
          </CommentButton>
        </CommentButtonDiv>
        <Divider />
        {commentListing?.commentsByBlog?.comments?.map((info: Comments) => {
          return (
            <DateContainer>
              <StyledThumbnail
                src={
                  info?.lawyer?.photo?.filename
                    ? `${UPLOAD_URI}/image/${info?.lawyer?.photo?.filename}`
                    : info?.company?.logo?.filename
                    ? `${UPLOAD_URI}/image/${info?.company?.logo?.filename}`
                    : noPhoto
                }
              />
              <Col>
                <UlDiv>
                  {loadingcomments ? (
                    <CreatedByText color={Colors.darkGray} size={18}>
                      {' '}
                    </CreatedByText>
                  ) : (
                    <CreatedByText color={Colors.darkGray} size={18}>
                      {info?.user_name}
                    </CreatedByText>
                  )}
                  <Ul>
                    <Li>{moment(info.created_at).fromNow()}</Li>
                  </Ul>
                </UlDiv>
                {loadingcomments ? (
                  <CreatedByText color={Colors.darkGray} weight={'400'}>
                    {' '}
                  </CreatedByText>
                ) : (
                  <CreatedByText color={Colors.darkGray} weight={'400'}>
                    {info?.body}
                  </CreatedByText>
                )}
              </Col>
            </DateContainer>
          );
        })}
        <PaginationDiv>
          <Pagination
            total={100}
            pages={commentListing?.commentsByBlog?.page_count || 0}
            maxButtons={10}
            activePage={activePage}
            onSelect={setActivePage}
            next={!(activePage === commentListing?.commentsByBlog?.page_count)}
            prev={!(activePage === 1)}
            disabled={loadingcomments}
          />
        </PaginationDiv>
      </SubWrapper>
    </Wrapper>
  );
};

export default BlogDetails;

export const PaginationDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;
export const SubWrapper = styled.div`
  width: 78%;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;
export const DateContainer = styled.div`
  direction: rtl;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 30px;
`;
export const TextRow = styled.div`
  direction: rtl;
  line-height: 1.8rem;
  margin: 25px 40px 0px;
`;

export const CreatedBy = styled(CustomText)`
  margin-right: 35px;
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
export const ShareCommentContainer = styled.div`
  direction: rtl;
  display: flex;
  flex-direction: row;
  margin-bottom: 35px;
  margin-top: 35px;
`;
export const ShareIcon = styled(IoIosShareAlt)`
  margin-right: 5px;
`;
export const CommentText = styled(CustomText)`
  margin-right: 35px;
`;
export const CommentIcon = styled(FaCommentAlt)`
  margin-right: 10px;
  margin-top: 1px;
`;
export const TextAreaWrapper = styled.div`
  direction: rtl;
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 35px;
  margin-top: 35px;
`;

export const TextArea = styled.textarea<{error?: boolean}>`
  margin-right: 25px;
  height: 80px;
  width: 100%;
  font-size: 16px;
  border-radius: 2.5px;
  border: 2px solid ${Colors.borderGray};
  outline: none;
  margin-top: 10px;
  padding: 10px;
  ${props => (props.error ? 'border-color: red' : '')};
  &::placeholder {
    margin-top: 20px;
    color: ${Colors.darkGray};
    font-size: 18px;
    @media (max-width: 750px) {
      font-size: 13px;
    }
  }
  @media (max-width: 750px) {
    font-size: 13px;
  }
`;
export const CommentButtonDiv = styled.div`
  /* direction: rtl; */
  /* margin-right: 20px; */

  /* width: 10%; */

  @media (max-width: 750) {
    width: 150%;
  }
`;
export const Divider = styled.div`
  border: 1px solid ${Colors.borderGray};
  width: 100%;
  margin-bottom: 70px;
  margin-top: 40px;
`;
export const CommentButton = styled(CustomButton)`
  color: ${Colors.white};
  @media (max-width: 750px) {
    width: 25%;
  }
`;

export const CreatedByText = styled(CustomText)`
  margin-right: 25px;
  margin-bottom: 8px;
  margin-top: 4px;
`;

export const UlDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;
export const Ul = styled.ul`
  font-size: 16px;
`;
export const Li = styled.li`
  color: ${Colors.lightGray3};
  @media (max-width: 750px) {
    font-size: 13px;
  }
`;

export const SeeMoreDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
`;
export const SeeMoreText = styled(CustomText)`
  @media (max-width: 750px) {
    margin-top: 5px;
    font-size: 17px;
  }
`;
export const ArrowDown = styled(AiOutlineArrowDown)`
  margin-top: 5px;
  margin-left: 15px;
`;
export const CreateBlogThumbnail = styled.div`
  margin-top: 10px;
  border-radius: 50%;
  height: 75px;
  width: 80px;
  background-color: ${Colors.blogBlue};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    height: 60px;
    min-width: 60px;
  }
`;
export const ThumbnailText = styled(CustomText)`
  font-weight: 900;
  font-size: 35px;
  color: ${Colors.white};
  padding: 0;
  align-self: center;
`;

export const StyledThumbnail = styled(Thumbnail)`
  height: 35px;
  width: 35px;
`;

export const TitleCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const UsernameInput = styled(CustomInput)<{error?: boolean}>`
  margin-right: 25px;
  border: 2px solid ${Colors.borderGray};
  outline: none;
  ${props => (props.error ? 'border-color: red' : '')};
  border-radius: 2.5px;
`;
