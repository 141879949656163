import React, {useState} from 'react';
import styled from 'styled-components';
import {CustomText} from './Common';
import {
  HeaderLogoWrapper,
  HeaderNav,
  HeaderNavOption,
  CustomTextLink,
} from './Header';
import Logo from '../Assets/Images/logo.png';
import Google from '../Assets/Images/google-store.png';
import Apple from '../Assets/Images/app-store.png';
import visaIcon from '../Assets/Images/visa-icon.svg';
import mastercardIcon from '../Assets/Images/mastercard-icon.svg';
import {IoLogoInstagram} from 'react-icons/io';
import {IoLogoYoutube} from 'react-icons/io';
import {AiOutlineTwitter} from 'react-icons/ai';
import {FaFacebookF} from 'react-icons/fa';
import packageJson from '../../package.json';
import moment from 'moment';
const Footer = () => {
  const [random, setRandom] = useState<Boolean>(true);

  return (
    <>
      <Wrapper>
        <CopyRightRowWrapper>
          <CustomText weight="400" size={13}>
            {` © cg imagine+invent. ${moment().year()}. All Rights Reserved`}
          </CustomText>
        </CopyRightRowWrapper>
        <RowWrapper>
          <DownloadWrapper>
            <Download
              src={Google}
              alt="google-play"
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=dev.wtm.mylegalid',
                )
              }
            />
            <Download
              src={Apple}
              alt="app-store"
              onClick={() =>
                window.open('https://apps.apple.com/jo/app/عدل/id1629846952')
              }
            />
          </DownloadWrapper>
          <FooterLogoWrapper>
            <FooterLogo src={Logo} />
          </FooterLogoWrapper>
        </RowWrapper>

        <BottomRowWrapper>
          <FooterNav>
            <FooterNavOption to="/">
              <TextLink weight="400">الرئيسية</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/guide">
              <TextLink weight="400">دليل الإستخدام</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/blog-listing">
              <TextLink weight="400">المجلة القانونية</TextLink>
            </FooterNavOption>
            <FooterNavOption
              onClick={() => setRandom(!random)}
              to={{
                pathname: '/',
                state: {contactus: random},
              }}>
              <TextLink weight="400">تواصل معنا</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/privacy-policy">
              <TextLink weight="400">سياسة الخصوصية</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/terms-and-conditions">
              <TextLink weight="400">اتفاقية الاستخدام</TextLink>
            </FooterNavOption>
          </FooterNav>
          <CopyRightCustomText weight="400" size={14}>
            {` © cg imagine+invent. ${moment().year()}. All Rights Reserved`}
            <br /> Version - {packageJson.version}
          </CopyRightCustomText>
        </BottomRowWrapper>
        <BottomRowWrapperMobile>
          <FooterNavMobile>
            <FooterNavOption to="/">
              <TextLink weight="400">الرئيسية</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/guide">
              <TextLink weight="400">دليل الإستخدام</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/blog-listing">
              <TextLink weight="400">المجلة القانونية</TextLink>
            </FooterNavOption>
          </FooterNavMobile>
          <FooterNavMobile>
            <FooterNavOption
              onClick={() => setRandom(!random)}
              to={{
                pathname: '/',
                state: {contactus: random},
              }}>
              <TextLink weight="400">تواصل معنا</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/privacy-policy">
              <TextLink weight="400">سياسة الخصوصية</TextLink>
            </FooterNavOption>
            <FooterNavOption to="/terms-and-conditions">
              <TextLink weight="400">اتفاقية الاستخدام</TextLink>
            </FooterNavOption>
          </FooterNavMobile>
        </BottomRowWrapperMobile>
        <SocialRow>
          <SocialWrapper>
            <InstagramIcon
              size={25}
              onClick={() => window.open('https://www.instagram.com/eadllaw/')}
            />
            <YoutubeIcon
              size={25}
              onClick={() =>
                window.open(
                  'https://www.youtube.com/channel/UCIivd1IZVNQCXCmdYRTudAw',
                )
              }
            />
            <TwitterIcon
              size={25}
              onClick={() => window.open('https://twitter.com/eadllaw')}
            />
            <FacebookIcon
              size={20}
              onClick={() => window.open('https://www.facebook.com/eadllaw')}
            />
          </SocialWrapper>
          <SocialWrapper>
            <VisaIcon></VisaIcon>
            <MastercardIcon></MastercardIcon>
          </SocialWrapper>
        </SocialRow>
      </Wrapper>
    </>
  );
};

export default Footer;

export const CustomContainer = styled.div`
  flex-direction: row;
  color: white;
  height: 80px;
  justify-content: space-between;
`;
export const Custom = styled.text`
  font-size: 14px;
  color: gray;
  margin: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;
const InstagramIcon = styled(IoLogoInstagram)`
  cursor: pointer;
  @media (max-width: 980px) {
    width: 30px;
    height: 30px;
  }
  :hover {
    opacity: 0.7;
  }
`;
const YoutubeIcon = styled(IoLogoYoutube)`
  margin-right: 5px;
  cursor: pointer;
  @media (max-width: 980px) {
    width: 30px;
    height: 30px;
  }
  :hover {
    opacity: 0.7;
  }
`;

const TwitterIcon = styled(AiOutlineTwitter)`
  margin-right: 5px;
  cursor: pointer;
  @media (max-width: 980px) {
    width: 30px;
    height: 30px;
  }
  :hover {
    opacity: 0.7;
  }
`;
const FacebookIcon = styled(FaFacebookF)`
  margin-right: 5px;
  cursor: pointer;
  @media (max-width: 980px) {
    width: 25px;
    height: 25px;
  }
  :hover {
    opacity: 0.7;
  }
`;
const VisaIcon = styled.div`
  width: 40px;
  height: 30px;
  background-image: url(${visaIcon});
  background-repeat: no-repeat;
  background-size: contain;
`;

const MastercardIcon = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${mastercardIcon});
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 15px;
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 0 20px;
  flex-wrap: wrap;
  @media (max-width: 475px) {
    flex-direction: column;
  }
`;
const SocialRow = styled(RowWrapper)`
  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column-reverse;
  }
`;
export const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  padding: 0 11px 0 0;
  @media (max-width: 980px) {
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    padding: 0 !important;
  }
`;
export const DownloadWrapper = styled(SocialWrapper)`
  padding: 38px 11px 0;
`;

export const FooterLogoWrapper = styled(HeaderLogoWrapper)`
  @media (max-width: 500px) {
    padding-left: 10px;
  }
`;

export const FooterLogo = styled.img`
  width: 120px;
  @media (max-width: 900px) {
    margin: 0;
  }
`;

export const FooterNav = styled(HeaderNav)`
  flex-wrap: wrap;
  padding-left: 0;

  @media (max-width: 1120px) {
    width: 100%;
    padding-right: 10px;
    justify-content: space-between;
  }
  @media (max-width: 850px) {
    padding-right: 0px;
    justify-content: space-evenly;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 100px;
    direction: rtl;
  }
`;

export const BottomRowWrapper = styled(RowWrapper)`
  @media (max-width: 1120px) {
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

export const FooterNavOption = styled(HeaderNavOption)`
  @media (max-width: 1400px) {
    padding-left: 10px;
  }
  @media (max-width: 800px) {
    padding-left: 10px;
    margin-top: 10px;
  }
  @media (max-width: 638px) {
    padding-left: 40px;
  }
  @media (max-width: 515px) {
    padding-left: 10px;
  }
`;

export const CopyRightRowWrapper = styled(RowWrapper)`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    justify-content: center;
  }
`;

export const CopyRightCustomText = styled(CustomText)`
  @media (max-width: 500px) {
    display: none;
  }
  @media (max-width: 330px) {
    font-size: 13px;
  }
`;

export const FooterNavMobile = styled.div`
  display: none;
  margin: 0 0 20px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    direction: rtl;
    padding-right: 20px;
  }
`;

export const BottomRowWrapperMobile = styled.div`
  display: none;
  margin: 0 0 20px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 90%;
  }
  @media (max-width: 340px) {
    width: 95%;
  }
`;
export const Download = styled.img`
  height: auto;
  width: 115px;
  margin: 5px;
  cursor: pointer;
`;
export const Version = styled(CustomText)`
  color: ${({theme}) => theme.colors.white};
  margin-bottom: 20px;
`;
export const TextLink = styled(CustomTextLink)`
  font-size: 16px;
  @media (max-width: 1193px) {
    font-size: 14px;
  }
  @media (max-width: 1120px) {
    font-size: 16px;
  }
  @media (max-width: 1120px) {
    font-size: 14px;
  }
`;
