import gql from 'graphql-tag';

export const CITIES = gql`
  query findAllCities {
    findAllCities {
      id
      city
    }
  }
`;
export const COUNTRY_FRAGMENT = gql`
  fragment CountryFragment on Country {
    id
    country
    show
    updated_at
    created_at
    isoCode
  }
`;
export const CITY_FRAGMENT = gql`
  fragment CityFragment on City {
    id
    city
    country {
      ...CountryFragment
    }

    show
    updated_at
    created_at
  }
  ${COUNTRY_FRAGMENT}
`;
export const SPECIALTIES_FRAGMENT = gql`
  fragment SpecialtiesFragment on Specialty {
    id
    show
    specialty
    type
    created_at
    updated_at
  }
`;
export const LAWYER_FRAGMENT = gql`
  fragment LawyerFragment on Lawyer {
    id
    type
    name
    email
    phone
    work_phone
    rate_count
    is_disabled
    disable_review
    years_of_practice
    rating
    biography
    city {
      ...CityFragment
    }
    pending_specialties {
      ...SpecialtiesFragment
    }
    specialty_photos {
      photos
      specialty
    }
    verified_specialties {
      ...SpecialtiesFragment
    }
    photo {
      id
      filename
      label
      type
      created_at
    }
    research {
      label
      url
    }
    average_rate
    languages
    scholar_degree
    scholar_major
    social_status
    date_of_birth
    hours_of_operation
    building_name
    street_name
    facebook_id
    google_id
    apple_id
    linkedin_id
    twitter_handle
    fax
    land_line
    google_maps_link
    website
    youtube
    telegram
    whats_app_phone
    token
    ejazeh_number
    naqabeh_number
    ssn
    training_courses {
      title
      acquired_from
      acquired_at
    }
    work_experience {
      name
      years
    }
    is_email_confirmed
    is_phone_confirmed
    deleted
    updated_at
    created_at
    membership {
      id
      type
      name
      created_at
      cost
      lawyer_privileges {
        pending_specialties
        scholar_degree
        training_courses
        research
        cv
        social_media
      }
      company_privileges {
        goals
        activities
        clients
        research
        team
        biography
        social_media
      }
    }
    membership_expiry
  }
  ${SPECIALTIES_FRAGMENT}
  ${CITY_FRAGMENT}
`;
export const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on Company {
    id
    email
    name
    address
    fax
    rating
    is_disabled
    disable_review
    average_rate
    city {
      ...CityFragment
    }
    mobile_phone
    whats_app_phone
    land_line
    google_maps_link
    website
    youtube
    telegram
    logo {
      id
      filename
      label
      type
      created_at
    }
    specialties {
      ...SpecialtiesFragment
    }
    clients
    photos {
      id
      filename
      label
      type
      created_at
    }
    token
    ssn
    city {
      ...CityFragment
    }
    authorized_signatory
    goals {
      ...SpecialtiesFragment
    }
    activities
    research {
      label
      url
    }
    company_type
    commercial_name
    date_of_establishment
    hours_of_operation
    number_of_employees
    biography
    is_email_confirmed
    deleted
    updated_at
    created_at
    rate_count
    team {
      id
      name
      role
      photo {
        filename
      }
    }
    membership {
      id
      type
      name
      created_at
      cost
      lawyer_privileges {
        pending_specialties
        scholar_degree
        training_courses
        research
        cv
        social_media
      }
      company_privileges {
        goals
        activities
        clients
        research
        team
        biography
        social_media
      }
    }
    membership_expiry
  }
  ${CITY_FRAGMENT}
  ${SPECIALTIES_FRAGMENT}
`;
export const CREATE_LAWYER = gql`
  mutation createLawyer(
    $type: LAWYER_ENUM!
    $name: String!
    $email: String!
    $password: String!
    $phone: String!
    $country: ID!
    $city: ID!
    $pending_specialties: [ID!]
    $naqabeh_number: String
    $ejazeh_number: String
    $ssn: String
  ) {
    createLawyer(
      createLawyerInput: {
        type: $type
        name: $name
        email: $email
        password: $password
        phone: $phone
        country: $country
        city: $city
        pending_specialties: $pending_specialties
        naqabeh_number: $naqabeh_number
        ejazeh_number: $ejazeh_number
        ssn: $ssn
      }
    ) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;
export const CREATE_COMPANY = gql`
  mutation createCompany(
    $ssn: String!
    $name: String!
    $authorized_signatory: String!
    $email: String!
    $password: String!
    $country: ID!
    $city: ID!
  ) {
    createCompany(
      createCompanyInput: {
        ssn: $ssn
        name: $name
        authorized_signatory: $authorized_signatory
        email: $email
        password: $password
        city: $city
        country: $country
      }
    ) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const FIND_BY_Naqabeh_Number = gql`
  query findLawyerByNaqabehNumber($naqabeh_number: String!) {
    findLawyerByNaqabehNumber(naqabeh_number: $naqabeh_number) {
      name
      phone
      email
    }
  }
`;

export const FIND_MEMBERSHIPS = gql`
  query findAllMemberships($id: String) {
    findAllMemberships(id: $id) {
      id
      type
      name
      cost
      lawyer_privileges {
        pending_specialties
        scholar_degree
        training_courses
        research
        cv
      }
      company_privileges {
        goals
        activities
        clients
        research
        team
      }
      new_cost
    }
  }
`;
