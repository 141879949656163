import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  // Row,
  RowExtended,
  ButtonsRow,
  Label,
  IconsDiv,
  Detail,
  A,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {MdDelete} from 'react-icons/md';
import {UPDATE_COMPANY, UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {
  Account,
  AccountReducer,
  Company,
  LawyerType,
  Research,
} from '../../../Action/types';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Loader} from 'rsuite';
import {
  LoaderContainer,
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Certificates';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import {CancelIcon} from '../../User/ProfilePreview';
import {
  CheckBox,
  CheckBoxWrapper,
  CheckBoxLabel,
} from '../../Search/SearchLawyers';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const Researches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [checkBox, setCheckBox] = useState<boolean>(false);

  const [certificateModal, setCertificateModalModal] = useState(false);
  const [removeId, setRemoveId] = useState<number | undefined>();

  const handledCertificateModal = (index?: number) => {
    setCertificateModalModal(!certificateModal);
    setRemoveId(index);
  };

  const {user, userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let research: Research[] | [] | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({research} = user);
  }

  const [updateLawyer, {loading: loadingLawyerUpdate}] = useMutation<{
    updateLawyer: Account;
  }>(UPDATE_LAWYER);
  const [updateCompany, {loading: loadingCompanyUpdate}] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);

  const validationSchema = Yup.object().shape({
    label: Yup.string().required(),
  });

  const {
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldError,
    resetForm,
  } = useFormik({
    initialValues: {
      researches: research ? research : [],
      research: '',
      label: '',
      url: '',
    },
    onSubmit: async submittedValues => {
      if (submittedValues.url) {
        const checkUrl =
          // eslint-disable-next-line no-useless-escape
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
            submittedValues.url,
          );
        if (!checkUrl) {
          setFieldError('url', 'الرجاء إدخال رابط صحيح');
          return Alert.info('الرجاء إدخال رابط صحيح', 2000);
        }
      }

      let variables: Research;
      variables = {
        label: submittedValues?.label,
      };

      if (submittedValues?.url) {
        variables.url = submittedValues?.url;
      }
      submittedValues.researches.push(variables);
      try {
        if (userType !== LawyerType.company) {
          const {data} = await updateLawyer({
            variables: {
              // @ts-ignore
              research: [...new Set(submittedValues.researches)],
            },
          });
          const updateUserData = data?.updateLawyer;
          dispatch(
            updateUser({
              ...user,
              research: [...((updateUserData?.research || []) as Research[])],
            }),
          );
        } else {
          const {data} = await updateCompany({
            variables: {
              // @ts-ignore
              research: [...new Set(submittedValues.researches)],
            },
          });
          if (data?.updateCompany) {
            const updateUserData = data?.updateCompany;
            dispatch(
              updateUser({
                ...user,
                research: [...((updateUserData?.research || []) as Research[])],
              }),
            );
          }
        }
        setAdd(false);
        setShow(true);
        resetForm();
        Alert.success('تمت العملية بنجاح');
      } catch (err) {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
    validationSchema: validationSchema,
  });

  const handleRemoveField = async (index: any) => {
    handledCertificateModal();
    research && research.splice(index, 1);
    const newData =
      research &&
      research?.map(item => {
        return {label: item?.label, url: item?.url};
      });
    if (userType === LawyerType.company) {
      try {
        const {data} = await updateCompany({
          variables: {
            research: newData,
          },
        });
        if (data?.updateCompany) {
          dispatch(
            updateUser({
              ...user,
              research: data?.updateCompany?.research,
            }),
          );
          Alert.success('تمت العملية بنجاح');
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const {data} = await updateLawyer({
          variables: {
            research: newData,
          },
        });
        if (data?.updateLawyer) {
          dispatch(
            updateUser({
              ...user,
              research: data?.updateLawyer?.research,
            }),
          );
          Alert.success('تمت العملية بنجاح');
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };
  return (
    <EditAccountWrapper>
      <RowExtended>
        <ProfileTitle> الأبحاث والمصنفات</ProfileTitle>
        {show && (
          <Col
            onClick={() => {
              if (
                (!user?.membership?.company_privileges?.research &&
                  user?.type === LawyerType.company) ||
                (!user?.membership?.lawyer_privileges?.research &&
                  user?.type !== LawyerType.company)
              ) {
                return notAvailable();
              }
              setAdd(true);
              setShow(false);
            }}>
            <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
            <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
              أضف بحث جديد
            </AddSectionText>
          </Col>
        )}
      </RowExtended>
      <div>
        {(research || [])?.map((field, index: number | undefined) => {
          return (
            <div>
              <Label>
                <Detail noPointer>
                  <A>
                    {field?.url ? (
                      <Link href={field?.url} target="_blank">
                        <CustomText padding={'3px'} color={Colors.seaBlue}>
                          {field?.label}
                        </CustomText>
                      </Link>
                    ) : (
                      <CustomText padding={'3px'}>{field?.label}</CustomText>
                    )}
                  </A>
                </Detail>
                <IconsDiv>
                  <MdDelete
                    size={19}
                    color={Colors.red}
                    onClick={() => handledCertificateModal(index)}
                  />
                </IconsDiv>
              </Label>
            </div>
          );
        })}
      </div>
      {add && (
        <div>
          <Inputs>
            <StyledInput
              placeholder={'عنوان البحث'}
              width={'60%'}
              border
              name="label"
              error={!!(errors.label && touched.label)}
              onChange={handleChange}
            />
            <CheckBoxWrapper>
              <CheckBox
                type={'checkbox'}
                id={'AdvancedSearch'}
                width={'10px'}
                onChange={() => setCheckBox(!checkBox)}
                checked={checkBox}
              />
              <CheckBoxLabel htmlFor={'AdvancedSearch'}>
                رابط البحث المنشور
              </CheckBoxLabel>
            </CheckBoxWrapper>
            {checkBox && (
              <StyledInput
                placeholder={'رابط البحث'}
                width={'60%'}
                border
                name={'url'}
                error={!!errors.url}
                onChange={handleChange}
              />
            )}
            {loadingCompanyUpdate || loadingLawyerUpdate ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <ButtonsRow>
                <CustomButton height={'33px'} width={'30%'} orangeBg>
                  <ButtonText
                    color={Colors.white}
                    weight={'400'}
                    onClick={() => {
                      handleSubmit();
                    }}>
                    حفظ
                  </ButtonText>
                </CustomButton>
                <CancelButton
                  height={'33px'}
                  bgColor={Colors.white}
                  withBorder
                  width={'30%'}>
                  <ButtonText
                    weight={'400'}
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                    }}>
                    إلغاء
                  </ButtonText>
                </CancelButton>
              </ButtonsRow>
            )}
          </Inputs>
        </div>
      )}
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleRemoveField(removeId);
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default Researches;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledInput = styled(CustomInput)<{error?: boolean}>`
  border: ${props =>
    props.error ? `1px solid ${Colors.red}` : `1px solid ${Colors.borderGray}`};
  padding: 12px 10px;
  outline: none;
  &::placeholder {
    padding-right: 15px;
  }
`;
const ProfileTitle = styled(CustomText)`
  width: 430px;
  /* @media (max-width: 884px) {
    width: 90%;
  } */
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Inputs = styled.div`
  width: 93%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
export const TextArea = styled.textarea<{error?: boolean}>`
  resize: none;
  height: 150px;
  width: 100%;
  font-size: 16px;
  border-radius: 2.5px;
  border: ${props =>
    props.error ? `2px solid ${Colors.red}` : `2px solid ${Colors.borderGray}`};
  outline: none;
  margin-top: 10px;
  font-weight: 400;
  &::placeholder {
    margin-top: 20px;
    padding-right: 20px;
    color: ${Colors.lightNavyBlue};
    font-size: 16px;
    font-weight: 400;
  }
`;
const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 230px;
  margin-right: 30px;
  cursor: pointer;
  /* @media (max-width: 768px) {
    justify-content: space-around;
  } */

  @media (max-width: 561px) {
    /* width: 70%; */
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
`;
const AddSectionText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 15px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
  /* margin-right: -50px; */
`;
const Link = styled.a``;
