import gql from 'graphql-tag';
import {COMPANY_FRAGMENT, LAWYER_FRAGMENT} from './SignUp';

export const LOGIN = gql`
  mutation login(
    $email: String
    $password: String
    $facebook_id: String
    $google_id: String
    $apple_id: String
    $linkedin_id: String
  ) {
    login(
      loginInput: {
        email: $email
        password: $password
        facebook_id: $facebook_id
        google_id: $google_id
        apple_id: $apple_id
        linkedin_id: $linkedin_id
      }
    ) {
      ...LawyerFragment
    }
  }
  ${LAWYER_FRAGMENT}
`;
export const COMPANY_LOGIN = gql`
  mutation companyLogin($email: String!, $password: String!) {
    companyLogin(companyLoginInput: {email: $email, password: $password}) {
      ...CompanyFragment
    }
  }
  ${COMPANY_FRAGMENT}
`;
