import React from 'react';
import {Alert, Loader} from 'rsuite';
import {LoaderContainer} from './Account/Components/EditAccountCompany';
import {MakePaymentAction} from '../PaymentHelper';
import {useApolloClient, useQuery} from '@apollo/client';
import {FIND_MEMBERSHIPS} from './Auth/GraphQl/SignUp';
import {Membership} from '../Action/types';
import {useParams} from 'react-router-dom';
import i18n from '../I18next/I18n';

function Payment() {
  const params = useParams<{
    accountToken: string;
    membershipId: string;
    accountId: string;
  }>();
  const client = useApolloClient();
  const {data: memberships} = useQuery<{
    findAllMemberships: Membership[];
  }>(FIND_MEMBERSHIPS, {
    variables: {
      id: params.accountId && params.accountId,
    },
    fetchPolicy: 'network-only',
    onError: error => {
      Alert.error(i18n.t('Something went wrong please try again later'));
    },
    onCompleted: () => {
      const selectedMembership = memberships?.findAllMemberships.find(
        membership => membership?.id === params?.membershipId,
      );

      if (params?.accountToken && params?.membershipId && selectedMembership) {
        MakePaymentAction({
          client,
          accountToken: decodeURIComponent(params.accountToken),
          input: {
            cart_currency: 'JOD',
            dynamic_redirection: 'home',
            membership_id: params?.membershipId,
            cart_amount: selectedMembership?.new_cost
              ? selectedMembership.new_cost?.toString()
              : selectedMembership?.cost?.toString(),
          },
        });
      }
    },
  });

  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
}

export default Payment;
