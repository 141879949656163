import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  CustomTextButton,
} from '../../Components/Common';
import Colors from '../Theme/Colors';
import Fonts from '../../Theme/Fonts';
import {BiLock} from 'react-icons/bi';
import {FiEdit} from 'react-icons/fi';
import {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
// import {parsePhoneNumber} from 'libphonenumber-js';
import {Alert, Loader, Modal} from 'rsuite';
import {Confirm, PrivacyModal, LoaderContainer} from './SignUp';
import {COMPANY_LOGIN, LOGIN} from './GraphQl/Login';
import {useMutation} from '@apollo/client';
import {useDispatch} from 'react-redux';
import {setToken, updateUser, logIn, setUserTypeRedux} from '../../Action';
import {useHistory} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import moment from 'moment';
import {LawyerType} from '../../Action/types';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {PasswordIconInputContainer, PasswordInput} from './ResetPassword';
import {ReactFacebookLoginInfo} from 'react-facebook-login';
import {FACEBOOK_ID, GOOGLE_API_KEY} from '../../Configs';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin, {GoogleLoginResponse} from 'react-google-login';

export interface LogInProps {
  rememberMe: boolean;
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
}
const Login: React.FC<LogInProps> = ({rememberMe, setRememberMe}) => {
  const [userType, setUserType] = useState('lawyer');
  const [, setCookie] = useCookies(['rememberMeCookie']);

  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const [passVisibility, setpassVisibility] = useState(false);

  const [login, {loading: loginLoading}] = useMutation(LOGIN);
  const [companyLogin, {loading: companyLoggingIn}] =
    useMutation(COMPANY_LOGIN);
  const dispatch = useDispatch();
  const history = useHistory();
  const ValidationSchema = Yup.object().shape(
    userType === 'lawyer'
      ? {
          emailOrPhone: Yup.string().required(
            'رقم الهاتف أو البريد الإلكتروني  خاطئ',
          ),
          password: Yup.string()
            .min(8)
            .required('يجب ان تتكون كلمة المرور من 8 خانات على الأقل'),
        }
      : {
          email: Yup.string()
            .email('البريد الإلكتروني خاطئ')
            .required('يجب ادخال البريد الإلكتروني '),
          password: Yup.string()
            .min(8)
            .required('يجب ان تتكون كلمة المرور من 8 خانات على الأقل'),
        }, // the empty object to be used for company int.
  );
  const {
    errors,
    touched,
    values,
    handleChange,
    handleSubmit,
    // setFieldError,
    resetForm,
  } = useFormik({
    initialValues: {
      emailOrPhone: '',
      email: '',
      password: '',
    },
    validationSchema: ValidationSchema,

    onSubmit: async submittedValues => {
      if (userType === 'lawyer') {
        // const emailLogin = !/^-?[\d.]+(?:e-?\d+)?$/.test(values.emailOrPhone);

        try {
          //   if (!emailLogin) {
          //     const phoneNumber = parsePhoneNumber(
          //       submittedValues.emailOrPhone,
          //       'JO',
          //     );

          //     if (!phoneNumber.isValid()) {
          //       setFieldError('phone', 'رقم الهاتف الذي ادخلته غير صحيح');
          //     }
          //     handleLawyerLogin({
          //       ...submittedValues,
          //       phone: phoneNumber.number as string,
          //     });
          //   } else {
          handleLawyerLogin({
            ...submittedValues,
            email: submittedValues.emailOrPhone,
          });
          // }
        } catch (err) {
          setModal({
            isOn: true,
            message: err,
          });
        }
      } else {
        try {
          handleCompanyLogin({
            email: submittedValues.email,
            password: submittedValues.password,
          });
        } catch (err) {
          setModal({
            isOn: true,
            message: err,
          });
        }
      }
    },
  });
  const handleCompanyLogin = async (inputs: {
    email?: string;
    password?: string;
    facebook_id?: string;
    google_id?: string;
  }) => {
    try {
      const {data} = await companyLogin({
        variables: {
          ...inputs,
        },
      });
      if (data) {
        const {token, is_email_confirmed, ...companyData} = data?.companyLogin;
        if (is_email_confirmed) {
          dispatch(setToken(token));
          dispatch(updateUser({rememberMe, ...companyData}));
          dispatch(setUserTypeRedux(LawyerType.company));
          dispatch(logIn());
          if (rememberMe) {
            setCookie('rememberMeCookie', 'month', {
              expires: moment(Date()).add(1, 'month').toDate(),
            });
          } else {
            setCookie('rememberMeCookie', 'day', {
              expires: moment(Date()).add(1, 'd').toDate(),
            });
          }
          setRememberMe(false);
          history.push('/');
        } else {
          history.push({
            pathname: `/verification/verifyuser/${companyData?.email}/company`,
            state: {email: companyData?.email},
          });
        }
      }
    } catch (err: any) {
      if (err.message === 'Requires Admin Approval To activate Account') {
        setModal({
          isOn: true,
          message: 'الحساب يتطلب موافقة مسؤول النظام قبل الاستخدام',
        });
      } else {
        setModal({
          isOn: true,
          message: 'البريد الالكتروني او كلمة السر غير صحيحة',
        });
      }
    }
  };
  const handleLawyerLogin = async (inputs: {
    email?: String;
    phone?: String;
    password?: String;
    facebook_id?: string;
    google_id?: string;
  }) => {
    try {
      const data = await login({
        variables: {
          ...inputs,
        },
      });
      const {token, is_phone_confirmed, is_email_confirmed, ...lawyerData} =
        data?.data?.login;
      if (is_email_confirmed || is_phone_confirmed) {
        dispatch(setToken(token));
        dispatch(updateUser({rememberMe, ...lawyerData}));
        dispatch(logIn());
        if (lawyerData.type) {
          dispatch(setUserTypeRedux(lawyerData.type));
        }
        if (rememberMe) {
          setCookie('rememberMeCookie', 'month', {
            expires: moment(Date()).add(1, 'month').toDate(),
          });
        } else {
          setCookie('rememberMeCookie', 'day', {
            expires: moment(Date()).add(1, 'd').toDate(),
          });
        }
        setRememberMe(false);
        history.push('/');
      } else {
        history.push({
          pathname: `/verification/verifyuser/${lawyerData?.email}/lawyer`,
          state: {phone: lawyerData?.phone, lawyerEmail: lawyerData?.email},
        });
      }
    } catch (err: any) {
      if (err.message === 'Requires Admin Approval To activate Account') {
        setModal({
          isOn: true,
          message: 'الحساب يتطلب موافقة مسؤول النظام قبل الاستخدام',
        });
      } else {
        setModal({
          isOn: true,
          message: 'البريد الالكتروني او كلمة السر غير صحيحة',
        });
      }
    }
  };
  return (
    <LogInContainer>
      <TabsContainer>
        <Tab
          current={userType === 'company'}
          onClick={() => {
            setUserType('company');
            resetForm();
          }}>
          شركة
        </Tab>
        <Tab
          current={userType === 'lawyer'}
          onClick={() => {
            setUserType('lawyer');
            resetForm();
          }}>
          أفراد
        </Tab>
      </TabsContainer>
      <Wrapper>
        <InnerContainer>
          <PreviewWrapper>
            <LeftSection>
              <SignUp
                color={Colors.seaBlue}
                weight={'500'}
                size={15}
                onClick={() => history.push('/sign-up')}>
                سجل في عدل
              </SignUp>
              <SignUpText weight={'500'} color={Colors.darkGray} size={15}>
                لست مسجل؟
              </SignUpText>
            </LeftSection>
            <Divider />
            <TextWrapper>
              <SignIn size={21} color={Colors.darkGray}>
                تسجيل الدخول
              </SignIn>
              <Inputs>
                {userType === 'lawyer' ? (
                  <>
                    <IconInputContianer
                      error={!!(errors.emailOrPhone && touched.emailOrPhone)}>
                      <StyledInput
                        name="emailOrPhone"
                        placeholder={'البريد الالكتروني'}
                        value={values.emailOrPhone}
                        width={'90%'}
                        onChange={handleChange}
                      />
                      <FiEdit size={12} color={Colors.darkGray} />
                    </IconInputContianer>
                    {!!(errors.emailOrPhone && touched.emailOrPhone) && (
                      <>
                        {values.emailOrPhone ? (
                          <ErrorText size={10}>
                            رقم الهاتف أو البريد الإلكتروني غير صحيح{' '}
                          </ErrorText>
                        ) : (
                          <ErrorText size={10}>
                            الرجاء ادخال البريد الالكتروني
                          </ErrorText>
                        )}
                      </>
                    )}

                    {/* <IconInputContianer
                      error={!!(errors.emailOrPhone && touched.emailOrPhone)}> */}
                    {/* <StyledInput
                        type={'email'}
                        name="email"
                        value={values.email}
                        placeholder={'بريدك الإلكتروني'}
                        width={'90%'}
                        onChange={handleChange}
                        error={!!(errors.email && touched.email)}
                      /> */}
                    {/* <FiEditIcon size={12} color={Colors.darkGray} /> */}
                    {/* </IconInputContianer> */}
                  </>
                ) : (
                  <>
                    <IconInputContianer
                      error={!!(errors.email && touched.email)}>
                      <StyledInput
                        type={'email'}
                        value={values.email}
                        name="email"
                        placeholder={'البريد الالكتروني'}
                        width={'90%'}
                        onChange={handleChange}
                      />
                      <FiEdit size={12} color={Colors.darkGray} />
                    </IconInputContianer>
                    {!!(errors.email && touched.email) && (
                      <>
                        {values.email ? (
                          <ErrorText size={10}>
                            الرجاء ادخال بريد الالكتروني صحيح
                          </ErrorText>
                        ) : (
                          <ErrorText size={10}>
                            الرجاء ادخال البريد الالكتروني{' '}
                          </ErrorText>
                        )}
                      </>
                    )}
                  </>
                )}
                {/* <IconInputContianer
                  error={!!(errors.password && touched.password)}>
                  <StyledInput
                    type="password"
                    name="password"
                    value={values.password}
                    placeholder={'كلمة السر'}
                    width={'90%'}
                    onChange={handleChange}
                  />
                  <BiLock size={15} color={Colors.darkGray} />
                </IconInputContianer> */}

                <PasswordIconInputContainer
                  error={!!(errors.password && touched.password)}>
                  <PasswordInputContianer>
                    <PasswordInput
                      type={passVisibility ? 'text' : 'password'}
                      name="password"
                      placeholder={'كلمة السر '}
                      width={'100%'}
                      value={values.password}
                      onChange={handleChange}
                    />
                    <BiLock size={15} color={Colors.darkGray} />
                  </PasswordInputContianer>
                  <AiOutlineEyeInvisible
                    size={passVisibility ? '0' : '20'}
                    color={Colors.darkGray}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setpassVisibility(!passVisibility);
                    }}
                  />
                  <AiOutlineEye
                    size={passVisibility ? '20' : '0'}
                    color={Colors.darkGray}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setpassVisibility(!passVisibility);
                    }}
                  />
                </PasswordIconInputContainer>

                {!!(errors.password && touched.password) && (
                  <>
                    {values.password ? (
                      <ErrorText size={10}>
                        يجب ان تتكون كلمة السر من 8 خانات على الاقل
                      </ErrorText>
                    ) : (
                      <ErrorText size={10}>الرجاء ادخال كلمة السر </ErrorText>
                    )}
                  </>
                )}
              </Inputs>
              <RowWrapper>
                <ForgetPasswordText
                  size={12}
                  weight={'500'}
                  color={Colors.lightGray}
                  onClick={() => history.push(`/forgot-password/${userType}`)}>
                  {' '}
                  هل نسيت كلمة السر؟
                </ForgetPasswordText>
                <ColumnWrapper>
                  <Label htmlFor={'rememberMe'}>تذكرني</Label>
                  <CheckBox
                    onChange={() => setRememberMe(!rememberMe)}
                    type={'checkbox'}
                    id={'rememberMe'}
                    width={'10px'}
                  />
                </ColumnWrapper>
              </RowWrapper>
              {loginLoading || companyLoggingIn ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <Register
                  type="submit"
                  orangeBg
                  width={'100%'}
                  onClick={() => {
                    handleSubmit();
                  }}>
                  <CustomTextButton color={Colors.white} size={12}>
                    تسجيل دخول{' '}
                  </CustomTextButton>
                </Register>
              )}
              {userType === 'lawyer' ? (
                <>
                  <CenteredRow>
                    <Or color={Colors.lightGray} size={12}>
                      أو{' '}
                    </Or>
                    <CustomText
                      color={Colors.lightGray}
                      size={12}
                      weight={'500'}>
                      سجل عن طريق شبكات التواصل الاجتماعي{' '}
                    </CustomText>
                  </CenteredRow>
                  <SocialsWrapper>
                    <GoogleLogin
                      clientId={GOOGLE_API_KEY}
                      onSuccess={async user => {
                        const {googleId} = user as GoogleLoginResponse;
                        if (!googleId) {
                          return Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                        }
                        const inputs = {
                          google_id: googleId,
                        };
                        try {
                          handleLawyerLogin(inputs);
                        } catch (err) {
                          setModal({
                            isOn: true,
                            message: err,
                          });
                        }
                      }}
                      onFailure={({error, details}) => {
                        if (
                          error !== 'popup_closed_by_user' &&
                          error !== 'idpiframe_initialization_failed'
                        )
                          Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                      }}
                      render={renderProps => (
                        <BlueButton
                          lightBlueBg
                          width={'43%'}
                          onClick={() => renderProps.onClick()}>
                          <CustomTextButton color={Colors.white} size={12}>
                            تسجيل مع غوغل
                          </CustomTextButton>
                        </BlueButton>
                      )}
                    />

                    <FacebookLogin
                      appId={FACEBOOK_ID}
                      fields="name,email"
                      disableMobileRedirect
                      reAuthenticate
                      callback={async (res: any) => {
                        const user = res as ReactFacebookLoginInfo;
                        if (!user) {
                          return Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                        }
                        const inputs = {
                          facebook_id: user?.id,
                        };
                        try {
                          handleLawyerLogin(inputs);
                        } catch (err) {
                          setModal({
                            isOn: true,
                            message: 'حدث خطأ ما! يرجى المحاولة مرة أخرى.',
                          });
                        }
                      }}
                      onFailure={(error: any) => {
                        if (error.status !== 'unknown')
                          Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                      }}
                      render={renderProps => (
                        <BlueButton
                          darkBlueBg
                          width={'43%'}
                          onClick={() => renderProps.onClick()}>
                          <CustomTextButton color={Colors.white} size={12}>
                            تسجيل مع فيسبوك
                          </CustomTextButton>
                        </BlueButton>
                      )}
                    />
                  </SocialsWrapper>
                </>
              ) : (
                <Empty />
              )}
            </TextWrapper>
          </PreviewWrapper>
        </InnerContainer>
      </Wrapper>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => setModal({isOn: false, message: ''})}>
            <CustomTextButton color={Colors.white} size={12}>
              حسنا
            </CustomTextButton>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
    </LogInContainer>
  );
};

export default Login;

export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const SignUp = styled(CustomText)`
  margin-right: 3px;
  cursor: pointer;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;
export const SignIn = styled(CustomText)`
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin: auto;
    font-size: 18px;
  }
`;

export const Inputs = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
`;

export const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 470px;
  margin-top: -20px;
  margin-bottom: -20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const PreviewWrapper = styled.div`
  margin: 100px 0;
  width: 73%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 1px 1px ${Colors.borderGray};
  padding: 20px 40px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 87%;
  }
  @media (min-width: 768px) {
    padding-left: 0px;
  }
  @media (max-width: 450px) {
    padding: 20px 0px;
  }
`;

export const StyledInput = styled(CustomInput)`
  direction: rtl;
  margin-top: 11px;
  border-bottom: none;
  width: 90%;
  outline: none;
  margin-left: -18px;
  @media (max-width: 1060px) {
    padding: 10px 0px 10px 0px;
    font-size: 13px;
  }
  &::-webkit-input-placeholder {
    padding-right: 20px;
  }
`;

export const IconInputContianer = styled.div<{error?: boolean}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const CheckBox = styled(CustomInput)`
  margin: 6px;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  @media (min-width: 768px) {
    margin-left: 30px;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 50%;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    text-align: center;
    padding: 10px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: end;
  align-items: center;
  @media (max-width: 768px) {
    direction: rtl;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
export const Label = styled.label`
  color: ${Colors.lightGray};
  font-size: 13px;
  margin: 4px 5px 0px 0px;
  font-family: ${Fonts.regular};
  font-weight: 500;
`;
export const ForgetPasswordText = styled(CustomText)`
  cursor: pointer;
  border-bottom: 1px ${Colors.borderGray};
  margin: 4px 92px 4px 0px;
  text-align: right;
  :hover {
    color: ${Colors.darkGray};
  }
  @media (max-width: 768px) {
    margin: auto;
    padding-top: 10px;
  }
`;

export const Register = styled(CustomButton)`
  margin-top: 20px;
  width: 95%;
  align-self: center;
  padding: 6px;
  cursor: pointer;
`;
export const CenteredRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 15px;
`;
export const Or = styled(CustomText)`
  margin-bottom: 15px;
`;
export const SocialsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: ${Fonts.regular};
`;
const Tab = styled.div<{
  current?: boolean;
}>`
  border-bottom: 2px solid
    ${props => (props.current ? Colors.softBlue : Colors.OffWhite)};
  padding: 0px 35px 5px;
  cursor: pointer;
`;
const LogInContainer = styled.div``;

const Empty = styled.div`
  height: 118px;
`;

const ErrorText = styled(CustomText)`
  text-align: right;
  font-size: 10;
  margin: auto;
  padding-top: 10px;
  color: red;
`;
const SignUpText = styled(CustomText)`
  margin: 0px;
  text-align: center;
  @media (max-width: 480px) {
    font-size: 13px;
  }
`;

const BlueButton = styled(CustomButton)`
  width: 45%;
  padding: 4px;
  @media (max-width: 450px) {
    align-self: center;
    width: 65%;
  }
`;

export const PasswordInputContianer = styled.div<{error?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;
