import React, {useEffect, useState} from 'react';
import {
  CustomButton,
  CustomInput,
  CustomText,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import styled from 'styled-components';
import {
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaWhatsapp,
  FaGoogle,
  FaTelegram,
  FaYoutube,
  FaGlobe,
  FaMapMarkerAlt,
  FaFax,
} from 'react-icons/fa';
import {TbDeviceLandlinePhone} from 'react-icons/tb';
import {AiOutlineClose} from 'react-icons/ai';
import {useDispatch, useSelector} from 'react-redux';
import {useMutation} from '@apollo/client';
import {AccountReducer, LawyerType} from '../../../Action/types';
import {UPDATE_COMPANY, UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {RootState} from '../../../Reducer';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert} from 'rsuite';
import {updateUser} from '../../../Action';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {ReactFacebookLoginInfo} from 'react-facebook-login';
import {FACEBOOK_ID, GOOGLE_API_KEY} from '../../../Configs';
import GoogleLogin, {GoogleLoginResponse} from 'react-google-login';
import i18n from '../../../I18next/I18n';
import {validatePhoneNumber} from '../../../helpers';

export interface CustomSocialLinksProps {
  linked?: string;
  name?: string;
  icon?: string;
  text?: string;
  onClose?: () => void;
  onPress?: () => void;
}

const CustomSocialLinks = ({
  linked,
  name,
  icon,
  text,
  onPress,
  onClose,
}: CustomSocialLinksProps) => {
  const dispatch = useDispatch();
  const [updateLawyer] = useMutation(UPDATE_LAWYER);
  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const [hideIcon, setHideIcon] = useState<boolean>(false);
  const [linkedAccount, setLinkedAccount] = useState<any>();

  useEffect(() => {
    setLinkedAccount(linked);
  }, [linked]);

  const {user, userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const ValidationSchema = Yup.object().shape(
    icon === 'linkedin'
      ? {linkedin: Yup.string().required('الحساب لا يوجد')}
      : icon === 'twitter'
      ? {twitter_handle: Yup.string().required('الحساب لا يوجد')}
      : icon === 'whatsapp'
      ? {whats_app_phone: Yup.string().required('رقم الهاتف خاطئ')}
      : icon === 'facebook'
      ? {facebookId: Yup.string().required('الحساب لا يوجد')}
      : icon === 'google_maps_link'
      ? {google_maps_link: Yup.string().required('لا يوجد رابط')}
      : icon === 'youtube'
      ? {youtube: Yup.string().required('الحساب لا يوجد')}
      : icon === 'telegram'
      ? {telegram: Yup.string().required('الحساب لا يوجد')}
      : icon === 'fax'
      ? {fax: Yup.string().required('رقم الفاكس خاطئ')}
      : icon === 'land_line'
      ? {land_line: Yup.string().required('رقم الهاتف الارضي خاطئ')}
      : icon === 'website'
      ? {website: Yup.string().required('لا يوجد رابط')}
      : {instagramId: Yup.string().required('الحساب لا يوجد')},
  );

  const {touched, errors, values, handleChange, resetForm, handleSubmit} =
    useFormik({
      initialValues: {
        twitter_handle: '',
        whats_app_phone: '',
        linkedin: '',
        google_maps_link: '',
        website: '',
        youtube: '',
        telegram: '',
        fax: '',
        land_line: '',
      },
      validationSchema: ValidationSchema,

      onSubmit: async submittedValues => {
        const variables: {
          twitter_handle?: string;
          whats_app_phone?: string;
          linkedin_id?: string;
          google_maps_link?: string;
          website?: string;
          youtube?: string;
          telegram?: string;
          fax?: string;
          land_line?: string;
        } = {};

        const urlRegex =
          /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/g;

        const trimAndSetVariable = (
          key: keyof typeof variables,
          value: string,
        ) => {
          variables[key] = value ? value.trim() : undefined;
        };

        const validateURL = (key: keyof typeof variables): boolean => {
          if (!variables[key]) {
            // Return true if the URL value is empty or not provided
            return true;
          } else if (!variables[key]?.match(urlRegex)) {
            // Return false if the URL value exists but is not valid
            return false;
          }
          // Return true if the URL value is valid
          return true;
        };

        trimAndSetVariable('twitter_handle', submittedValues.twitter_handle);
        trimAndSetVariable('linkedin_id', submittedValues.linkedin);
        trimAndSetVariable(
          'google_maps_link',
          submittedValues.google_maps_link,
        );
        trimAndSetVariable('website', submittedValues.website);
        trimAndSetVariable('youtube', submittedValues.youtube);

        if (!validateURL('google_maps_link')) {
          return Alert.error(i18n.t('Please enter a valid link'));
        }

        if (!validateURL('website')) {
          return Alert.error(i18n.t('Please enter a valid link'));
        }

        if (!validateURL('youtube')) {
          return Alert.error(i18n.t('Please enter a valid link'));
        }

        let parsedWhatsAppNumber;
        if (submittedValues.whats_app_phone !== '') {
          const phoneNumber = validatePhoneNumber(
            submittedValues.whats_app_phone.toString(),
            true,
          );
          if (phoneNumber.isValid()) {
            parsedWhatsAppNumber = phoneNumber.number as string;
            variables.whats_app_phone = parsedWhatsAppNumber;
          } else {
            return Alert.error(i18n.t('Phone number is incorrect'));
          }
        }

        let parsedTelegramNumber;
        if (submittedValues.telegram !== '') {
          const phoneNumber = validatePhoneNumber(
            submittedValues.telegram.toString(),
            true,
          );
          if (phoneNumber.isValid()) {
            parsedTelegramNumber = phoneNumber.number as string;
            variables.telegram = parsedTelegramNumber;
          } else {
            return Alert.error(i18n.t('Phone number is incorrect'));
          }
        }

        let parsedFaxNumber;
        if (submittedValues.fax !== '') {
          const phoneNumber = validatePhoneNumber(
            submittedValues.fax.toString(),
            true,
          );

          if (phoneNumber.isValid()) {
            parsedFaxNumber = phoneNumber.number as string;
            variables.fax = parsedFaxNumber;
          } else {
            return Alert.error(i18n.t('fax number is incorrect'));
          }
        }

        let parsedLandLineNumber;
        if (submittedValues.land_line !== '') {
          const phoneNumber = validatePhoneNumber(
            submittedValues.land_line.toString(),
            true,
          );
          if (phoneNumber.isValid()) {
            parsedLandLineNumber = phoneNumber.number as string;
            variables.land_line = parsedLandLineNumber;
          } else {
            return Alert.error(i18n.t('Phone number is incorrect'));
          }
        }
        try {
          // eslint-disable-next-line no-useless-escape
          const twitterRegex = /^(\@)?([a-z0-9_]{1,15})$/i;
          const linkedInRegex = /[A-z0-9_-]+/;
          if (
            variables.twitter_handle &&
            variables.twitter_handle?.match(urlRegex)
          ) {
            return Alert.error(i18n.t('Please enter the username'));
          }
          if (variables.linkedin_id && variables.linkedin_id?.match(urlRegex)) {
            return Alert.error(i18n.t('Please enter the username'));
          }
          if (
            variables.twitter_handle &&
            !variables.twitter_handle?.match(twitterRegex)
          ) {
            return Alert.error(i18n.t('Please enter a correct user name'));
          }
          if (
            variables.linkedin_id &&
            !variables.linkedin_id?.match(linkedInRegex)
          ) {
            return Alert.error(i18n.t('Please enter a correct user name'));
          }
          if (userType !== LawyerType.company) {
            const {data} = await updateLawyer({
              variables: variables,
            });
            if (data?.updateLawyer) {
              resetForm();
              setLinkedAccount('');
              const updatedData = data?.updateLawyer;
              dispatch(updateUser({...user, ...updatedData}));

              if (icon === 'twitter' || icon === 'linkedin') {
                const accountName =
                  submittedValues.twitter_handle || submittedValues.linkedin;
                Alert.success(`.${accountName} تم ادخال الحساب باسم`);
              } else if (parsedWhatsAppNumber) {
                Alert.success(
                  `تم ادخال حساب واتساب المقترن برقم الهاتف ${submittedValues.whats_app_phone}`,
                  5000,
                );
              } else if (parsedTelegramNumber) {
                Alert.success(
                  `تم ادخال حساب تيليجرام المقترن برقم الهاتف ${submittedValues.telegram}`,
                  5000,
                );
              } else {
                Alert.success(`تمت العملية بنحاح`, 5000);
              }
            }
          } else {
            const {data} = await updateCompany({
              variables: variables,
            });
            if (data?.updateCompany) {
              resetForm();
              setLinkedAccount('');
              const updatedData = data?.updateCompany;
              dispatch(updateUser({...user, ...updatedData}));

              if (icon === 'twitter' || icon === 'linkedin') {
                const accountName =
                  submittedValues.twitter_handle || submittedValues.linkedin;
                Alert.success(`.${accountName} تم ادخال حساب باسم`);
              } else if (parsedWhatsAppNumber) {
                Alert.success(
                  `تم ادخال حساب واتساب المقترن برقم الهاتف ${submittedValues.whats_app_phone}`,
                  5000,
                );
              } else if (parsedTelegramNumber) {
                Alert.success(
                  `تم ادخال حساب تيليجرام المقترن برقم الهاتف ${submittedValues.telegram}`,
                  5000,
                );
              } else {
                Alert.success(`تمت العملية بنحاح`, 5000);
              }
            }
          }
        } catch (err: any) {
          if (
            err?.message ===
              'Facebook Account Already Associated With Another Account' ||
            err?.message ===
              'Google Account Already Associated With Another Account' ||
            err?.message ===
              'Apple Account Already Associated With Another Account'
          ) {
            Alert.error(
              i18n.t(err?.message, {
                fallback: 'Something went wrong please try again later',
              }),
            );
          } else {
            Alert.error(i18n.t('Something went wrong'));
          }
        }
      },
    });

  const disConnect = async (
    pressed:
      | 'twitter'
      | 'whatsapp'
      | 'facebook'
      | 'google'
      | 'linkedin'
      | 'google_maps_link'
      | 'website'
      | 'youtube'
      | 'telegram'
      | 'fax'
      | 'land_line',
  ) => {
    const variables: {
      twitter_handle?: string;
      whats_app_phone?: string;
      facebook_id?: string;
      google_id?: string;
      linkedin_id?: string;
      google_maps_link?: string;
      website?: string;
      youtube?: string;
      telegram?: string;
      fax?: string;
      land_line?: string;
    } = {};

    if (pressed === 'twitter') {
      variables.twitter_handle = '';
    }
    if (pressed === 'whatsapp') {
      variables.whats_app_phone = '';
    }
    if (pressed === 'facebook') {
      variables.facebook_id = '';
    }
    if (pressed === 'google') {
      variables.google_id = '';
    }
    if (pressed === 'linkedin') {
      variables.linkedin_id = '';
    }
    if (pressed === 'google_maps_link') {
      variables.google_maps_link = '';
    }
    if (pressed === 'website') {
      variables.website = '';
    }
    if (pressed === 'youtube') {
      variables.youtube = '';
    }
    if (pressed === 'telegram') {
      variables.telegram = '';
    }

    if (pressed === 'fax') {
      variables.fax = '';
    }

    if (pressed === 'land_line') {
      variables.land_line = '';
    }
    try {
      if (userType !== LawyerType.company) {
        const {data} = await updateLawyer({
          variables: variables,
        });
        if (data?.updateLawyer) {
          const updatedData = data?.updateLawyer;
          dispatch(updateUser({...user, ...updatedData}));
          Alert.success(`تمت العملية بنحاح`);
        }
      } else {
        const {data} = await updateCompany({
          variables: variables,
        });
        if (data?.updateCompany) {
          const updatedData = data?.updateCompany;
          dispatch(updateUser({...user, ...updatedData}));
          Alert.success(`تمت العملية بنحاح`);
        }
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong'));
    }
  };

  return (
    <>
      <RowSpaceBetween>
        <Row>
          {icon === 'twitter' ? (
            <SocialContainer>
              <IconText hidden={hideIcon || !!errors.twitter_handle}>
                <IconsView>
                  <FaTwitter color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.twitter_handle ? (
                  <>
                    <TwitterConnectedText
                      color={Colors.darkGray}
                      weight={'400'}>
                      تم الادخال
                    </TwitterConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => {
                        disConnect('twitter');
                      }}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'facebook' ? (
            <SocialContainer>
              <IconText>
                <IconsView>
                  <FacebookIcon color={Colors.white} size={25} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.facebook_id ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الربط
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => {
                        disConnect('facebook');
                      }}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <>
                        <FacebookLogin
                          appId={FACEBOOK_ID}
                          fields="name,email"
                          disableMobileRedirect
                          reAuthenticate
                          callback={async (res: any) => {
                            const facebookUser = res as ReactFacebookLoginInfo;
                            if (!user) {
                              return Alert.error(
                                'Something went wrong! Please try again.',
                                5000,
                              );
                            }
                            try {
                              const {data} = await updateLawyer({
                                variables: {
                                  facebook_id: facebookUser.id,
                                },
                              });

                              if (data?.updateLawyer) {
                                const updatedData = data?.updateLawyer;
                                dispatch(updateUser({...user, ...updatedData}));
                                Alert.success(`تم الربط بحساب فيسبوك الخاص بك`);
                              }
                            } catch (err: any) {
                              if (
                                err?.message ===
                                  'Facebook Account Already Associated With Another Account' ||
                                err?.message ===
                                  'Google Account Already Associated With Another Account' ||
                                err?.message ===
                                  'Apple Account Already Associated With Another Account'
                              ) {
                                Alert.error(
                                  i18n.t(err?.message, {
                                    fallback:
                                      'Something went wrong please try again later',
                                  }),
                                );
                              } else {
                                Alert.error(i18n.t('Something went wrong'));
                              }
                            }
                          }}
                          onFailure={(error: any) => {
                            if (error.status !== 'unknown')
                              Alert.error(
                                'Something went wrong! Please try again.',
                                5000,
                              );
                          }}
                          render={renderProps => (
                            <CustomButton
                              onClick={() => renderProps.onClick()}
                              orangeBg
                              width={'100px'}
                              height={'33px'}>
                              {' '}
                              <ButtonText color={Colors.white} weight={'400'}>
                                {' '}
                                ربط
                              </ButtonText>{' '}
                            </CustomButton>
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'whatsapp' ? (
            <SocialContainer>
              <IconText hidden={hideIcon || !!errors.whats_app_phone}>
                <IconsView>
                  <FaWhatsapp color={Colors.white} size={20} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              {user?.whats_app_phone ? (
                <RowSpaceEvenly>
                  <ConnectedText color={Colors.darkGray} weight={'400'}>
                    تم الادخال
                  </ConnectedText>
                  <ConnectedCloseIcon
                    size={18}
                    color={Colors.lightGray}
                    onClick={() => {
                      disConnect('whatsapp');
                    }}
                  />
                </RowSpaceEvenly>
              ) : (
                <RowSpaceEvenly>
                  {icon !== linked && (
                    <CustomButton
                      onClick={() => {
                        setHideIcon(true);
                        onPress && onPress();
                      }}
                      orangeBg
                      width={'100px'}
                      height={'33px'}>
                      <ButtonText color={Colors.white} weight={'400'}>
                        ادخال
                      </ButtonText>
                    </CustomButton>
                  )}
                </RowSpaceEvenly>
              )}
            </SocialContainer>
          ) : icon === 'google' ? (
            <SocialContainer>
              <IconText>
                <IconsView>
                  <FaGoogle color={Colors.white} size={17} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              {user?.google_id ? (
                <RowSpaceEvenly>
                  <ConnectedText color={Colors.darkGray} weight={'400'}>
                    تم الربط
                  </ConnectedText>
                  <ConnectedCloseIcon
                    size={18}
                    color={Colors.lightGray}
                    onClick={() => {
                      disConnect('google');
                    }}
                  />
                </RowSpaceEvenly>
              ) : (
                <RowSpaceEvenly>
                  {icon !== linked && (
                    <GoogleLogin
                      clientId={GOOGLE_API_KEY}
                      onSuccess={async user => {
                        const {googleId} = user as GoogleLoginResponse;

                        if (!googleId) {
                          return Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                        }

                        try {
                          const {data} = await updateLawyer({
                            variables: {
                              google_id: googleId,
                            },
                          });
                          if (data?.updateLawyer) {
                            const updatedData = data?.updateLawyer;
                            dispatch(updateUser({...user, ...updatedData}));
                            Alert.success(`تم الربط بحساب جوجل الخاص بك`);
                          }
                        } catch (err: any) {
                          if (
                            err?.message ===
                              'Facebook Account Already Associated With Another Account' ||
                            err?.message ===
                              'Google Account Already Associated With Another Account' ||
                            err?.message ===
                              'Apple Account Already Associated With Another Account'
                          ) {
                            Alert.error(
                              i18n.t(err?.message, {
                                fallback:
                                  'Something went wrong please try again later',
                              }),
                            );
                          } else {
                            Alert.error(i18n.t('Something went wrong'));
                          }
                        }
                      }}
                      onFailure={({error, details}) => {
                        if (
                          error !== 'popup_closed_by_user' &&
                          error !== 'idpiframe_initialization_failed'
                        )
                          Alert.error(
                            'Something went wrong! Please try again.',
                            5000,
                          );
                      }}
                      render={renderProps => (
                        <CustomButton
                          onClick={() => renderProps.onClick()}
                          orangeBg
                          width={'100px'}
                          height={'33px'}>
                          <ButtonText color={Colors.white} weight={'400'}>
                            ربط
                          </ButtonText>
                        </CustomButton>
                      )}
                    />
                  )}
                </RowSpaceEvenly>
              )}
            </SocialContainer>
          ) : icon === 'linkedin' ? (
            <SocialContainer>
              <IconText hidden={hideIcon}>
                <IconsView>
                  <FaLinkedinIn color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.linkedin_id ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الادخال
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => disConnect('linkedin')}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'telegram' ? (
            <SocialContainer>
              <IconText hidden={hideIcon}>
                <IconsView>
                  <FaTelegram color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.telegram ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الادخال
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => disConnect('telegram')}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'youtube' ? (
            <SocialContainer>
              <IconText hidden={hideIcon}>
                <IconsView>
                  <FaYoutube color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.youtube ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الادخال
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => disConnect('youtube')}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'website' ? (
            <SocialContainer>
              <IconText hidden={hideIcon}>
                <IconsView>
                  <FaGlobe color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.website ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الادخال
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => disConnect('website')}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'google_maps_link' ? (
            <SocialContainer>
              <IconText hidden={hideIcon}>
                <IconsView>
                  <FaMapMarkerAlt color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              <RowSpaceEvenly>
                {user?.google_maps_link ? (
                  <>
                    <ConnectedText color={Colors.darkGray} weight={'400'}>
                      تم الادخال
                    </ConnectedText>
                    <ConnectedCloseIcon
                      size={18}
                      color={Colors.lightGray}
                      onClick={() => disConnect('google_maps_link')}
                    />
                  </>
                ) : (
                  <>
                    {icon !== linked && (
                      <CustomButton
                        onClick={() => {
                          setHideIcon(true);
                          onPress && onPress();
                        }}
                        orangeBg
                        width={'100px'}
                        height={'33px'}>
                        <ButtonText color={Colors.white} weight={'400'}>
                          ادخال
                        </ButtonText>
                      </CustomButton>
                    )}
                  </>
                )}
              </RowSpaceEvenly>
            </SocialContainer>
          ) : icon === 'fax' ? (
            <SocialContainer>
              <IconText hidden={hideIcon || !!errors.fax}>
                <IconsView>
                  <FaFax color={Colors.white} size={14} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              {user?.fax ? (
                <RowSpaceEvenly>
                  <ConnectedText color={Colors.darkGray} weight={'400'}>
                    تم الادخال
                  </ConnectedText>
                  <ConnectedCloseIcon
                    size={18}
                    color={Colors.lightGray}
                    onClick={() => {
                      disConnect('fax');
                    }}
                  />
                </RowSpaceEvenly>
              ) : (
                <RowSpaceEvenly>
                  {icon !== linked && (
                    <CustomButton
                      onClick={() => {
                        setHideIcon(true);
                        onPress && onPress();
                      }}
                      orangeBg
                      width={'100px'}
                      height={'33px'}>
                      <ButtonText color={Colors.white} weight={'400'}>
                        ادخال
                      </ButtonText>
                    </CustomButton>
                  )}
                </RowSpaceEvenly>
              )}
            </SocialContainer>
          ) : icon === 'land_line' ? (
            <SocialContainer>
              <IconText hidden={hideIcon || !!errors.land_line}>
                <IconsView>
                  <TbDeviceLandlinePhone color={Colors.white} size={16} />
                </IconsView>
                <SocialText size={15} color={Colors.darkGray}>
                  {text}
                </SocialText>
              </IconText>
              {user?.land_line ? (
                <RowSpaceEvenly>
                  <ConnectedText color={Colors.darkGray} weight={'400'}>
                    تم الادخال
                  </ConnectedText>
                  <ConnectedCloseIcon
                    size={18}
                    color={Colors.lightGray}
                    onClick={() => {
                      disConnect('land_line');
                    }}
                  />
                </RowSpaceEvenly>
              ) : (
                <RowSpaceEvenly>
                  {icon !== linked && (
                    <CustomButton
                      onClick={() => {
                        setHideIcon(true);
                        onPress && onPress();
                      }}
                      orangeBg
                      width={'100px'}
                      height={'33px'}>
                      <ButtonText color={Colors.white} weight={'400'}>
                        ادخال
                      </ButtonText>
                    </CustomButton>
                  )}
                </RowSpaceEvenly>
              )}
            </SocialContainer>
          ) : null}
        </Row>
        {icon === linkedAccount && (
          <Linked fixPosition={hideIcon}>
            <InputView>
              {icon === 'whatsapp' ||
              icon === 'telegram' ||
              icon === 'fax' ||
              icon === 'land_line' ? (
                <StyledInput
                  name={name}
                  placeholder={
                    name === 'fax' ? 'أدخل رقم الفاكس' : 'أدخل رقم الهاتف'
                  }
                  width={'145px'}
                  type="number"
                  border
                  value={
                    name === 'twitter_handle'
                      ? values.twitter_handle
                      : name === 'whats_app_phone'
                      ? values.whats_app_phone
                      : name === 'telegram'
                      ? values.telegram
                      : name === 'fax'
                      ? values.fax
                      : name === 'land_line'
                      ? values.land_line
                      : values.linkedin
                  }
                  onChange={handleChange}
                  error={
                    name === 'twitter_handle'
                      ? !!(errors.twitter_handle && touched.twitter_handle)
                      : name === 'whats_app_phone'
                      ? !!(errors.whats_app_phone && touched.whats_app_phone)
                      : name === 'telegram'
                      ? !!(errors.telegram && touched.telegram)
                      : name === 'fax'
                      ? !!(errors.fax && touched.fax)
                      : name === 'land_line'
                      ? !!(errors.land_line && touched.land_line)
                      : !!(errors.linkedin && touched.linkedin)
                  }
                />
              ) : icon === 'twitter' || icon === 'linkedin' ? (
                <StyledInput
                  name={name}
                  placeholder={'أدخل أسم الحساب'}
                  width={'145px'}
                  border
                  value={
                    name === 'twitter_handle'
                      ? values.twitter_handle
                      : name === 'whats_app_phone'
                      ? values.whats_app_phone
                      : values.linkedin
                  }
                  onChange={handleChange}
                  error={
                    name === 'twitter_handle'
                      ? !!(errors.twitter_handle && touched.twitter_handle)
                      : name === 'whats_app_phone'
                      ? !!(errors.whats_app_phone && touched.whats_app_phone)
                      : !!(errors.linkedin && touched.linkedin)
                  }
                />
              ) : (
                <StyledInput
                  name={name}
                  placeholder={'أدخل الرابط'}
                  width={'145px'}
                  border
                  value={
                    name === 'website'
                      ? values.website
                      : name === 'google_maps_link'
                      ? values.google_maps_link
                      : values.youtube
                  }
                  onChange={handleChange}
                  error={
                    name === 'website'
                      ? !!(errors.website && touched.website)
                      : name === 'google_maps_link'
                      ? !!(errors.google_maps_link && touched.google_maps_link)
                      : !!(errors.youtube && touched.youtube)
                  }
                />
              )}
            </InputView>
            <SendText
              size={18}
              color={Colors.seaBlue}
              onClick={() => {
                setHideIcon(false);
                handleSubmit();
                onClose && onClose();
              }}>
              إرسال
            </SendText>
            <Icon
              size={18}
              color={Colors.lightGray}
              onClick={() => {
                setHideIcon(false);
                onClose && onClose();
                resetForm();
              }}
            />
          </Linked>
        )}
      </RowSpaceBetween>
    </>
  );
};

export default CustomSocialLinks;

const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 3rem;
  margin-left: 2rem;

  /*width: 100%; */
  @media (max-width: 350px) {
    margin-right: 1.5rem;
  }
  @media (max-width: 330px) {
    margin-right: 1.2rem;
  }
  /* @media(max-width: 425px){
    margin-right: 2rem;
    width: 80%;
    
  } */
`;
const RowSpaceEvenly = styled.div`
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: flex-end;
  @media (min-width: 600px) {
    margin-left: 25px;
  }
  @media (max-width: 396px) {
    width: auto;
    margin-top: 2px;
  }
`;
const Linked = styled.div<{fixPosition?: boolean}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 560px) {
    ${props => (props.fixPosition ? 'width:250px' : '')};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const SocialText = styled(CustomText)`
  margin-top: 10px;
  margin-left: 10px;

  @media (min-width: 375px) {
    margin-top: 15px;
    font-size: 13px;
  }

  @media (min-width: 320px) {
    margin-top: 15px;
    font-size: 12px;
  }
`;

const SendText = styled(CustomText)`
  margin-top: 10px;
  margin-left: 12px;
  cursor: pointer;
`;
const StyledInput = styled(CustomInput)`
  height: 35px;
  outline: none;
  margin-left: 20px;
  ${props => (props.error ? 'border-color: red' : '')};
`;
const Icon = styled(AiOutlineClose)`
  margin-top: 11px;
  margin-left: 8px;
  cursor: pointer;
`;
const ConnectedCloseIcon = styled(AiOutlineClose)`
  margin-top: 10px;
  margin-right: 20px;
  cursor: pointer;
`;
const IconsView = styled.div`
  border-radius: 30px;
  background-color: ${Colors.borderGray};
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 25px;
  margin-top: 7px;
`;

const FacebookIcon = styled(FaFacebookF)`
  position: absolute;
  top: 5px;
`;

const InputView = styled.div`
  border-radius: 3px;
  height: 35px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const TwitterConnectedText = styled(CustomText)`
  margin-top: 8px;
  margin-right: 23px;
  font-size: 16px;

  @media (max-width: 396px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;
const ConnectedText = styled(CustomText)`
  margin-top: 10px;
  margin-right: 23px;
  font-size: 16px;

  @media (max-width: 396px) {
    font-size: 14px;
    margin-top: 10px;
  }
`;
const IconText = styled.div<{hidden?: boolean}>`
  display: flex;
  flex-direction: row;
  @media (max-width: 560px) {
    ${props => (props.hidden ? 'display:none' : '')};
  }
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
