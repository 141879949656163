import {
  AccountReducer,
  UPDATE_USER,
  SET_USER_TYPE,
  LOGOUT,
  ActionTypes,
} from '../Action/types';

const initialState: AccountReducer = {
  user: {},
  userType: '',
};

export const Account = (
  state = initialState,
  action: ActionTypes,
): AccountReducer => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        user: {},
        userType: '',
      };
    default:
      return state;
  }
};
