import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import Colors from '../Theme/Colors';
import Certificates, {
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Components/Certificates';
import ConnectSocials from './Components/ConnectSocials';
import Courses from './Components/Courses';
import Skills from './Components/Skills';
import Researches from './Components/Researches';
import EditAccount from './Components/EditAccount';
import ExperinceYears from './Components/ExperienceYears';
// import CertificatePictures from './Components/CertificatePictures';
import {CustomButton, CustomText} from '../../Components/Common';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../Reducer';
import {
  Account,
  AccountReducer,
  ConfigsReducer,
  LawyerType,
  Membership,
  UPDATE_USER,
} from '../../Action/types';
import EditAccountCompany from './Components/EditAccountCompany';
import CompanyCV from './Components/CompanyCV';
import CompanyGoals from './Components/CompanyGoals';
import CompanyActivities from './Components/CompanyActivities';
import TeamMembers from './Components/TeamMembers';
import Albums from './Components/Albums';
import SpecialtyPicker from './Components/Speciality';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  DELETE_COMPANY_ACCOUNT,
  DELETE_LAWYER_ACCOUNT,
  GET_CURRENT_COMPANY,
  GET_CURRENT_LAWYER,
} from './GraphQL/EditAccount';
import {useEffect} from 'react';
import moment from 'moment';
import {FIND_MEMBERSHIPS} from '../Auth/GraphQl/SignUp';
import CompanyClients from './Components/CompanyClients';
import {logoutUser} from '../../Action';
import {CancelIcon, ModalFooter} from '../User/ProfilePreview';
import {Alert} from 'rsuite';
import {QR_CODE_URI} from '../../Configs';
import {InlineShareButtons} from 'sharethis-reactjs';
import i18n from '../../I18next/I18n';

const MyAccount = () => {
  const editAccountRef = useRef<any>(null);
  const socialsRef = useRef<any>(null);
  const resetPasswordRef = useRef<any>(null);
  const certificatesRef = useRef<any>(null);
  const coursesRef = useRef<any>(null);
  const clientsRef = useRef<any>(null);
  const skillsRef = useRef<any>(null);
  const researchRef = useRef<any>(null);
  const experinceRef = useRef<any>(null);
  const photosRef = useRef<any>(null);
  const companyCvRef = useRef<any>(null);
  const companyGoalsRef = useRef<any>(null);
  const companyActivitiesRef = useRef<any>(null);
  const teamMembersRef = useRef<any>(null);
  const specialtyRef = useRef<any>(null);

  const [tab, setTab] = useState('profile');
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);

  const history = useHistory();
  const {userType, user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const dispatch = useDispatch();

  const {data: memberships} = useQuery<{
    findAllMemberships: Membership[];
  }>(FIND_MEMBERSHIPS);

  const [getCurrentCompany, {data: company, error, loading}] = useLazyQuery<{
    findCurrentCompany: Account;
  }>(GET_CURRENT_COMPANY, {fetchPolicy: 'no-cache'});

  const [getCurrentLawyer, {data: lawyer}] = useLazyQuery<{
    findCurrentLawyer: Account;
  }>(GET_CURRENT_LAWYER, {fetchPolicy: 'no-cache'});

  const [requestLawyerDeletion] = useMutation(DELETE_LAWYER_ACCOUNT);
  const [requestCompanyDeletion] = useMutation(DELETE_COMPANY_ACCOUNT);

  useEffect(() => {
    if (user?.is_disabled || user?.deleted) {
      dispatch(logoutUser());
      history.push('/login');
    }
  }, [dispatch, history, user]);

  useEffect(() => {
    if (userType === 'company') {
      getCurrentCompany();
    } else {
      getCurrentLawyer();
    }
  }, [getCurrentCompany, getCurrentLawyer, userType]);

  useEffect(() => {
    if (userType === 'company') {
      if (company?.findCurrentCompany) {
        const {token, ...rest} = company?.findCurrentCompany;
        user &&
          dispatch({
            type: UPDATE_USER,
            payload: {...user, ...rest},
          });
      }
    } else {
      if (lawyer?.findCurrentLawyer) {
        const {token, ...rest} = lawyer?.findCurrentLawyer;
        user &&
          dispatch({
            type: UPDATE_USER,
            payload: {...user, ...rest},
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company?.findCurrentCompany,
    lawyer?.findCurrentLawyer,
    dispatch,
    userType,
    error,
    loading,
  ]);

  const {loggedIn} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  if (!loggedIn) {
    history.push('/login');
  }

  const handleDeleteAccountModal = () => {
    setDeleteAccountModal(prev => !prev);
  };

  const handleDeleteAccount = () => {
    setDeleteAccountModal(false);

    if (userType === 'company') {
      requestCompanyDeletion()
        .then(res => {
          if (res.data.requestCompanyDeletion) {
            dispatch(logoutUser());
            history.push('/login');
            Alert.success('تم حذف الحساب بنجاح');
          } else {
            Alert.error(
              i18n.t('Something went wrong please try again later'),
              5000,
            );
          }
        })
        .catch(() => {
          Alert.error(
            i18n.t('Something went wrong please try again later'),
            5000,
          );
        });
    } else {
      requestLawyerDeletion()
        .then(res => {
          if (res.data.requestLawyerDeletion) {
            dispatch(logoutUser());
            history.push('/login');
            Alert.success('تم حذف الحساب بنجاح');
          } else {
            Alert.error(
              i18n.t('Something went wrong please try again later'),
              5000,
            );
          }
        })
        .catch(() => {
          Alert.error(
            i18n.t('Something went wrong please try again later'),
            5000,
          );
        });
    }
  };

  return (
    <Wrapper>
      <SubWrapper>
        <RightSection>
          <TabsWrapper>
            <TabsContainer>
              <>
                <Tab
                  current={tab === 'profile'}
                  onClick={() => {
                    setTab('profile');
                    editAccountRef?.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}>
                  {userType === 'company' ? (
                    <TabText size={15} color={Colors.darkGray}>
                      {'ملف الشركة'}
                    </TabText>
                  ) : (
                    <TabText size={15} color={Colors.darkGray}>
                      {'الملف الشخصي'}
                    </TabText>
                  )}
                </Tab>

                <Tab
                  current={tab === 'socials'}
                  onClick={() => {
                    setTab('socials');
                    socialsRef?.current.scrollIntoView({behavior: 'smooth'});
                  }}>
                  <TabText size={15} color={Colors.darkGray}>
                    {' وسائل التواصل'}
                  </TabText>
                </Tab>
                {userType !== LawyerType.company ? (
                  <Tab
                    current={tab === 'specialty'}
                    onClick={() => {
                      setTab('specialty');
                      specialtyRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'التخصص'}
                    </TabText>
                  </Tab>
                ) : (
                  <></>
                )}
                {userType !== LawyerType.company ? (
                  <Tab
                    current={tab === 'certificates'}
                    onClick={() => {
                      setTab('certificates');
                      certificatesRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'الشهادات العلمية'}
                    </TabText>
                  </Tab>
                ) : (
                  <></>
                )}
                <Tab
                  current={tab === 'skills'}
                  onClick={() => {
                    setTab('skills');
                    userType === 'company'
                      ? companyCvRef?.current.scrollIntoView({
                          behavior: 'smooth',
                        })
                      : skillsRef?.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                  }}>
                  {userType === 'company' ? (
                    <TabText size={15} color={Colors.darkGray}>
                      {'نبذة عن الشركة'}
                    </TabText>
                  ) : (
                    <TabText size={15} color={Colors.darkGray}>
                      {'الخبرات العملية'}
                    </TabText>
                  )}
                </Tab>
                {userType !== 'company' && (
                  <Tab
                    current={tab === 'courses'}
                    onClick={() => {
                      setTab('cv');
                      companyCvRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'السيرة الذاتية'}
                    </TabText>
                  </Tab>
                )}

                {userType !== 'company' && (
                  <Tab
                    current={tab === 'courses'}
                    onClick={() => {
                      setTab('courses');
                      coursesRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'الدورات التدريبية'}
                    </TabText>
                  </Tab>
                )}
                {userType === 'company' && (
                  <Tab
                    current={tab === 'clients'}
                    onClick={() => {
                      setTab('clients');
                      clientsRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'عملاؤنا'}
                    </TabText>
                  </Tab>
                )}
                <Tab
                  current={tab === 'research'}
                  onClick={() => {
                    setTab('research');
                    researchRef?.current.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}>
                  <TabText size={15} color={Colors.darkGray}>
                    {'الأبحاث'}
                  </TabText>
                </Tab>
                {userType === 'company' && (
                  <Tab
                    current={tab === 'goals'}
                    onClick={() => {
                      setTab('goals');
                      companyGoalsRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'غايات الشركة'}
                    </TabText>
                  </Tab>
                )}
                <Tab
                  current={tab === 'experince'}
                  onClick={() => {
                    setTab('experince');
                    userType === 'company'
                      ? companyActivitiesRef?.current.scrollIntoView({
                          behavior: 'smooth',
                        })
                      : experinceRef?.current.scrollIntoView({
                          behavior: 'smooth',
                        });
                  }}>
                  {userType === 'company' ? (
                    <TabText size={15} color={Colors.darkGray}>
                      {'النشاطات'}
                    </TabText>
                  ) : (
                    <TabText size={15} color={Colors.darkGray}>
                      {'سنوات الخبرة'}
                    </TabText>
                  )}
                </Tab>
                {userType === 'company' && (
                  <Tab
                    current={tab === 'teamMembers'}
                    onClick={() => {
                      setTab('teamMembers');
                      teamMembersRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'فريق العمل'}
                    </TabText>
                  </Tab>
                )}
                {userType === 'company' && (
                  <Tab
                    current={tab === 'photos'}
                    onClick={() => {
                      setTab('photos');
                      photosRef?.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}>
                    <TabText size={15} color={Colors.darkGray}>
                      {'الألبومات'}
                    </TabText>
                  </Tab>
                )}
              </>
            </TabsContainer>
          </TabsWrapper>
          {/* <SecondWrapper> */}
          {userType !== LawyerType.company ? (
            <QrCodeContainer>
              <QrCodeWrapper>
                <a
                  href={`${QR_CODE_URI}/${user?.id}`}
                  style={{marginBottom: 10}}
                  target="_blank"
                  rel="noreferrer">
                  <QrCode src={`${QR_CODE_URI}/${user?.id}`} alt={'QR code'} />
                </a>

                <QrcodeText size={15} color={Colors.darkGray}>
                  {'شارك أو امسح رمز الاستجابة السريعة الخاص بك باستخدام هاتفك'}
                </QrcodeText>

                <InlineShareButtons
                  config={{
                    alignment: 'center', // alignment of buttons (left, center, right)
                    color: 'social', // set the color of buttons (social, white)
                    enabled: true, // show/hide buttons (true, false)
                    font_size: 16, // font size for the buttons
                    labels: null, // button labels (cta, counts, null)
                    language: 'en', // which language to use (see LANGUAGES)
                    networks: [
                      'linkedin',
                      'facebook',
                      'twitter',
                      'email',
                      'whatsapp',
                      'messenger',
                    ],
                    padding: 10, // padding within buttons (INTEGER)
                    radius: 4, // the corner radius on each button (INTEGER)
                    show_total: false,
                    size: 40, // the size of each button (INTEGER)
                    // OPTIONAL PARAMETERS
                    url: `${QR_CODE_URI}/${user?.id}`, // (defaults to current url)
                    image: `${QR_CODE_URI}/${user?.id}`, // (defaults to og:image or twitter:image)
                    description: '', // (defaults to og:description or twitter:description)
                    message: '', // (only for email sharing)
                    title: 'رمز الاستجابة السريعة الخاص بي',
                    subject: 'رمز الاستجابة السريعة', // (only for email sharing)
                    username: user?.name, // (only for twitter sharing)
                  }}
                />
              </QrCodeWrapper>
            </QrCodeContainer>
          ) : (
            <EmptySpace />
          )}

          <EditAccountWrapper>
            <Inputs>
              <ProfileTitle color={Colors.orange}>
                {user?.membership?.name}
              </ProfileTitle>
              <Col>
                {user?.membership?.type !== 0 && (
                  <>
                    <RenewDate color={Colors.lightGray3}>
                      تاريخ تجديد الاشتراك
                    </RenewDate>

                    <CustomText weight={'400'}>
                      {moment(user?.membership_expiry)
                        .locale('ar')
                        .format('YYYY/MM/DD')}
                    </CustomText>
                  </>
                )}
                {(() => {
                  if (user) {
                    const nextMembership = memberships?.findAllMemberships.find(
                      membership =>
                        // @ts-ignore: Unreachable code error
                        membership.type === user?.membership?.type + 1,
                    );

                    return nextMembership && user?.membership?.type !== 0 ? (
                      <>
                        <From color={Colors.lightGray3}>التكلفة القادمة </From>
                        <CustomText weight={'400'}>
                          {nextMembership?.cost} دينار
                        </CustomText>
                      </>
                    ) : (
                      <></>
                    );
                  }
                })()}
              </Col>
              <CancelButton
                height={'33px'}
                bgColor={Colors.white}
                withBorder
                width={'100%'}>
                <ButtonText
                  color={Colors.darkGray}
                  weight={'400'}
                  onClick={() => history.push('Memberships')}>
                  {(() => {
                    if (user && memberships) {
                      const nextMembership =
                        memberships?.findAllMemberships.find(
                          membership =>
                            // @ts-ignore: Unreachable code error
                            membership.type === user?.membership?.type + 1,
                        );
                      // @ts-ignore: Unreachable code error
                      return nextMembership
                        ? `احصل على اشتراك ${nextMembership?.name}`
                        : 'جدد اشتراكك';
                    }
                  })()}
                </ButtonText>
              </CancelButton>
            </Inputs>
          </EditAccountWrapper>
          <DeleteAccountWrapper>
            <Inputs>
              <ProfileTitle color={Colors.black}>حذف الحساب</ProfileTitle>
              <DeleteAccountButton
                height={'33px'}
                bgColor={Colors.white}
                borderColor={Colors.red}
                withBorder
                autoWidth
                onClick={handleDeleteAccountModal}>
                <ButtonText weight={'400'}>حذف</ButtonText>
              </DeleteAccountButton>
            </Inputs>
          </DeleteAccountWrapper>

          {/* <EmptySpace />
            <EmptySpace /> */}
          {/* </SecondWrapper> */}
        </RightSection>
        <PagesContainer>
          {userType !== 'company' ? (
            <>
              <PageWrapper ref={editAccountRef}>
                <EditAccount />
              </PageWrapper>
              <PageWrapper ref={socialsRef}>
                <ConnectSocials />
              </PageWrapper>
              <PageWrapper ref={resetPasswordRef}></PageWrapper>
              <PageWrapper ref={companyCvRef}>
                <CompanyCV />
              </PageWrapper>
              <PageWrapper ref={certificatesRef}>
                <Certificates />
              </PageWrapper>
              <PageWrapper ref={skillsRef}>
                <Skills />
                <PageWrapper ref={coursesRef}>
                  <Courses />
                </PageWrapper>
              </PageWrapper>
              <PageWrapper ref={specialtyRef}>
                <SpecialtyPicker />
              </PageWrapper>
              <PageWrapper ref={researchRef}>
                <Researches />
              </PageWrapper>
              <PageWrapper ref={experinceRef}>
                <ExperinceYears />
              </PageWrapper>
            </>
          ) : (
            <>
              <PageWrapper ref={editAccountRef}>
                <EditAccountCompany />
              </PageWrapper>
              <PageWrapper ref={socialsRef}>
                <ConnectSocials />
              </PageWrapper>
              <PageWrapper ref={resetPasswordRef}></PageWrapper>
              <PageWrapper ref={researchRef}>
                <Researches />
              </PageWrapper>
              <PageWrapper ref={companyCvRef}>
                <CompanyCV />
              </PageWrapper>
              <PageWrapper ref={clientsRef}>
                <CompanyClients />
              </PageWrapper>
              <PageWrapper ref={companyGoalsRef}>
                <CompanyGoals />
              </PageWrapper>

              <PageWrapper ref={companyActivitiesRef}>
                <CompanyActivities />
              </PageWrapper>
              <PageWrapper ref={teamMembersRef}>
                <TeamMembers />
              </PageWrapper>
              <PageWrapper ref={photosRef}>
                <Albums />
              </PageWrapper>
            </>
          )}
        </PagesContainer>
      </SubWrapper>
      <ResetPasswordModal
        show={deleteAccountModal}
        onHide={() => handleDeleteAccountModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handleDeleteAccountModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save orangeBg onClick={handleDeleteAccount}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </Wrapper>
  );
};

export default MyAccount;

const EditAccountWrapper = styled.div`
  width: 60%;

  /* display: none; */

  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 83%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
const DeleteAccountWrapper = styled.div`
  width: 60%;

  margin: 40px 0;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 83%;
    margin: 35px 0;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
const EmptySpace = styled.div`
  margin-bottom: 40px;
  @media (max-width: 1024px) {
    margin-bottom: 35px;
  }
`;
const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: rtl;
`;
const SubWrapper = styled.div`
  width: 100%;
  display: flex;
  direction: rtl;
  justify-content: center;
  align-items: baseline;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    /* min-width: 400px; */
    align-items: center;
  }
`;
// const SecondWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   direction: rtl;
//   justify-content: space-evenly;
//   margin-top: -50px;
//   margin-right: 230px;
//   @media (max-width: 1024px) {
//     /* margin-bottom: 15px; */
//     /* display: none; */
//   }
// `;

const ProfileTitle = styled(CustomText)`
  padding: 10px 0px 15px 0px;
`;
const TabText = styled(CustomText)`
  font-weight: 300;
`;
const QrcodeText = styled(CustomText)`
  font-weight: 300;
  text-align: center;
  padding: 20px;
`;
const RenewDate = styled(CustomText)`
  margin-top: 8px;
  margin-left: 15px;
  margin-bottom: -10px;
`;
const From = styled(CustomText)`
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: -10px;
`;
const Inputs = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
  /* background-color:red; */
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
  font-size: 95%;
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
const CancelButton = styled(CustomButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
const DeleteAccountButton = styled(CustomButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
const TabsWrapper = styled.div`
  margin: 100px 0 0px 0;
  width: 60%;
  display: flex;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  /* margin-left: 120px; */
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  /* margin-top: 10px; */
  @media (max-width: 500px) {
    width: 85%;
  }
`;
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  /* background-color: blue; */

  @media (max-width: 1024px) {
    width: 85%;
  }
`;
const PageWrapper = styled.div`
  @media (max-width: 1024px) {
    /* width: 100%; */
    /* align-items: center; */
  }
`;

const Tab = styled.div<{
  current?: boolean;
}>`
  border-bottom: 2px solid ${Colors.OffWhite};
  color: ${Colors.darkGray};
  padding: 25px 25px 10px;
  cursor: pointer;
  font-size: 16px;
`;
// const LastTab = styled.div<{
// current?: boolean;
// }>`
// color: ${Colors.darkGray};
// padding: 25px 25px 15px;
// cursor: pointer;
// font-size: 16px;
// `;

const QrCode = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 10px;
`;

const QrCodeContainer = styled.div`
  width: 60%;
  margin: 40px 0;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  padding: 20px 0;
  @media (max-width: 1024px) {
    width: 83%;
    margin: 35px 0;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const QrCodeWrapper = styled.div`
  /* width: 85%; */
  display: flex;
  flex-direction: column;
  direction: rtl;
  /* padding-right: 40px;
  padding-top: 20px;
  /* justify-content: center; */
  align-items: center;
`;
