import React, {useEffect, useState} from 'react';
import ReactCountryFlag from 'react-country-flag';
import {Dropdown} from 'rsuite';
import styled from 'styled-components';
import Colors from '../Containers/Theme/Colors';
import Fonts from '../Theme/Fonts';
import {Select, ArrowUpIcon, ArrowDownIcon} from './Header';
import {useQuery} from '@apollo/client';
import {setCountries, setSelectedCountry} from '../Action';
import {COUNTRIES} from '../Containers/Search/GraphQl/Home';
import {useDispatch, useSelector} from 'react-redux';
import {ConfigsReducer, Country} from '../Action/types';
import {RootState} from '../Reducer';
import {SelectContainer} from '../Containers/Auth/SignUp';
import axios from 'axios';
import _ from 'lodash';

interface CountryDropDownProps {
  signUp?: boolean;
  disabled?: boolean;
}

const CountryDropDown = ({signUp, disabled}: CountryDropDownProps) => {
  const dispatch = useDispatch();
  const [countriesArray, setCountriesArray] = useState<any[]>([]);
  const {selectedCountry, countries} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const [flipCountryArrow, setFlipCountryArrow] = useState<boolean>(false);

  const {data: countriesData} = useQuery<{
    findAllCountries: any;
  }>(COUNTRIES);

  const handleSelectCountry = (
    countryId: string,
    isoCode: string,
    supportNumber: string,
    currency: string,
  ) => {
    dispatch(
      setSelectedCountry({
        id: countryId,
        isoCode: isoCode,
        supportNumber: supportNumber,
        currency: currency,
      }),
    );
  };

  useEffect(() => {
    const setCountryToJordan = () => {
      const jordanCountry = countriesData?.findAllCountries?.find(
        (country: Country) => country.isoCode === 'JO',
      );
      if (jordanCountry) {
        dispatch(
          setSelectedCountry({
            id: jordanCountry.id,
            isoCode: jordanCountry.isoCode,
            supportNumber: jordanCountry.whats_app_phone,
            currency: jordanCountry.currency,
          }),
        );
      }
    };

    const fetchCountryByIP = async () => {
      try {
        const response = await axios.get('https://api.country.is');
        const countryCode = response?.data?.country;

        const foundCountry = countriesData?.findAllCountries?.find(
          (country: Country) => country.isoCode === countryCode,
        );

        if (foundCountry) {
          dispatch(
            setSelectedCountry({
              id: foundCountry.id,
              isoCode: foundCountry.isoCode,
              supportNumber: foundCountry.whats_app_phone,
              currency: foundCountry.currency,
            }),
          );
        } else {
          setCountryToJordan();
        }
      } catch (error) {
        console.error('Error fetching country by IP:', error);
        setCountryToJordan();
      }
    };

    if (!selectedCountry?.id && countriesData?.findAllCountries?.length > 0) {
      fetchCountryByIP();
    }
  }, [countriesData?.findAllCountries, dispatch, selectedCountry]);

  useEffect(() => {
    if (countriesData?.findAllCountries?.length > 0) {
      const sortedCountries = [...(countriesData?.findAllCountries || [])].sort(
        (a: any, b: any) =>
          a.disabled && !b.disabled ? 1 : !a.disabled && b.disabled ? -1 : 0,
      );
      setCountriesArray(sortedCountries);
    } else if (countries?.length > 0) {
      const sortedCountries = [...(countries || [])].sort((a: any, b: any) =>
        a.disabled && !b.disabled ? 1 : !a.disabled && b.disabled ? -1 : 0,
      );
      setCountriesArray(sortedCountries);
    }
  }, [countries, countriesData?.findAllCountries]);

  useEffect(() => {
    if (
      countriesData?.findAllCountries?.length > 0 &&
      !_.isEqual(_.sortBy(countries), _.sortBy(countriesData?.findAllCountries))
    ) {
      dispatch(setCountries(countriesData?.findAllCountries));
    }
  }, [countries, countriesData?.findAllCountries, dispatch]);

  if (signUp) {
    return (
      <>
        <SelectContainer>
          <SelectComponent
            required
            disabled={disabled}
            name="country"
            onChange={e => {
              const selectedOption = e.target.options[e.target.selectedIndex];
              const selectedId = selectedOption.value;
              const selectedIsoCode =
                selectedOption.getAttribute('data-isocode') || '';
              const selectedWhatsappPhoneNumber =
                selectedOption.getAttribute('data-whatsapp') || '';
              const selectedCurrency =
                selectedOption.getAttribute('data-currency') || '';
              handleSelectCountry(
                selectedId,
                selectedIsoCode,
                selectedWhatsappPhoneNumber,
                selectedCurrency,
              );
            }}
            value={selectedCountry?.id || ''}>
            {countriesArray
              ?.filter(
                (item: {
                  country: any;
                  id: any;
                  disabled: boolean;
                  isoCode: string;
                  whats_app_phone: string;
                }) => !item.disabled,
              )
              ?.map(
                (item: {
                  country: any;
                  id: any;
                  disabled: boolean;
                  isoCode: string;
                  whats_app_phone: string;
                }) => (
                  <Option
                    value={item.id}
                    data-isocode={item.isoCode}
                    data-whatsapp={item.whats_app_phone}
                    key={item.id}>
                    {item.country}
                  </Option>
                ),
              )}
          </SelectComponent>
        </SelectContainer>
      </>
    );
  }
  return (
    <CountryDropWrapper>
      <Select
        title=""
        onToggle={() => setFlipCountryArrow(!flipCountryArrow)}
        icon={
          <>
            {flipCountryArrow ? (
              <ArrowUpIcon color={Colors.seaBlue} size={15} />
            ) : (
              <ArrowDownIcon color={Colors.seaBlue} size={15} />
            )}
            <ReactCountryFlag
              className="emojiFlag"
              countryCode={selectedCountry?.isoCode || 'jo'}
              style={{
                fontSize: '1.5em',
              }}
            />
          </>
        }
        noCaret>
        {(countriesArray || [])?.map(
          (item: {
            country: any;
            id: any;
            disabled: boolean;
            isoCode: string;
            whats_app_phone: string;
            currency: string;
          }) => {
            return (
              <DropItem
                onSelect={() =>
                  handleSelectCountry(
                    item?.id,
                    item.isoCode,
                    item.whats_app_phone,
                    item.currency,
                  )
                }
                disabled={item.disabled}
                style={{
                  fontFamily: Fonts.regular,
                  paddingLeft: 0,
                }}
                key={item?.id}>
                <CountryWrapper>
                  <ReactCountryFlag
                    className="emojiFlag"
                    countryCode={item?.isoCode}
                    style={{
                      fontSize: '1.5em',
                      marginRight: '10px',
                      marginLeft: '10px',
                      marginTop: '5px',
                    }}
                    aria-label={item?.country}
                  />
                  {item?.country}
                </CountryWrapper>
              </DropItem>
            );
          },
        )}
      </Select>
    </CountryDropWrapper>
  );
};

export default CountryDropDown;

export const CountryDropWrapper = styled.div`
  border-bottom: 5px solid ${Colors.seaBlue};
  width: 83%;
`;

export const DropItem = styled(Dropdown.Item)`
  text-align: end;
  padding-left: 2rem;
`;

export const CountryWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const SelectComponent = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  outline: none;
  border: none;
  padding-bottom: 12px;
  width: 95%;
  margin-left: 7px;
  color: ${Colors.lightGray};
  font-family: ${Fonts.regular};
`;
export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  &:first-child {
    color: ${Colors.lightGray};
  }
`;
