import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {SelectContainer} from '../Containers/Auth/SignUp';
import {FIND_CITY_BY_COUNTRY} from '../Containers/Search/GraphQl/Search';
import CountryDropDown from './CountryDropDown';
import {City, ConfigsReducer} from '../Action/types';
import Colors from '../Containers/Theme/Colors';
import {RootState} from '../Reducer';
import Fonts from '../Theme/Fonts';
import {CustomText} from './Common';
import {useQuery} from '@apollo/client';

interface CountryCityPickerProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: any;
  errors: any;
  touched: any;
  subType: string;
  resetForm: () => void;
}

const CountryCityPicker = ({
  setFieldValue,
  values,
  errors,
  touched,
  subType,
  resetForm,
}: CountryCityPickerProps) => {
  const {selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const [cities, setCities] = useState<City[]>([]);

  const {data: countryData, refetch} = useQuery(FIND_CITY_BY_COUNTRY, {
    variables: {country: selectedCountry?.id},
    fetchPolicy: 'network-only',
    skip: !selectedCountry,
  });

  useEffect(() => {
    if (countryData) {
      setCities(countryData.findCitiesByCountry);
    }
  }, [countryData]);

  useEffect(() => {
    resetForm();
    setFieldValue('type', subType);
    if (selectedCountry && selectedCountry.id) {
      refetch({country: selectedCountry.id});
    }
  }, [selectedCountry, refetch, resetForm, setFieldValue, subType]);

  return (
    <>
      <CountryDropDown signUp />
      <SelectContainer error={!!(errors.city && touched.city && values.type)}>
        <Select
          onChange={(e: {target: {value: any}}) =>
            setFieldValue('city', e?.target?.value)
          }
          value={values.city}
          placeholder="المدينة"
          required
          name="city">
          <Option value={''} disabled={!!values.city} selected={!values.city}>
            المدينة
          </Option>
          {cities.map((city, index) => (
            <Option value={city.id} key={index}>
              {city.city}
            </Option>
          ))}
        </Select>
      </SelectContainer>
      {!!(errors.city && touched.city && values.type) && (
        <>
          <ErrorText size={10}>
            {errors.city && touched.city && errors.city}
          </ErrorText>
        </>
      )}
    </>
  );
};

export default React.memo(CountryCityPicker);

export const Select = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  outline: none;
  border: none;
  padding-bottom: 12px;
  width: 95%;
  margin-left: 7px;
  color: ${Colors.lightGray};
  font-family: ${Fonts.regular};
`;

export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  &:first-child {
    color: ${Colors.lightGray};
  }
`;

const ErrorText = styled(CustomText)`
  text-align: right;
  font-size: 10;
  margin: auto;
  padding-top: 10px;
  color: red;
  margin-right: 7px;
`;
