import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Fonts from '../../../Theme/Fonts';
import {
  CustomButton,
  CustomInput,
  CustomText,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {
  AiFillPlusCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai';
import noPhoto from '../../../Assets/Images/noPhoto.png';
import {
  Account,
  AccountReducer,
  City,
  ConfigsReducer,
  Lawyer,
  LawyerType,
} from '../../../Action/types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Confirm, Select} from '../../Auth/SignUp';
import {useMutation, useQuery} from '@apollo/client';
import {CITIES} from '../../Auth/GraphQl/SignUp';
import {updateUser} from '../../../Action';
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {RESET_PASSWORD} from '../../Auth/GraphQl/ResetPassword';
import {RootState} from '../../../Reducer';
import {Modal, Loader, Alert} from 'rsuite';
import Dropzone from 'react-dropzone';
import {UPLOAD_URI} from '../../../Configs';
import axios from 'axios';
import {ModalFooter, ModalBody, CancelIcon} from '../../User/ProfilePreview';
import i18n from '../../../I18next/I18n';
import {validateArabicInput, validatePhoneNumber} from '../../../helpers';
export interface AccountProps {
  user?: Account;
  userType?: LawyerType;
}
const sharedValidation = {
  email: Yup.string().email().required('البريد الالكتروني خاطئ'),
  city: Yup.string().required('الرجاء الاختيار من القائمة'),
};

export interface UpdateLawyerInput {
  name?: string;
  phone?: string;
  email?: string;
  city?: string;
  building_name?: string;
  street_name?: string;
}

export interface PASSWORDS {
  oldPassword: string;
  password: string;
  confirm_password: string;
}

const EditAccount: React.FC = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    isOn: false,
    message: '',
  });
  const [passVisibility, setpassVisibility] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const handleResetPasswordModal = () => {
    setResetPasswordModal(!resetPasswordModal);
  };
  const [resetPassword, {loading: loadingResetPassword}] = useMutation<{
    updatePassword: Lawyer;
  }>(RESET_PASSWORD);
  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: Lawyer;
  }>(UPDATE_LAWYER);

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const {token} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {data, loading} = useQuery(CITIES);
  const [cities, setCities] = useState<City[]>();

  const ValidationSchema = Yup.object().shape(
    resetPasswordModal
      ? {
          oldPassword: Yup.string().min(8).required('مطلوب'),
          password: Yup.string()
            .min(8)
            .required('كلمة السر يجب ان تحتوي على ٨ خانات على الأقل'),
          confirm_password: Yup.string().required(
            'كلمة السر يجب ان تتطابق مع تأكيد كلمة السر',
          ),
        }
      : {
          ...sharedValidation,
          name: Yup.string()
            .required('مطلوب')
            .test('name', val => validateArabicInput(val))
            .matches(
              /^\s*\S+(?:\s+\S+){3,}\s*$/,
              'يجب ان يتكون الاسم من 4 مقاطع على الأقل',
            ),
          phone: Yup.string().required('رقم الهاتف خاطئ'),
          // building_name: Yup.string().required('رقم الهاتف خاطئ'),
          // street_name: Yup.string().required('رقم الهاتف خاطئ'),
        },
  );

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setErrors,
  } = useFormik({
    initialValues: {
      name: user?.name || '',
      phone: user?.phone || '',
      email: user?.email || '',
      city: user?.city?.id || '',
      building_name: user?.building_name || user?.building_name || '',
      street_name: user?.street_name || user?.street_name || '',
      oldPassword: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: ValidationSchema,

    onSubmit: async submittedValues => {
      if (resetPasswordModal) {
        if (submittedValues.oldPassword === submittedValues.password) {
          return Alert.error(
            i18n.t("old password can't be the same as the new password"),
          );
        }
        if (submittedValues.password !== submittedValues.confirm_password) {
          return Alert.error(i18n.t('Passwords do not match'));
        }
        try {
          handleResetPassword(submittedValues);
        } catch (err) {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      } else {
        const phoneNumber = validatePhoneNumber(submittedValues?.phone, true);
        if (!phoneNumber.isValid()) {
          return Alert.error(i18n.t('Phone number is incorrect'));
        }
        const {email, phone, city, street_name, building_name, name} =
          submittedValues;
        const mutationInput = {} as UpdateLawyerInput;
        if (email !== user?.email) {
          mutationInput.email = email;
        }
        if (phone !== user?.phone) {
          mutationInput.phone = phone;
        }
        if (city !== user?.city?.id) {
          mutationInput.city = city;
        }
        if (name !== user?.name) {
          mutationInput.name = name;
        }
        if (street_name !== user?.street_name) {
          mutationInput.street_name = street_name;
        }
        if (building_name !== user?.building_name) {
          mutationInput.building_name = building_name;
        }

        if (Object.keys(mutationInput).length) {
          handleUpdateAccount(mutationInput);
        } else {
          Alert.error(i18n.t('At least one field must be changed'));
        }
      }
    },
  });

  const handleResetPassword = async (submittedValues: PASSWORDS) => {
    let variables: PASSWORDS = {
      password: submittedValues.password,
      oldPassword: submittedValues.oldPassword,
      confirm_password: submittedValues.confirm_password,
    };
    let data;
    let type;
    try {
      ({data} = await resetPassword({
        variables: variables,
      }));
      if (data?.updatePassword?.type) {
        ({type} = data?.updatePassword);
      }
      if (type && data?.updatePassword) {
        dispatch(updateUser({...user, ...data?.updatePassword}));
        Alert.success('تمت العملية بنجاح');
        handleResetPasswordModal();
        clearResetpasswordModalInputs();
      }
    } catch (err) {
      if (err.message === "Old password doesn't match") {
        Alert.error(
          i18n.t(err?.message, {
            fallback: 'Something went wrong please try again later',
          }),
        );
      } else {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    }
  };
  const clearResetpasswordModalInputs = () => {
    values.oldPassword = '';
    values.password = '';
    values.confirm_password = '';
    setErrors({});
  };
  const handleUpdateAccount = async (updateValue: UpdateLawyerInput) => {
    const variables: UpdateLawyerInput = {};

    if (updateValue.phone !== user?.phone) {
      variables.phone = updateValue.phone;
    }
    if (updateValue.name !== user?.name) variables.name = updateValue.name;
    if (updateValue.email !== user?.email) variables.email = updateValue.email;
    if (updateValue.city !== user?.city) variables.city = updateValue.city;
    if (updateValue.building_name !== user?.building_name) {
      variables.building_name = updateValue.building_name;
    }
    if (updateValue.street_name !== user?.street_name) {
      variables.street_name = updateValue.street_name;
    }
    try {
      await updateLawyer({
        variables: variables,
      }).then(async data => {
        const updateUserData = data?.data?.updateLawyer;
        dispatch(updateUser({...updateUserData}));
        setModal({isOn: true, message: 'تم تحديث البيانات بنجاح'});
      });
    } catch (err) {
      setModal({isOn: true, message: err?.graphQLErrors[0].message});
    }
  };
  useEffect(() => {
    if (!loading) {
      setCities(data.findAllCities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const uploadFiles = async (file: any) => {
    const body = new FormData();
    body.append('file', file[0]);
    axios({
      method: 'post',
      url: `${UPLOAD_URI}`,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
      },
    })
      .then(async res => {
        await updateLawyer({
          variables: {
            photo: res?.data?.id,
          },
        })
          .then(async ({data}) => {
            if (data?.updateLawyer) {
              dispatch(updateUser({...user, ...data?.updateLawyer}));
            }
          })
          .catch(err => {
            // tslint:disable-next-line: no-console
            console.log(err);
          });
      })
      .catch(error => {
        // tslint:disable-next-line: no-console
        console.log(error);
      });
  };

  return (
    <EditAccountWrapper>
      <Col>
        <ProfileTitle>الملف الشخصي</ProfileTitle>
        <Dropzone
          onDrop={acceptedFiles => {
            uploadFiles(acceptedFiles);
          }}
          multiple={false}>
          {({getRootProps, getInputProps}) => (
            <ProfilePicWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <IconWrapper>
                <PlusIcon size={21.5} color={Colors.lightGray4} />
              </IconWrapper>
              <ProfilePic
                src={
                  user?.photo?.filename
                    ? `${UPLOAD_URI}/image/${user?.photo.filename}`
                    : noPhoto
                }
              />
            </ProfilePicWrapper>
          )}
        </Dropzone>
        <ResetPasswordText
          color={Colors.black}
          size={15}
          weight={'400'}
          onClick={() => {
            handleResetPasswordModal();
          }}>
          تغيير كلمة السر
        </ResetPasswordText>
      </Col>
      <Divider />
      <Inputs>
        <>
          <Input
            placeholder={`${'الأسم'}`}
            name="name"
            onChange={handleChange}
            width={'86%'}
            value={values.name}
            error={!!(errors.name && touched.name)}
          />
          {errors.name && touched.name && (
            <CustomText color={Colors.red} size={11}>
              {errors.name.includes('مقاطع')
                ? errors.name
                : 'يجب أن يكون الاسم باللغة العربية'}
            </CustomText>
          )}
          <Input
            placeholder={`${'رقم الهاتف'}`}
            width={'86%'}
            name="phone"
            style={{direction: 'ltr', textAlign: 'right'}}
            onChange={handleChange}
            value={values.phone}
            error={!!(errors.phone && touched.phone)}
          />
        </>

        <Input
          placeholder={`${'البريد الإلكتروني'}`}
          width={'86%'}
          name="email"
          onChange={handleChange}
          value={values.email}
          error={!!(errors.email && touched.email)}
        />
        <SelectContainer error={!!(errors.city && touched.city)}>
          <SelectCity onChange={handleChange} required name="city">
            <Option value={user?.city && user?.city.id} hidden>
              {user?.city ? user?.city.city : 'المدينة'}
            </Option>
            {cities?.map((city, index) => (
              <Option value={city.id} key={index}>
                {city.city}
              </Option>
            ))}
          </SelectCity>
        </SelectContainer>
        <Input
          width={'86%'}
          placeholder={`${'العنوان'}`}
          name="street_name"
          onChange={handleChange}
          value={values.street_name}
          error={!!(errors.street_name && touched.street_name)}
        />
        <Input
          width={'86%'}
          placeholder={`${'اسم الشارع و رقم المبنى'}`}
          name="building_name"
          onChange={handleChange}
          value={values.building_name}
          error={!!(errors.building_name && touched.building_name)}
        />
        <Row>
          <CustomButton
            height={'33px'}
            width={'100px'}
            orangeBg
            type="button"
            disabled={updateLoading}
            onClick={() => {
              handleSubmit();
            }}>
            {updateLoading ? (
              <Loader />
            ) : (
              <ButtonText color={Colors.white} weight={'400'}>
                حفظ
              </ButtonText>
            )}
          </CustomButton>
        </Row>
      </Inputs>
      <PrivacyModal
        show={modal.isOn}
        onHide={() => setModal({isOn: false, message: ''})}>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Confirm
            orangeBg
            onClick={() => setModal({isOn: false, message: ''})}>
            <CustomText color={Colors.white} size={12}>
              حسنا
            </CustomText>
          </Confirm>
        </Modal.Footer>
      </PrivacyModal>
      <ResetPasswordModal
        show={resetPasswordModal}
        onHide={() => {
          handleResetPasswordModal();
          clearResetpasswordModalInputs();
        }}>
        <ResetPasswordModalBody>
          <CancelIcon
            onClick={() => {
              handleResetPasswordModal();
              clearResetpasswordModalInputs();
            }}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>تغيير كلمة السر</ResetPasswordTitle>
          <InputsWrapper>
            <PasswordIconInputContainer
              error={!!(errors.oldPassword && touched.oldPassword)}>
              <PasswordInput
                error={!!(errors.oldPassword && touched.oldPassword)}
                width={'86%'}
                placeholder={`${'كلمة السر القديمة'}`}
                name="oldPassword"
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                value={values.oldPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>
            <PasswordIconInputContainer
              error={!!(errors.password && touched.password)}>
              <PasswordInput
                error={!!(errors.password && touched.password)}
                width={'86%'}
                placeholder={`${'كلمة السر الجديدة'}`}
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>

            <PasswordIconInputContainer
              error={!!(errors.password && touched.password)}>
              <PasswordInput
                error={!!(errors.confirm_password && touched.confirm_password)}
                width={'86%'}
                placeholder={`${'تأكيد كلمة السر الجديدة'}`}
                name="confirm_password"
                type={passVisibility ? 'text' : 'password'}
                style={{borderBottom: 0}}
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleBlur}
              />
              {!passVisibility ? (
                <AiOutlineEyeInvisible
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              ) : (
                <AiOutlineEye
                  size={20}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              )}
            </PasswordIconInputContainer>
            {values.confirm_password !== values.password &&
              touched.password &&
              touched.confirm_password && (
                <ErrorText
                  color="red"
                  style={{
                    marginLeft: 'auto',
                    marginTop: '10px',
                    marginRight: '35px',
                    position: 'relative',
                  }}>
                  يجب ان تتطابق كلمة السر مع تأكيد كلمة السر
                </ErrorText>
              )}
          </InputsWrapper>
        </ResetPasswordModalBody>
        <ModalFooter>
          {loadingResetPassword ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Save orangeBg type="submit" onClick={() => handleSubmit()}>
              <SaveText color={Colors.white} size={23}>
                حفظ
              </SaveText>
            </Save>
          )}
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default EditAccount;
export const PrivacyModal = styled(Modal)`
  direction: rtl;
`;
export const ResetPasswordModal = styled(Modal)`
  direction: rtl;
  @media (max-width: 1100px) {
    width: 40%;
  }
  @media (max-width: 768px) {
    width: 70%;
  }
  @media (max-width: 400px) {
    width: 80%;
  }
`;
const ResetPasswordModalBody = styled(ModalBody)`
  @media (max-width: 500px) {
    padding-bottom: 5px;
  }
`;
const ResetPasswordTitle = styled(CustomText)`
  width: 70%;
  margin-top: 10px;
  text-align: center;
  margin: auto;
  font-size: 33px;
  @media (max-width: 1300px) {
    font-size: 28px;
  }
  @media (max-width: 1150px) {
    font-size: 23px;
  }
  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 850px) {
    font-size: 19px;
  }
  @media (max-width: 782px) {
    font-size: 18px;
  }
  @media (max-width: 426px) {
    font-size: 16px;
  }
  @media (max-width: 338px) {
    font-size: 15px;
  }
`;
const Save = styled(Confirm)`
  @media (max-width: 500px) {
    padding: 7.5px;
  }
`;
const SaveText = styled(CustomText)`
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1150px) {
    font-size: 13px;
  }
`;
const Input = styled(CustomInput)`
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  @media (max-width: 400px) {
    margin: 20px 0;
  }
`;
const PasswordInput = styled(CustomInput)`
  font-size: 20px;
  font-weight: 700;
  @media (max-width: 1300px) {
    font-size: 18px;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
  }
  @media (max-width: 1100px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
  }
`;
const EditAccountWrapper = styled.div`
  margin: 100px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Col = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  align-items: center;
`;

const ProfileTitle = styled(CustomText)`
  padding: 35px 0px 20px 0px;

  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

const ButtonText = styled(CustomText)`
  margin-top: -3px;
  width: 100%;
  font-size: 16px;
`;
const ResetPasswordText = styled(ButtonText)`
  font-size: 15px;
  cursor: pointer;
  margin-top: 25%;
  display: flex;
  color: cornflowerblue;
  justify-content: center;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0px 30px;
  justify-content: cetner;
  @media (max-width: 768px) {
    margin-bottom: 7%;
    width: 85%;
  }
`;
const Inputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding: 20px 50px;
  }
`;
const ProfilePicWrapper = styled.div`
  position: relative;
  background-color: ${Colors.white};
  border-radius: 50px;
  /* width: 80%; */
  cursor: pointer;
`;
const PlusIcon = styled(AiFillPlusCircle)`
  &:hover {
    opacity: 0.8;
  }
`;

const IconWrapper = styled.div`
  background-color: ${Colors.white};
  position: absolute;
  top: 90px;
  z-index: 2;
  border-radius: 50px;
  right: 5px;
  width: 22.33px;
  height: 22.33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProfilePic = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 100px;
  position: relative;
  @media (max-width: 320px) {
    width: 100px;
    height: 100px;
  }
`;
const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  height: 355px;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    height: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;
export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.Grey};
`;
const SelectContainer = styled.div<{
  error?: boolean;
}>`
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color:red' : '')};
  margin-top: 15px;
  width: 86%;
  @media screen and (max-width: 1366px) {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const SelectCity = styled(Select)`
  width: 93%;
  display: flex;
  margin: auto;
  color: ${Colors.Grey};
  font-family: ${Fonts.regular};
`;
export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px auto;
  justify-content: center;
`;
const ErrorText = styled(CustomText)`
  align-self: end;
  font-size: 10px;
  color: red;
  margin-right: 50px;
`;

export const PasswordIconInputContainer = styled.div<{error?: boolean}>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  justify-content: space-around;
  width: 86%;
`;
