import React from 'react';
import styled from 'styled-components';
import {CustomButton, CustomText} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import 'react-datepicker/dist/react-datepicker.css';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {AccountReducer, Lawyer} from '../../../Action/types';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {Alert, Loader} from 'rsuite';
import {LoaderContainer} from '../../Auth/SignUp';
import i18n from '../../../I18next/I18n';
export interface UPDATE_LAWYER_DATA {
  user: Lawyer;
  token: string;
}

const ExperinceYears = () => {
  const dispatch = useDispatch();

  const years = [];
  for (let i = 0; i < 31; ++i) {
    years.push(i.toString());
  }

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: UPDATE_LAWYER_DATA;
  }>(UPDATE_LAWYER);

  const {setFieldValue, handleSubmit} = useFormik({
    initialValues: {
      years_of_practice: '',
    },
    onSubmit: async submittedaVlues => {
      const variables: {
        years_of_practice?: number;
      } = {};
      if (submittedaVlues.years_of_practice) {
        variables.years_of_practice = Number(submittedaVlues.years_of_practice);
      }
      if (Object.keys(variables).length !== 0) {
        try {
          const {data} = await updateLawyer({
            variables: variables,
          });
          if (data?.updateLawyer) {
            const updatedData = data?.updateLawyer;
            dispatch(updateUser({...user, ...updatedData}));
            Alert.success('تم تحديث البيانات بنجاح');
          }
        } catch (err) {
          Alert.error(i18n.t('Something went wrong'));
        }
      }
    },
  });

  return (
    <EditAccountWrapper>
      <Inputs>
        <ProfileTitle> سنوات الخبرة</ProfileTitle>
        <Col>
          <From color={Colors.lightGray}>من</From>
          <SelectWrapper>
            <Select
              onChange={year => {
                setFieldValue('years_of_practice', year?.target?.value);
              }}
              placeholder={'سنة'}>
              <Option
                disabled
                selected
                value={'default'}
                hidden={
                  !!user?.years_of_practice || user?.years_of_practice === 0
                }>
                {user?.years_of_practice
                  ? user?.years_of_practice
                  : user?.years_of_practice === 0
                  ? user?.years_of_practice
                  : 'سنة'}
              </Option>
              {years?.length !== 0 && (
                <>
                  {years?.map(number => {
                    return <Option value={number}>{number}</Option>;
                  })}
                </>
              )}
            </Select>
          </SelectWrapper>
        </Col>
      </Inputs>
      <Divider />
      <Row>
        <Buttons>
          {updateLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <>
              <CustomButton height={'33px'} width={'33%'} orangeBg>
                <ButtonText
                  color={Colors.white}
                  weight={'400'}
                  onClick={() => handleSubmit()}>
                  حفظ
                </ButtonText>
              </CustomButton>
            </>
          )}
        </Buttons>
      </Row>
    </EditAccountWrapper>
  );
};

export default ExperinceYears;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  height: 280px;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const SelectWrapper = styled.div`
  background-color: ${Colors.white};
  width: 100%;
  position: relative;
  background-color: ${Colors.borderGray2};
  width: 18%;
  border-radius: 15px;
  height: 40px;
  padding: 0px 8px 0px 10px;
`;

const ProfileTitle = styled(CustomText)`
  padding: 10px 0px 15px 0px;
`;

const From = styled(CustomText)`
  margin-top: 8px;
  margin-left: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 40px 0px;
`;
const Inputs = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
const Buttons = styled.div`
  width: 83%;
`;
const Divider = styled.div`
  width: 87%;
  border-bottom: 0.5px solid ${Colors.borderGray2};
  align-self: center;
  margin: 20px 0px 10px;
`;
const Col = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  @media (max-width: 768px) {
    justify-content: space-around;
  }
`;

const Option = styled.option`
  font-size: 16px;
`;
const Select = styled.select`
  outline: none;
  border: none;
  border-radius: 15px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: #e0e0e0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
`;
