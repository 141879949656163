import React, {useState} from 'react';
import styled from 'styled-components';
import {CustomButton, CustomText} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {TextArea} from './Researches';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {UPDATE_COMPANY, UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {
  Account,
  AccountReducer,
  Company,
  LawyerType,
} from '../../../Action/types';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {Detail} from '../../../Components/Common';
import {LoaderContainer} from './Certificates';
import {Alert, Loader} from 'rsuite';
import i18n from '../../../I18next/I18n';
import {useHistory} from 'react-router-dom';

const CompanyCV = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);

  const {user, userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let biography: string | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({biography} = user);
  }

  const [updateCompany, {loading: loadingCompanyUpdate}] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);
  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: Account;
  }>(UPDATE_LAWYER);

  const validationSchema = Yup.object().shape({
    biography: Yup.string().required(),
  });
  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };
  const {errors, touched, values, setFieldValue, handleSubmit, resetForm} =
    useFormik({
      initialValues: {
        biography: biography || '',
      },
      onSubmit: async () => {
        try {
          if (userType === LawyerType.company) {
            const {data} = await updateCompany({
              variables: {
                biography: values.biography.trim(),
              },
            });

            if (data?.updateCompany) {
              dispatch(
                updateUser({
                  ...user,
                  ...data?.updateCompany,
                }),
              );
            }
          } else {
            const {data} = await updateLawyer({
              variables: {
                biography: values.biography.trim(),
              },
            });
            if (data?.updateLawyer) {
              dispatch(
                updateUser({
                  ...user,
                  ...data?.updateLawyer,
                }),
              );
            }
          }
          setAdd(false);
          setShow(true);
          Alert.success('تمت العملية بنجاح');
        } catch (err) {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      },
      validationSchema: validationSchema,
    });

  return (
    <EditAccountWrapper>
      <Inputs>
        <StyledRow>
          {userType === LawyerType.company ? (
            <ProfileTitle>نبذة عن الشركة</ProfileTitle>
          ) : (
            <ProfileTitle>السيرة الذاتية</ProfileTitle>
          )}
          {show && (
            <Col
              onClick={() => {
                if (
                  !user?.membership?.lawyer_privileges?.cv &&
                  user?.type !== LawyerType.company
                ) {
                  return notAvailable();
                }
                setAdd(true);
                setShow(false);
              }}>
              <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
              <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
                أضف سيرة
              </AddSectionText>
            </Col>
          )}
        </StyledRow>
        {biography && !add && (
          <Detail noPointer>
            <CustomText padding={'3px'} style={{whiteSpace: 'pre-line'}}>
              {biography}
            </CustomText>
          </Detail>
        )}

        {add && (
          <>
            <TextArea
              placeholder={userType === LawyerType.company ? 'نبذة' : 'السيرة'}
              name="biography"
              onChange={val => setFieldValue('biography', val.target.value)}
              error={!!(errors.biography && touched.biography)}
              value={values.biography}
            />
            <Row>
              {loadingCompanyUpdate || updateLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <CustomButton
                    height={'33px'}
                    width={'33%'}
                    orangeBg
                    onClick={() => handleSubmit()}>
                    <ButtonText color={Colors.white} weight={'400'}>
                      حفظ
                    </ButtonText>
                  </CustomButton>
                  <CancelButton
                    height={'33px'}
                    bgColor={Colors.white}
                    withBorder
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      resetForm();
                    }}
                    width={'33%'}>
                    <ButtonText weight={'400'}>إلغاء</ButtonText>
                  </CancelButton>
                </>
              )}
            </Row>
          </>
        )}
      </Inputs>
    </EditAccountWrapper>
  );
};

export default CompanyCV;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ProfileTitle = styled(CustomText)`
  padding: 10px 0px 15px 0px;
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0px 30px;
`;
const Inputs = styled.div`
  width: 91.2%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
  flex-wrap: wrap;
`;
export const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  /* width: 180px; */
  /* margin-right: 30px; */
  margin-top: 5px;
  cursor: pointer;
`;
export const AddSectionText = styled(CustomText)<{margin?: string}>`
  margin-top: 4px;
  margin-right: ${props => (props.margin ? props.margin : '15')}px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
`;
export const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
