import React from 'react';
import Colors from '../Containers/Theme/Colors';
import styled from 'styled-components';
import noPhoto from '../Assets/Images/noPhoto.png';
import {CustomText} from './Common';
import {IoIosArrowBack} from 'react-icons/io';
import {Account} from '../Action/types';
import {useHistory} from 'react-router-dom';
import {UPLOAD_URI} from '../Configs';
export interface Props {
  lawyerData: Account;
  searchWithName: string | undefined;
  type?: string | undefined;
}

const CommonResult: React.FC<Props> = ({lawyerData, type}) => {
  const history = useHistory();
  const {name, city} = lawyerData;

  return (
    <>
      <ResultWrapper
        lawyerData={lawyerData}
        onClick={() => {
          history.push({
            pathname: `/profile-preview/${type}/${lawyerData?.id}`,
            state: {lawyerData, type},
          });
        }}>
        <LawyerDetailsWrapper>
          <LawyerImg
            src={
              lawyerData?.photo?.filename
                ? `${UPLOAD_URI}/image/${lawyerData?.photo?.filename}`
                : lawyerData?.logo?.filename
                ? `${UPLOAD_URI}/image/${lawyerData?.logo?.filename}`
                : noPhoto
            }
          />
          <LawyerDetails>
            <ResultText>{name}</ResultText>
            <CustomTextHyphen weight="400">
              &nbsp;&nbsp;-&nbsp;&nbsp;
            </CustomTextHyphen>
            <ResultText weight="400">{city?.city}</ResultText>
          </LawyerDetails>
        </LawyerDetailsWrapper>
        <IoIosArrowBackResult color={Colors.lightOrange} size={30} />
      </ResultWrapper>
    </>
  );
};

export default CommonResult;

export const ResultWrapper = styled.div<{lawyerData?: Account}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.lightSilver};
  padding: 10px 30px;
  background-color: ${props =>
    props?.lawyerData?.membership?.type === 2 ? `#f4f4f4` : 'transparent'};

  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  p {
    @media (max-width: 440px) {
      margin-top: 20px;
    }
  }
  p + p {
    margin-top: 0px;
    @media (max-width: 440px) {
      margin-top: 5px;
    }
  }
  @media (max-width: 440px) {
    padding: 10px 0px;
  }
`;

export const LawyerImg = styled.img`
  border-radius: 50%;
  width: 80px;
  height: 80px;
  @media (max-width: 440px) {
    width: 25%;
    margin: auto;
  }
  @media (max-width: 400px) {
    width: 30%;
  }
`;

export const LawyerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 440px) {
    flex-direction: column;
  }
`;

export const LawyerDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 0 10px;
  /* flex: 0.6; */
  @media (max-width: 680px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  @media (max-width: 440px) {
    align-items: center;
    margin: auto;
  }
`;
export const CustomTextHyphen = styled(CustomText)`
  @media (max-width: 680px) {
    display: none;
  }
`;
export const ResultText = styled(CustomText)`
  @media (max-width: 440px) {
    font-size: 16px;
    text-align: center;
  }
  @media (max-width: 350px) {
    font-size: 14px;
  }
`;

export const IoIosArrowBackResult = styled(IoIosArrowBack)`
  @media (max-width: 440px) {
    display: none;
  }
`;
