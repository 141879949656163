import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  IconsDiv,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {useFormik} from 'formik';
import {UPDATE_COMPANY} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {AccountReducer, Company} from '../../../Action/types';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {StyledRow, Col, AddSectionText} from './CompanyCV';
import {MdDelete} from 'react-icons/md';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import {CancelIcon} from '../../User/ProfilePreview';
import {
  LoaderContainer,
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Certificates';
import {Alert, Loader} from 'rsuite';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const CompanyClients = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const [certificateModal, setCertificateModalModal] = useState(false);
  const [removeId, setRemoveId] = useState<number | undefined>();

  const handledCertificateModal = (index?: number) => {
    setCertificateModalModal(!certificateModal);
    setRemoveId(index);
  };

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let clients: string[] | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({clients} = user);
  }

  const [updateCompany, {loading: loadingCompanyUpdate}] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);

  const {values, handleChange, handleSubmit, resetForm, setFieldValue} =
    useFormik({
      initialValues: {
        clients: clients ? clients : [],
        client: '',
        clientIndex: 0,
      },
      onSubmit: async () => {
        if (values.client !== '') {
          values.clients.push(values.client);
        } else {
          setError(true);
        }
        const variables = {clients: values.clients};

        try {
          const {data} = await updateCompany({variables});

          if (data?.updateCompany) {
            dispatch(
              updateUser({
                ...user,
                ...data?.updateCompany,
              }),
            );
            resetForm();
            setAdd(false);
            setError(false);
            setShow(true);
            Alert.success('تمت العملية بنجاح');
          }
        } catch (err) {
          console.log(err);
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      },
    });

  const handleRemoveField = async (index: any) => {
    clients && clients.splice(index, 1);
    handledCertificateModal();
    const newData =
      clients &&
      clients?.map((item: any) => {
        return item;
      });

    setFieldValue('clients', newData);
    const {data} = await updateCompany({
      variables: {
        clients: newData,
      },
    });

    if (data?.updateCompany) {
      dispatch(
        updateUser({
          ...user,
          clients: data?.updateCompany?.clients,
        }),
      );
      Alert.success('تمت العملية بنجاح');
    }
  };
  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };

  return (
    <EditAccountWrapper>
      <Inputs>
        <StyledRow>
          <ProfileTitle>عملاؤنا</ProfileTitle>
          {show && (
            <Col
              onClick={() => {
                if (!user?.membership?.company_privileges?.clients) {
                  return notAvailable();
                }
                setAdd(true);
                setShow(false);
              }}>
              <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
              <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
                أضف عميل جديد
              </AddSectionText>
            </Col>
          )}
        </StyledRow>
        <CompanyActivitiesList>
          {clients &&
            clients?.map((client, index) => (
              <StyledRow>
                <ListItem>
                  <CustomText>{`${client}`}</CustomText>
                </ListItem>
                <IconsDiv>
                  <MdDelete
                    size={19}
                    color={Colors.red}
                    onClick={() => {
                      handledCertificateModal(index);
                    }}
                  />
                </IconsDiv>
              </StyledRow>
            ))}
        </CompanyActivitiesList>
        {add && (
          <>
            <CustomInput
              placeholder={'العميل'}
              width={'85%'}
              name={'client'}
              value={values.client}
              error={error}
              onChange={handleChange}
            />
            <Row>
              {loadingCompanyUpdate ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <CustomButton
                    height={'33px'}
                    width={'33%'}
                    orangeBg
                    onClick={() => handleSubmit()}
                    disabled={values.client === ''}>
                    <ButtonText color={Colors.white} weight={'400'}>
                      حفظ
                    </ButtonText>
                  </CustomButton>
                  <CancelButton
                    height={'33px'}
                    bgColor={Colors.white}
                    withBorder
                    width={'33%'}
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      resetForm();
                    }}>
                    <ButtonText weight={'400'}>إلغاء</ButtonText>
                  </CancelButton>
                </>
              )}
            </Row>
          </>
        )}
      </Inputs>
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleRemoveField(removeId);
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default CompanyClients;

const CompanyActivitiesList = styled.ol``;
const ListItem = styled.li``;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ProfileTitle = styled(CustomText)`
  padding: 10px 0px 15px 0px;
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Row = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0px 30px;
`;
const Inputs = styled.div`
  width: 93.5%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
