// ! Development
export const domain =
  window.location.hostname === 'eadllaw.com'
    ? `${window.location.hostname}`
    : 'eadllaw.staging.wtmsrv.com';
export const GQL_URI = `https://${domain}/graphql/v1.0`;
export const FACEBOOK_ID = '1635696749955376';
export const GOOGLE_API_KEY =
  '617105621199-jlrmn2fda7b9sagq36r9tjm2uvc7nnjs.apps.googleusercontent.com';
export const UPLOAD_URI = `https://${domain}/api/v1.0/upload`;
export const STAGING_URI = `https://${domain}/blog-details`;
export const PAYMENT_URI = 'https://secure-jordan.paytabs.com/payment/request';
export const QR_CODE_URI = `https://${domain}/api/v1.0/qr-code`;
