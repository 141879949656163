import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {CustomText, CustomInput, CustomButton} from '../Components/Common';
import Colors from './Theme/Colors';
import Fonts from '../Theme/Fonts';
import backgroundImg from '../Assets/Images/main-header.png';
import backgroundImg2 from '../Assets/Images/background.jpg';
import backgroundImg3 from '../Assets/Images/worldMap.png';
import {Alert, Icon, Loader} from 'rsuite';
import mainFooter from '../Assets/Images/main-footer.png';
import AdvancedSearch from './Account/Components/AdvancedSearchModal';
import {useFormik} from 'formik';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CONTACTUS, NEWSLETTER} from './Search/GraphQl/Contactus';
import * as Yup from 'yup';
import {useHistory, useLocation} from 'react-router-dom';
import {ConfigsReducer, LawyerType, searchButtonEnum} from '../Action/types';
import i18n from '../I18next/I18n';
import {HOME_PAGE_CONTENT, SEARCH_CONFIG} from './Search/GraphQl/Home';
import Announcements from '../Components/Announcements';
import {LoaderContainer} from './Auth/SignUp';
import {validatePhoneNumber} from '../helpers';
import {useSelector} from 'react-redux';
import {RootState} from '../Reducer';

const MainPage = () => {
  const history = useHistory();
  const [createContactus, {loading}] = useMutation(CONTACTUS);
  const [createNewsletter, {loading: loadingNewsLetter}] =
    useMutation(NEWSLETTER);

  const {selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const [
    getSearchConfigs,
    {data: searchConfigs, loading: loadingSearchConfigs},
  ] = useLazyQuery(SEARCH_CONFIG);

  const [
    getHomePageQuery,
    {data: homePageContent, loading: loadingHomePageContent},
  ] = useLazyQuery(HOME_PAGE_CONTENT);

  const searchConfigsButtons = searchConfigs?.searchConfigByCountry?.configs;

  const location = useLocation();
  const {state} = location;
  const ref = useRef<any>(null);

  useEffect(() => {
    if (state && ref) {
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [state, ref]);

  useEffect(() => {
    if (selectedCountry?.id) {
      setTimeout(() => {
        getSearchConfigs();
        getHomePageQuery();
      }, 500);
    }
  }, [selectedCountry?.id, getSearchConfigs, getHomePageQuery]);

  const newsLetterValidationSchema = Yup.object().shape({
    newsLetterEmail: Yup.string().email().required('مطلوب'),
  });
  const {
    errors: newsLetterErrors,
    touched: newsLetterTouched,
    values: newsLetterValues,
    handleSubmit: newsLetterHandleSubmit,
    handleChange: newsLetterHandleChange,
    resetForm: newsLetterRestForm,
  } = useFormik({
    initialValues: {
      newsLetterEmail: '',
    },
    onSubmit: async submittedValues => {
      const Variables: {
        email?: string;
      } = {
        email: submittedValues.newsLetterEmail,
      };
      try {
        const {data} = await createNewsletter({
          variables: Variables,
        });

        if (data?.createNewsletter === 'Account already Subscribed') {
          Alert.error(i18n.t(data?.createNewsletter));
        } else {
          Alert.success('تم الإرسال بنجاح');
          newsLetterRestForm();
        }
      } catch (err: any) {
        if (err?.message === 'Account already Subscribed') {
          Alert.error(
            i18n.t(err?.message, {
              fallback: 'Something went wrong please try again later',
            }),
          );
        } else {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      }
    },
    validationSchema: newsLetterValidationSchema,
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('مطلوب'),
    email: Yup.string().email().required('مطلوب'),
    phone: Yup.string().required('مطلوب'),
    messageBody: Yup.string().required('مطلوب'),
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    setFieldError,
    resetForm,
    handleChange,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      messageBody: '',
    },
    onSubmit: async submittedValues => {
      // eslint-disable-next-line no-useless-escape
      const reg = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(
        submittedValues.phone,
      );
      if (!reg) {
        setFieldError('phone', 'رقم الهاتف غير صالح');
        return;
      }
      const phoneNumber = validatePhoneNumber(submittedValues.phone, false);
      if (!phoneNumber.isValid()) {
        setFieldError('phone', 'رقم الهاتف غير صالح');
        return;
      }
      const variables: {
        name?: string;
        phoneNumber?: string;
        email?: string;
        messageBody?: string;
      } = {
        name: submittedValues.name,
        phoneNumber: phoneNumber?.number as string,
        email: submittedValues.email,
        messageBody: submittedValues.messageBody,
      };
      try {
        const {data} = await createContactus({
          variables: variables,
        });
        if (
          data?.createContactUs === 'Message with this email already exists' ||
          data?.createContactUs ===
            'Message with this phone number already exists'
        ) {
          Alert.error(i18n.t(data?.createContactUs));
        } else {
          Alert.success('تم الإرسال بنجاح');
          resetForm();
        }
      } catch (err) {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
    validationSchema: validationSchema,
  });

  const buttonToTypeMap = {
    [searchButtonEnum.search_for_lawyer]: LawyerType.lawyer,
    [searchButtonEnum.search_for_judge]: LawyerType.judge,
    [searchButtonEnum.search_for_expert]: LawyerType.expert,
    [searchButtonEnum.search_for_shari]: LawyerType.shari,
    [searchButtonEnum.search_for_advisor]: LawyerType.advisor,
    [searchButtonEnum.search_for_company]: LawyerType.company,
  };

  if (!homePageContent || loadingHomePageContent) {
    return (
      <LoaderContainer>
        <Loader color="black" size={'sm'} />
      </LoaderContainer>
    );
  }
  const {getHomePageContent = {}} = homePageContent;

  return (
    <>
      <Announcements />
      <Container>
        <AdvancedSearch />
        <Header>
          <Title size={40} color={Colors.white} weight="bold">
            {getHomePageContent?.main?.title}
          </Title>
          <HeaderContent size={14} color={Colors.white}>
            {getHomePageContent?.main?.firstContent}
            <br />
            {getHomePageContent?.main?.secondContent}
          </HeaderContent>

          <ButtonsContainer>
            {!loadingSearchConfigs &&
              searchConfigsButtons?.length > 0 &&
              searchConfigsButtons.map(
                (
                  {
                    button,
                    labelAr,
                  }: {
                    button: searchButtonEnum;
                    labelAr: string;
                  },
                  index: number,
                ) => {
                  const type = buttonToTypeMap[button];
                  return (
                    <Button
                      key={index}
                      onClick={() =>
                        history.push({
                          pathname: '/search-lawyers',
                          state: {type},
                        })
                      }>
                      {labelAr}
                    </Button>
                  );
                },
              )}
          </ButtonsContainer>
          <BlueSection>
            <SubSect>
              <LearnMoreTitle size={12} color={Colors.white}>
                {getHomePageContent?.learnMore?.title}
              </LearnMoreTitle>
              <LearnMoreCont>
                <LearnMoreContent size={11} color={Colors.white}>
                  {getHomePageContent?.learnMore?.content}
                </LearnMoreContent>
                <IconContainer>
                  <PlayIcon
                    icon="play-circle"
                    inverse={Colors.white}
                    size="2x"
                    onClick={() => history.push('Guide')}
                  />
                </IconContainer>
              </LearnMoreCont>
            </SubSect>
          </BlueSection>
        </Header>
        <UnderHeader>
          <RightSect>
            <SubSect>
              <UnderHeaderTitle size={40} weight="bold">
                {getHomePageContent?.afaq?.title}
              </UnderHeaderTitle>
              <AfaqContent size={16}>
                {getHomePageContent?.afaq?.content}
              </AfaqContent>
              {getHomePageContent?.afaqPoints?.map(
                (
                  point: {
                    title?: string;
                    content?: string;
                  },
                  index: number,
                ) => (
                  <AfaqPoint key={index}>
                    <BoxLineCont>
                      <BlackBox />
                      <VerticalLine />
                    </BoxLineCont>
                    <AfaqPointCont>
                      <AfaqPointTitle weight="bold">
                        {point?.title}
                      </AfaqPointTitle>
                      <Text size={12}>{point?.content}</Text>
                    </AfaqPointCont>
                  </AfaqPoint>
                ),
              )}
            </SubSect>
          </RightSect>
          <LeftSect></LeftSect>
        </UnderHeader>
        <WorldMap />
        <ContactUsSect ref={ref}>
          <ContactUsContent>
            <ContactUsTitle weight="bold" size={33}>
              تواصل معنا / الدعم الفني
            </ContactUsTitle>
            <ContactUsSubTitle size={12}>
              يسعدنا استقبال الملاحظات والإقتراحات حول محرك البحث عدل او طلب
              الدعم الفني على البريد الإلكتروني
            </ContactUsSubTitle>
          </ContactUsContent>
          <FormContainer>
            <NameEmailCont>
              <Input
                border
                placeholder="الأسم الكامل"
                width="31%"
                name={'name'}
                onChange={handleChange}
                value={values.name}
                error={!!(errors.name && touched.name)}
              />
              <Input
                border
                placeholder="البريد الإلكتروني"
                width="31%"
                name={'email'}
                onChange={handleChange}
                value={values.email}
                error={!!(errors.email && touched.email)}
              />
              <Input
                border
                placeholder="رقم الهاتف"
                width="31%"
                name={'phone'}
                style={{textAlign: 'right', direction: 'ltr'}}
                onChange={handleChange}
                value={values.phone}
                error={!!(errors.phone && touched.phone)}
              />
            </NameEmailCont>
            <MessageText
              placeholder="نص الرسالة"
              name={'messageBody'}
              onChange={handleChange}
              value={values.messageBody}
              error={!!(errors.messageBody && touched.messageBody)}
            />
            <SendButton>
              <CustomButton orangeBg onClick={() => handleSubmit()}>
                {loading ? (
                  <SendLoader />
                ) : (
                  <SendText color={Colors.white}>ارسال</SendText>
                )}
              </CustomButton>
            </SendButton>
          </FormContainer>
        </ContactUsSect>
        {/* <LogosSection>
      <Scale src={blackScale} alt="black-scale" />
      <Scale src={scale} alt="scale" />
      <Logo src={shahid} alt="al-shahid" />
    </LogosSection> */}
        <MainFooter>
          <SubFooter>
            <FooterText color={Colors.white} size={31} weight="bold">
              إبقى معنا، تابع مجلتنا
            </FooterText>
            <FooterSubText color={Colors.white} size={14}>
              إشترك في مجلتنا لتصلك احدث المقالات والابحاث والدراسات القانونية
              المتخصصة التي تنشر في مجلة عدل القانونية، سجل الآن
            </FooterSubText>
          </SubFooter>
          <EmailSubCont
            error={
              !!(
                newsLetterErrors.newsLetterEmail &&
                newsLetterTouched.newsLetterEmail
              )
            }>
            <EmailInput
              placeholder="بريدك الإلكتروني"
              width="300px"
              name={'newsLetterEmail'}
              onChange={newsLetterHandleChange}
              value={newsLetterValues.newsLetterEmail}
            />
            <SubscribeBtn onClick={() => newsLetterHandleSubmit()}>
              {loadingNewsLetter ? (
                <SendLoader />
              ) : (
                <Register color={Colors.white} size={14}>
                  سجل
                </Register>
              )}
            </SubscribeBtn>
          </EmailSubCont>
        </MainFooter>
      </Container>
    </>
  );
};

export default MainPage;
const EmailInput = styled(CustomInput)`
  margin: 0px;
  background-color: transparent;
  padding-right: 25px;
  border-color: transparent;
  color: ${Colors.white};
  &::placeholder {
    color: ${Colors.white};
  }
  &:focus {
    outline: none;
    color: ${Colors.white};
  }

  @media (max-width: 768px) {
    width: 80%;
    padding: 5px 10px;
  }

  @media (max-width: 497px) {
    font-size: 12px;
  }
`;

const Register = styled(CustomText)`
  cursor: pointer;
  @media (max-width: 497px) {
    font-size: 12px;
  }
`;

const SubscribeBtn = styled.div`
  border-right: 1px solid ${Colors.white};
  color: ${Colors.white};
  width: 80px;
  text-align: center;
  margin: 0px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const EmailSubCont = styled.div<{
  error?: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.white};
  border-color: ${props => (props.error ? Colors.red : Colors.white)};
  border-radius: 3px;

  @media (max-width: 768px) {
    width: 50%;
  }

  @media (max-width: 400px) {
    width: 63%;
  }
`;
const MainFooter = styled.div`
  background-image: url(${mainFooter});
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 320px;

  @media (max-width: 1100px) {
    padding: 0 200px;
  }

  @media (max-width: 956px) {
    padding: 0 150px;
  }

  @media (max-width: 856px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    height: 280px;
  }
`;

const SubFooter = styled.div`
  @media (max-width: 856px) {
    width: 80%;
  }
`;

const FooterText = styled(CustomText)`
  margin-bottom: 30px;
  text-align: center;

  @media (max-width: 497px) {
    font-size: 25px;
  }

  @media (max-width: 400px) {
    margin-bottom: 25px;
  }
`;

const FooterSubText = styled(CustomText)`
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 497px) {
    font-size: 12px;
  }
`;

const ContactUsTitle = styled(CustomText)`
  margin-bottom: 15px;
  @media (max-width: 801px) {
    text-align: center;
  }
`;

const ContactUsSubTitle = styled(CustomText)`
  @media (max-width: 801px) {
    text-align: center;
    margin-bottom: 20px;
  }

  @media (max-width: 530px) {
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
  }
`;
const ContactUsContent = styled.div`
  width: 20%;
  margin-left: 25px;

  @media (max-width: 1003px) {
    width: 25%;
  }

  @media (max-width: 801px) {
    width: 100%;
    margin-left: 0;
  }
`;
const FormContainer = styled.div`
  width: 70%;
  height: 250px;

  @media (max-width: 801px) {
    width: 80%;
  }
`;
const NameEmailCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const AfaqContent = styled(CustomText)`
  margin-bottom: 40px;

  @media (max-width: 400px) {
    font-size: 12px;
  }
`;
const UnderHeaderTitle = styled(CustomText)`
  margin-bottom: 30px;
  margin-top: 30px;

  @media (max-width: 850px) {
    font-size: 35px;
  }

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;
const Container = styled.div`
  background-color: ${Colors.lightBlueBg};
  width: 100%;
  direction: rtl;
`;
const SubSect = styled.div``;
const Header = styled.div`
  background-image: url(${backgroundImg});
  background-size: cover;
  min-height: 455px;
  width: 100%;
  position: relative;
  padding: 100px 180px;
  @media (max-width: 1012px) {
    padding: 100px 50px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 60px 0 0 0;
    margin-top: 0;
    align-items: center;
  }
`;

const Title = styled(CustomText)`
  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
  }

  @media (max-width: 458px) {
    text-align: center;
    width: 66.6%;
  }
`;
const LearnMoreTitle = styled(Title)`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 25px;
  }

  @media (max-width: 458px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const Text = styled(CustomText)`
  @media (max-width: 400px) {
    font-size: 10px;
  }
`;

const HeaderContent = styled(CustomText)`
  margin-top: 27px;
  width: 65%;

  @media (max-width: 1245px) {
    width: 70%;
  }

  @media (max-width: 1181px) {
    width: 80%;
  }

  @media (max-width: 1079px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 11px;
  }

  @media (max-width: 450px) {
    width: 70%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 60%;
  justify-content: center;
  padding-left: 150px;
  flex-direction: row;

  @media (max-width: 1300px) {
    padding-left: 100px;
  }

  @media (max-width: 1200px) {
    padding-left: 0px;
  }

  @media (max-width: 537px) {
    width: 71%;
  }
`;
const Button = styled.button`
  background-image: linear-gradient(
    to bottom right,
    ${Colors.buttonColor1},
    ${Colors.buttonColor2}
  );
  width: 40%;
  padding: 5px;
  border-radius: 20px;
  color: ${Colors.white};
  font-size: 12px;
  font-family: ${Fonts.regular};
  margin: 5px 10px;

  @media (max-width: 458px) {
    height: 40px;
    font-size: 14px;
    width: 77%;
  }

  @media (max-width: 356px) {
    width: 101%;
  }
`;

const BlueSection = styled.div`
  background-image: linear-gradient(
    to left,
    ${Colors.gradientSect1},
    ${Colors.gradientSect2},
    ${Colors.gradientSect3},
    ${Colors.gradientSect4}
  );
  width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 30px;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    position: initial;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
  }

  @media (max-width: 400px) {
    height: auto;
  }
`;

const LearnMoreCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
`;
const LearnMoreContent = styled(CustomText)`
  width: 300px;

  @media (max-width: 860px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    text-align: center;
    width: 300px;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

const IconContainer = styled.div``;
const PlayIcon = styled(Icon)`
  margin-right: 70px;
  cursor: pointer;

  @media (max-width: 1100px) {
    margin-right: 30px;
  }

  @media (max-width: 1000px) {
    margin-right: 10px;
  }

  @media (max-width: 950px) {
    margin-right: 0px;
  }

  @media (max-width: 930px) {
    margin-top: 10px;
  }
`;
const UnderHeader = styled.div`
  display: flex;
  direction: row;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`;

const RightSect = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 100px;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 1000px) {
    padding: 0px 50px;
  }

  @media (max-width: 944px) {
    padding: 50px 50px;
    flex-direction: unset;
  }

  @media (max-width: 768px) {
    margin: 50px 0;
    width: 100%;
  }

  @media (max-width: 550px) {
    padding: 20px 50px;
  }
`;

const LeftSect = styled.div`
  width: 50%;
  background-image: url(${backgroundImg2});
  background-size: cover;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 1200px) {
    width: 40%;
  }
`;

const AfaqPoint = styled.div`
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 22px;
`;
const BlackBox = styled.div`
  height: 13px;
  width: 13px;
  background-color: black;
`;
const VerticalLine = styled.div`
  height: 80px;
  background-color: ${Colors.darkGray1};
  width: 1.5px;
  margin: auto;
  margin-top: 13px;
`;
const BoxLineCont = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 13px;
  height: 100%;
`;

const AfaqPointCont = styled.div`
  margin-right: 20px;
`;
const AfaqPointTitle = styled(CustomText)`
  margin-bottom: 5px;

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const WorldMap = styled.div`
  background-image: url(${backgroundImg3});
  background-size: contain;
  width: 100%;
  position: relative;
  top: -1px;
  background-repeat: no-repeat;
  padding-top: 61%;
`;

const ContactUsSect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 80px 0;
  width: 80%;
  margin: auto;

  @media (max-width: 801px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 595px) {
    width: 100%;
  }
`;

const Input = styled(CustomInput)`
  font-weight: 500;
  @media (max-width: 480px) {
    font-size: 10px;
  }

  @media (max-width: 380px) {
    font-size: 7.8px;
  }
`;

const MessageText = styled.textarea<{error?: boolean}>`
  height: 80%;
  line-height: 25px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  outline: none;
  padding: 5px 10px;
  &::placeholder {
    color: ${props => props.theme.colors.gray};
    font-size: 14px;
    margin-top: 20px;
    @media (max-width: 480px) {
      font-size: 11px;
    }
  }
  overflow: auto;
  resize: none;
  font-family: ${Fonts.regular};
  font-weight: 500;
`;

// const LogosSection = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-around;
//   align-items: center;
//   background-color: ${Colors.white};
//   padding: 35px 0px;
// `;
// export const Logo = styled.img`
//   height: auto;
//   width: 150px;

//   @media (max-width: 400px) {
//     width: 100px;
//     height: 45px;
//   }
// `;

// const Scale = styled(Logo)`
//   width: 70px;
//   height: 70px;
//   margin: 0px 80px;

//   @media (max-width: 1300px) {
//     margin: 0 40px;
//   }

//   @media (max-width: 500px) {
//     margin: 0;
//   }

//   @media (max-width: 400px) {
//     width: 50px;
//     height: 50px;
//   }
// `;
const SendButton = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const SendText = styled(CustomText)`
  margin-top: 3px;
  cursor: pointer;
  @media (max-width: 540px) {
    font-size: 10px;
  }
`;
const SendLoader = styled(Loader)`
  margin-top: 3px;
`;

export const Select = styled.select`
  background-color: ${Colors.white};
  direction: rtl;
  cursor: pointer;
  outline: none;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 10px;
  width: 98%;
  margin-left: 7px;
  border-radius: 5px;
  color: ${Colors.lightGray};
  font-family: ${Fonts.regular};
`;

export const Option = styled.option`
  background-color: ${Colors.white};
  color: ${Colors.darkGray};
  cursor: pointer;
  &:first-child {
    color: ${Colors.lightGray};
  }
`;
