import gql from 'graphql-tag';

export const CERTIFICATES = gql`
  query findCertificatesByLawyerId($id: ID!) {
    findCertificatesByLawyerId(id: $id) {
      id
      certificate_name
      certificate_id
      certificate_url
      certificate_issued_from
      certificate_issue_date
      deleted
      photo {
        id
        label
        type
        filename
      }
    }
  }
`;

export const FIND_LAWYER_BY_ID = gql`
  query findLawyerById($id: String!) {
    findLawyerById(id: $id) {
      id
      type
      name
      rating
      is_disabled
      disable_review
      whats_app_phone
      facebook_id
      google_id
      apple_id
      linkedin_id
      twitter_handle
      fax
      land_line
      google_maps_link
      website
      youtube
      telegram
      rate_count
      average_rate
      pending_specialties {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      specialty_photos {
        photos
        specialty
      }
      city {
        id
        country {
          id
          country
          isoCode
        }
        city
      }
      photo {
        filename
        label
      }
      verified_specialties {
        id
        show
        specialty
        type
        created_at
        updated_at
      }
      years_of_practice
      work_phone
      email
      phone
      street_name
      building_name
      biography
      languages
      scholar_degree
      scholar_major
      social_status
      date_of_birth
      research {
        label
        url
      }
      hours_of_operation
      work_experience {
        name
        years
      }
      training_courses {
        title
        acquired_from
        acquired_at
      }
    }
  }
`;

export const FIND_COMPANY_BY_ID = gql`
  query findCompanyById($id: String!) {
    findCompanyById(id: $id) {
      id
      email
      name
      address
      fax
      is_disabled
      disable_review
      commercial_name
      rate_count
      average_rate
      city {
        city
        country {
          id
          country
          isoCode
        }
      }
      mobile_phone
      whats_app_phone
      land_line
      google_maps_link
      website
      youtube
      telegram
      rating
      goals {
        id
        type
        specialty
        show
      }
      specialties {
        specialty
        id
      }
      authorized_signatory
      clients
      activities
      research {
        label
        url
      }
      biography
      photos {
        id
        filename
      }
      logo {
        id
        filename
      }
      team {
        id
        name
        role
        photo {
          filename
        }
      }
    }
  }
`;

export const RATE_LAWYER = gql`
  mutation createReview(
    $company: ID
    $lawyer: ID
    $rating: Float!
    $comment: String
  ) {
    createReview(
      createReviewInput: {
        company: $company
        lawyer: $lawyer
        rating: $rating
        comment: $comment
      }
    ) {
      id
      lawyer {
        id
      }
      company {
        id
      }
      rating
      comment
    }
  }
`;
