import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Footer from './Components/Footer';
import Header from './Components/Header';
import ScrollToTop from './Components/ScrollToTop';
import PageTitle from './Components/PageTitle';
import MyAccount from './Containers/Account/MyAccount';
import ForgotPassword from './Containers/Auth/ForgotPassword';
import Login from './Containers/Auth/Login';
import ResetPassword from './Containers/Auth/ResetPassword';
import SignUp from './Containers/Auth/SignUp';
import Verification from './Containers/Auth/Verification';
import BlogDetails from './Containers/Blog/BlogDetails';
import BlogListing from './Containers/Blog/BlogListing';
import CreateBlog from './Containers/Blog/CreateBlog';
import ContactUs from './Containers/ContactUs';
import Guide from './Containers/Guide';
import MainPage from './Containers/MainPage';
import Memberships from './Containers/Membersips';
import PrivacyPolicy from './Containers/PrivacyPolicy';
import SearchLawyers from './Containers/Search/SearchLawyers';
import SearchResult from './Containers/Search/SearchResult';
import TermsAndCondtions from './Containers/TermsAndConditions';
import ProfilePreview from './Containers/User/ProfilePreview';
import {CREATE_DEVICE} from './Routes.graphql';
import {useMutation} from '@apollo/client';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from './Reducer';
import {ConfigsReducer, LawyerType} from './Action/types';
import {
  logIn,
  logoutUser,
  setDeviceId,
  setToken,
  setUserTypeRedux,
  updateUser,
} from './Action';
import {useCookies} from 'react-cookie';
import {
  SWITCH_ACCOUNT_COMPANY_LOGIN,
  SWITCH_ACCOUNT_LAWYER_LOGIN,
} from './Containers/Search/GraphQl/Home';
import {Alert} from 'rsuite';
import Payment from './Containers/Payment';
import * as Sentry from '@sentry/react';
import i18n from './I18next/I18n';

export interface RoutesProps {
  rememberMe: boolean;
  setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
}

const SentryRoute = Sentry.withSentryRouting(Route);

const Routes: React.FC<RoutesProps> = ({rememberMe, setRememberMe}) => {
  const [cookies] = useCookies(['rememberMeCookie']);
  const dispatch = useDispatch();
  const [createDevice] = useMutation<{
    createDevice: {id: string};
  }>(CREATE_DEVICE);
  const {deviceId, loggedIn} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const [switchAccountLawyerLogin] = useMutation(SWITCH_ACCOUNT_LAWYER_LOGIN);
  const [switchAccountCompanyLogin] = useMutation(SWITCH_ACCOUNT_COMPANY_LOGIN);

  useEffect(() => {
    if (
      loggedIn &&
      Object.keys(cookies)?.length === 1 &&
      !cookies.rememberMeCookie
    ) {
      dispatch(updateUser({}));
      dispatch(logoutUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies, dispatch]);

  useEffect(() => {
    if (!deviceId) {
      (async () => {
        const {data} = await createDevice({
          variables: {
            os: 'computer',
            lang: 'ar',
          },
        });
        data && dispatch(setDeviceId(data.createDevice.id));
      })();
    }
  }, [deviceId, createDevice, dispatch]);

  const switchAccountURL = window?.location?.pathname?.split('/');

  useEffect(() => {
    if (!switchAccountURL?.includes('admin-switch-account')) return;
    try {
      if (switchAccountURL?.includes(LawyerType.lawyer)) {
        switchAccountLawyerLogin({
          variables: {
            token: decodeURIComponent(switchAccountURL[2]),
            lawyer_id: switchAccountURL[3],
          },
        }).then(data => {
          const {token, ...lawyerData} = data?.data?.switchAccountLawyerLogin;
          dispatch(setToken(token));
          dispatch(updateUser({...lawyerData}));
          dispatch(logIn());
          dispatch(setUserTypeRedux(LawyerType.lawyer));
        });
      }
      if (switchAccountURL?.includes(LawyerType.company)) {
        switchAccountCompanyLogin({
          variables: {
            token: decodeURIComponent(switchAccountURL[2]),
            company_id: switchAccountURL[3],
          },
        }).then(data => {
          const {token, ...companyData} = data?.data?.switchAccountCompanyLogin;
          dispatch(setToken(token));
          dispatch(updateUser({...companyData}));
          dispatch(logIn());
          dispatch(setUserTypeRedux(LawyerType.company));
        });
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchAccountURL]);

  return (
    <Router basename="/">
      <ScrollToTop />
      <div>
        <Header />
        <Switch>
          <SentryRoute path="/" exact>
            <MainPage />
            <PageTitle title="الرئيسية" />
          </SentryRoute>
          <SentryRoute path="/login" exact>
            <Login rememberMe={rememberMe} setRememberMe={setRememberMe} />

            <PageTitle title="تسجيل الدخول" />
          </SentryRoute>
          <SentryRoute path="/sign-up" exact>
            <SignUp />

            <PageTitle title="التسجيل في عدل" />
          </SentryRoute>
          <SentryRoute path="/verification/:page?/:email?/:userType?/:response?">
            <Verification />

            <PageTitle title="التحقق" />
          </SentryRoute>
          <SentryRoute path="/forgot-password/:usertype?" exact>
            <ForgotPassword />
            <PageTitle title="نسيت كلمة السر" />
          </SentryRoute>
          <SentryRoute path="/reset-password" exact>
            <ResetPassword />
            <PageTitle title="اعادة تعيين كلمة السر" />
          </SentryRoute>
          <SentryRoute path="/memberships/:search?/:subType?" exact>
            <Memberships />
            <PageTitle title="الإشتراكات" />
          </SentryRoute>
          <SentryRoute path="/my-account" exact>
            <MyAccount />
            <PageTitle title="الحساب" />
          </SentryRoute>
          <SentryRoute path="/blog-details" exact>
            <BlogDetails />
            <PageTitle title="تفاصيل المقالة" />
          </SentryRoute>
          <SentryRoute path="/blog-listing" exact>
            <BlogListing />
            <PageTitle title="المجلة القانونية" />
          </SentryRoute>
          <SentryRoute path="/create-blog" exact>
            <CreateBlog />
            <PageTitle title="إنشاء مدونة" />
          </SentryRoute>
          <SentryRoute path="/search-result" exact>
            <SearchResult />
            <PageTitle title="نتائج البحث " />
          </SentryRoute>
          <SentryRoute path="/search-lawyers" exact>
            <SearchLawyers />
            <PageTitle title="البحث" />
          </SentryRoute>
          <SentryRoute path="/profile-preview/:userType?/:id?" exact>
            <ProfilePreview />
            <PageTitle title="الملف الشخصي" />
          </SentryRoute>
          <SentryRoute path="/guide" exact>
            <Guide />
            <PageTitle title="دليل الإستخدام" />
          </SentryRoute>
          <SentryRoute path="/contact-us" exact>
            <ContactUs />
            <PageTitle title="تواصل معنا" />
          </SentryRoute>
          <SentryRoute path="/privacy-policy" exact>
            <PrivacyPolicy />
            <PageTitle title="سياسة الخصوصية" />
          </SentryRoute>
          <SentryRoute path="/terms-and-conditions" exact>
            <TermsAndCondtions />
            <PageTitle title="اتفاقية الإستخدام" />
          </SentryRoute>
          <SentryRoute
            path="/payment/:accountToken?/:membershipId?/:accountId?"
            exact>
            <Payment />
          </SentryRoute>
          <SentryRoute path="/*" exact>
            <Redirect to="/" />
          </SentryRoute>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default Routes;
