import React from 'react';
import {useQuery} from '@apollo/client';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Autoplay} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import styled from 'styled-components';
import {ANNOUNCEMENTS} from '../Containers/Search/GraphQl/Home';
import {CustomText} from './Common';
import {UPLOAD_URI} from '../Configs';
interface Announcement {
  title?: string;
  body?: string;
  active: boolean;
  redirect_url?: string;
  order: number;
  background_image: {
    filename: string;
  };
}

interface AnnouncementsData {
  findAllAnnouncements: Announcement[];
}
// const testAnnouncements: Announcement[] = [
//   {
//     active: true,
//     order: 1,
//     background_image: 'https://picsum.photos/1920/1080?random=1',
//     title: 'تيست',
//     body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac quam bibendum, faucibus risus vel, rhoncus elit.',
//   },
//   {
//     active: false,
//     order: 2,
//     background_image: 'https://picsum.photos/1920/1080?random=1',
//     title: 'Announcement 2',
//     body: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
//   },
//   {
//     active: true,
//     redirect_url: 'https://example.com',
//     order: 3,
//     background_image: 'https://picsum.photos/1920/1080?random=1',
//     title: 'Announcement 3',
//     body: 'Nunc sit amet dolor euismod, congue mauris eget, rhoncus quam.',
//   },
// ];

const Announcements = () => {
  const {data, loading} = useQuery<AnnouncementsData>(ANNOUNCEMENTS);

  if (loading || !data || !data.findAllAnnouncements.length) {
    return <AnnouncementWrapper />;
  }

  const announcements = data?.findAllAnnouncements
    ?.filter(
      announcement =>
        typeof announcement.order === 'number' && announcement.active,
    )
    ?.sort((a, b) => a?.order - b?.order);

  return (
    <AnnouncementWrapper>
      {!!announcements.length && (
        <AnnouncementSwiper
          direction={'vertical'}
          pagination={{clickable: true}}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}>
          {announcements?.map((announcement: Announcement) => (
            <AnnouncementSlide
              key={announcement.order}
              backgroundImage={`${UPLOAD_URI}/image/${encodeURIComponent(
                announcement?.background_image?.filename,
              )}`}
              hasRedirectUrl={!!announcement.redirect_url}
              onClick={() =>
                announcement.redirect_url &&
                window.open(
                  announcement.redirect_url,
                  '_blank',
                  'noopener,noreferrer',
                )
              }>
              {/* <AnnouncementOverlay /> */}
              <AnnouncementContent>
                <AnnouncementTitle>{announcement?.title}</AnnouncementTitle>
                <AnnouncementBody style={{whiteSpace: 'pre-line'}}>
                  {announcement?.body}
                </AnnouncementBody>
              </AnnouncementContent>
            </AnnouncementSlide>
          ))}
        </AnnouncementSwiper>
      )}
    </AnnouncementWrapper>
  );
};

export default Announcements;

const AnnouncementWrapper = styled.div`
  overflow: hidden;
`;

// const AnnouncementOverlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.4);
// `;

const AnnouncementSlide = styled(SwiperSlide)<{
  backgroundImage: string;
  hasRedirectUrl?: boolean;
}>`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-image: url(${props => props.backgroundImage});
  cursor: ${props => (props.hasRedirectUrl ? 'pointer' : 'default')};
`;
const AnnouncementContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
`;

const AnnouncementTitle = styled(CustomText)`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
`;

const AnnouncementBody = styled(CustomText)`
  font-size: 1.2rem;
  color: #ffffff;
`;

const AnnouncementSwiper = styled(Swiper)`
  height: 275px;
  @media (min-width: 769px) {
    height: 300px;
  }
`;
