import {ApolloClient, gql} from '@apollo/client';
import {ConfigsReducer, WebPaymentInput} from './Action/types';
import {
  COMPANY_FRAGMENT,
  LAWYER_FRAGMENT,
} from './Containers/Auth/GraphQl/SignUp';
import {store} from './Store';

export interface PaymentProps {
  client: ApolloClient<object>;
  accountToken?: string;
  input: WebPaymentInput;
}

export const MakePaymentAction = async ({
  accountToken,
  client,
  input,
}: PaymentProps) => {
  try {
    const {data} = await client.mutate({
      mutation: WEB_PAYMENT,
      fetchPolicy: 'no-cache',
      variables: {input},
      context: {
        headers: {
          // just handle the previous state
          Authorization: `Bearer ${accountToken}`,
          device: (store.getState().Configs as ConfigsReducer)?.deviceId,
          API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
        },
      },
    });

    if (data) {
      // @ts-ignore: Unreachable code error
      window?.open(data.webPayment.redirect_url as string, '_self');
    }
  } catch (error) {
    console.log(error, 'error');
  }
};

export const INIT_PAYMENT = gql`
  mutation initPayment {
    initPayment {
      id
      transaction_number
    }
  }
`;

export const WEB_PAYMENT = gql`
  mutation webPayment($input: WebPaymentInput!) {
    webPayment(input: $input) {
      tran_ref
      tran_type
      cart_id
      cart_description
      cart_currency
      cart_amount
      callback
      return
      redirect_url
      serviceId
      trace
    }
  }
`;

export const UPGRADE_MEMBERSHIP = gql`
  mutation upgradeMembership(
    $updatePaymentInput: UpdatePaymentInput!
    $membership_id: String!
  ) {
    upgradeMembership(
      updatePaymentInput: $updatePaymentInput
      membership_id: $membership_id
    ) {
      id
      lawyer {
        ...LawyerFragment
      }
      company {
        ...CompanyFragment
      }
    }
  }
  ${LAWYER_FRAGMENT}
  ${COMPANY_FRAGMENT}
`;
