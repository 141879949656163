import React, {useState} from 'react';
import styled from 'styled-components';
import {CustomText} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import CustomSocials from './CustomSocials';
import {useSelector} from 'react-redux';
import {AccountReducer, LawyerType} from '../../../Action/types';
import {RootState} from '../../../Reducer';

const SocialLinks = () => {
  const [linkSocial, setLinkSocial] = useState<string>('');
  const {userType} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  return (
    <SocialsWrapper>
      <Wrapper>
        <ProfileTitle>وسائل التواصل</ProfileTitle>
        {userType !== LawyerType.company && (
          <>
            <CustomSocials
              linked={linkSocial}
              icon={'twitter'}
              name={'twitter_handle'}
              text="تويتر"
              onClose={() => setLinkSocial('')}
              onPress={() => setLinkSocial('twitter')}
            />
            <Divider />
          </>
        )}

        {userType !== LawyerType.company && (
          <>
            <CustomSocials
              linked={linkSocial}
              icon={'facebook'}
              text="فيسبوك"
              onClose={() => setLinkSocial('')}
              onPress={() => setLinkSocial('facebook')}
            />
            <Divider />
          </>
        )}

        <CustomSocials
          linked={linkSocial}
          icon={'whatsapp'}
          name={'whats_app_phone'}
          text="واتساب"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('whatsapp')}
        />
        <Divider />
        <CustomSocials
          linked={linkSocial}
          icon={'telegram'}
          name={'telegram'}
          text="تيليجرام"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('telegram')}
        />

        <Divider />

        {userType !== LawyerType.company && (
          <>
            <CustomSocials
              linked={linkSocial}
              icon={'google'}
              text="جوجل"
              onClose={() => setLinkSocial('')}
              onPress={() => setLinkSocial('google')}
            />
            <Divider />
          </>
        )}

        {userType !== LawyerType.company && (
          <>
            <CustomSocials
              linked={linkSocial}
              icon={'linkedin'}
              name={'linkedin'}
              text="لينكد إن"
              onClose={() => setLinkSocial('')}
              onPress={() => setLinkSocial('linkedin')}
            />
            <Divider />
          </>
        )}

        <CustomSocials
          linked={linkSocial}
          icon={'youtube'}
          name={'youtube'}
          text="يوتيوب"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('youtube')}
        />
        <Divider />
        <CustomSocials
          linked={linkSocial}
          icon={'website'}
          name={'website'}
          text="موقع الكتروني"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('website')}
        />
        <Divider />
        <CustomSocials
          linked={linkSocial}
          icon={'google_maps_link'}
          name={'google_maps_link'}
          text="رابط خرائط جوجل"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('google_maps_link')}
        />
        <Divider />
        {userType !== LawyerType.company && (
          <>
            <CustomSocials
              linked={linkSocial}
              icon={'fax'}
              name={'fax'}
              text="رقم الفاكس"
              onClose={() => setLinkSocial('')}
              onPress={() => setLinkSocial('fax')}
            />
            <Divider />
          </>
        )}
        <CustomSocials
          linked={linkSocial}
          icon={'land_line'}
          name={'land_line'}
          text="رقم الهاتف الارضي"
          onClose={() => setLinkSocial('')}
          onPress={() => setLinkSocial('land_line')}
        />
      </Wrapper>
    </SocialsWrapper>
  );
};

export default SocialLinks;

const SocialsWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: row;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  padding-bottom: 30px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  background-color: ${Colors.white};
`;
const ProfileTitle = styled(CustomText)`
  padding: 30px 30px 20px 0px;
`;
const Divider = styled.div`
  width: 90%;
  border-bottom: 0.5px solid ${Colors.borderGray};
  align-self: center;
  margin: 25px 0px;
`;
