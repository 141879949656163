import {Alert, Loader} from 'rsuite';
import {CustomText} from '../../Components/Common';
import Colors from '../Theme/Colors';
import {LoaderContainer} from './SignUp';
import {
  SignIn,
  PreviewWrapper,
  LeftSection,
  LoginSubtitle,
  TextWrapper,
  FiEditIcon,
  SendButton,
} from './ForgotPassword';
import {useHistory, useLocation} from 'react-router-dom';
import {
  Divider,
  InnerContainer,
  StyledInput,
  Wrapper,
  CodeText,
} from './Verification';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';
import {COMPANY_UPDATE_PASSWORD, RESET_PASSWORD} from './GraphQl/ResetPassword';
import {logIn, setUserTypeRedux, updateUser} from '../../Action';
import {useDispatch, useSelector} from 'react-redux';
import {
  AccountReducer,
  Company,
  ConfigsReducer,
  Lawyer,
  LawyerType,
} from '../../Action/types';
import {RootState} from '../../Reducer';
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {useState} from 'react';
import i18n from '../../I18next/I18n';

export interface PASSWORDS {
  oldPassword?: string;
  password: string;
}

const ResetPassword = () => {
  const [passVisibility, setpassVisibility] = useState(false);

  const [resetPassword, {loading: loadingLawyer}] = useMutation<{
    updatePassword: Lawyer;
  }>(RESET_PASSWORD);
  const [resetCompanyPassword, {loading: companyLoading}] = useMutation<{
    companyUpdatePassword: Company;
  }>(COMPANY_UPDATE_PASSWORD);
  const {loggedIn} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const history = useHistory();
  const location = useLocation<any>();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape(
    !loggedIn
      ? {
          password: Yup.string()
            .min(8)
            .required('كلمة السر يجب ان يحتوي ٨ خانات على الأقل'),
        }
      : {
          oldPassword: Yup.string()
            .min(8)
            .required('كلمة السر يجب ان يحتوي ٨ خانات على الأقل'),
          password: Yup.string()
            .min(8)
            .required('كلمة السر يجب ان يحتوي ٨ خانات على الأقل'),
        },
  );

  const {values, handleChange, handleSubmit, errors, touched} = useFormik({
    initialValues: {
      oldPassword: '',
      password: '',
    },
    onSubmit: async submittedValues => {
      if (submittedValues.oldPassword === submittedValues.password) {
        return Alert.error(
          i18n.t("old password can't be the same as the new password"),
        );
      }
      try {
        handleResetPassword(submittedValues);
      } catch (err) {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
    validationSchema: validationSchema,
  });

  const handleResetPassword = async (submittedValues: PASSWORDS) => {
    let variables: PASSWORDS = {
      password: submittedValues.password,
    };
    if (!loggedIn) {
      variables.oldPassword = submittedValues.oldPassword;
    }
    let data;
    let type;

    try {
      if (location.state.userType === 'lawyer') {
        ({data} = await resetPassword({
          variables: variables,
        }));
        if (data?.updatePassword?.type) {
          ({type} = data?.updatePassword);
        }
        if (type && data?.updatePassword) {
          dispatch(updateUser({...user, ...data?.updatePassword}));
          dispatch(setUserTypeRedux(type));
          dispatch(logIn());
          history.push('/');
        }
      } else {
        ({data} = await resetCompanyPassword({
          variables,
        }));
        type = LawyerType.company;
        if (type && data?.companyUpdatePassword) {
          dispatch(updateUser({...user, ...data?.companyUpdatePassword}));
          dispatch(setUserTypeRedux(type));
          dispatch(logIn());
          history.push('/');
        }
      }
    } catch (err) {
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  return (
    <>
      <Wrapper>
        <InnerContainer>
          <PreviewWrapper>
            <LeftSection>
              <SignIn
                color={Colors.seaBlue}
                weight={'500'}
                size={15}
                onClick={() => history.push('/login')}>
                تسجيل الدخول
              </SignIn>
              <LoginSubtitle weight={'500'} color={Colors.darkGray} size={15}>
                هل لديك حساب في عدل؟
              </LoginSubtitle>
            </LeftSection>
            <Divider />
            <TextWrapper>
              <CodeText size={21} color={Colors.darkGray}>
                اعادة تعيين كلمة السر{' '}
              </CodeText>
              {loggedIn && (
                <IconInputContianer
                  error={!!(errors.oldPassword && touched.oldPassword)}>
                  <PasswordInput
                    type={'password'}
                    name="oldPassword"
                    placeholder={'كلمة السر القديمة'}
                    value={values.oldPassword}
                    onChange={handleChange}
                  />
                  <FiEditIcon size={12} color={Colors.darkGray} />
                </IconInputContianer>
              )}
              <PasswordIconInputContainer
                error={!!(errors.password && touched.password)}>
                <IconInputContianer>
                  <PasswordInput
                    type={passVisibility ? 'text' : 'password'}
                    name="password"
                    placeholder={'كلمة السر الجديدة'}
                    width={'100%'}
                    value={values.password}
                    onChange={handleChange}
                  />
                  <FiEditIcon size={12} color={Colors.darkGray} />
                </IconInputContianer>
                <AiOutlineEyeInvisible
                  size={passVisibility ? '0' : '20'}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
                <AiOutlineEye
                  size={passVisibility ? '20' : '0'}
                  color={Colors.darkGray}
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setpassVisibility(!passVisibility);
                  }}
                />
              </PasswordIconInputContainer>

              {loadingLawyer || companyLoading ? (
                <LoaderContainer>
                  <Loader />
                </LoaderContainer>
              ) : (
                <>
                  <SendButton
                    type="submit"
                    orangeBg
                    width={'100%'}
                    onClick={() => handleSubmit()}>
                    <CustomText color={Colors.white} size={12}>
                      إرسال{' '}
                    </CustomText>
                  </SendButton>
                  {!!(errors.password && touched.password) && (
                    <>
                      {values.password ? (
                        <ErrorText color="red">
                          يجب ان تتكون كلمة السر من 8 خانات على الاقل
                        </ErrorText>
                      ) : (
                        <ErrorText color="red">
                          الرجاء ادخال كلمة السر{' '}
                        </ErrorText>
                      )}
                    </>
                  )}
                </>
              )}
            </TextWrapper>
          </PreviewWrapper>
        </InnerContainer>
      </Wrapper>
    </>
  );
};

export default ResetPassword;

export const PasswordInput = styled(StyledInput)`
  border-color: ${Colors.white};
  width: 84%;
`;

export const ErrorText = styled(CustomText)`
  text-align: center;
  width: 100%;
  margin-top: 10px;
`;

export const IconInputContianer = styled.div<{error?: boolean}>`
  /* border-bottom: 1px solid ${Colors.borderGray}; */
  /* ${props => (props.error ? 'border-color: red' : '')}; */
  width: 100%;
  position: relative;
`;

export const PasswordIconInputContainer = styled.div<{error?: boolean}>`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid ${Colors.borderGray};
  ${props => (props.error ? 'border-color: red' : '')};
  justify-content: space-around;
  width: 100%;
`;
