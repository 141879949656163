import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  // Row,
  ButtonsRow,
  RowExtended,
  Label,
  IconsDiv,
  Detail,
  DetailsText,
  A,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {MdDelete} from 'react-icons/md';
import DatePicker from 'react-datepicker';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert, Loader} from 'rsuite';
import moment from 'moment';
import {UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {
  Account,
  AccountReducer,
  LawyerType,
  TrainingCourses,
} from '../../../Action/types';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Certificates';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import {CancelIcon} from '../../User/ProfilePreview';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const Courses = () => {
  const [certificateModal, setCertificateModalModal] = useState(false);
  const [removeId, setRemoveId] = useState<number | undefined>();

  const handledCertificateModal = (index?: number) => {
    setCertificateModalModal(!certificateModal);
    setRemoveId(index);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [more, showMore] = useState<string | number>('');
  const [less, showLess] = useState<string | number>('');
  const [startDate, setStartDate] = useState<any>();

  const setDate = (date: Date | [Date, Date] | null) => {
    setStartDate(date);
    setFieldValue('acquired_at', date);
  };
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let training_courses: any | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({training_courses} = user);
  }

  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: Account;
  }>(UPDATE_LAWYER);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    acquired_from: Yup.string().required(),
    acquired_at: Yup.string().required(),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      title: '',
      acquired_from: '',
      acquired_at: '',
    },
    onSubmit: async submittedValues => {
      const variables: TrainingCourses[] = [
        ...training_courses,
        {
          title: submittedValues?.title,
          acquired_from: submittedValues?.acquired_from,
          acquired_at: moment(startDate).lang('en').format(),
        },
      ];
      try {
        const {data} = await updateLawyer({
          variables: {
            training_courses: variables,
          },
        });
        if (data?.updateLawyer) {
          const updateUserData = data?.updateLawyer;

          dispatch(
            updateUser({
              ...user,
              training_courses: updateUserData?.training_courses,
            }),
          );

          setAdd(false);
          setShow(true);
          setStartDate('');
          resetForm();
          Alert.success('تمت العملية بنجاح');
        }
      } catch (err: any) {
        if (
          err.message ===
          'Your membership level allows no training courses to be added'
        ) {
          Alert.error(
            i18n.t(err?.message, {
              fallback: 'Something went wrong please try again later',
            }),
          );
        } else {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      }
    },
    validationSchema: validationSchema,
  });

  const handleRemoveField = async (index: number | undefined) => {
    training_courses && training_courses.splice(index, 1);
    handledCertificateModal();
    const newData =
      training_courses &&
      training_courses?.map((item: any) => {
        return {
          title: item?.title,
          acquired_from: item?.acquired_from,
          acquired_at: item?.acquired_at,
        };
      });

    const {data} = await updateLawyer({
      variables: {
        training_courses: newData,
      },
    });

    if (data?.updateLawyer) {
      dispatch(
        updateUser({
          ...user,
          training_courses: data?.updateLawyer?.training_courses,
        }),
      );
      Alert.success('تمت العملية بنجاح');
    }
  };
  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };
  return (
    <EditAccountWrapper>
      <RowExtended>
        <ProfileTitle> الدورات التدريبية</ProfileTitle>
        {show && (
          <Col
            onClick={() => {
              if (
                !user?.membership?.lawyer_privileges?.training_courses &&
                user?.type !== LawyerType.company
              ) {
                return notAvailable();
              }
              setAdd(true);
              setShow(false);
            }}>
            <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
            <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
              أضف دورة جديدة
            </AddSectionText>
          </Col>
        )}
      </RowExtended>
      <>
        {training_courses && (
          <>
            {training_courses.map((field: TrainingCourses, index: number) => {
              return (
                <div>
                  <Label>
                    <Detail noPointer>
                      <A>
                        <CustomText padding={'3px'}>{field?.title}</CustomText>
                      </A>
                      {more === index && (
                        <>
                          <DetailsText>
                            &#x2022; {`مكان الحصول: ${field?.acquired_from}`}
                          </DetailsText>
                          <DetailsText>
                            &#x2022;{' '}
                            {`تاريخ الحصول: ${moment(field?.acquired_at)
                              .lang('ar-sa')
                              .format('MMMM D, YYYY')}`}
                          </DetailsText>
                        </>
                      )}

                      {less === index ? (
                        <MoreLess
                          padding={'3px'}
                          marginTop={'2px'}
                          size={13}
                          color={Colors.lightGray}
                          onClick={() => {
                            showMore('');
                            showLess('');
                          }}>
                          اقل ..
                        </MoreLess>
                      ) : (
                        <MoreLess
                          padding={'3px'}
                          marginTop={'2px'}
                          size={13}
                          color={Colors.lightGray}
                          onClick={() => {
                            showMore(index);
                            showLess(index);
                          }}>
                          المزيد ..
                        </MoreLess>
                      )}
                    </Detail>
                    <IconsDiv>
                      <MdDelete
                        size={19}
                        color={Colors.red}
                        onClick={() => handledCertificateModal(index)}
                      />
                    </IconsDiv>
                  </Label>
                </div>
              );
            })}
          </>
        )}
      </>
      {add && (
        <div>
          <Inputs>
            <StyledDatePicker
              strictParsing
              placeholderText={'تاريخ الاصدار'}
              selected={startDate}
              dateFormat="dd/MM/yyyy"
              error={!!(errors.acquired_at && touched.acquired_at)}
              onChange={(date: Date) => {
                setDate(date);
              }}
            />
            <CustomInput
              placeholder={'أسم الدورة'}
              width={'100%'}
              name={'title'}
              value={values.title}
              error={!!(errors.title && touched.title)}
              onChange={handleChange}
            />
            <CustomInput
              placeholder={'أسم المركز'}
              width={'100%'}
              name={'acquired_from'}
              value={values.acquired_from}
              error={!!(errors.acquired_from && touched.acquired_from)}
              onChange={handleChange}
            />
            {updateLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <ButtonsRow>
                <CustomButton
                  height={'33px'}
                  width={'30%'}
                  orangeBg
                  onClick={() => {
                    handleSubmit();
                  }}>
                  <ButtonText color={Colors.white} weight={'400'}>
                    حفظ
                  </ButtonText>
                </CustomButton>
                <CancelButton
                  height={'33px'}
                  bgColor={Colors.white}
                  withBorder
                  width={'30%'}>
                  <ButtonText
                    weight={'400'}
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      setDate(null);
                      resetForm();
                    }}>
                    إلغاء
                  </ButtonText>
                </CancelButton>
              </ButtonsRow>
            )}
          </Inputs>
        </div>
      )}
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleRemoveField(removeId);
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default Courses;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ProfileTitle = styled(CustomText)`
  width: 84%;
  /* @media (max-width: 884px) {
    width: 90%;
  } */
`;

const MoreLess = styled(CustomText)`
  cursor: pointer;
`;

const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Inputs = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
export const TextArea = styled.textarea`
  height: 150px;
  line-height: 60px;
  width: 100%;
  font-size: 16px;
  border-radius: 2.5px;
  border: 2px solid ${Colors.borderGray};
  outline: none;
  margin-top: 10px;
  font-weight: 400;
  &::placeholder {
    margin-top: 20px;
    padding-right: 20px;
    color: ${Colors.lightNavyBlue};
    font-size: 16px;
    font-weight: 400;
  }
`;
const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
  margin-right: 30px;
  cursor: pointer;
  /* @media (max-width: 768px) {
    justify-content: space-around;
  } */
  @media (max-width: 561px) {
    /* width: 70%; */
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
`;
const AddSectionText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 15px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
  /* margin-right: -50px; */
`;
const StyledDatePicker = styled(DatePicker)<{error?: boolean}>`
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 15px 15px 10px;
  border-bottom: ${props =>
    props.error ? `1px solid ${Colors.red}` : `1px solid ${Colors.borderGray}`};
  &::placeholder {
    color: ${props => props.theme.colors.gray};
  }
`;
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  width: 100%;
`;
