import {parsePhoneNumber} from 'libphonenumber-js';
import {store} from './Store';

export const validatePhoneNumber = (
  phoneNumber: string,
  useAccountCountry: boolean,
) => {
  const {selectedCountry} = store.getState().Configs;
  const {user} = store.getState().Account;
  const isoCode = useAccountCountry
    ? user?.city?.country?.isoCode
    : selectedCountry?.isoCode;
  const phoneNumberValidation = parsePhoneNumber(phoneNumber, isoCode);
  return phoneNumberValidation;
};

export const validateArabicInput = (text?: string) => {
  if (!text) return false;
  // eslint-disable-next-line no-control-regex
  const arabicRegex = /^[؀-ۿ\s0-9]+$/;
  return arabicRegex.test(text);
};
