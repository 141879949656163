import styled from 'styled-components';
import Colors from '../Containers/Theme/Colors';
import Fonts from '../Theme/Fonts';
export interface CustomTextStyle {
  color?: string;
  size?: number;
  weight?: string;
  font?: string;
  padding?: string;
  marginTop?: string;
}
export interface DetailsTextStyle {
  weight?: string;
}
export interface CustomInputStyle {
  border?: boolean;
  bottomBorder?: boolean;
  width?: string;
  error?: boolean;
  font?: string;
}
export interface CustomButtonStyle {
  bgColor?: string;
  orangeBg?: boolean;
  width?: string;
  margin?: string;
  color?: string;
  darkBlueBg?: boolean;
  lightBlueBg?: boolean;
  font?: string;
  withBorder?: boolean;
  height?: string;
  borderColor?: string;
  autoWidth?: boolean;
}
export interface RowStyle {
  width?: string;
  display?: string;
  flexDirection?: string;
  direction?: string;
  padding?: string;
}
export interface LabelStyle {
  width?: string;
  padding?: string;
  display?: string;
  flexDirection?: string;
}
export const IconsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
`;
export const Detail = styled.div<{noPointer?: boolean}>`
  display: flex;
  flex-direction: column;
  ${props => (props.noPointer ? '' : 'cursor: pointer')};
  margin-bottom: 10px;
  max-width: 90%;
`;
export const A = styled.a`
  text-decoration: none !important;
`;

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 0;
`;
export const Label = styled.div<LabelStyle>`
  padding: ${props => (props.padding ? props.padding : '15px 36px')};
  width: ${props => (props.width ? props.width : '100%')};
  display: ${props => (props.display ? props.display : 'flex')};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  justify-content: space-between;
`;
export const Row = styled.div<RowStyle>`
  width: ${props => (props.width ? props.width : '100%')};
  display: ${props => (props.display ? props.display : 'flex')};
  flex-direction: ${props =>
    props.flexDirection ? props.flexDirection : 'row'};
  direction: ${props => (props.direction ? props.direction : 'rtl')};
  padding: ${props => (props.padding ? props.padding : '30px 40px 20px')};
  justify-content: center;
  /* @media (max-width: 561px) {
    flex-direction: column;
  } */
`;
export const CustomText = styled.p<CustomTextStyle>`
  font-size: ${props => (props.size ? `${props.size}px` : '16px')};
  font-weight: ${props => (props.weight ? props.weight : 600)};
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};
  font-family: ${props => (props.font ? props.font : Fonts.regular)};
  padding: ${props => props.padding};
  margin-top: ${props => props.marginTop};
`;
export const DetailsText = styled.p<DetailsTextStyle>`
  width: 100%;
  font-weight: ${props => (props.weight ? props.weight : 500)};
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};
  padding: 4px 0 10px;
`;
export const CustomInput = styled.input<CustomInputStyle>`
  direction: rtl;
  border: ${props =>
    props.border ? `1px solid ${props.theme.colors.lightSilver}` : '0'};
  border-bottom: ${props =>
    !props.border && `1px solid ${props.theme.colors.lightSilver}`};
  ${props => (props.error ? 'border-color:red' : '')};
  padding: 10px 15px;
  border-radius: ${props => (props.border ? '5px' : '0')};
  width: ${props => (props.width ? props.width : '30%')};
  margin: 5px 0;
  font-family: ${props => (props.font ? props.font : Fonts.regular)};
  ${props => (props.height ? `height:${props.height}` : '')};
  &:focus,
  &:visited,
  &:link,
  &:active {
    border: 1px solid ${props => props.theme.colors.gradientBlue};
  }

  &::placeholder {
    color: ${props => props.theme.colors.gray};
  }
  outline: none;
`;

export const CustomButton = styled.button<CustomButtonStyle>`
  cursor: pointer;
  outline: none;
  padding: 10px;
  width: ${props =>
    props.width ? props.width : props.autoWidth ? 'auto' : '20%'};
  background: ${props =>
    props.orangeBg
      ? props.theme.colors.gradientFromLeft
      : props.darkBlueBg
      ? Colors.darkBlue
      : props.lightBlueBg
      ? Colors.lightBlue
      : props.bgColor
      ? props.bgColor
      : props.theme.colors.gradientBlue};
  height: ${props => (props.height ? props.height : '')};
  border: ${props =>
    props.withBorder
      ? props.borderColor
        ? `1px solid ${props.borderColor}`
        : `1px solid ${Colors.borderGray}`
      : 0};
  border-radius: 50px;
  transition: all 0.5s ease-in-out;
  margin: 5px 0;
  font-family: ${props => (props.font ? props.font : Fonts.regular)};

  &:hover {
    opacity: 0.8;
  }
`;

export const RowExtended = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: row;
  direction: rtl;
  padding: 30px 40px 20px;
  justify-content: center;
  @media (max-width: 561px) {
    flex-direction: column;
  }
`;
export const CustomTextButton = styled(CustomText)`
  cursor: pointer;
`;
