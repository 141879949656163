import React, {useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {createGlobalStyle, ThemeProvider} from 'styled-components';
import Routes from './Routes';
import {Theme} from './Theme';
import {Provider, useDispatch, useSelector} from 'react-redux';
import {RootState} from './Reducer';
import {ConfigsReducer} from './Action/types';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {store} from './Store';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import {GQL_URI} from './Configs';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import 'rsuite/dist/styles/rsuite-default.css';
import {logoutUser} from './Action';
import * as Sentry from '@sentry/react';
import {SentryLink} from 'apollo-link-sentry';
import ReactGA from 'react-ga4';
import reportWebVitals from './reportWebVitals';

const GlobalStyle = createGlobalStyle`
  body, h1, p{
    padding: 0;
    margin:0;
  }
`;

const persistor = persistStore(store);

ReactGA.initialize('UA-222955971-');

const ApolloApp: React.FC = () => {
  const dispatch = useDispatch();
  const [rememberMe, setRememberMe] = useState(false);
  const {user} = store.getState().Account;

  const {token, deviceId, selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;

  const httpLink = new HttpLink({uri: GQL_URI, credentials: 'same-origin'});

  const sentryLink = new SentryLink({
    uri: GQL_URI,
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      sentryLink,
      onError(({operation, graphQLErrors, networkError}) => {
        if (graphQLErrors) {
          Sentry.withScope(scope => {
            scope.setTransactionName(operation.operationName);
            scope.setContext('Graphql Operation', {
              operationName: operation.operationName,
              variables: operation.variables,
              extensions: operation.extensions,
              headers: operation.getContext().headers,
            });

            graphQLErrors?.forEach(error => {
              Sentry.captureMessage(error.message, {
                level: 'error',
                fingerprint: ['{{ default }}', '{{ transaction }}'],
                contexts: {
                  'Graphql Error': {
                    error,
                    message: error.message,
                    extensions: error.extensions,
                  },
                },
              });
            });
          });
          // eslint-disable-next-line array-callback-return
          graphQLErrors.map(({message, locations, path}) => {
            if (message === 'Forbidden resource') {
              // log user out if token is not valid
              dispatch(logoutUser());
            }
            // tslint:disable-next-line: no-console
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
          });
        }
        if (networkError) {
          // tslint:disable-next-line: no-console
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      setContext((_operation, pervContext) => {
        let countryId = selectedCountry?.id;

        if (
          _operation.operationName === 'findAllMemberships' &&
          user?.city?.country?.id
        ) {
          countryId = user.city?.country?.id;
        }

        return new Promise(async resolve => {
          resolve({
            headers: {
              Authorization: `Bearer ${token}`,
              device: deviceId,
              API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
              'x-country-id': countryId,
              ...pervContext.headers,
            },
          });
        });
      }),
      httpLink,
    ]),
    cache: new InMemoryCache({addTypename: false}),
  });
  useEffect(() => {
    Sentry.configureScope(scope => {
      if (user) {
        scope.setUser({
          id: user?.id?.toString(),
          username: user?.name || '',
          email: user?.email,
        });
      } else {
        scope.setUser(null);
      }
    });
  }, [user]);

  return (
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <>
          <ThemeProvider theme={Theme}>
            <BrowserRouter>
              <GlobalStyle />
              <Routes rememberMe={rememberMe} setRememberMe={setRememberMe} />
            </BrowserRouter>
          </ThemeProvider>
        </>
      </PersistGate>
    </ApolloProvider>
  );
};

function App() {
  return (
    <>
      <Provider store={store}>
        <ApolloApp />
      </Provider>
    </>
  );
}

const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);

export default Sentry.withProfiler(App);
