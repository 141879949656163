import styled from 'styled-components';
import Colors from './Theme/Colors';
import BackgroundImg from '../Assets/Images/office.jpg';
import Fonts from '../Theme/Fonts';
import {useQuery} from '@apollo/client';
import {SYSTEM_CONFIGS} from './Account/GraphQL/SystemConfigs';
import {useSelector} from 'react-redux';
import {ConfigsReducer} from '../Action/types';
import {RootState} from '../Reducer';
const Guide = () => {
  const {data, loading} = useQuery(SYSTEM_CONFIGS);
  const videoUrl = data?.systemConfigsByCountry?.video_url;
  const {selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const supportNumber = selectedCountry?.supportNumber;

  return (
    <GuideContainer>
      <Header />
      {videoUrl && !loading && (
        <VideoWrapper>
          <iframe
            width="853"
            height="480"
            src={videoUrl?.replace('watch?v=', 'embed/')}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </VideoWrapper>
      )}
      <Section>
        <Title>دليل الإستخدام</Title>
        <SubTitle>تعريف</SubTitle>
        <Text>
          محرك البحث عدل هو محرك بحث متخصص في البحث عن مقدمي الخدمات القانونية
          من محامين ومحكمين وخبراء وشركات قانونية حول العالم وجمعها ضمن قواعد
          بيانات ضخمة تساعد على الوصول لهم بأسرع واسهل الطرق، وتصنيفهم ضمن
          التخصصات القانونية المقدمة من قبلهم وتوفير المعلومات اللازمة عنهم
          ومنها سنوات الخبرة والمؤهلات العلمية والعملية وطرق التواصل معهم،
          بالإضافة الى السماح لمتلقي الخدمات القانونية من تقييم الخدمة المقدمة
          له من أي من مقدمي الخدمات، بالإضافة الى المزيد من الخدمات الحالية
          والتي سيتم اضافتها في المستقبل.
        </Text>
        <SubTitle>طريقة الاستخدام</SubTitle>
        <Text>
          اختر الدولة التي تبحث فيها عن الخدمات القانونية من الصفحة الرئيسية
        </Text>
        <Text>ثم اختر نوع البحث (محامي محكم خبير شركة قانونية)</Text>
        <Text>سيظهر لك مربعي بحث</Text>
        <Text>الأول البحث بواسطة التخصص</Text>
        <Text>
          يمكنك من خلاله البحث عن الخدمة بواسطة التخصص والمدينة بعد تحديدهما
          اضغط على بحث
        </Text>
        <Text>تظهر لك صفحة النتائج بالنتائج الخاصة بالبحث المحدد</Text>
        <Text>
          والضغط على أي نتيجة يعرض لك الصفحة الشخصية لمقدم الخدمة القانونية
          والتي تحتوي على السيرة الشخصية لمقدم الخدمة القانونية ووسائل التواصل
          معه .
        </Text>
        <Text>
          ملاحظة: كذلك يمكنك البحث بواسطة التخصص دون اختيار المدينة لإظهار جميع
          المتخصصين في التخصص المحدد في كامل الدولة المحددة، والعكس حيث يمكن
          البحث بواسطة المدينة دون تحديد التخصص لإظهار مقدمي الخدمات القانونية
          المجودين في المدينة المحددة.
        </Text>
        <Text>
          بالإضافة الى وجود خيارات اضافية للبحث موجودة ضمن خيار البحث المتقدم إن
          قمت بتفعيل الخيار يظهر لك ثلاث خيارات بحث اضافية وهي
        </Text>
        <OrderedList type="1">
          <Point>البحث بواسطة عدد سنوات الخبرة.</Point>
          <Point>البحث بواسطة التقييم الذي حصل علية مقدم الخدمة.</Point>
          <Point>البحث ضمن مقدمي الخدمات الذين تم التحقق من صحة تخصصهم.</Point>
        </OrderedList>
        <Text>الثاني البحث بواسطة الاسم</Text>
        <Text>
          يمكنك هذا المربع من ادخال اسم مقدم الخدمة للوصول الى سيرته الذاتية
          ووسائل التواصل معه، ما عليك إلا إدخال مقطع من اسم المحامي او الخبير او
          حسب خيار البحث الذي تم اختياره، وسيظهر في نتائج البحث جميع من يحملون
          نفس المقطع من الاسم، في حال لم تكن ادخلت الاسم الرباعي.
        </Text>
        <Text>
          ملاحظة: قد تحتاج عند البحث الى مراعاة الكتابة باستخدام بعض الاحرف مثل
          الهاء والتاء المربوطة في نهاية أي كلمة واستخدام الالف والهمزة، حتى
          تظهر النتائج بشكل صحيح.
        </Text>
        <Text>
          ثالثا إن لم تجد ما تبحث عنه اطلب منا ان نقترح عليك، وإن كان لديك سؤال
          او اقتراح ارسل لنا في صندوق الاقتراحات والطلبات اسفل صفحة البحث، لا
          تنسى كتابة بريدك الإلكتروني لنستطيع الرد عليك.{' '}
        </Text>
        <SubTitle>نتائج البحث</SubTitle>
        <Text>ستظهر لك نتائج البحث في صفحة مستقلة </Text>
        <Text>
          كل نتيجة ستحتوي على صورة مقدم الخدمة القانونية واسمه والمدينة
        </Text>
        <Text>
          الضغط على النتيجة ينقلك الى الصفحة الشخصية لمقدم الخدمة القانونية
        </Text>
        <Text>
          ستجد فيها السيرة الذاتية لمقدم الخدمة القانونية بالإضافة الى كافة
          وسائل التواصل المتوفرة
        </Text>
        <Text>
          كما يمكنك تقييم تجربتك مع مقدم الخدمة القانونية ان كان لديك تجربة
          سابقة معه بواسطة النجوم عند الضغط على زر اترك تقييما اسفل الصفحة
          الشخصية لمقدم الخدمة القانونية.
        </Text>
      </Section>

      <Section>
        <Title>التسجيل</Title>
        <PointContent>
          في حال لم يكن للمحامي الذي يريد الاشتراك في عدل رقم هاتف او بريد
          الكتروني لدى نقابة المحامين فلا يمكنه التسجيل في عدل إلا بعد اضافة رقم
          الهاتف والبريد الإلكتروني لمعلوماته لدى نقابة المحامين.
        </PointContent>
        <SubTitle>طريقة التسجيل للمحامين</SubTitle>
        <OrderedList type="1">
          <Point> من الصفحة الرئيسية في رأس الصفحة الضغط على اشترك الآن.</Point>
          <Point> ستظهر صفحة فيها نموذج المعلومات المطلوبة للتسجيل.</Point>
          <Point>حدد نوع التخصص القانوني محامي </Point>
          <Point>ادخل الرقم النقابي.</Point>
          <Point>
            بعد ادخال الرقم النقابي ستظهر لك معلوماتك المحفوظة في نقابة
            المحامين.
          </Point>
          <PointContent>ملاحظات مهمه :</PointContent>
          <PointContent>
            &bull; يعتمد البريد الإلكتروني ورقم الهاتف المحفوظ لدى نقابة
            المحامين.
          </PointContent>
          <PointContent>
            &bull; لا يمكن تغير رقم الهاتف والبريد الإلكتروني الظاهران، يجب
            تغيرهما اولا لدى نقابة المحامين ثم التواصل مع الدعم الفني لدى عدل
            لإكمال التسجيل.
          </PointContent>
          <PointContent>
            في حال لم يكن للمحامي الذي يريد الاشتراك في عدل رقم هاتف او بريد
            الكتروني لدى نقابة المحامين فلا يمكنه التسجيل في عدل إلا بعد اضافة
            رقم الهاتف والبريد الإلكتروني لمعلوماته لدى نقابة المحامين.
          </PointContent>
          <Point>ادخل باقي المعلومات المطلوبة .</Point>
          <Point>
            {' '}
            اختر نوع الاشتراك واضغط على اشترك الآن. (ملاحظة لمعرفة امتيازات كل
            اشتراك وان كان مجاني او مدفوع اضغط على المزيد من المعلومات أسفل نوع
            الاشتراك)
          </Point>
          <Point>الموافقة على سياسية الخصوصية واتفاقية الاستخدام. </Point>
          <Point>اضغط على إنشاء حساب.</Point>
          <PointContent>ملاحظات مهمه :</PointContent>
          <PointContent>
            &bull; ان كانت معلومات الحساب موجودة سابقا سيتم فتح الحساب مباشرة.
          </PointContent>
          <PointContent>
            &bull; ان كان الحساب جديدا ولم يكن لدى محرك البحث عدل أي معلومات
            سابقة عن المحامي الذي يريد الاشتراك سيتم فتح الحساب وايقافه عن العمل
            الى حين التحقق من صحة المعلومات المدخلة، ومطابقة البريد الإلكتروني
            المدخل ورقم الهاتف مع المحفوظان لدى نقابة المحامين، وسيتم تفعيل
            الحساب خلال 72 ساعة ان شاء الله.
          </PointContent>
        </OrderedList>
        <SubTitle>طريقة التسجيل للمحامين الشرعيين (خاص بالاردن)</SubTitle>
        <OrderedList type="1">
          <Point>من الصفحة الرئيسية في رأس الصفحة الضغط على اشترك الآن.</Point>
          <Point>ستظهر صفحة التسجيل حدد نوع التخصص القانوني محامي شرعي.</Point>
          <Point>ادخل الرقم الإجازة الشرعية. </Point>
          <Point>ادخل باقي المعلومات المطلوبة.</Point>
          <Point>
            اختر نوع الاشتراك واضغط على اشترك الآن. (ملاحظة لمعرفة امتيازات كل
            اشتراك وان كان مجاني او مدفوع اضغط على المزيد من المعلومات أسفل نوع
            الاشتراك)
          </Point>
          <Point>الموافقة على سياسية الخصوصية واتفاقية الاستخدام.</Point>
          <Point>اضغط على إنشاء حساب.</Point>
        </OrderedList>
        <SubTitle>طريقة التسجيل للمحكمين</SubTitle>
        <OrderedList type="1">
          <Point>من الصفحة الرئيسية في رأس الصفحة الضغط على اشترك الآن.</Point>
          <Point> لك مستظهر صفحة التسجيل حدد نوع التخصص القانوني محكم.</Point>
          <Point>ادخل باقي المعلومات المطلوبة. </Point>
          <Point>
            اختر نوع الاشتراك واضغط على اشترك الآن. (ملاحظة لمعرفة امتيازات كل
            اشتراك وان كان مجاني او مدفوع اضغط على المزيد من المعلومات أسفل نوع
            الاشتراك)
          </Point>
          <Point>ادخل باقي المعلومات المطلوبة .</Point>
          <Point>الموافقة على سياسية الخصوصية واتفاقية الاستخدام.</Point>
          <Point>اضغط على إنشاء حساب.</Point>
        </OrderedList>
        <SubTitle>طريقة التسجيل خبير</SubTitle>
        <OrderedList type="1">
          <Point>
            {' '}
            من الصفحة الرئيسية في رأس الصفحة الضغط على اشترك الآن.{' '}
          </Point>
          <Point>ستظهر صفحة التسجيل حدد نوع التخصص القانوني خبير.</Point>
          <Point>ادخل باقي المعلومات المطلوبة. </Point>
          <Point>ستظهر صفحة التسجيل حدد نوع التخصص القانوني خبير.</Point>
          <Point>ادخل باقي المعلومات المطلوبة .</Point>
          <Point>
            اختر نوع الاشتراك واضغط على اشترك الآن. (ملاحظة لمعرفة امتيازات كل
            اشتراك وان كان مجاني او مدفوع اضغط على المزيد من المعلومات أسفل نوع
            الاشتراك)
          </Point>
          <Point>الموافقة على سياسية الخصوصية واتفاقية الاستخدام.</Point>
          <Point>اضغط على إنشاء حساب.</Point>
        </OrderedList>
        <SubTitle>طريقة تسجيل شركة</SubTitle>
        <OrderedList type="1">
          <Point>من الصفحة الرئيسية في رأس الصفحة الضغط على اشترك الآن.</Point>
          <Point> ستظهر صفحة التسجيل حدد نوع التخصص القانوني شركة.</Point>
          <Point>ادخل باقي المعلومات المطلوبة. </Point>
          <Point>
            اختر نوع الاشتراك واضغط على اشترك الآن. (ملاحظة لمعرفة امتيازات كل
            اشتراك وان كان مجاني او مدفوع اضغط على المزيد من المعلومات أسفل نوع
            الاشتراك){' '}
          </Point>
          <Point> الموافقة على سياسية الخصوصية واتفاقية الاستخدام.</Point>

          <Point>اضغط على إنشاء حساب.</Point>
        </OrderedList>
        <SubTitle> تسجيل دخول لحسابك</SubTitle>
        <OrderedList type="1">
          <Point>من الصفحة الرئيسية اضغط على اشترك الآن. </Point>
          <Point> في راس الصفحة باللون البرتقالي اضغط على تسجيل دخول.</Point>
          <Point>
            من صفحة تسجيل الدخول اختر افراد ان كنت تريد تسجيل الدخول لحساب محامي
            او محامي شرعي او محكم او خبير، او اختر شركة ان كنت تريد تسجيل الدخول
            لحساب شركة.
          </Point>
          <Point>ادخل البريد الإلكتروني كاسم مستخدم وكلمة السر.</Point>
          <Point> اضغط على تسجيل دخول.</Point>

          <Point>
            ستنتقل تلقائيا للصفحة الرئيسية، منها اضغط على رمز الشخصية الذي ظهر
            بدل خيار اشترك الآن، في أعلى يمين الصفحة
          </Point>
          <Point>ستنتقل تلقائيا الى صفحة الحساب.</Point>
          <PointContent>
            ملاحظة يمكنك ربط حسابك على عدل بحسابك على google او facebook وتسجيل
            الدخول بواسطتهم.
          </PointContent>
          <PointContent>
            ملاحظة من خيار عدل ملفك الآن تستطيع من خلاله تعديل عنوانك بريدك
            الالكتروني رقم الهاتف والتخصصات القانونية.
          </PointContent>
        </OrderedList>
        <Title>طلب تأكيد التخصص</Title>
        <PointContent>
          يتم تأكيد التخصص من قبل إدارة محرك البحث عدل للمحامي او مقدم الخدمات
          القانونية بناء على تقديمة ما يثبت انه عمل على التخصص الذي حدده في عدد
          من القضايا او المشاريع، وليتم تأكيد التخصص من قبل محرك عدل لا بد من
          تزويدنا بقائمة تحتوي على ما لا يقل عن ارقام عشر قضايا (رقم الأساس
          والمحكمة) او مشاريع عمل عليها مقدم الخدمة القانونية من نفس التخصص
          المراد تأكيده.
        </PointContent>
        <PointContent>
          طريقة تزويدنا بالقائمة المذكورة عن طريق التطبيق هي:
        </PointContent>
        <PointContent>
          بعد تحديد التخصصات وحفظها يظهر خيار بجانب اسم التخصص المختار باسم "أضف
          وثائق".{' '}
        </PointContent>
        <OrderedList type="1">
          <Point>اضغط على أضف وثائق.</Point>
          <Point> ستظهر مربع منبثق اختر منه أضف وثيقة اضغط عليه.</Point>
          <Point> سيظهر لك خيار التقط صورة او اختر من الاستديو.</Point>
          <Point>عندما يظهر اسم الصورة او الملف في المربع اضغط على حفظ. </Point>
          <Point>ستظهر بجانب اسم التخصص عبارة قيد الاعتماد.</Point>

          <PointContent>
            سيتم مراجعة الوثيقة المقدمة وبناء عليه سيتم تأكيد التخصص او رفض
            التأكيد.
          </PointContent>
          <PointContent>
            عند اعتماد طلب التأكيد سيظهر بجانب اسم التخصص كلمة "معتمدة" وفي هذه
            الحالة سيظهر اسم مقدم الخدمة القانونية عند البحث عن التخصص بواسطة
            البحث المتقدم عند تفعيل خيار اظهار النتائج التي تم التأكد من تخصصها.
          </PointContent>
        </OrderedList>
      </Section>
      <Section>
        <Title>تعديل نوع الاشتراك</Title>
        <Text>
          تستطيع تعديل نوع الاشتراك من داخل حسابك الشخصي باختيار نوع الاشتراك
          الجديد في أي وقت، من مربع إدخال خاص.
        </Text>
        <Text>
          ملاحظة: في حال رفعت من نوع الاشتراك فإن التعديلات تظهر على الحساب بشكل
          فوري، أما إن طلبت الانتقال من اشتراك اعلى الى اشتراك اقل فإن التعديلات
          تبدل من تاريخ انتهاء الاشتراك الاول.
        </Text>
        <SubTitle>طلب الدعم الفني</SubTitle>
        <Text>
          تستطيع طلب الدعم الفني عن طريق الصفحة الرئيسية بالضغط على تواصل معنا
        </Text>
        <Text>
          او عن طريق الوتساب على الرابط (
          <a
            href={
              supportNumber
                ? `https://wsend.co/${supportNumber}`
                : 'https://wsend.co/962799454539'
            }>
            اضغط هنا
          </a>
          )
        </Text>
      </Section>
    </GuideContainer>
  );
};

export default Guide;

const GuideContainer = styled.div`
  background-color: ${Colors.lightBlueBg};
  direction: rtl;
  height: 100%;
  padding-bottom: 100px;
`;
const Header = styled.div`
  background-image: url(${BackgroundImg});
  /* border-bottom-left-radius: 40px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 75px;
  height: 550px;
  width: 100%;
  margin-bottom: 70px;
  margin-top: -145px;
  position: relative;
  @media (max-width: 1250px) {
    background-position: center 100px;
  }
  @media (max-width: 768px) {
    background-size: contain;
    background-position: center 140px;
    margin-bottom: 0px;
    height: 550px;
  }
  @media (max-width: 600px) {
    height: 350px;
  }
`;
export const Section = styled.div`
  margin: 30px 100px;
  padding: 60px 30px;
  background-color: ${Colors.white};
  border-radius: 5px;
  box-shadow: 3px 3px ${Colors.shadow};
  @media (max-width: 768px) {
    width: 95%;
    margin: 30px auto;
    padding: 60px 20px;
  }
`;
export const Title = styled.div`
  color: ${Colors.orange};
  margin-bottom: 20px;
  font-family: ${Fonts.regular};
  font-size: 20px;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  font-family: ${Fonts.regular};
`;
export const Text = styled.div`
  margin-bottom: 10px;
  font-family: ${Fonts.regular};
  font-size: 16px;
`;
const OrderedList = styled.ol``;
const Point = styled.li`
  font-family: ${Fonts.regular};
  margin-bottom: 7px;
  font-size: 16px;
`;
const PointContent = styled.div`
  font-family: ${Fonts.regular};
  margin-bottom: 7px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2% 6%;
`;
