import gql from 'graphql-tag';

export const FORGET_PASSWORD = gql`
  mutation forgetPassword($email: String!) {
    forgetPassword(email: $email)
  }
`;
export const FORGET_PASSWORD_COMPANY = gql`
  mutation companyForgetPassword($email: String!) {
    companyForgetPassword(email: $email)
  }
`;
