import {
  ActionTypes,
  SET_TOKEN,
  UPDATE_USER,
  LOGIN,
  LOGOUT,
  SET_USER_TYPE,
  LawyerType,
  SET_DEVICE_ID,
  Account,
  SET_SELECTED_COUNTRY,
  SET_COUNTRIES,
  Country,
} from './types';

export const setToken = (token: string): ActionTypes => ({
  type: SET_TOKEN,
  payload: token,
});

export const setDeviceId = (deviceId: string): ActionTypes => ({
  type: SET_DEVICE_ID,
  payload: deviceId,
});

export const setSelectedCountry = (country: {
  id: string | null;
  isoCode: string | null;
  supportNumber: string | null;
  currency: string | null;
}): ActionTypes => ({
  type: SET_SELECTED_COUNTRY,
  payload: {
    id: country.id,
    isoCode: country.isoCode,
    supportNumber: country.supportNumber,
    currency: country.currency,
  },
});

export const updateUser = (user: Account): ActionTypes => ({
  type: UPDATE_USER,
  payload: user,
});

export const logIn = (): ActionTypes => ({
  type: LOGIN,
});
export const logoutUser = (): ActionTypes => ({
  type: LOGOUT,
});
export const setUserTypeRedux = (userType: LawyerType): ActionTypes => ({
  type: SET_USER_TYPE,
  payload: userType,
});

export const setCountries = (countries: Country[]): ActionTypes => ({
  type: SET_COUNTRIES,
  payload: countries,
});
