import React from 'react';
import {
  CustomButton,
  CustomInput,
  CustomText,
} from '../../../Components/Common';
import {TextRow} from '../../Blog/BlogDetails';
import Colors from '../../Theme/Colors';
import {
  ButtonText,
  MessageText,
  ProfileTitle,
  StyledRow,
  TextRowText,
} from '../SearchLawyers';
import {useFormik} from 'formik';
import {useMutation} from '@apollo/client';
import {SUGGESTION} from '../GraphQl/Home';
import * as Yup from 'yup';
import {Alert} from 'rsuite';
import i18n from '../../../I18next/I18n';
import styled from 'styled-components';

const Suggestions: React.FC = () => {
  const validationSchema = Yup.object().shape({
    suggestion: Yup.string().required('مطلوب'),
    email: Yup.string().required('مطلوب').email('البريد الالكتروني خاطئ'),
    phoneNumber: Yup.string().required('يجب ادخال رقم الهاتف'),
    name: Yup.string().required('مطلوب'),
  });
  const [suggestion, {loading}] = useMutation<{
    createSuggestion: {
      id: string;
      suggestion: string;
    };
  }>(SUGGESTION);

  const inputPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    // @ts-ignore
    if (e.target.value === '' || re.test(e.target.value)) {
      // @ts-ignore
      setFieldValue('phoneNumber', e.target.value);
    }
  };

  const {
    values,
    resetForm,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      suggestion: '',
      email: '',
      phoneNumber: '',
      name: '',
    },

    onSubmit: async submittedValues => {
      try {
        const {data} = await suggestion({
          variables: {
            suggestion: submittedValues.suggestion,
            email: submittedValues.email,
            phoneNumber: submittedValues.phoneNumber,
            name: submittedValues.name,
          },
        });
        if (data?.createSuggestion.id) {
          Alert.success('تم الارسال بنجاح');
          resetForm();
        }
      } catch (error) {
        Alert.error(i18n.t('Something went wrong please try again later'));
      }
    },
    validationSchema,
  });
  return (
    <>
      <StyledRow>
        <ProfileTitle color={Colors.orange} size={20}>
          قدم اقتراح/ طلب ترشيح
        </ProfileTitle>
      </StyledRow>
      <TextRow>
        <TextRowText weight={'400'}>
          ان لم تجد ما كنت تبحث عنه اقترح اضافته او اطلب منا ترشيح محامي في
          موضوع قضيتك.
        </TextRowText>
      </TextRow>
      <StyledRow padding={'10px 40px 10px'}>
        <InputWrapper>
          <StyledInput
            placeholder={'الاسم'}
            name="name"
            onChange={handleChange}
            value={values.name}
            error={!!(errors.name && touched.name)}
          />
          {!!(errors.name && touched.name) && (
            <>
              <ErrorText size={10}>
                {errors.name && touched.name && errors.name}
              </ErrorText>
            </>
          )}
        </InputWrapper>
      </StyledRow>
      <StyledRow padding={'10px 40px 10px'}>
        <InputWrapper>
          <StyledInput
            placeholder={'البريد الإلكتروني'}
            name="email"
            onChange={handleChange}
            value={values.email}
            error={!!(errors.email && touched.email)}
          />
          {!!(errors.email && touched.email) && (
            <>
              <ErrorText size={10}>
                {errors.email && touched.email && errors.email}
              </ErrorText>
            </>
          )}
        </InputWrapper>
      </StyledRow>
      <StyledRow padding={'10px 40px 10px'}>
        <InputWrapper>
          <StyledInput
            placeholder={'رقم الهاتف'}
            name="phoneNumber"
            onChange={inputPhoneNumber}
            value={values.phoneNumber}
            error={!!(errors.phoneNumber && touched.phoneNumber)}
          />
          {!!(errors.phoneNumber && touched.phoneNumber) && (
            <>
              <ErrorText size={10}>
                {errors.phoneNumber &&
                  touched.phoneNumber &&
                  errors.phoneNumber}
              </ErrorText>
            </>
          )}
        </InputWrapper>
      </StyledRow>
      <StyledRow>
        <MessageText
          placeholder="‫مقترحات‬"
          name={'suggestion'}
          value={values.suggestion}
          onChange={handleChange}
          error={!!(errors.suggestion && touched.suggestion)}
        />
      </StyledRow>
      <StyledRow>
        <CustomButton
          height={'33px'}
          width={'110px'}
          orangeBg
          onClick={() => handleSubmit()}
          disabled={loading}>
          <ButtonText color={Colors.white} weight={'400'}>
            ‫أرسل‬
          </ButtonText>
        </CustomButton>
      </StyledRow>
    </>
  );
};

export default Suggestions;

export const StyledInput = styled(CustomInput)`
  direction: rtl;
  outline: none;
  width: 50%;
  @media screen and (max-width: 395px) {
    font-size: x-small;
  }

  padding: 5px 10px;

  @media (max-width: 1000px) {
    width: 80%;
  }
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const ErrorText = styled(CustomText)`
  text-align: right;
  font-size: 10;
  margin: auto;
  padding-top: 10px;
  color: red;
  margin-right: 7px;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
