import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {CustomText, RowExtended} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import Dropzone from 'react-dropzone';
import {UPLOAD_URI} from '../../../Configs';
import axios from 'axios';
import {RootState} from '../../../Reducer';
import {Company, ConfigsReducer, AccountReducer} from '../../../Action/types';
import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_COMPANY} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {Alert} from 'rsuite';
import {updateUser} from '../../../Action';
import {RiCloseCircleFill} from 'react-icons/ri';
import {
  ResetPasswordModal,
  ResetPasswordModalBody,
  ResetPasswordTitle,
  Save,
  SaveText,
} from './Certificates';
import ModalFooter from 'rsuite/lib/Modal/ModalFooter';
import {CancelIcon} from '../../User/ProfilePreview';
import i18n from '../../../I18next/I18n';

const Albums = () => {
  const [certificateModal, setCertificateModalModal] = useState(false);
  const [removeId, setRemoveId] = useState<number | undefined>();

  const handledCertificateModal = (index?: number | undefined) => {
    setCertificateModalModal(!certificateModal);
    setRemoveId(index);
  };

  const dispatch = useDispatch();
  const {token} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  const [updateCompany] = useMutation<{
    updateCompany: Company;
  }>(UPDATE_COMPANY);

  const [albumsArray, setAlbumsArray] = useState<any>([]);

  const uploadFiles = async (file: any) => {
    const body = new FormData();
    body.append('file', file[0]);
    axios({
      method: 'post',
      url: `${UPLOAD_URI}`,
      data: body,
      headers: {
        'Accept-Content': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        API_KEY: 'TXlMZWdhaWxJREFQSUtFWQ==',
      },
    })
      .then(async res => {
        albumsArray.push(res?.data?.id);
        const variables = {photos: albumsArray};
        if (albumsArray.length <= 20) {
          try {
            const {data} = await updateCompany({
              variables,
            });
            if (data?.updateCompany) {
              data?.updateCompany &&
                dispatch(updateUser({...data?.updateCompany}));
              Alert.success('تمت العملية بنجاح');
            }
          } catch (error) {
            Alert.error(i18n.t('Something went wrong please try again later'));
          }
        } else {
          Alert.error(i18n.t('photos array must have 20 items at most'));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (user?.photos) {
      albumsArray.push(
        ...(user?.photos && user?.photos?.map(item => item?.id)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveField = async (index: any) => {
    user?.photos && user?.photos.splice(index, 1);
    handledCertificateModal();
    const newData =
      user?.photos &&
      user?.photos?.map((item: any) => {
        return item?.id;
      });
    try {
      const {data} = await updateCompany({
        variables: {photos: newData},
      });
      if (data?.updateCompany) {
        dispatch(
          updateUser({
            ...user,
            photos: data?.updateCompany?.photos,
          }),
        );
        setAlbumsArray(user?.photos && user?.photos?.map(item => item?.id));
        Alert.success('تمت العملية بنجاح');
      }
    } catch (err) {
      console.log(err);
      Alert.error(i18n.t('Something went wrong please try again later'));
    }
  };

  return (
    <EditAccountWrapper>
      <RowExtended>
        <ProfileTitle> الألبومات</ProfileTitle>

        <Dropzone
          onDrop={acceptedFiles => {
            uploadFiles(acceptedFiles);
          }}
          multiple={false}>
          {({getRootProps, getInputProps}) => (
            <Col {...getRootProps()}>
              <input {...getInputProps()} />

              <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
              <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
                أضف صورة جديدة
              </AddSectionText>
            </Col>
          )}
        </Dropzone>
      </RowExtended>
      {user?.photos && user?.photos?.length > 0 && (
        <PhotosRow>
          {user?.photos &&
            user?.photos.map((photo, index) => {
              return (
                <PhotosWapper>
                  <Link
                    href={`${UPLOAD_URI}/image/${photo?.filename}`}
                    target="_blank">
                    <ProfilePic
                      src={`${UPLOAD_URI}/image/${photo?.filename}`}
                    />
                  </Link>
                  <RemoveButton
                    size={18}
                    color={Colors.red}
                    onClick={() => {
                      handledCertificateModal(index);
                    }}
                  />
                </PhotosWapper>
              );
            })}
        </PhotosRow>
      )}
      <ResetPasswordModal
        show={certificateModal}
        onHide={() => handledCertificateModal()}>
        <ResetPasswordModalBody>
          <CancelIcon onClick={() => handledCertificateModal()}>
            <svg
              focusable="false"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
            </svg>
          </CancelIcon>
          <ResetPasswordTitle>هل أنت متأكد؟</ResetPasswordTitle>
        </ResetPasswordModalBody>
        <ModalFooter>
          <Save
            orangeBg
            onClick={() => {
              handleRemoveField(removeId);
            }}>
            <SaveText color={Colors.white} size={23}>
              حذف
            </SaveText>
          </Save>
        </ModalFooter>
      </ResetPasswordModal>
    </EditAccountWrapper>
  );
};

export default Albums;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const Col = styled.div`
  display: flex;
  justify-content: space-between;
  /* width: 250px; */
  /* margin-right: 30px; */
  cursor: pointer;

  @media (max-width: 561px) {
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
`;

const AddSectionText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 10px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
`;

const ProfileTitle = styled(CustomText)`
  width: 400px;
`;

export const Link = styled.a`
  text-decoration: none;
  margin-right: 5px;
`;

export const ProfilePic = styled.img<{width?: string; height?: string}>`
  border-radius: 10px;
  width: ${props => (props.width ? `${props.width}` : '55px')};
  height: ${props => (props.height ? `${props.height}` : '55px')};
  margin-top: 10px;
  margin-right: 5px;
  &:hover {
    opacity: 0.8;
  }
`;
export const PhotosRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 25px;
  margin-bottom: 20px;
`;
const RemoveButton = styled(RiCloseCircleFill)`
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 1px;
  &:hover {
    opacity: 0.7;
  }
`;
export const PhotosWapper = styled.div`
  position: relative;
`;
