import React, {useState} from 'react';
import styled from 'styled-components';
import {CustomButton, CustomText} from '../Components/Common';
import Colors from './Theme/Colors';
import {IoIosArrowBack} from 'react-icons/io';
import {useHistory, useParams} from 'react-router-dom';
import {BsCheck} from 'react-icons/bs';
import {BsX} from 'react-icons/bs';
import {FIND_MEMBERSHIPS} from './Auth/GraphQl/SignUp';
import {
  AccountReducer,
  CompanyPrivileges,
  ConfigsReducer,
  LawyerPrivileges,
  LawyerType,
  Membership,
  PrivilegesTranslate,
  PrivilegesTranslateType,
} from '../Action/types';
import {useApolloClient, useQuery} from '@apollo/client';
import {RootState} from '../Reducer';
import {useSelector} from 'react-redux';
import {MakePaymentAction} from '../PaymentHelper';

interface SignUpProps {
  search: string;
  subType: string;
}

const Memberships = () => {
  const params: SignUpProps = useParams();

  const {user, userType: type} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;
  const {token, selectedCountry} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  const history = useHistory();
  const [userType, setUserType] = useState<'lawyer' | 'company'>(
    params?.subType
      ? (params?.subType as 'lawyer' | 'company')
      : type === LawyerType.company
      ? 'company'
      : 'lawyer',
  );

  const client = useApolloClient();

  const {data: memberships, loading: membershipsLoading} = useQuery<{
    findAllMemberships: Membership[];
  }>(FIND_MEMBERSHIPS, {variables: {id: user?.id}, fetchPolicy: 'no-cache'});

  const handleMakePayment = (membership_id: string, cart_amount: number) => {
    MakePaymentAction({
      client,
      accountToken: token,
      input: {
        cart_currency: selectedCountry.currency || 'JOD',
        dynamic_redirection: 'profile',
        membership_id,
        cart_amount: cart_amount.toString(),
      },
    });
  };

  return (
    <Wrapper>
      {params?.search === 'fromSignup' && (
        <GoBackWrapper>
          <Arrow />
          <Arrow />
          <GoBackToSignUp
            onClick={() => {
              history.push('/sign-up');
            }}>
            العودة الى صفحة التسجيل{' '}
          </GoBackToSignUp>
        </GoBackWrapper>
      )}
      <SubWrapper>
        <HeaderTextWrapper>
          <MembershipTitle size={30}>
            عروض إشتراكات {userType === 'lawyer' ? 'المحامين' : 'الشركات'}
          </MembershipTitle>
          <Description size={20}>
            {' '}
            إن كنت {userType === 'lawyer' ? 'محامي' : 'صاحب شركة'} وترغب في
            إشهار تخصصاتك القانونية اختر احد عروض التسجيل ادناه لتساعد الباحثين
            عن الخدمات القانونية المتخصصة من التواصل معك
          </Description>
        </HeaderTextWrapper>
      </SubWrapper>
      {!user?.id && (
        <TabsContainer>
          <Tab
            current={userType === 'company'}
            onClick={() => {
              setUserType('company');
            }}>
            <TabTitle size={25}>شركة</TabTitle>
          </Tab>
          <LawyerTab
            current={userType === 'lawyer'}
            onClick={() => {
              setUserType('lawyer');
            }}>
            <TabTitle size={25}>أفراد</TabTitle>
          </LawyerTab>
        </TabsContainer>
      )}

      <InnerContainer>
        <PreviewWrapper>
          {!membershipsLoading &&
            memberships &&
            memberships?.findAllMemberships?.map((membership, index) => {
              return (
                <>
                  <MiddleSection>
                    <DescriptionWrapper>
                      <Title size={25}>{membership.name}</Title>
                      {/* <MembershipsDescription size={13}>
                        في كل مجال هناك من هو مميز بشكل خاص هذا الحساب يناسب
                        المتميزين في مجالهم، هذا الإشتراك يوفر كافة الميزات التي
                        يقدمها . لإظهار تميزك
                      </MembershipsDescription> */}
                      {membership?.cost &&
                      membership?.type >= (user?.membership?.type || 0) ? (
                        <Title
                          size={25}
                          lineThrough={
                            !!membership?.new_cost &&
                            membership?.cost !== membership?.new_cost
                          }>
                          {`${membership?.cost} ${
                            selectedCountry.currency?.toLowerCase() === 'jod'
                              ? 'دينار'
                              : selectedCountry.currency?.toLowerCase() ===
                                'usd'
                              ? 'دولار'
                              : selectedCountry.currency
                          } سنوياً`}
                        </Title>
                      ) : (
                        <Title size={25}>لا يوجد اشتراك</Title>
                      )}
                      {membership?.new_cost &&
                        membership?.cost &&
                        membership?.cost !== membership?.new_cost &&
                        membership?.type >= (user?.membership?.type || 0) && (
                          <Title size={25}>
                            {`${membership?.new_cost?.toFixed(2)} ${
                              selectedCountry.currency?.toLowerCase() === 'jod'
                                ? 'دينار'
                                : selectedCountry.currency?.toLowerCase() ===
                                  'usd'
                                ? 'دولار'
                                : selectedCountry.currency
                            } سنوياً`}
                          </Title>
                        )}
                    </DescriptionWrapper>
                    {params?.search !== 'fromSignup' &&
                      membership?.type !== 0 &&
                      membership?.type >= (user?.membership?.type || 0) && (
                        <CustomButton
                          orangeBg
                          type="submit"
                          width={'100%'}
                          onClick={() => {
                            handleMakePayment(
                              membership?.id,
                              membership?.new_cost
                                ? membership?.new_cost
                                : membership?.cost,
                            );
                          }}>
                          <RegisterButtonText color={Colors.white} size={13}>
                            {membership?.type === user?.membership?.type
                              ? 'جدد اشتراكك'
                              : 'سجل'}
                          </RegisterButtonText>{' '}
                        </CustomButton>
                      )}
                    <RowsContainer current={params?.search === 'fromSignup'}>
                      {(() => {
                        const privileges =
                          userType === 'company'
                            ? membership?.company_privileges
                            : membership?.lawyer_privileges;
                        return Object.keys(privileges).map(key => {
                          const privilege =
                            privileges[
                              key as keyof (
                                | LawyerPrivileges
                                | CompanyPrivileges
                              )
                            ];

                          return (
                            <RowWrapper>
                              <Specialties size={13}>
                                {
                                  PrivilegesTranslate[
                                    key as keyof PrivilegesTranslateType
                                  ]
                                }
                              </Specialties>
                              {typeof privilege === 'number' ? (
                                <SpecialtiesNumber size={13}>
                                  {privilege}
                                </SpecialtiesNumber>
                              ) : privilege ? (
                                <BsCheck
                                  size={13}
                                  color={Colors.buttonColor2}
                                />
                              ) : (
                                <BsX size={13} color={Colors.buttonColor2} />
                              )}
                            </RowWrapper>
                          );
                        });
                      })()}
                    </RowsContainer>
                  </MiddleSection>
                  {index !== memberships?.findAllMemberships?.length - 1 && (
                    <Divider />
                  )}
                </>
              );
            })}
        </PreviewWrapper>
      </InnerContainer>
    </Wrapper>
  );
};

export default Memberships;

export const Wrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
  padding-top: 50px;
  padding-bottom: 80px;
`;
export const SubWrapper = styled.div`
  background-color: ${Colors.lightBlueBg};
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;
export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;
  width: 70%;
  @media (max-width: 500px) {
    text-align: center;
  }
  @media (max-width: 340px) {
    width: 80%;
  }
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1070px) {
    align-items: center;
  }
`;
export const Title = styled(CustomText)<{
  lineThrough?: boolean;
}>`
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: ${({lineThrough}) =>
    lineThrough ? 'line-through' : 'none'};
  @media (max-width: 1027px) {
    font-size: 22px;
  }
  @media (max-width: 960px) {
    font-size: 20px;
  }
  @media (max-width: 415px) {
    font-size: 18px;
  }
`;
// const MembershipsDescription = styled(CustomText)`
//   @media (max-width: 1027px) {
//     font-size: 12px;
//   }
//   @media (max-width: 1070px) {
//     text-align: center;
//   }
//   @media (max-width: 960px) {
//     font-size: 11px;
//   }
//   @media (max-width: 768px) {
//     width: 80%;
//   }
//   @media (max-width: 415px) {
//     width: 100%;
//   }
//   @media (max-width: 415px) {
//     font-size: 10px;
//   }
// `;
const MembershipTitle = styled(CustomText)`
  @media (max-width: 960px) {
    font-size: 25px;
  }
  @media (max-width: 665px) {
    font-size: 22px;
  }
  @media (max-width: 415px) {
    font-size: 20px;
  }
`;
export const Description = styled(CustomText)`
  padding-top: 10px;
  padding-bottom: 30px;
  @media (max-width: 960px) {
    font-size: 15px;
  }
  @media (max-width: 665px) {
    font-size: 13px;
  }
  @media (max-width: 415px) {
    font-size: 11px;
  }
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
export const Divider = styled.div`
  border-right: 1px solid ${Colors.borderGray};
  margin-top: -20px;
  margin-bottom: -20px;
  @media (max-width: 768px) {
    border-right: none;
    border-top: 1px solid ${Colors.borderGray};
    height: 20px;
    margin-top: 50px;
    margin-bottom: 0;
  }
`;
export const PreviewWrapper = styled.div`
  margin: 50px 0;
  width: 70%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-radius: 5px;
  background-color: ${Colors.white};
  box-shadow: 0px 0px 1px 1px ${Colors.borderGray};
  padding: 20px 40px;
  @media (max-width: 878px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 400px) {
    padding: 20px;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35%;
  margin-right: -20px;
  margin-left: 20px;
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  direction: rtl;
  margin-right: 25px;
  @media (max-width: 1070px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 0 40px;
  }
`;
export const MiddleSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 40%;
  direction: rtl;
  margin-right: 30px;
  margin-left: 35px;
  @media (max-width: 1070px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;
export const RightSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  direction: rtl;
  margin-right: -10px;
  margin-left: 35px;
  @media (max-width: 1070px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const RowsContainer = styled.div<{
  current?: boolean;
}>`
  margin-top: ${props => (props.current ? '0px' : '23px')};
  @media (max-width: 1070px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
`;
const Specialties = styled(CustomText)`
  max-width: 88%;
  @media (max-width: 415px) {
    font-size: 12px;
  }
`;
const SpecialtiesNumber = styled(CustomText)`
  margin-top: 1.3px;
  @media (max-width: 768px) {
    margin-left: 4px;
  }
`;
const RegisterButtonText = styled(CustomText)`
  @media (max-width: 415px) {
    font-size: 12px;
  }
`;
const TabTitle = styled(CustomText)`
  text-align: center;
  @media (max-width: 960px) {
    font-size: 20px;
  }
  @media (max-width: 665px) {
    font-size: 18px;
  }
  @media (max-width: 614px) {
    font-size: 16px;
  }
  @media (max-width: 415px) {
    font-size: 14px;
  }
`;
const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Tab = styled.div<{
  current?: boolean;
}>`
  border-bottom: 2px solid
    ${props => (props.current ? Colors.softBlue : Colors.OffWhite)};
  padding: 0px 35px 5px;
  cursor: pointer;
  width: 35%;
  @media (max-width: 878px) {
    width: 40%;
  }
  @media (max-width: 377px) {
    padding: 0px 10px 5px;
  }
`;
const LawyerTab = styled(Tab)`
  @media (max-width: 565px) {
    padding: 13px 35px 13px;
  }
`;
const GoBackWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin-left: 100px;
  margin-top: 23px;
  @media (max-width: 900px) {
    margin-left: 70px;
  }
  @media (max-width: 750px) {
    margin-left: 50px;
  }
  @media (max-width: 650px) {
    margin-left: 30px;
  }
  @media (max-width: 450px) {
    margin-top: 0px;
  }
`;
const GoBackToSignUp = styled(CustomText)`
  cursor: pointer;
  color: ${Colors.softBlue};
  font-size: 20px;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
  @media (max-width: 750px) {
    font-size: 16px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  @media (max-width: 580px) {
    visibility: hidden;
    ::after {
      content: 'العودة';
      visibility: visible;
    }
  }
`;
const Arrow = styled(IoIosArrowBack)`
  margin-top: 2.5px;
  margin-left: -12px;
  color: ${Colors.softBlue};
  font-size: 20px;
  @media (max-width: 1000px) {
    font-size: 18px;
    margin-top: 1.5px;
  }
  @media (max-width: 750px) {
    font-size: 16px;
    margin-top: 0.5px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    margin-left: -9px;
    margin-top: 1.5px;
  }
`;
