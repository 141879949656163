import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CustomButton,
  CustomInput,
  CustomText,
  // Row,
  RowExtended,
  ButtonsRow,
  Label,
  IconsDiv,
  Detail,
  DetailsText,
  A,
} from '../../../Components/Common';
import Colors from '../../Theme/Colors';
import {AiOutlinePlusCircle} from 'react-icons/ai';
import {MdDelete} from 'react-icons/md';
import {UPDATE_LAWYER} from '../GraphQL/EditAccount';
import {useMutation} from '@apollo/client';
import {
  Account,
  AccountReducer,
  LawyerType,
  WorkExperience,
} from '../../../Action/types';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from '../../../Action';
import {RootState} from '../../../Reducer';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Alert} from 'rsuite';
import {Loader} from 'rsuite';
import {LoaderContainer} from './Certificates';
import {useHistory} from 'react-router-dom';
import i18n from '../../../I18next/I18n';

const Skills = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [add, setAdd] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const [more, showMore] = useState<string | number>('');
  const [less, showLess] = useState<string | number>('');

  const {user} = useSelector<RootState>(
    state => state.Account,
  ) as AccountReducer;

  let work_experience: [WorkExperience?] | undefined;
  if (user && Object.keys(user).length !== 0) {
    ({work_experience} = user);
  }

  const [updateLawyer, {loading: updateLoading}] = useMutation<{
    updateLawyer: Account;
  }>(UPDATE_LAWYER);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    years: Yup.string().required(),
  });

  // console.log('user', work_experience);

  const {values, errors, touched, handleChange, handleSubmit, resetForm} =
    useFormik({
      initialValues: {
        experinces: work_experience ? work_experience : [],
        experince: '',
        name: '',
        years: '',
      },
      onSubmit: async submittedValues => {
        const allExperiences: [(WorkExperience | undefined)?] = work_experience
          ? work_experience
          : [];
        const variables: WorkExperience = {
          name: submittedValues?.name,
          years: submittedValues?.years.toString(),
        };

        if (Object.keys(variables).length !== 0) {
          allExperiences.push(variables);
        }

        try {
          const {data} = await updateLawyer({
            variables: {
              work_experience: allExperiences,
            },
          });
          if (data?.updateLawyer) {
            dispatch(
              updateUser({
                ...user,
                work_experience: data?.updateLawyer.work_experience,
              }),
            );
            setAdd(false);
            setShow(true);
            resetForm();
            Alert.success('تمت العملية بنجاح');
          }
        } catch (err) {
          Alert.error(i18n.t('Something went wrong please try again later'));
        }
      },
      validationSchema: validationSchema,
    });

  const handleRemoveField = async (index: number) => {
    work_experience && work_experience.splice(index, 1);
    const newData =
      work_experience &&
      work_experience?.map(item => {
        return {name: item?.name, years: item?.years};
      });
    const {data} = await updateLawyer({
      variables: {
        work_experience: newData,
      },
    });
    if (data?.updateLawyer) {
      dispatch(
        updateUser({
          ...user,
          work_experience: data?.updateLawyer?.work_experience,
        }),
      );
      Alert.success('تمت العملية بنجاح');
    }
  };
  const notAvailable = () => {
    history.push('/Memberships');
    Alert.info('عليك ترقية اشتراكك لكي تتمكن من اظهار هذا الحقل بالبحث', 3000);
  };

  return (
    <EditAccountWrapper>
      <RowExtended>
        <ProfileTitle> الخبرات العملية</ProfileTitle>
        {show && (
          <Col
            onClick={() => {
              if (
                !user?.membership?.lawyer_privileges?.training_courses &&
                user?.type !== LawyerType.company
              ) {
                return notAvailable();
              }
              setAdd(true);
              setShow(false);
            }}>
            <AiOutlinePlusCircle color={Colors.seaBlue} size={26} />
            <AddSectionText size={17} color={Colors.seaBlue} weight={'400'}>
              أضف خبرة جديدة
            </AddSectionText>
          </Col>
        )}
      </RowExtended>
      <div>
        {(work_experience || []).map((field, index) => {
          return (
            <div>
              <Label>
                <Detail noPointer>
                  <A>
                    <CustomText padding={'3px'}>{field?.name}</CustomText>
                  </A>
                  {more === index && field?.years && (
                    <DetailsText>
                      &#x2022; سنوات الخبرة: {field?.years}{' '}
                      {field?.years === '1'
                        ? ' سنة '
                        : field?.years === '2'
                        ? ' سنتين '
                        : ' سنوات '}
                    </DetailsText>
                  )}

                  {less === index ? (
                    <PointerText
                      padding={'3px'}
                      marginTop={'2px'}
                      size={13}
                      color={Colors.lightGray}
                      onClick={() => {
                        showMore('');
                        showLess('');
                      }}>
                      اقل ..
                    </PointerText>
                  ) : (
                    <PointerText
                      padding={'3px'}
                      marginTop={'2px'}
                      size={13}
                      color={Colors.lightGray}
                      onClick={() => {
                        showMore(index);
                        showLess(index);
                      }}>
                      المزيد ..
                    </PointerText>
                  )}
                </Detail>
                <IconsDiv>
                  <MdDelete
                    size={19}
                    color={Colors.red}
                    onClick={() => {
                      handleRemoveField(index);
                    }}
                  />
                </IconsDiv>
              </Label>
            </div>
          );
        })}
      </div>
      {add && (
        <div>
          <Inputs>
            <CustomInput
              placeholder={'اسم العمل'}
              width={'100%'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
              error={!!(errors.name && touched.name)}
            />
            <CustomInput
              type={'number'}
              min="0"
              placeholder={'سنوات الخبرة'}
              width={'100%'}
              name={'years'}
              value={values.years}
              onChange={handleChange}
              error={!!(errors.years && touched.years)}
            />
            {updateLoading ? (
              <LoaderContainer>
                <Loader />
              </LoaderContainer>
            ) : (
              <ButtonsRow>
                <CustomButton height={'33px'} width={'30%'} orangeBg>
                  <ButtonText
                    color={Colors.white}
                    weight={'400'}
                    onClick={() => {
                      handleSubmit();
                    }}>
                    حفظ
                  </ButtonText>
                </CustomButton>
                <CancelButton
                  height={'33px'}
                  bgColor={Colors.white}
                  withBorder
                  width={'30%'}>
                  <ButtonText
                    weight={'400'}
                    onClick={() => {
                      setAdd(false);
                      setShow(true);
                      resetForm();
                    }}>
                    إلغاء
                  </ButtonText>
                </CancelButton>
              </ButtonsRow>
            )}
          </Inputs>
        </div>
      )}
    </EditAccountWrapper>
  );
};

export default Skills;

const EditAccountWrapper = styled.div`
  margin: 0px 0 50px;
  width: 75%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  border-radius: 2px;
  background-color: ${Colors.white};
  box-shadow: 3px 3px 3px 0.1px ${Colors.borderGray};
  border: 0.5px solid ${Colors.OffWhite};

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const ProfileTitle = styled(CustomText)`
  width: 482px;
  /* @media (max-width: 884px) {
    width: 90%;
  } */
`;
const CancelButton = styled(CustomButton)`
  margin-right: 15px;
`;
const ButtonText = styled(CustomText)`
  margin-top: -3px;
`;
const Inputs = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding-right: 40px;
  padding-top: 20px;
`;
export const TextArea = styled.textarea`
  height: 150px;
  line-height: 60px;
  width: 100%;
  font-size: 16px;
  border-radius: 2.5px;
  border: 2px solid ${Colors.borderGray};
  outline: none;
  margin-top: 10px;
  font-weight: 400;
  &::placeholder {
    margin-top: 20px;
    padding-right: 20px;
    color: ${Colors.lightNavyBlue};
    font-size: 16px;
    font-weight: 400;
  }
`;
const Col = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 250px;
  margin-right: 30px;
  cursor: pointer;
  /* @media(max-width: 768px){
    justify-content: space-around;
  } */

  @media (max-width: 561px) {
    /* width: 70%; */
    justify-content: flex-start;
    margin-right: 0;
    margin-top: 5px;
  }
`;
const AddSectionText = styled(CustomText)`
  margin-top: 4px;
  margin-right: 15px;
  @media (max-width: 561px) {
    font-size: 15px;
  }
  /* margin-right: -50px; */
`;

const PointerText = styled(CustomText)`
  cursor: pointer;
`;
