import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {keyframes} from 'styled-components';
import {ConfigsReducer} from '../Action/types';
import {RootState} from '../Reducer';
import {CustomText} from './Common';
import {useHistory} from 'react-router-dom';
import Colors from '../Containers/Theme/Colors';
import {logoutUser, updateUser} from '../Action';
import Hamburger from './HamburgerMenu';
import {
  HeaderNavOption,
  HeaderLogo,
  HeaderNavOptionAnchorWrapper,
} from './Header';
import {useCookies} from 'react-cookie';
import Logo from '../Assets/Images/logo.png';
import CountryDropDown from './CountryDropDown';

const Menu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const close = () => setOpen(false);
  const [, , removeCookie] = useCookies(['rememberMeCookie']);
  const [random, setRandom] = useState<Boolean>(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const {loggedIn} = useSelector<RootState>(
    state => state.Configs,
  ) as ConfigsReducer;
  return (
    <MenuWrapper>
      <StyledMenuWrapper open={open} onClick={() => close()}>
        <StyledMenu open={open} onClick={e => e.stopPropagation()}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <CountryDropContainer>
              <CountryDropDown />
            </CountryDropContainer>
          </div>
          <MenuLogoWrapper>
            <StyledLink>
              <HeaderNavOption to="/">
                <HeaderLogo src={Logo} />
              </HeaderNavOption>
            </StyledLink>
          </MenuLogoWrapper>
          {loggedIn ? (
            <StyledLink
              onClick={() => {
                history.push('/my-account');
                close();
              }}>
              <HeaderNavOption to="/">
                <CustomText size={22}>الحساب</CustomText>
              </HeaderNavOption>
            </StyledLink>
          ) : (
            <StyledLink>
              <HeaderNavOption to="/login" onClick={() => close()}>
                <CustomText size={22}>تسجيل الدخول</CustomText>
              </HeaderNavOption>
            </StyledLink>
          )}

          <StyledLink onClick={() => close()}>
            <HeaderNavOption to="/">
              <CustomText size={22}>الرئيسية</CustomText>
            </HeaderNavOption>
          </StyledLink>
          <StyledLink onClick={() => close()}>
            <HeaderNavOption to="/guide">
              <CustomText size={22}>دليل الإستخدام</CustomText>
            </HeaderNavOption>
          </StyledLink>
          <StyledLink onClick={() => close()}>
            <HeaderNavOption to="/blog-listing">
              <CustomText size={22}>المجلة القانونية</CustomText>
            </HeaderNavOption>
          </StyledLink>
          <StyledLink onClick={() => close()}>
            <HeaderNavOptionAnchorWrapper
              href="http://mustsharcom.com/"
              target="_blank">
              <CustomText size={22}>المدونة</CustomText>
            </HeaderNavOptionAnchorWrapper>
          </StyledLink>
          <StyledLink onClick={() => close()}>
            <HeaderNavOption
              onClick={() => setRandom(!random)}
              to={{
                pathname: '/',
                state: {contactus: random},
              }}>
              <CustomText size={22}>تواصل معنا</CustomText>
            </HeaderNavOption>
          </StyledLink>

          {loggedIn ? (
            <StyledLink>
              <HeaderNavOption
                to="/"
                onClick={() => {
                  dispatch(logoutUser());
                  dispatch(updateUser({}));
                  removeCookie('rememberMeCookie');
                  history.push('/');
                  close();
                }}>
                <CustomText size={22}>تسجيل الخروج</CustomText>
              </HeaderNavOption>
            </StyledLink>
          ) : (
            <div></div>
          )}
        </StyledMenu>
      </StyledMenuWrapper>
      <Hamburger open={open} setOpen={setOpen} />
    </MenuWrapper>
  );
};

export default Menu;

const MenuWrapper = styled.div``;

const StyledMenuWrapper = styled.div<{open: boolean}>`
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  touch-action: none;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10rem 0;
  flex-direction: column;
  direction: rtl;
  display: ${({open}) => (open ? 'flex' : 'none')};
`;

const SlideInAnimation = keyframes`
 0% {
            transform: translateX(50px);
    opacity: 0;
  }
  100% {
            transform: translateX(0);
    opacity: 1;
  }
`;

const breatheAnimation = keyframes`
 0% {
    opacity: 0;
  }
  50% {
          
    opacity: 0.5;
  }
  100% {   
    opacity: 1;
      }
`;

const StyledMenu = styled.nav<{open: boolean}>`
  top: 0;
  right: 0;
  height: 100vh;
  width: 75%;
  position: fixed;
  touch-action: none;
  background-color: ${Colors.white};
  opacity: 1;
  padding: 10rem 0;
  padding-top: 1.875rem;
  flex-direction: column;
  direction: rtl;
  display: ${({open}) => (open ? 'flex' : 'none')};
  animation: ${SlideInAnimation} 0.3s ease-in both;
  animation-iteration-count: 1;
`;
const StyledLink = styled.a`
  padding: 0 2rem;
  color: ${Colors.black};
  text-decoration: none;
  margin-top: 30px;

  :hover {
    cursor: pointer;
    text-decoration: none;
    opacity: 0.7;
    transition: 0.3s;
  }
`;

export const MenuLogoWrapper = styled.div`
  animation: ${breatheAnimation} 0.8s ease-in both;
  animation-iteration-count: 1;
`;

export const CountryDropContainer = styled.div`
  direction: ltr;
  margin-left: 20px;
`;
